.news-article-testimonial {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 60px;
    border-bottom: 1px dashed $grey-alpha;
    .avatar {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center center;
        margin-bottom: 24px;
    }
    .desc {
        max-width: 440px;
        width: 100%;
        .desc-author {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 16px;
        }
        .desc-info {
            font-size: 14px;
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    @include breakpoint(sm) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .avatar {
            margin-right: 24px;
            margin-bottom: 0;
            flex-shrink: 0;
            flex-grow: 0;
        }
    }
    @include breakpoint(lg) {
        padding-top: 60px;
        padding-bottom: 60px;
        margin-bottom: 80px;
    }
}
