.more-episodes2 {
    background-color: $gray-dark2;
    padding-bottom: 60px;
    .card-group-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
    }
    .top-desc {
        text-align: center;
        max-width: 640px;
        margin: 0 auto;
        padding: 60px 20px 40px 20px;
        h2 {
            @extend .d2;
            color: $white;
            margin-bottom: 24px;
        }
        p {
            color: white;
            font-size: rem(18);
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .card {
        border-radius: 8px;
        flex: 100%;
        padding: 0;
        margin-bottom: 20px;
        background-color: #FFFFFF15;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            text-decoration: none;
        }
        .card-main-link {
            flex-grow: 1;
            &:hover, &:focus {
                background-color: #FFFFFF29;
            }
            &:active, &:active:focus {
                background-color: #FFFFFF39;
            }
        }
        .card-col-left {
            flex-grow: 1;
            flex-shrink: 1;
            padding-right: 24px;
        }
        .card-col-right {
            display: flex;
            align-items: center;
            flex-grow: 0;
            flex-shrink: 0;
            img {
                object-fit: cover;
                width: 60px;
                height: 60px;
                border-radius: 8px;
                max-width: none;
            }
        }
        .card-footer {
            padding: 16px 24px;
            border-top: 1px solid #FFFFFF1F;
            a {
                margin-right: 24px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .card-body {
            display: flex;
            align-items: center;
            padding: 24px;
            min-height: 100%;
            .card-title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-family: $font-title;
                font-size: 12px;
                color: $white;
                font-weight: 400;
                margin-bottom: 16px;
                line-height: initial;
                .card-title-tag {
                    font-family: $font-content;
                    font-size: 12px;
                    font-weight: 700;
                    color: $red;
                    background-color: $white;
                    padding: 4px 12px;
                    border-radius: 4px;
                    margin-top: 12px;
                }
            }
            .card-text {
                font-family: $font-title;
                font-size: 16px;
                font-weight: 500;
                color: $white;
                line-height: initial;
                p:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &.active-episode {
            background-color: transparent;
            border: 1px solid #ffffff1f;
            .card-main-link {
                &:hover, &:focus {
                    background-color: transparent;
                }
                &:active, &:active:focus {
                    background-color: transparent;
                }
            }
        }
    }
    @include breakpoint(sm) {
        .card {
            .card-body {
                .card-title {
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .card-title-tag {
                        margin-top: 0;
                        margin-left: 12px;
                    }
                }
            }
        }
    }
    @include breakpoint(md) {
        .card {
            flex: 0 0 calc(50% - 10px);
        }
    }
    @include breakpoint(lg) {
        padding-bottom: 120px;
        .top-desc {
            padding: 120px 20px 80px 20px;
        }
        .card {
            .card-col-right {
                img {
                    width: 140px;
                    height: 140px;
                }
            }
            .card-body {
                .card-title {
                    font-size: 14px;
                }
                .card-text {
                    font-size: 24px;
                }
            }
            &:nth-child(odd):nth-last-child(2) {
                margin-bottom: 0;
            }
        }
    }
}
