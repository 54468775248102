.about-partners-main-content {
    background-color: $grey;
    padding-top: 40px;
    padding-bottom: 60px;
    .container-custom-sm {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .partners-logos-wrapper:first-child {
        margin-bottom: 40px;
    }
    .col-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .about-partners-title {
        margin-bottom: 32px;
    }
    .partners-main-img {
        width: 100%;
    }
    .content-2 {
        .content-2-title {
            margin-bottom: 32px;
        }
        .content-2-text {
            margin-bottom: 32px;
            font-size: 16px;
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    @include breakpoint(md) {
        .about-partners-title {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        padding-top: 60px;
        padding-bottom: 120px;
        .container-custom-sm {
            margin-bottom: 60px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .content-2 {
            .content-2-text {
                margin-bottom: 46px;
            }
        }
    }
}

.partners-logos-container {
    margin: -6px;
    .partners-logo {
        display: inline-block;
        padding: 16px 20px;
        border-radius: 4px;
        background-color: $grey;
        margin: 6px;
        .partners-logo-img {
            height: 32px;
        }
    }
}
