.img-podcast {
    border-radius: 8px;
}

.light-version {
    background-color: #ffffff;
    &.container-custom, &.container-fluid {
        div {
            h2, p {
                color: #1d1d1d;
            }
            &.text-podcast-container {
                background-color: #ffffff;
                .p-wrapper > p {
                    color: #1d1d1d;
                }
            }
        }
        .top-curve-wrapper {
            .top-curve {
                background-color: #ffffff;
            }
        }
    }
    &.td-section-one-wrapper {
        background-color: #ffffff;
    }
}

.text-podcast-container {
    position: relative;
    background-color: #1d1d1d;
    @media screen and (max-width: 575px) {
        padding-left: 15px;
        padding-right: 15px;
    }

}

.light-version.container-fluid div.text-podcast-container {
    &.text-container-on-arch {
        padding-left: 15%;
        padding-right: 15%;
        background-color: transparent;
        margin-top: -150px;
        padding-bottom: 4rem;
        @media screen and (max-width: 991px) {
            padding-left: 10%;
            padding-right: 10%;
            margin-top: 0;
        }

        h2 {
            font-size: 40px;
            @media screen and (max-width: 991px) {
                //padding-top: 15rem;
                font-size: 32px;
            }
            @media screen and (min-width: 992px) and (max-width: 1600px) {
                padding-top: 7rem;

            }
        }
        p {
            font-size: 18px;
            @media screen and (max-width: 991px) {
                font-size: 16px;
            }
        }
    }
}

.title-text-image-podcast-wrapper {
    .top-curve-wrapper {
        bottom: 75px;
        @media screen and (min-width: 992px) {
            bottom: 112px;
        }
    }
    .top-curve {
        background-color: #1d1d1d;
    }
}

.container-fluid {
    div.text-podcast-container {
        .p-wrapper > p {
            color: #FFFFFF;
        }
    }
}

.banner-image-container {
    position: relative;
}

.white-arc-container {
    position: absolute;
    bottom: -2px;
    height: auto;
    width: 100%;
    img {
        width: 100%;
    }
}
