.toolbox-pp {
    background-color: $white;

    &__container {
        margin: 0 auto;
        max-width: 1176px;
        padding: 52px 15px 60px;

        @media (min-width: 576px) {
            padding: 104px 15px 118px;
        }
    }

    &__title {
        color: #003963;
        font-family: Caros, sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 50px;
    }

    &__row {
        margin-left: -10px;
        margin-right: -10px;
        row-gap: 20px;
    }

    &__col {
        padding-left: 10px;
        padding-right: 10px;
    }

    &__card {
        display: block;
        padding: 36px 28px;
        background-color: #FFE4D4;
        border-radius: 18px;
        height: 100%;
        transition: all .2s;

        &:hover {
            background-color: #dbecf9;
        }
    }

    &__img {
        display: inline-block;
        height: 42px;
        width: auto;
        margin-bottom: 32px;
    }

    &__text {
        color: #013A64;
        font-size: 22px;
        line-height: 24px;
    }

    & a {
        text-decoration: none;
    }
}
