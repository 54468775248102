.more-episodes2 {
    form {
        text-align: center;
    }
    #filter-episode-alter, #filter-session-alter {
        padding: 25px 0;
        display: inline-flex;
        flex-direction: column;
        .selection-result, .selection-result-session {
            background-color: $white;
            color: $red;
            border-radius: 50px;
            padding: 10px 10px 10px 20px;
            display: inline-flex;
            font-weight: bold;
            gap: 5px;
            cursor: pointer;
            z-index: 1;
            align-items: center;
            width: 170px;
            justify-content: center;
        }
        .selection-option, .selection-option-session {
            display: none;
            background-color: #fff;
            color: $blue-green;
            flex-direction: column;
            gap: 10px;
            border-radius: 25px;
            padding: 27px 0;
            margin-top: 0;
            z-index: 2;
            &.show {
                display: inline-flex;
                position: absolute;
                width: 170px;
            }
            div {
                cursor: pointer;
                &::before {
                    content: "";
                    border-radius: 50%;
                    width: 5px;
                    height: 5px;
                    margin-right: 10px;
                    display: inline-block;
                    margin-bottom: 2px;
                    margin-left: -10px;

                }
            }
            div:hover {
                font-weight: bold;
                &::before {
                    background-color: $red;
                }
            }
        }
    }
}
