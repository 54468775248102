.topics-list {
    @extend .articles-list-one;
    background-color: $grey;
    padding-bottom: 80px;
    .content-wrap {
        background-color: $blue-light;
    }
    .content {
        .topics-list-title {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: bold;
            padding-top: 60px;
            margin-bottom: 40px;
            img {
                width: 46px;
                margin-right: 18px;
            }
            sup {
                color: $red;
                font-weight: 500;
                margin-left: 10px;
            }
        }
        .content-body {
            margin-top: 25px;
        }
    }
    .banner-advice {
        margin-top: 25px;
        .banner-body {
            max-width: 770px;
            margin: 0 auto;
            text-align: center;
            padding: 40px 32px;
            border: 1px solid $blue;
            border-radius: 8px;
            box-shadow: 0 2px 20px $grey-shadow;
            .col-right {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            p {
                color: $blue;
                font-size: 14px;
                strong {
                    font-family: $font-title;
                    font-weight: 500;
                    font-size: 20px;
                    margin-bottom: 20px;
                }
            }
            .btn-skin-1 {
                color: $blue !important;
                margin-top: 32px;
            }
        }
    }
    @include breakpoint(lg) {
        padding-bottom: 100px;
        .content {
            .topics-list-title {
                padding-top: 80px;
            }
        }
        .banner-advice {
            margin-top: 35px;
            .banner-body {
                text-align: left;
                .col-right {
                    justify-content: flex-end;
                }
                .btn-skin-1 {
                    margin-top: 0;
                }
            }
        }
    }
}
