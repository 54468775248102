/* ANIMATIONS
===========================
Important: Use custom easing types with longer durations for better results.
 */

$ease-out-circ: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

.scale-in {
  animation: scaleIn 1200 $ease-out-circ;
  animation-fill-mode: forwards;
}

.scroll {
  animation: scroll 5000ms infinite linear;
}

.pulse-loop {
    animation: pulseIn 2000ms ease-out infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 396px;
  }
}

@keyframes appear_up_go_down {
    0% {
        opacity: 0;
        transform: scale(0) translateY(-200px);
    }
    20% {
        opacity: 1;
        transform: scale(1) translateY(-200px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes pulseIn {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

