.form-contact-us-wrapper {
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.20);
    padding: 15px;
    margin: 0 auto;
    max-width: 845px;
    .fcu-body {
        padding: 50px;
        border-left: 1px solid #eee;
        input {
            font-size: 12pt;
            border: 1px solid #00407164;
            border-radius: 30pt;
            padding: 15px 45px;
            margin-bottom: 20px;
            width: 100%;
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #0000006F;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #0000006F;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: #0000006F;
        }
        select {
            background-color: #0040710A;
            padding: 10px 25px;
            border: 0;
            font-size: 12pt;
            font-weight: bold;
            color: #004071;
            option {
                font-size: 10pt;
                font-weight: normal;
                padding: 5px ;
            }
        }
        h3 {
            color: #E6472A;
            font-size: 20pt;
            margin-bottom: 25px;
        }
        p {
            color: #004071;
            font-size: 13pt;
            font-weight: bold;
            margin: 25px 0;
        }
        .checkbox-container {
            display: flex;
            flex-direction: row;
            label {
                display: flex;
                align-items: center;
                padding: 10px;
                gap: 10px;
                background-color: #0040710A;
                border: 1px solid #CBD6E2;
                margin: 5px;
                border-radius: 5px;
                cursor: pointer;
            }
            input[type="checkbox"] {
                width: auto;
                margin-bottom: 0;
            }
        }
        .fcu-footer {
            display: flex;
            align-items: center;
            gap: 10px;
            button {
                @extend .btn-blue-1-alt;
                position: initial;
                gap: 10px;
                img {
                    width: 20px;
                }
            }
        }
    }
}
@media (max-width: 768px){
    .form-contact-us-wrapper {
        .fcu-body {
            .fcu-footer {
                flex-direction: column;
            }
        }
    }
}
