.event-calendar-page {
    background-color: $grey;
    padding: 30px 20px 60px 20px;
    @include breakpoint(lg) {
        padding-bottom: 100px;
    }
}

.event-filter-container, .event-calendar-page-wrapper {
    width: 932px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.event-filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 40px;
    .event-filter-item {
        margin-bottom: 12px;
        position: relative;
        &:after {
            /* arrow is put after due to browser limitations (select) */
            @include material-icon("keyboard_arrow_down");
            position: absolute;
            top: 50%;
            right: 38px;
            transform: translateY(-50%);
            font-size: 16px;
            color: $blue;
            pointer-events: none;
        }
        .event-filter {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: $white;
            border: 1px solid $blue;
            border-radius: 32px;
            font-family: $font-content;
            font-size: 15px;
            font-weight: 700;
            color: $blue;
            padding: 16px 63px 16px 38px;
            &:after {
                display: none;
            }
        }
        .event-filter-dropdown {
            z-index: 10;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        flex-direction: row;
        padding-left: 50px;
        margin-bottom: 70px;
        .event-filter-item {
            margin-bottom: 0;
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.event-calendar-page-wrapper {
    @extend .articles-list-one;
    padding: 0;
    .content {
        .content-body {
            .article-card {
                padding-bottom: 0;
                margin-bottom: 30px;
                a {
                    color: $blue;
                    z-index: 5;
                    .article-card-content {
                        flex-grow: 1;
                        flex-shrink: 1;
                        padding: 25px;
                    }
                    .article-card-title {
                        font-size: rem(26);
                        font-family: $font-content;
                        font-weight: 700;
                        margin-bottom: 40px;
                    }
                    .article-card-desc {
                        font-size: rem(20);
                    }
                    .article-card-city {
                        &:before {
                            content: "";
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: $red;
                            margin-top: -6px;
                            margin-right: 12px;
                            vertical-align: middle;
                        }
                    }
                    &:after {
                        z-index: -1;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    @include breakpoint(lg) {
        .content {
            .content-body {
                .article-card {
                    a {
                        .img-wrapper {
                            width: 180px;
                        }
                        .article-card-content {
                            padding: 44px 44px 36px 44px;
                        }
                        .article-card-desc {
                            display: flex;
                            justify-content: space-between;
                        }
                        .article-card-date, .article-card-city {
                            width: calc(50% - 20px);
                            flex-grow: 0;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
    }
}

.event-calendar-container {
    overflow: auto;
}

.event-calendar-calendar {
    min-width: 580px;
    .bootstrap-datetimepicker-widget {
        background-color: $white;
        padding: 40px;
        border-radius: 8px;
        overflow: hidden;
        table {
            thead {
                tr {
                    &:last-child {
                        display: none;
                    }
                    th {
                        border: none;
                        &.picker-switch {
                            padding: 22px;
                            color: $blue;
                            transition: background-color $time-sm ease;
                        }
                        &.prev, &.next {
                            position: relative;
                            span {
                                position: absolute;
                                top: -40px;
                                width: 80px;
                                height: 80px;
                                background-color: $blue;
                                color: $white;
                                transition: background-color $time-sm ease;
                                &:before {
                                    position: absolute;
                                    top: 22px;
                                }
                            }
                            &:hover, &:focus {
                                background: none;
                                span {
                                    background-color: darken($blue, 8);
                                }
                            }
                            &:active, &:active:focus {
                                background: none;
                                span {
                                    background-color: darken($blue, 12);
                                }
                            }
                        }
                        &.prev {
                            span {
                                left: -40px;
                                border-top-left-radius: 8px;
                                border-bottom-right-radius: 100px;
                                &:before {
                                    @include material-icon("west");
                                    font-size: 26px;
                                    left: 22px;
                                }
                            }
                        }
                        &.next {
                            span {
                                right: -40px;
                                border-top-right-radius: 8px;
                                border-bottom-left-radius: 100px;
                                &:before {
                                    @include material-icon("east");
                                    font-size: 26px;
                                    right: 22px;
                                }
                            }
                        }
                    }
                }
            }
            td {
                &.new, &.old {
                    opacity: 0;
                }
                &.day {
                    position: relative;
                    z-index: 2;
                    font-family: $font-content;
                    font-size: rem(26);
                    font-weight: 700;
                    color: $red;
                    height: 78px;
                    vertical-align: middle;
                    border: none;
                    transition: color $time-sm ease;
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        height: 70px;
                        width: 70px;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%)translateY(-50%);
                        border-radius: 50%;
                        pointer-events: none;
                        border: 1px solid $red;
                        -webkit-box-shadow: inset 0 0 0 0 $red;
                        box-shadow: inset 0 0 0 0 $red;
                        transition: box-shadow $time-sm ease;
                    }
                    &.disabled {
                        color: $blue;
                        &:after {
                            display: none;
                        }
                    }
                    &.today {
                        &:before {
                            display: none;
                        }
                    }
                    &:hover, &:focus, &:active, &:active:focus, &.active {
                        background: none;
                        color: $blue;
                        &:after {
                            -webkit-box-shadow: inset 0 0 0 35px $red;
                            box-shadow: inset 0 0 0 35px $red;
                        }
                    }
                }
            }
        }
        .picker-switch {
            font-family: $font-title;
            font-size: rem(32);
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    @include breakpoint(lg) {
        padding: 0 34px;
    }
}

.even-calendar-list-bottom-content {
    margin-top: 40px;
    @include breakpoint(lg) {
        margin-top: 70px;
    }
}

.event-calendar-page-empty {
    .event-calendar-page-empty-text {
        color: $blue;
        padding: 32px;
        border: 1px $blue dashed;
    }
    @include breakpoint(lg) {
        padding-left: 50px;
        .event-calendar-page-empty-text {
            padding: 60px;
        }
    }
}
