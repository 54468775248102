.title-text-image-backlink-block {

    &__content {
        align-items: stretch;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0 auto;
        max-width: 1424px;
        padding: 60px 15px 0;
        width: 100%;

        @media(max-width: 1199px) {
            flex-direction: column-reverse;
            padding: 0 15px 60px;
        }
    }

    &__backlink-container {
        display: block;
        text-decoration: initial;
        margin-bottom: 40px;

        @media(max-width: 1199px) {
            margin-bottom: 40px;
        }

        &:hover {
            text-decoration: initial;
        }
    }

    &__backlink-icon {
        align-items: center;
        background-color: #F5CDB1;
        border-radius: 50%;
        display: inline-flex;
        height: 36px;
        justify-content: center;
        margin-right: 12px;
        width: 36px;

        img {
            width: 17px;
            height: 13px;
        }
    }

    &__backlink-text {
        color: #013A64;
        font-family: Caros, sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
    }

    &__image {
        aspect-ratio: 4 / 4;
        border-radius: 19px;
        display: inline-block;
        height: 100%;
        max-height: 486px;
        max-width: 486px;
        object-fit: cover;
        width: 100%;

         @media(max-width: 1199px) {
             aspect-ratio: 16 / 9;
             max-width: 100%;
             max-height: initial;
             margin: 50px 0;
         }

        &-container {
            width: 50%;
            position: relative;
            z-index: 2;

            @media(max-width: 1199px) {
                margin-bottom: initial;
                max-width: initial;
                width: 100%;;
            }
            @media(min-width: 1200px) {
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        &-content {
            aspect-ratio: 16 / 9;
            border-radius: 17px;
            margin-bottom: 111px;
            overflow: hidden;
            position: relative;

            @media(max-width: 1199px) {
                margin-bottom: 55px;
            }
        }

        &-thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }

        &-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 66px;
            height: 66px;
            background-color: rgba(0, 18, 49, 0.8);
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            img {
                width: 20px;
                height: 25px;
            }
        }
    }

    &__text {
        &-container {
            width: 50%;
            position: relative;

            @media(max-width: 1199px) {
                width: 100%;
            }
        }

        &-content {
            max-width: 712px;
            margin-right: auto;
            margin-top: 134px;
            margin-left: 64px;
            padding-right: 60px;

            @media(max-width: 1199px) {
                padding: 0 20px;
                margin: 67px 0;
                max-width: initial;
            }
        }
    }

    &__title {
        color: #013A64;
        font-size: 40px;
        line-height: 54px;
        font-family: Caros, sans-serif;
        margin-bottom: 36px;
    }

    &__rich-text {
        @media (min-width: 1200px) {
            margin-bottom: 120px;
        }

        * {
            color: #013A64;
            font-size: 18px;
            line-height: 24px;
        }
    }
}

body {
    &.header-img-overlay-pp {
        .main-header {
            @media (min-width: 1200px) {
                position: relative;
                margin-bottom: -50px;
            }
        }
    }
}
