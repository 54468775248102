.video_flex_block {
    padding: 80px 0;
    .td-section-one-wrapper {
        border-bottom: initial;
        padding: initial;
        background-color: inherit;
        .resp-video-container {
            height: calc(100% - 40px);
            max-height: 612px;
            max-width: 100%;
            overflow: hidden;
            @media (max-width: 1199px) {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }
    .container-custom {
        background-color: inherit;
        width: 100%;
        max-width: 1424px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .td-section-one-wrapper .container .row div video {
        width: 100%;
    }
    .td-section-one-wrapper .container div.row {
        p, h2, li {
            color: unset;
        }
    }
    &.text-white {
        .cms-content.font-18 a {
            color: $twitter-color !important;
        }
    }
    .font-size-16px {
        font-size: 16px;
        p {
            font-size: 16px;
        }
    }
    .btn-blue-to-orange-short {
        padding: 20px 35px;
    }
}

