#news-top-article-nav-container {
    transition: height $time-xs ease;
}

.news-top-article-nav-wrapper {
    background-color: $grey;
    border-bottom: 1px solid #e1e7ef;
    width: 100%;
    z-index: 6;
    .red-deco {
        width: 0;
        height: 4px;
        background-color: $red;
        display: inline-block;
        position: absolute;
        border: 0;
        left: 0;
        margin-top: -5px;
        transition: width $time-md ease;
    }
    .news-top-article-nav {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .col-left {
            display: flex;
            align-items: center;
            max-width: calc(50% - 7.5px);
            order: 1;
            a {
                font-family: $font-title;
                font-weight: 500;
                text-decoration: none;
                color: $blue;
                font-size: rem(15);
                display: flex;
                align-items: center;
                .material-icons {
                    flex-grow: 0;
                    flex-shrink: 0;
                    background-color: $skin;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $blue;
                    font-size: 13pt;
                    font-weight: bold;
                    margin-right: 12px;
                    position: relative;
                    z-index: 2;
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        width: 100%;
                        padding-bottom: 100%;
                        border-radius: 50%;
                        background-color: $skin-dark;
                        transform: scale(0);
                        transition: transform $time-sm ease, background-color $time-sm ease;
                    }

                }
                &:hover, &:focus {
                    color: $red;
                    .material-icons {
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
                &:active, &:active:focus {
                    color: darken($red, 4%);
                    .material-icons {
                        &:after {
                            background-color: darken($skin-dark, 6%);
                            transform: scale(1);
                        }
                    }
                }
            }
        }
        .col-center {
            display: none;
            width: 100%;
            max-width: 670px;
            padding-left: 20px;
            padding-right: 20px;
            opacity: 0;
            order: 2;
            transition: opacity $time-md ease;
            .bar-title {
                font-family: $font-title;
                font-size: rem(20);
                color: $blue;
                p:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .col-right {
            display: flex;
            align-items: center;
            font-size: rem(14);
            color: $red;
            max-width: calc(50% - 7.5px);
            order: 3;
            opacity: 0;
            transition: opacity $time-md ease;
            .icon-custom {
                flex-grow: 0;
                flex-shrink: 0;
                background-color: #f7ebe9;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
            }
        }
    }
    &.news-top-article-nav-fixed {
        position: fixed;
        top: 0;
        .red-deco {
            width: 100px;
        }
        .news-top-article-nav {
            .col-center {
                opacity: 1;
            }
            .col-right {
                opacity: 1;
            }
        }
    }
    @include breakpoint(lg) {
        .news-top-article-nav {
            flex-wrap: nowrap;
            padding-top: 32px;
            padding-bottom: 32px;
            .col-left {
                flex: 1;
            }
            .col-center {
                display: block;
                width: auto;
                flex-shrink: 1;
                flex-grow: 1;
            }
            .col-right {
                flex: 1;
                justify-content: flex-end;
            }
        }
    }
}
