.td-section-two-wrapper {
    text-align: center;
    .row {
        margin: 0;
    }
    h2 {
        color: $gray-dark2;
        margin-bottom: 24px;
    }
    p {
        font-size: rem(18);
        color: $gray-dark2;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .content-middle {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }
    @include breakpoint(lg){
        .col-left,
        .col-right {
            min-height: 362px;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .col-left {
            background-position-x: right;
        }
        .col-right {
            background-position-x: left;
        }
        .content-middle {
            padding: 60px;
        }
    }
}
