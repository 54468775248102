.text-icons-pp {
    padding: 86px 0;
    border-radius: 100px 0 0 0;
    background-color: #FFE4D4;

    @media (min-width: 576px) and (max-width: 1199px) {
        padding-left: 62px;
    }
    @media (min-width: 1200px) {
        padding: 160px 0 86px;
    }

    &__container {
        margin: 0 auto;
        max-width: 1424px;
        padding: 0 15px;

        @media (min-width: 1200px) {
            padding-left: 124px;
            max-width: 1544px;
        }
    }

    &__title {
        font-size: 32px;
        line-height: 40px;
        color: #013A64;
        margin-bottom: 56px;
    }

    &__rich-text {

        * {
            font-size: 18px;
            line-height: 24px;
            color: #013A64;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul, ol {
            padding-left: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            text-decoration: underline;
        }
    }

    &__cards {
        &-content {
            @media (min-width: 1200px) {
                max-width: 508px;
                margin-left: auto;
            }
        }

        &-row {
            row-gap: 48px;
        }

        &-col {
            @media (min-width: 1200px) {
                margin-right: auto;
                max-width: calc(50% - 88px);
            }
        }
    }

    &__card {
        &-icon {
            width: 41px;
            height: 41px;
            border-radius: 4px;
            margin-bottom: 10px;
        }

        &-title {
            font-size: 32px;
            line-height: 40px;
            color: #013A64;
            margin-bottom: 8px;
        }

        &-text {
            font-size: 18px;
            line-height: 24px;
            color: #013A64;
            text-transform: uppercase;
        }
    }
}
