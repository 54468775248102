.fo-newsletter {
    padding-bottom: 65px;
    position: relative;
    z-index: 5;
    .fo-newsletter-container {
        @extend .container-custom;
        max-width: 1100px;
        position: relative;
        padding-top: 45px;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            height: 1px;
            width: calc(100% - 250px);
            max-width: 790px;
            background-color: $grey-alpha;
        }
    }
    .fo-newsletter-info-container {
        margin-bottom: 32px;
    }
    .fo-newsletter-info-title {
        margin-bottom: 20px;
    }
    .fo-newsletter-info-text {
        font-size: 16px;
        p:last-child {
            margin-bottom: 0;
        }
    }
    .form-confirmation {
        background-color: $white;
        .form-confirmation-text {
            flex-grow: 0;
        }
    }
    .fo-newsletter-circles {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .fo-newsletter-circle {
            z-index: -1;
            position: absolute;
            border-radius: 50%;
        }
        .fo-newsletter-circle-skin-light {
            top: 30px;
            left: 90px;
            width: 52px;
            height: 52px;
            background-color: $skin-light;
        }
        .fo-newsletter-circle-blue {
            bottom: 90px;
            left: 40%;
            width: 40px;
            height: 40px;
            background-color: $blue;
        }
        .fo-newsletter-circle-red-tomato {
            top: -20px;
            right: 60px;
            width: 65px;
            height: 65px;
            background-color: $red;
        }
    }
    @include breakpoint(md) {
        .fo-newsletter-container {
            display: flex;
            .fo-newsletter-info-container {
                width: 385px;
                padding-right: 32px;
                margin-bottom: 0;
                flex-grow: 0;
                flex-shrink: 0;
            }
            .fo-newsletter-form-container {
                flex-grow: 1;
                flex-shrink: 1;
            }
        }
        .form-confirmation {
            min-height: 200px;
        }
    }
    @include breakpoint(lg) {
        .fo-newsletter-container {
            padding-left: 110px;
        }
        .form-confirmation {
            min-height: 250px;
        }
    }
}
