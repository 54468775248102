.video_podcast_block {
    .container-custom {
        .row {
            justify-content: space-evenly;
        }
    }
    .resp-video-container {
        background-color: transparent;
        .video-button-new {
            display: flex;
            align-items: center;
            background-color: #f5cdb1;
            padding: 20px 20px 20px 40px;
            font-size: 13pt;
            font-weight: bold;
            border-radius: 50px;
            color: $blue;
            cursor: pointer;
            position: absolute;
            z-index: 5;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            overflow: hidden;
            span {
                margin-left: 10px;
            }
            img {
                width: 30px !important;
                margin-left: 10px;
                margin-bottom: 0 !important;
            }
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                left: -5%;
                width: 110%;
                padding-bottom: 110%;
                border-radius: 50%;
                background-color: $skin-dark;
                transform: scale(0);
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
            &:hover, &:focus {
                &:after {
                    transform: scale(1);
                }
            }
            &:active, &:active:focus {
                &:after {
                    background-color: darken($skin-dark, 6%);
                    transform: scale(1);
                }
            }
        }
        img, video, iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .container-custom {
        max-width: 900px;
    }
    .td-section-one-wrapper {
        border-bottom: 1px solid #333;
        .col-right {
            display: initial !important;
        }
    }
}

@media (max-width: 768px) {
    .video_podcast_block {
        .col-left {
            align-items: center;
        }
    }
}

