.exercises-pp {
    background: #DBECF9;
    padding: 52px 0;
    border-top-right-radius: 100px;

    @media (min-width: 576px) {
        padding: 104px 0 168px;
    }

    &__container {
        margin: 0 auto;
        max-width: 1414px;
        padding: 0 15px;
    }

    &__title {
        color: #013A64;
        font-family: Caros, sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 40px;
    }

    &__row {
        margin: 0 -12px;
        row-gap: 24px;
    }

    &__col {
        padding: 0 12px;
    }

    &__card {
        align-items: flex-start;
        background-color: $white;
        border-bottom: 1px solid #00308429;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;
        padding: 22px 40px 28px;
        position: relative;
        text-decoration: initial;
        &:first-child {
            border-top: 1px solid #00308429;
        }

        @media (max-width: 575px) {
            padding: 15px 15px 54px;
        }

        &:hover {
            text-decoration: initial;
        }

        &-text {
            color: #033C66;
            font-family: Caros, sans-serif;
            font-size: 16px;
            line-height: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
