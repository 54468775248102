.social-share-stripe {
    border-top: 1px dashed $grey-alpha;
    padding: 40px 0 60px 0;
    .sss-wrapper {
        .sss-text {
            font-size: 16px;
            margin-bottom: 10px;
        }
        .sss-icons {
            display: flex;
            align-items: center;
        }
        .sss-icon {
            color: $blue;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid #ccc;
            width: 32px;
            height: 32px;
            text-decoration: none;
            position: relative;
            z-index: 2;
            transition: color $time-sm ease;
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: $blue;
                transform: scale(0);
                border-radius: 50%;
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
            &:hover, &:focus {
                color: $white;
                &:after {
                    transform: scale(1);
                }
            }
            &:active, &:active:focus {
                color: $white;
                &:after {
                    transform: scale(1);
                    background-color: darken($blue, 4%);
                }
            }
        }
    }
    @include breakpoint(sm) {
        .sss-wrapper {
            .sss-icon {
                width: 48px;
                height: 48px;
                margin-right: 12px;
                font-size: 16px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    @include breakpoint(md) {
        .sss-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            .sss-text {
                margin-bottom: 0;
                margin-right: 38px;
            }
        }
    }
}

.tooltip.bs-tooltip-bottom {
    pointer-events: none;
    margin-top: -20px;
    transition: opacity $time-sm ease, margin-top $time-sm ease;
    &.show {
        margin-top: 0;
    }
}
