#global-search {
    .autocomplete-wrapper {
        position: relative;
        padding: 40px 10px;
        transition: all .2s linear;

        &.open-list {
            padding: 70px 10px;

            .autocomplete-container {
                background-color: #dbecf9;
                border-radius: 10px;
                padding: 15px 10px;

                .autocomplete-dropdown {
                    opacity: 1;
                }
            }
        }
    }

    .autocomplete-container {
        background-color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        max-width: 692px;
        position: absolute;
        top: 0;
        transition: all .2s linear;
        width: 100%;
        z-index: 2;

        .autocomplete-input {
            border: initial;
            padding: 24px 52px;
            font-size: 16px;
            line-height: 24px;
            color: $blue-repertoire;
            &-container {
                background: white;
                border-radius: 4px;
                border: 1px solid #c8d6e1;
                position: relative;

                .vertical-line {
                    background-color: #014172;
                    display: inline-block;
                    height: 28px;
                    margin-right: 50px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                }
            }
        }

        .autocomplete__search-icon {
            color: $blue-repertoire;
            font-size: 19px;
            left: 0;
            margin-left: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .clear-global-search-btn {
            align-items: center;
            background-color: $blue-repertoire;
            border-radius: 50%;
            border: initial;
            color: white;
            display: inline-flex;
            height: 26px;
            justify-content: center;
            margin-right: 12px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 26px;

            .fa-times {
                font-size: 18px;
            }
        }

        .quick-links {
            margin: 16px 10px 0;

            &__list {
                padding-left: initial;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                list-style-type: none;
            }

            &__item {
                margin-bottom: 10px;
            }

            &__link {
                font-size: 16px;
                color: $blue-repertoire;
                transition: all 100ms linear;
                position: relative;
            }
        }

        .autocomplete-dropdown {
            padding: initial;
            z-index: 1000;
            opacity: 0;
            background: initial;
            border: medium none currentColor;
            border: initial;
            box-shadow: initial;
            margin: 0 0 0 10px;
            list-style: none;

            h6 {
                color: $red;
                font-size: 16px;
            }

            li {
                list-style: none;
                padding: 10px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 400;
                border: initial;
            }

            .recent-searches {
                display: -webkit-box;
                display: flex;
                flex-direction: column;
                padding-left: initial;
                margin: 20px 0 0 0;
                list-style: none;
                justify-content: flex-end;
                width: inherit;
            }

            .recent-search-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                list-style: none;
                padding: 10px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 400;
                border: initial;

                a {
                    color: $blue-repertoire;
                    transition: all 75ms linear;
                }

                img {
                    width: 20px;
                    cursor: pointer;
                    vertical-align: sub;
                    margin-right: 10px;
                }

                &__remove {
                    margin-right: initial !important;
                    align-self: flex-start;
                    margin-top: 4px;
                }
            }

            .search-link {
                color: $blue-repertoire;
                transition: all 75ms linear;

                .fa-search {
                    font-size: 15px;
                    width: 30px;
                    height: 30px;
                    color: $red;
                    background: #f0f5f8;
                    border-radius: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                }

                em {
                    font-style: normal;
                }

                span span {
                    font-weight: 700;
                }
            }
        }

    }
}
