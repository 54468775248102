.template-study_results-page {
    .text-content {
        h1, p  {
            color: $blue;
        }
    }

    header.main-header .header-content .text-content .text-content-body .header-content-title h1.title-bright {
        color: $blue;
    }
    header.main-header.header-dark .header-content .text-content .text-content-body p {
        color: $blue;
    }

    .animation-on-scroll-container {
        position: relative;

        .panel > div {
            padding-top: 32px;
            min-height: 604px;
        }
        div.study-results-title-text-container {
            padding-top: 84px;
            padding-bottom: 70px;
        }

        a {
            &.study-results-title-url {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    .study-results-container {

        .study-results-title {
            font-size: 32px !important;
            line-height: 40px;
            text-align: center;
            @media (max-width: 1199px) {
                font-size: 22px !important;
                line-height: 30px;
            }
        }

        .study-results-rich-text {
            h2 {
                font-size: 32px;
                line-height: 40px;
            }

            p, a, li, span {
                font-size: 18px !important;
                line-height: 24px;
                @media (max-width: 575px) {
                    font-size: 14px !important;
                    line-height: 20px;
                }
            }
        }

        .study-results-title-text-container {
            padding-top: 70px;
            max-width: 588px;

            .study-results-title {
                color: #FFFFFF;
                padding-top: 32px;
                padding-bottom: 32px;
            }

            .study-results-rich-text {
                p, a, li {
                    color: #FFFFFF;
                }
            }
        }

        .study-results-title-img-text-card {
            padding: 0 100px 32px 100px;
            background-color: #286E83;
            color:#FFFFFF;
            @media (max-width: 991px) {
                padding: 0 50px 32px 50px;
            }
            @media (max-width: 575px) {
                padding: 0 18px 32px 18px;
            }
            border-radius: 20px;

            .study-results-title {
                margin-bottom: 32px;
            }

            .study-results-rich-text {
                @media (max-width: 575px) {
                    text-align: center;
                }
                @media (min-width: 992px) {
                    width: 75%;
                }
            }

            .img-fluid {
                max-height: 170px;
                @media (max-width: 575px) {
                    margin-bottom: 32px;
                }
            }
        }

        .study-results-title-img-text-nested-card {
            @extend .study-results-title-img-text-card;

            .img-fluid {
                max-height: 300px;
            }

            .study-results-nested-img-text-container {
                border-radius: 20px;
                padding: 36px;
                margin-top: 40px;
                position: relative;

                .img-fluid {
                    max-height: 136px;
                    @media (min-width: 576px) {
                        position: absolute;
                        top: -3px;
                    }
                }
            }
        }

        .study-results-title-two-imgs-text-card {
            @extend .study-results-title-img-text-card;

            .img-fluid {
                max-height: 360px;
                margin-bottom: 46px;
            }
        }

        .study-results-title-large-img-text-card {
            @extend .study-results-title-img-text-card;

            .study-results-rich-text {
                width: 100%;
            }

            .img-fluid {
                max-height: 324px;
                margin-bottom: 12px;
                @media (max-width: 767px) {
                    margin-bottom: 36px;
                }
            }
        }

        .study-results-title-three-imgs-and-nested-imgs-card {
            @extend .study-results-title-img-text-card;

            .img-fluid {
                max-height: 550px;
                margin-bottom: 48px;
            }

            .study-results-imgs-nested-container {
                border-radius: 20px;
                padding: 30px 40px;

                .img-fluid {
                    max-height: 118px;
                    margin-bottom: initial;
                }

            }
        }

        .study-results-img-title-text-link-block {
            padding: 0 100px;
            margin-top: 88px;
            margin-bottom: 56px;
            @media (max-width: 991px) {
                padding: 0 50px;
            }
            @media (max-width: 575px) {
                padding: 0;
            }

            .img-fluid {
                border-radius: 8px;
                margin-bottom: 32px;
            }

            .study-results-title {
                text-align: initial;
                font-size: 32px !important;
                line-height: 40px;
                margin-bottom: 36px;
                color: #FFFFFF;
                @media (max-width: 1199px) {
                    font-size: 22px !important;
                    line-height: 30px;
                }
            }

            .study-results-rich-text {
                margin-bottom: 24px;
                p, a, li {
                    color: #FFFFFF;
                }
            }

            .btn-blue-1 {
                font-size: 15px;
            }
        }

        .study-results-title-link-video-card {
            margin-bottom: 20px;
            border-radius: 20px;
            overflow: hidden;
            margin-top: 100px;

            .study-results-title-link-container {
                @media (max-width: 991px) {
                    padding: 36px 50px;
                }
                @media (max-width: 575px) {
                    padding: 36px 18px;
                }
                @media (min-width: 991px) {
                    padding: 36px 0 36px 100px;
                }

                .study-results-title {
                    text-align: initial;
                    font-size: 32px !important;
                    line-height: 40px;
                    margin-bottom: 36px;
                    @media (max-width: 1199px) {
                        font-size: 22px !important;
                        line-height: 30px;
                    }
                }

                .btn-blue-1 {
                    font-size: 15px;
                }
            }

            .study-results-video-container {
                height: 100%;
                overflow: hidden;
                position: relative;

                iframe, video {
                    width: 100%;
                    height: 100%;
                    min-height: 340px;
                    object-fit: cover;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }

            }

        }

        .study-results-background-button-block {
            height: 756px;
            display: flex;

            .study-results-button-and-background-container {
                display: inline-block;
                margin-top: 32px;
                margin-left: auto;
                margin-right: auto;
                position: relative;
                overflow: hidden;

                .study-results-button-background {
                    width: 100%;
                    height: auto;
                    max-width: 518px;
                    @media (max-width: 430px) {
                        scale: 1.2;
                    }
                }

                .study-results-title-and-button-container {
                    position: absolute;
                    top: 36%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    max-width: 50%;
                    text-align: center;
                    @media (max-width: 430px) {
                        top: 28%;
                    }

                    h3 {
                        font-size: 24px !important;
                        line-height: 32px;
                        @media (max-width: 575px) {
                            font-size: 17px !important;
                            line-height: 24px;
                        }
                    }

                    a {
                        background-color: $skin;
                        color: $blue;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none !important;
                        font-family: Caros, sans-serif;
                        font-weight: 700;
                        border-radius: 32px;
                        padding: 18px 32px;
                        margin-top: 20px;
                        position: relative;
                        z-index: 1;
                        overflow: hidden;

                        &:after {
                            content: "";
                            position: absolute;
                            z-index: -1;
                            width: 110%;
                            padding-bottom: 110%;
                            border-radius: 50%;
                            background-color: darken($skin, 8);
                            transform: scale(0);
                            transition: transform $time-sm ease, background-color $time-sm ease;
                        }

                        &:hover, &:focus {
                            &:after {
                                transform: scale(1);
                            }
                        }

                        @media (max-width: 575px) {
                            padding: 12px 24px;
                        }

                        span {
                            margin-left: 10px;
                            font-size: 15px !important;
                        }
                    }
                }
            }
        }

        .study-results-assistant-block {
            .more-news-cards-top-art {
                position: relative;
                top: 2px;

                img {
                    margin-top: 50px;
                }
            }

            .section-one {
                background-color: #FFFFFF;

                .content {
                    background-color: inherit;
                }
            }

            .section-one {
                img {
                    border-radius: 50%;
                }
            }
        }
    }

    .stack-cards {
        --stack-cards-gap: 74px;
        margin-bottom: 3em;
        @media (max-width: 767px) {
            --stack-cards-gap: 20px;
        }

        &__item {
            position: sticky;
            top: var( --stack-cards-gap );
            transform-origin: center top;

            & + & {
                margin-top: 1em;
            }
        }
    }
}


