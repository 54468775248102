.banner-info {
    background-color: #fafafa;
    padding: 50px 0;
    .banner-one-content {
        border: 1px solid $blue;
        border-left: 5px solid $blue;
        border-radius: 5px;
        margin: 0 auto;
        padding: 25px;
        background-color: $white;
        .icon {
            display: inline-block;
            position: absolute;
            img {
                max-width: 35px;
                width: 100%;
            }
        }
        p {
            color: $blue;
            padding-left: 55px;
        }
        .li-link {
            padding-left: 55px;
        }
    }
}
