.themes-pp {
    background: #FFE4D4;
    padding: 52px 0 60px;

    @media (min-width: 576px) {
        padding: 104px 0 120px;
    }

    &__container {
        margin: 0 auto;
        max-width: 1414px;
        padding: 0 15px;
    }

    &__rich-text {
        max-width: 588px;
        margin: 0 auto 80px;
        text-align: center;

        * {
            color: #013A64;
            font-size: 18px;
            line-height: 24px;
        }

        ul, ol {
            padding-left: 16px;
        }

        a {
            text-decoration: underline;
        }
    }

    &__title {
        color: #013A64;
        font-family: Caros, sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 40px;
        text-align: center;
    }

    &__row {
        margin: 0 -12px;
        row-gap: 24px;
    }

    &__col {
        padding: 0 12px;
    }

    &__card {
        background-color: $white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;
        text-decoration: initial;

        &:hover {
            text-decoration: initial;
        }

        &-text-container {
            flex: 1;
            padding: 32px;
        }

        &-title {
            color: #013A64;
            font-family: Caros, sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 26px;
        }

        &-rich-text {
            * {
                color: #013A64;
                font-size: 18px;
                line-height: 24px;

                &:last-child {
                    margin-bottom: initial;
                }
            }

            ul, ol {
                padding-left: 16px;
            }

            a {
                text-decoration: underline;
            }
        }

        &-img {
            height: 100%;
            object-fit: cover;
            width: 100%;

            &-container {
                height: 156px;
                position: relative;
                width: 100%;
            }
        }

        &-icon {
            background-color: #003084;
            border-radius: 100% 0 0 0;
            bottom: 0;
            height: 59px;
            position: absolute;
            right: 0;
            width: 67px;

            .material-icons {
                bottom: 14px;
                color: $white;
                position: absolute;
                right: 16px;
            }
        }

        &--contact-us {
            align-items: center;
            border: 1px solid #013184;
            justify-content: center;
            padding-bottom: 32px;

            .themes-pp__card-text-container {
                flex: initial;
                max-width: 304px;
                text-align: center;
            }

            .themes-pp__card-title {
                font-family: Caros, sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 20px;
            }

            .themes-pp__card-rich-text {
                * {
                    font-size: 14px;
                    line-height: 20px;
                }

            }

            .btn-skin-1 {
                margin: initial;
            }
        }
    }
}
