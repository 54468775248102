.at-post-list {
    background-color: $grey ;
    padding-top: 15px;
    padding-bottom: 60px;
    .card-container {
        display: flex;
        flex-wrap: wrap;
        margin: -12px;
    }
    .card {
        min-height: 190px;
        width: calc(100% - 24px);
        margin: 12px;
        padding: 0;
        border: none;
        border-radius: 8px;
        text-decoration: none !important;
        position: relative;
        z-index: 3;
        overflow: hidden;
        .card-content {
            z-index: 2;
            display: flex;
            flex-direction: column;
            min-height: 100%;
        }
        .card-body {
            padding: 170px 20px 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex-grow: 1;
            p {
                font-family: $font-title;
                font-size: 24px;
                font-weight: 700;
                color: $white;
                margin: 0;
            }
            h2 {
                color: $white;
            }
        }
        .card-footer {
            background: transparent;
            border: 0;
            display: flex;
            justify-content: flex-end;
            padding: 0;
        }
        .arrow-elem {
            background-color: $blue;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 100%;
            width: 50px;
            height: 50px;
            padding-left: 10px;
            padding-top: 10px;
            transition: background-color $time-sm ease;
        }
        .card-background {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            transition: transform $time-sm ease;
            &.embed-thumbnail {
                top: 50%;
                transform: translateY(-50%);
                height: 143%;
            }
        }
        &:after {
            content: "";
            height: 190px;
            width: 100%;
            background: transparent linear-gradient(180deg, #00000000 0%, #000000CC 100%) 0 0 no-repeat padding-box;
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 0;
        }
        &:hover, &:focus {
            .arrow-elem {
                background-color: darken($blue, 8%);
            }
            .card-background {
                transform: scale(1.05);
                &.embed-thumbnail {
                    transform: translateY(-50%) scale(1.05);
                }
            }
        }
        &:active, &:active:focus {
            .arrow-elem {
                background-color: darken($blue, 12%);
            }
            .card-background {
                transform: translateY(-50%) scale(1.08);
            }
        }
    }
    @include breakpoint(sm) {
        .card {
            width: calc(50% - 24px);
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
        .card {
            width: calc((100% / 3) - 24px);
            .card-body {
                padding: 250px 32px 0 32px;
            }
        }
    }
}
