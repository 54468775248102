.wwa-section-four-wrapper {
    background-color: $blue-light;
    padding: 40px 5px 40px 5px;
    color: $blue;
    .wwa-section-four-intro {
        margin-bottom: 40px;
        .wwa-section-four-intro-title {
            margin-bottom: 32px;
        }
        .wwa-section-four-intro-text {
            font-size: 16px;
            margin-bottom: 0;
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    .wwa-section-four {
        .img-container {
            width: 100%;
            padding-bottom: 40%;
            position: relative;
            img {
                object-fit: cover;
                object-position: center center;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 8px;
            }
        }
    }
    @include breakpoint(lg) {
        padding: 60px 5px 60px 5px;
        .wwa-section-four-intro {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 60px;
            .wwa-section-four-intro-title {
                margin-bottom: 0;
                max-width: 490px;
            }
            .wwa-section-four-intro-text {
                font-size: 20px;
                padding: 0 35px;
                width: 50%;
            }
        }
    }
}
