.tool-detail-families-game--section-three {
    background-color: $green;
    .banner-quote-2-wrapper {
        background-color: $green;
    }
    .banner-quote-2 {
        padding: 60px 0 40px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        p {
            padding: 0;
            margin: 0 !important;
            color: $green-dark;
        }
        .dec {
            display: inline-block;
        }
        .top-info {
            span {
                color: $blue;
            }
        }
        .large-strong {
            font-family: $font-title;
            p {
                font-weight: 700;
                font-size: 20px;
            }
        }
        .banner-quote-2-right {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 240px;
                width: 240px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center center;
                margin-bottom: 32px;
            }
        }
    }
    @include breakpoint(lg) {
        .banner-quote-2 {
            padding-top: 120px;
            padding-bottom: 60px;
            .large-strong {
                p {
                    font-size: 24px;
                }
            }
            .banner-quote-2-left {
                padding-right: 66px;
            }
            .banner-quote-2-right {
                img {
                    margin-bottom: 0;
                }
            }
        }
    }
}
