.text-image-pp {
    padding: 68px 0 50px;
    background-color: $white;

     @media (min-width: 576px) {
        padding: 136px 0 100px;
    }

    &__container {
        margin: 0 auto;
        max-width: 1176px;
        padding: 0 15px;
    }

    &__text-container {
        margin-bottom: 32px;

        @media (min-width: 768px) {
            margin-bottom: initial;
        }
    }

    &__title {
        color: #013A64;
        font-family: Caros, sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 40px;
    }

    &__rich-text {
        * {
            color: #013A64;
            font-size: 18px;
            line-height: 24px;
        }

        ul, ol {
            padding-left: 16px;
        }

        a {
            text-decoration: underline;
        }
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;

        &-container {
            aspect-ratio: 16 /9;
            width: 100%;
            border-radius: 12px;
            overflow: hidden;

            @media (min-width: 768px) {
                height: 402px;
                aspect-ratio: initial;
            }
        }
    }
}
