.for-organizations-project-funding {
    .container {
        max-width: 780px;
        margin: 0 auto;
        padding: 50px 25px;

        h2 {
            font-size: 24pt;
        }
        h3 {
            font-size: 20pt;
        }
        h2, h3 {
            color: $blue;
            font-weight: bold;
            margin: 50px 0;
        }
        p, li {
            font-size: 12pt;
            color: $blue;
        }
        li {
            list-style: none;
        }
        li::before {
            content: "\2022";
            color: $red;
            font-weight: 800;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
}
@import "SectionOne";
@import "SectionTwo";
@import "SectionThree";
@import "TabsDisplay";

