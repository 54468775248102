.slider-one-wrapper {
    background-color: $skin-light;
    padding: 25px 0 60px 0;
    .slider-one {
        padding: 0 20px;
    }
    .content {
        max-width: 1380px;
        margin: 0 auto 40px;
        .content-container {
            text-align: center;
        }
        p.p-title {
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: 12px;
            color: $blue;
        }
        .slider-one-title {
            margin-bottom: 32px;
            h1, h2, h3, h4, p {
                @extend .d1;
                &:last-child {
                    margin-bottom: 0;
                }
            }

        }
        .slider-one-text {
            max-width: 600px;
            margin: 0 auto;
            margin-bottom: 32px;
            h1, h2, h3, h4, p {
                font-size: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.slider-container {
    width: 100%;
    margin-bottom: 32px;
    overflow: hidden;
    margin-left: -12px;
    margin-right: -12px;
    //overflow-x: scroll;
    .slides-wrapper {
        width: 100%;
        transition: all 700ms;
        ul {
            list-style: none;
            display: flex;
            align-items: center;
            padding-left: 20px;
            margin-bottom: 0;
            li {
                border-radius: 10px;
                margin-left: 12px;
                margin-right: 12px;
                display: inline;
                img {
                    width: 280px;
                    height: 280px;
                    border-radius: 8px;
                    object-fit: cover;
                    object-position: center center;
                }
                &.active {
                    //box-shadow: 1px 1px 14px 0 rgba(0,0,0,0.80);
                    //border: 5px solid #0fe70f;
                    //box-sizing: border-box;
                    //border-radius: 5px;
                }
            }
        }
    }
    @include breakpoint(lg) {
        height: 385px;
        width: calc(100% - 100px);
        margin-left: 88px;
        .slides-wrapper {
            ul {
                padding-left: 0;
                li {
                    img {
                        width: 350px;
                        height: 350px;
                    }
                }
            }
        }
    }
}

.slider-text {
    min-height: 165px;
    max-width: 280px;
    margin-left: 20px;
    margin-bottom: 40px;
    color: $blue;
    transition: height $time-sm ease;
    .slider-text-title {
        margin-bottom: 12px;
        p {
            margin-bottom: 0;
        }
    }
    .slider-text-description {
        font-size: 16px;
        p:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        max-width: 350px;
        margin-left: 475px;
    }
}

.slider-controllers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .material-icons {
        position: relative;
        z-index: 1;
        cursor: pointer;
        font-size: 30px;
        color: $blue;
        background-color: $white;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        border-radius: 50%;
        transition: color $time-sm ease;
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            width: 100%;
            padding-bottom: 100%;
            border-radius: 50%;
            background-color: darken($blue, 8);
            transform: scale(0);
            transition: transform $time-sm ease, background-color $time-sm ease;
        }
        &:hover, &:focus {
            color: $white;
            &:after {
                transform: scale(1);
            }
        }
        &:active, &:active:focus {
            color: $white;
            &:after {
                background-color: darken($blue, 12);
                transform: scale(1);
            }
        }
    }
    .mobile-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        .dot {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background-color: $blue;
            margin-right: 10px;
            opacity: .4;
            &:last-child {
                margin-right: 0;
            }
            &.middle-size {
                height: 6px;
                width: 6px;
            }
            &.active {
                width: 8px;
                height: 8px;
                opacity: 1;
            }
        }
    }
    @include breakpoint(lg) {
        max-width: 350px;
        margin-left: 475px;
        padding: 0;
        justify-content: flex-end;
        .material-icons {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
        .mobile-dots {
            display: none;
        }
    }
}
