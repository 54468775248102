.td-section-one-wrapper {
    background-color: $gray-dark2;
    padding: 60px 0;
    img {
        max-width: 100%;
    }
    .col-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
            color: white;
            margin-bottom: 32px;
        }
        p {
            font-size: rem(18);
            color: white;
            &:last-child {
                margin-bottom: 60px;
            }
        }
    }
    .col-right {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @include breakpoint(lg) {
        padding: 80px 0;
        .col-left {
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
}
