.repertoire-search-form-wrapper {
    @media(min-width: 1200px) {
        margin-bottom: 24px;
    }

    .repertoire-search-form-title {
        margin-bottom: 40px;
        color: #024273;
        @media(max-width: 575px) {
            font-size: 16px;
            margin-bottom: 16px;
            //text-align: center;
        }
    }

    .repertoire-search-form-top-space {
        height: 16px;
        background: #F8F9FA;
        margin: initial;
        border: initial;
        @media (min-width: 576px) {
            display: none;
        }
    }

    .repertoire-search-form-container {
        max-width: 1384px;
        padding: 42px 48px;
        margin: 0 auto;
        background-color: white;
        border-radius: 8px;
        min-height: 294px;
        @media(max-width: 575px) {
            padding: 42px 15px;
        }

        .address-search-button {
            padding: 18px 35px;
            @media(min-width: 1200px) {
                margin-top: 38px !important;
            }
        }

        .tag-cloud {
            display: flex;
            flex-wrap: wrap;
            margin: 3rem -10px 0 -10px;
        }

        .tags {
            margin: 0 10px 10px 10px;
            font-size: 16px;
            background: rgba(0, 48, 132, .0392156863);
            border: 1px solid rgba(0, 48, 132, .1607843137);
            border-radius: 4px;
            padding: 10px 15px;

            &.checked {
                background: $blue;
                border: 1px solid $blue;
                color: #fff;
            }
        }

        .button span {
            font-size: 16px;
        }
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: #9bb4c7;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.select2-dropdown.select2-dropdown--below, .select2-dropdown.select2-dropdown--above {
    z-index: 2;
    opacity: 0;
    animation: fadeIn 0.5s ease 0s forwards;
    border: 1px solid #00407129 !important;
    border-radius: 4px !important;
    padding: 26px 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #00407129 !important;
    //border-radius: 4px !important;
    padding: 6px 12px;
    color: #004071;
    font-size: 16px;
}

// Selected postal code option
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: initial !important;
    color: #004071 !important;
    font-weight: bold;
}

// Postal code option that is already selected
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #0040710A !important;
}

.select2-search.select2-search--dropdown {
    padding-left: 34px;
    padding-right: 34px;
}

.select2-results {
    padding-left: 30px;
}

.select2-results__option {
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
}


.select2-dropdown.select2-dropdown--below {
    transform: translateY(30px);
}

.select2-dropdown.select2-dropdown--above {
    transform: translateY(-30px);
}

.select2-selection__arrow {
    display: none;
}

#resetButton {
    display: none;
    position: absolute;
    border: initial;
    background: initial;
    top: 50%;
    transform: translateY(-55%);
    right: 170px;
    @media(max-width: 575px) {
        right: 10px;
    }
}

.select2-container--default .select2-results > .select2-results__options {
    overflow-y: scroll !important;
    max-height: 285px !important;
}

#select2-id_postal_code-results {
    scrollbar-width: thin;
    scrollbar-color: #d2dce6 white;

    &::-webkit-scrollbar {
        width: 18px;
    }

    &::-webkit-scrollbar-track {
        background: white;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: #d2dce6;
        border-radius: 50px;
        border: 6px solid white;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #c2c9d4;
    }
}

.postal-code-tooltip {
    background-color: #E6472A;
    border-radius: 4px;
    bottom: -15px;
    color: white;
    display: inline-block;
    font-size: 13px;
    left: 15px;
    line-height: 15px;
    margin-right: 15px;
    margin-top: 24px;
    padding: 10px 12px;
    position: absolute;
    text-align: center;
    width: fit-content;
    z-index: 2;

    @media (max-width: 575px) {
        bottom: initial;
        top: 90px;
    }
}

.postal-code-tooltip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #E6472A transparent; /* Orange arrow */
}

.search-service__filters-container {
    .postal-code-tooltip {
        left: 0;
        margin-right: initial;
        @media (max-width: 575px) {
            top: 65px;
        }
    }
}

//.repertoire-search-form-wrapper, .search-service__filters-container {

.repertoire-page, .template-home-page {

    .form-group {
        margin-bottom: 0;
        position: relative;

        .auto-complete {
            padding-top: 20px;
            display: none;
            position: absolute;
            left: 0;
            z-index: 2;
            transform: translateY(10px);

            option {
                font-size: 14px !important;
                color: $blue;
                margin-bottom: 20px;
            }

        }

        label {
            font-family: "Moderat", sans-serif;
            font-weight: bold;
            line-height: 24px;
            font-size: 18px;
            margin-bottom: 16px;
            @media (max-width: 575px) {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 8px;
            }
        }

        .input-container {
            width: 100%;
            position: relative;
            height: 56px;
            font-size: 1.1875rem;
            line-height: 1.9375rem;
            background: #0040710A;
            border: 1px solid #d9e0e9;
            border-radius: 4px;
            color: $blue-green;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 12px 12px 12px 74px;

            &.keyword-search {
                padding: 12px 12px 12px 74px;
            }

            .reset-input-button {
                display: inline-flex;
                position: absolute;
                right: 15px;
                padding-left: 20px;
                border: initial;
                background: initial;
                //@media (max-width: 575px) {
                //    right: 64px;
                //}

                img {
                    width: 16px;
                    height: 16px;
                }
            }

            &.postal-code-container {
                padding: initial;

                .reset-input-button {
                    right: 64px;
                    @media (min-width: 576px) {
                        right: 170px;
                    }
                }
            }

            input {
                background-color: #f6f7fa;
                border: initial;
                color: #004071;
                font-size: 16px;
                line-height: 24px;

                &::placeholder {
                    color: #9bb4c6;
                    font-size: 16px;
                    @media (max-width: 575px) {
                        font-size: 13px;
                        line-height: 20px;
                    }
                }

                &:focus {
                    box-shadow: initial;
                }
            }

            .select2-container--default .select2-selection--single {
                border: initial;
            }

            #select2-id_postal_code-container {
                background-color: #f6f7fa;
                border: initial;
                padding: 0 12px 12px 74px;
                color: #004071;
                font-size: 16px;
            }

            .input-icon {
                position: absolute;
                left: 0;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                border-right: 1px solid #00308429;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon-wrapper {
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 14px;
                    }

                    .input-search-icon {
                        width: 18px;
                    }
                }
            }

            .autocomplete-container {
                position: absolute;
                left: 0;
                top: 7px;
                width: 100%;

                .autocomplete-input {
                    padding-left: 74px;
                    padding-right: 50px;
                }

                .autocomplete-dropdown {
                    margin-top: 16px;
                    border: 1px solid #00407129;
                    border-radius: 4px;
                    background: white;
                    padding: 25px 29px;
                    position: relative;
                    z-index: 30;
                    overflow: scroll;
                    max-height: 285px;

                    .recent-searches {
                        &-title {
                            @extend .autocomplete-suggestions__title;
                        }

                        .recent-search-list {
                            padding: initial;
                            margin-bottom: 32px;
                        }

                        .recent-search-item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-left: initial;
                            padding-right: initial;
                            margin-bottom: 20px;

                            &:last-child {
                                margin-bottom: initial;
                            }

                            &__btn {
                                background: initial;
                                border: initial;
                                color: #004071;
                                font-size: 16px;
                                line-height: 24px;
                                transition: all 0.15s linear;

                                &:hover,
                                &:focus {
                                    font-weight: 700;
                                }
                            }

                            &__remove {
                                background: initial;
                                border: initial;

                                &:hover,
                                &:focus {
                                    opacity: 0.75;
                                }
                            }

                            img {
                                height: auto;
                                cursor: pointer;
                                vertical-align: sub;
                                margin-right: 14px;
                            }

                            &__clock-icon {
                                width: 16px;
                            }

                            &__delete-icon {
                                width: 23px;
                            }
                        }
                    }

                    .autocomplete-suggestions {

                        &__title {
                            font-family: "Moderat", sans-serif;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 24px;
                            color: $red;
                            margin-bottom: 20px;
                        }

                        &__list {
                            list-style: none;
                            padding-left: initial;

                            &-item {

                                button {
                                    background: initial;
                                    border: initial;
                                    display: block;
                                    font-family: "Moderat", sans-serif;
                                    font-weight: normal;
                                    font-size: 16px;
                                    line-height: 24px;
                                    text-align: initial;
                                    color: $blue;
                                    margin-bottom: 20px;
                                    transition: all .15s linear;

                                    &:hover {
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }

                    .loader {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #004071;
                        border-bottom-color: transparent;
                        border-radius: 50%;
                        display: inline-block;
                        box-sizing: border-box;
                        animation: spin 1s linear infinite;
                    }

                    @keyframes spin {
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }

            }
            // React "address or postal code" component
            .css-13cymwt-control {
                border: initial;
                background: #f6f7fa;
            }
             .css-t3ipsp-control {
                background: #f6f7fa;
            }

            .css-qbdosj-Input {
                color: #004071;
                font-size: 16px;
                line-height: 24px;


                &:focus {
                    box-shadow: initial;
                }
            }

            #react-select-2-placeholder {
                color: #9bb4c6;
                font-size: 16px;
                line-height: 24px;
                text-align: left;
                @media (max-width: 575px) {
                    font-size: 13px;
                    line-height: 20px;
                }
            }

            .css-1fdsijx-ValueContainer {
                padding-left: initial;
            }
        }

        .geolocation {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            right: 12px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            border: none;
            border-radius: 2px;
            font-family: $font-title;
            padding: 8px 12px;
            background: $blue;
            -webkit-transition: .3s;
            transition: .3s;
            margin: 0;
            z-index: 20;
            @media(min-width: 576px) {
                min-width: 149px;
            }

            span {
                color: #fff;
                font-size: 12px !important;
                line-height: 15px;
            }

            img {
                line-height: unset;
                height: 15px;
                width: auto;
                border-style: none;
                @media (min-width: 575px) {
                    margin-right: 6px;
                }
            }
            //
            //@media (max-width: 575px) {
            //    top: 6px;
            //    right: 0;
            //}
        }
    }

    .address-input-sub-label {
        margin-left: auto;
        margin-top: 12px;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: initial;
        color: $blue;
        @media (min-width: 575px) {
            font-size: 16px;
            line-height: 20px;
            margin-top: 18px;
        }
    }
}

.template-home-page {
    .postal-code-tooltip {
        top: 62%;
        left: 0;
        bottom: auto;
        z-index: 1;
    }

    .form-group {
        .input-container {
            &.keyword-search {
                background: $white;

                .autocomplete-dropdown {
                    text-align: left;
                }

                .autocomplete-input {
                    background: $white;

                    &::placeholder {
                        color: $blue;
                    }
                }
            }
        }
    }
}


//.search-service__filters-container .form-group .geolocation {
//    @media (max-width: 575px) {
//        top: -25px;
//    }
//}
