.td-section-two-wrapper.dark-version {
    background-color: #1d1d1d;
    h2, p {
        color: #ffffff;
    }
}

.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-podcast-img-container {
    &.img-right {
        display: none;
    }
    &.img-left {
        order: 1;
        min-width: 100%;
        padding-right: 0;
    }
    @media screen and (min-width: 768px) {
        &.col-3 {
            min-width: 50%;
            display: block;
        }
        &.img-left {
            order: 1;
            padding-right: 8px;
        }
        &.img-right {
            order: 1;
            padding-left: 8px;
        }
    }
    @media screen and (min-width: 1600px) {
        &.col-3 {
            max-width: 25%;
            min-width: initial;
        }
        &.img-left {
            order: 1;
        }
        &.img-right {
            order: 3;
        }
    }
}

.banner-podcast-text-container {
    @media screen and (min-width: 768px) {
        min-width: 100%;
    }
    @media screen and (min-width: 1600px) {
        min-width: initial;
    }
}

.banner-podcast-img-container {
    @media screen and (max-width: 1599px) {
    &.img-right {
       display: none;
    }
    &.img-left {
        display: none;
    }
  }
}
