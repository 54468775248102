.resources-pp {
    background: #F8F9FA;
    padding: 50px 0;

    @media (min-width: 576px) {
        padding: 92px 0 104px;
    }

    &__container {
        margin: 0 auto;
        max-width: 1414px;
        padding: 0 15px;
    }

    &__rich-text {
        max-width: 588px;
        margin: 0 auto 80px;
        text-align: center;

        * {
            color: #013A64;
            font-size: 18px;
            line-height: 24px;
        }

        ul, ol {
            padding-left: 16px;
        }

        a {
            text-decoration: underline;
        }
    }

    &__title {
        color: #11566E;
        font-family: Caros, sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 40px;
        text-align: center;
    }

    &__row {
        margin: 0 -12px;
        row-gap: 24px;
    }

    &__col {
        padding: 0 12px;
    }

    &__card {
        align-items: flex-start;
        background-color: $white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;
        padding: 32px 32px 62px;
        position: relative;
        text-decoration: initial;

        @media (max-width: 575px) {
            padding: 15px 15px 62px;
        }

        &:hover {
            text-decoration: initial;
        }

        &-text-container {
            margin-bottom: 62px;
        }

        &-title {
            color: #11566E;
            font-family: Caros, sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 26px;
        }

        &-link {
            background-color: initial;
            border: initial;
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-start;
            padding: initial;

            &:hover {
                text-decoration: none;
            }

            &-container {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 16px 32px;
                margin-bottom: 26px;
            }

            &-text {
                font-size: 16px;
                line-height: 24px;
                text-decoration: underline;
                color: #013A64;
                margin-right: 9px;
            }

            .material-icons {
                color: #013A64;
                font-size: 17px;
                margin-bottom: 1px;
            }
        }

        &-file {
            align-items: center;
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;
            justify-content: center;
            text-align: center;

            &-icon {
                width: 16px;
                height: 16px;
            }

            &-text {
                color: #013184;
                font-family: Caros, sans-serif;
                font-size: 15px;
                font-weight: 700;
                line-height: 24px;
            }

            &-size {
                color: #013184;
                font-family: Caros, sans-serif;
                font-size: 12px;
                line-height: 24px;
            }
        }

        &-rich-text {
            * {
                color: #003963;
                font-size: 18px;
                line-height: 24px;

                &:last-child {
                    margin-bottom: initial;
                }
            }

            ul, ol {
                padding-left: 16px;
            }

            a {
                text-decoration: underline;
            }
        }

        &-icon {
            background-color: #003084;
            border-radius: 100% 0 0 0;
            bottom: 0;
            height: 59px;
            position: absolute;
            right: 0;
            width: 67px;

            .material-icons {
                bottom: 14px;
                color: $white;
                position: absolute;
                right: 16px;
            }
        }

        &--contact-us {
            align-items: center;
            border: 1px solid #013184;
            justify-content: center;
            padding-bottom: 32px;

            .resources-pp__card-text-container {
                flex: initial;
                max-width: 304px;
                text-align: center;
            }

            .resources-pp__card-title {
                color: #12576F;
                font-family: Caros, sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 20px;
            }

            .resources-pp__card-rich-text {
                * {
                    color: #12576F;
                    font-size: 14px;
                    line-height: 20px;
                }

            }

            .btn-skin-1 {
                margin: initial;
                color: #12576F;

                .material-icons {
                    color: #013184;
                }
            }
        }
    }
}
