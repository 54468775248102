@import "scss/library/colors";

.template-training-page {
    .form-container {
        border: 0
    }
    .actuality-training-main-content {
        background-color: $grey;
        padding: 100px 150px;
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .section-one {
                max-width: 1000px;
            }
        }
        div.margin-w-40 {
            margin: 0 40px;
        }
        h2 {
            color: $blue-green;
            font-family: Caros,sans-serif;
            font-size: 32px;
            margin: 40px 0;
        }
        p {
            font-size: 18px;
            color: $blue-green;
         }
        .section-one {
            background-color: white;
            border-radius: 5px;
            border-left: 8px solid $skin-light;
            padding: 30px;
            .header-image {
                width: 100%;
                max-height: 230px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                img {
                    width: 100%;
                }
            }
        }
        .form-section-title {
            font-size: 16px;
        }
        .form-radio-section {
            display: flex; gap: 25px
        }
        button[type="submit"] {
            font-size: 15px;
            .material-icons {
                font-size: 20px;
            }
        }
    }
    .section-container-with-shadow {
        background-color: #fff;
        max-width: 1000px;
        width: 100%;
        padding: 40px;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05%);
        .image-wrapper {
            max-width: 400px;
            height: 200px;
            border-radius: 10px;
            width: 100%;
            background-size: cover;
            background-position: center;
        }
    }
    .color-red {color: $red}
}
.title_right_image_left {
    display: flex;
    gap: 25px;
    flex-flow: wrap;
    & > div {
        flex: 45%;
        display: flex;
        align-items: center;
    }
    img {
        border-radius: 10px;
        width: 100%;
        max-width: 400px;
        max-height: 200px;
        overflow: hidden;
    }
    h2 {
       font-size: 22px !important;
    }
    p {
        font-weight: bold;
        font-size: 22px !important;
        b {
            color: $red;
        }
    }
}
.title_list {
    h3 {
        font-size: 32px;
    }
    ul {
        li {
            font-size: 20px;
            color: $blue;
            list-style: none;
            display: flex;
            align-items: baseline;
        }
        li:before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: $red;
            border-radius: 50%;
            margin-right: 10px;
            margin-top: 10px;
            display: block;
            flex: none;
        }
    }
}
.title_button {
    text-align: center;
    font-size: 12px;
    padding: 40px;
    p {
        font-size: 32px;
        font-weight: bold;
    }
}
@media (max-width: 792px) {
    .title_right_image_left {
        text-align: center;
        flex-flow: column-reverse;
        h2 {
            margin: 0 !important;
        }
    }
}

@media (max-width: 992px) {
    .template-training-page {
        .actuality-training-main-content {
            padding: 25px 0;
            .container {
                max-width: fit-content;
            }
        }
        .g-recaptcha, .g-recaptcha > div {
            width: 100% !important;
            max-width: 100% !important;
        }
        .rc-anchor-normal, iframe[title="reCAPTCHA"] {
            width: 100% !important;
        }
    }
}
