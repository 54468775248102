.tool-detail-families-game--section-five {
    background-color: $purple;
    padding-top: 80px;
    padding-bottom: 60px;
    .top-txt-wrapper {
        max-width: 640px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        .top-txt-title {
            color: $white;
            text-align: center;
            margin-bottom: 24px;
        }
        .top-txt-desc {
            font-size: 20px;
            color: $white;
            text-align: center;
            margin-bottom: 0;
        }
    }
    .family-card-container {
        margin: -12px;
        .family-card-item {
            padding: 12px;
        }
    }
    .family-card {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        width: 100%;
        border-radius: 8px;
        transition: transform $time-md ease;
        overflow: hidden;
        background-color: $green;
        text-decoration: none !important;
        .top-txt {
            font-size: 14px;
            color: $white;
            margin-bottom: 16px;
        }
        .bottom-txt {
            font-family: $font-title;
            font-size: rem(24);
            font-weight: 500;
            color: $white;
            margin-bottom: 0;
            h1, h2, h3, h4, p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .left-col {
            padding: 24px 32px;
        }
        .right-col {
            overflow: hidden;
            width: 100%;
            .right-col-img {
                background-size: cover;
                background-position: center center;
                width: 100%;
                height: 100%;
                min-height: 224px;
                transition: transform $time-md ease;
            }
        }
        &.family-card-dark {
            .top-txt, .bottom-txt {
                color: $black;
            }
        }
        &:hover, &:focus {
            transform: translateY(-10px);
            .right-col {
                .right-col-img {
                    transform: scale(1.1);
                }
            }
        }
        &:active, &:active:focus {
            transform: translateY(5px);
        }
    }
    @include breakpoint(sm) {
        padding-top: 120px;
        padding-bottom: 100px;
        .top-txt-wrapper {
            margin-bottom: 60px;
        }
        .family-card {
            flex-direction: row;
            .top-txt, .bottom-txt {
                max-width: 165px;
            }
            .left-col {
                width: calc(50% - 25px);
            }
            .right-col {
                width: calc(50% + 25px);
            }
        }
    }
    @include breakpoint(md) {
        .family-card-container {
            display: flex;
            flex-wrap: wrap;
            .family-card-item {
                width: 50%;
            }
        }
    }
}

.template-game-page {
    .tool-detail-families-game--section-five {
        padding-top: 60px;
    }
    @include breakpoint(sm) {
        .tool-detail-families-game--section-five {
            padding-top: 80px;
            padding-bottom: 120px;
        }
    }
}
