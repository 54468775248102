/* WIDGETS
=========================== */

.nixa-widget {
  padding: 20px 20px 20px 10px;
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
  z-index: 0;
  text-decoration: none!important;
  .nixa-logo {
    position: absolute;
    top: 2px;
    bottom: 0;
    left: 20px;
    width: 30px;
    height: 40px;
    margin: auto;
    transform: scale(0);
    opacity: 0;
    transition: all 500ms ease;
    .nixa-logo-img {
      max-width: 100%;
    }
  }
  .nixa-text {
    text-transform: uppercase;
    color: $black;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.1em;
    transition: all 500ms ease;
  }
  &:after, &:before {
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    transition: all 500ms ease;
    transform: scaleX(0);
    border-radius: 100px;
    box-shadow: none;
    opacity: 0;
  }
  &:before {
    background-color: $color-primary;
  }
  &:after {
    background-image: url('../img/nixa-pattern.png');
    opacity: 0.1!important;
    @extend .scroll;
  }
  &:hover, &:focus {
    text-decoration: none;
    transform: translateX(-25px);
    .nixa-logo {
      transform: scale(1);
      opacity: 1;
    }
    .nixa-text {
      color: $white;
      transform: translateX(50px);
    }
    &:after, &:before {
      right: -50px;
      transform: scaleX(1);
      box-shadow: 0 5px 15px 0 rgba($color-primary, 0.4);
    }
    &:before {
      opacity: 1;
    }
  }
  &:focus {
    &:after, &:before {
      opacity: 0.9;
    }
  }
  &:active {
    &:after, &:before {
      opacity: 0.8;
      box-shadow: none;
      transform: scaleX(0.95);
    }
  }
}

.loading-widget {
    position: fixed;
    z-index: 2147483647;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: 1000ms ease opacity;
    .loading-center-container {
        opacity: 0;
        &.loading {
            opacity: 1;
        }
    }
    .loading-center {
        padding: 15px;
        width: 200px;
        height: 130px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        @extend .pulse-loop;
        .loading-image {
            @extend .pulse-loop;
            width: 100%;
        }
    }
    &:before, &:after {
        content: "";
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
        z-index: -1;
        background-color: $blue-green;
        transition: all 1000ms ease;
    }
    &:before {
        left: 0;
    }
    &:after {
        left: 50%;
    }
}

.loaded {
    .loading-widget {
        opacity: 0;
        //&:before {
        //    left: -50%;
        //}
        //&:after {
        //    left: 100%;
        //}
        &:before {
            top: 100%;
        }
        &:after {
            top: 100%;
        }
    }
}

.unloading {
    .loading-widget {
        opacity: 1;
        &:before {
            left: 0;
        }
        &:after {
            left: 50%;
        }
    }
}

.resp-video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    width: 100%;
    background-color: $skin-dark;
    .video-button {
        display: flex;
        align-items: center;
        background-color: #f5cdb1;
        padding: 20px 20px 20px 40px;
        font-size: 13pt;
        font-weight: bold;
        border-radius: 50px;
        color: $blue;
        cursor: pointer;
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        overflow: hidden;
        span {
            margin-left: 10px;
        }
        img {
            width: 30px !important;
            margin-left: 10px;
            margin-bottom: 0 !important;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            left: -5%;
            width: 110%;
            padding-bottom: 110%;
            border-radius: 50%;
            background-color: $skin-dark;
            transform: scale(0);
            transition: transform $time-sm ease, background-color $time-sm ease;
        }
        &:hover, &:focus {
            &:after {
                transform: scale(1);
            }
        }
        &:active, &:active:focus {
            &:after {
                background-color: darken($skin-dark, 6%);
                transform: scale(1);
            }
        }
    }
    img, video, iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.animated-title {
    position: relative;
    z-index: 4;
    .animated-title-item {
        transition: opacity $time-md ease;
    }
    span {
        display: inline-block;
        transform: translateY(-7px);
        opacity: 0;
        transition: opacity $time-lg ease, transform $time-lg ease;
        &.animated-title-letter {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.animated-title-small {
    opacity: 0;
    @extend .animated-title;
}


.animated-title-2 {
    color: $blue;
    min-height: 160px;
    display: flex;
    justify-content: center;
    z-index: 4;
    position: relative;
    opacity: 0;
    transition: all 0.5s ease;
    &.show {
        opacity: 1;
    }
    span {
        color: $red;
    }
    &.whitter {
        color: white;
        text-shadow: 0 0 10px #000000b8;;
        span {
            color: white;
        }
    }
    .animated-title-2-content {
        position: fixed;
        top: 160px
    }
}

@media (max-width: 990px) {
    .animated-title-2 {
        min-height: auto;
        .animated-title-2-content {
            position: initial;
            top: 0;
        }
    }
}

.info-text-widget {
    .info-text-logo {
        margin-bottom: 20px;
    }
    .info-text-content {
        .info-text-title {
            margin-bottom: 20px;
            h1, h2, h3, h4, p {
                font-family: $font-title;
                font-weight: 700;
                font-size: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .info-text-text {
            p {
                font-size: 20px;
            }
        }
    }
    @include breakpoint(md) {
        display: flex;
        align-items: flex-start;
        .info-text-logo {
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: 0;
            margin-right: 18px;
        }
        .info-text-content {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}
