.flex-accordion {
    background-color: inherit;
    padding: 60px 0;
    .padding-top-80px {
        padding-top: 80px;
    }
    .flex-accordion-title {
        font-family: $font-content;
        font-size: 14px;
        text-transform: uppercase;
        color: $red;
        font-weight: 400;
        margin-bottom: 20px;
    }
    .flex-accordion-container {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .card {
        padding: 0;
        margin-bottom: 12px;
        border-radius: 8px;
        border: 0;
        box-shadow: 0 2px 20px $grey-shadow;
        .card-header {
            padding: 0;
            background-color: transparent;
            border-bottom: 0;
            .btn-link {
                display: flex;
                align-items: center;
                padding: 20px;
                width: 100%;
                text-align: left;
                text-decoration: none;
                text-transform: initial;
                letter-spacing: initial;
                border-left: 8px solid $skin-light;
                border-top-left-radius: 8px;
                .card-header-maincontent {
                    flex-grow: 1;
                    flex-shrink: 1;
                }
                .icon-avatar-col {
                    margin-bottom: 14px;
                    .icon-avatar {
                        height: 45px;
                        width: 45px;
                        background-color: $grey;
                        border-radius: 50%;
                        img {
                            border-radius: 50%;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .full-name-col {
                    margin-bottom: 14px;
                    .name-section {
                        font-family: $font-title;
                        font-size: 20px;
                        font-weight: 500;
                        color: $red;
                    }
                }
                .job-title-col {
                    .text-section {
                        p {
                            color: $blue;
                            font-size: 16px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .icon-circle-col {
                    flex-shrink: 0;
                    flex-grow: 0;
                    margin-left: 15px;
                    .icon-section {
                        .icon-section-icon {
                            margin-left: auto;
                            margin-right: auto;
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            background-color: $skin;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            z-index: 1;
                            .icon-bar {
                                position: absolute;
                                width: 16px;
                                height: 2px;
                                background-color: $blue;
                                border-radius: 2px;
                                transform: rotate(0);
                                transform-origin: center center;
                                transition: transform $time-sm ease;
                            }
                            &:after {
                                content: "";
                                position: absolute;
                                z-index: -1;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                background-color: $skin-dark;
                                transform: scale(0);
                                transition: transform $time-sm ease, background-color $time-sm ease;
                            }
                        }
                    }
                }
                &.collapsed {
                    border-color: $white;
                    border-bottom-left-radius: 8px;
                    .full-name-col {
                        .name-section {
                            color: $blue;
                        }
                    }
                    .icon-circle-col {
                        .icon-section {
                            .icon-section-icon {
                                .icon-bar:last-child {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }
                &:hover, &:focus {
                    border-color: $skin-light;
                    .full-name-col {
                        .name-section {
                            color: $red;
                        }
                    }
                    .icon-circle-col {
                        .icon-section {
                            .icon-section-icon {
                                &:after {
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                }
                &:active, &:active:focus {
                    border-color: $skin-dark;
                    .full-name-col {
                        .name-section {
                            color: darken($red, 6%);
                        }
                    }
                    .icon-circle-col {
                        .icon-section {
                            .icon-section-icon {
                                &:after {
                                    background-color: darken($skin-dark, 6%);
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                }
            }
        }
        .card-collapse {
            .card-body {
                border-radius: 0 0 8px 8px;
                border-left: 8px solid $skin-light;
                padding: 0 20px 32px 20px;
                .card-body-content {
                    padding: 20px 0 0 0;
                    border-top: 1px solid $grey-alpha;
                    font-size: 16px;
                    p:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        padding: 80px 0;
        .card {
            .card-header {
                .btn-link {
                    padding: 24px;
                    .card-header-maincontent {
                        display: flex;
                        align-items: center;
                    }
                    .icon-avatar-col {
                        flex-grow: 0;
                        flex-shrink: 0;
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                    .full-name-col {
                        flex-grow: 0;
                        flex-shrink: 0;
                        width: 282px;
                        margin-bottom: 0;
                        margin-right: 32px;
                    }
                    .job-title-col {
                        flex-grow: 1;
                        flex-shrink: 1;
                        margin-right: 32px;
                        .text-section {
                            max-width: 432px;
                        }
                    }
                    .icon-circle-col {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
