/* PAGES */
html {
    scroll-behavior: smooth;
}

/* Custom scroll styles */
* {
    scrollbar-width: auto;
    scrollbar-color: #d1dde6 #fff; /* Thumb color, track color */
}

.burger-open {
    height: 100vh;
    overflow: hidden;
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
