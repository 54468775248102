.repertoire-bottom-banner {
    .atcs-header {
        background-color: $blue-light;
    }
    .about-team-contact-section {
        .atcs-body {
            background-color: #fff;
            .circle-elem:after {
                background-color: $blue-light;
            }
        }
    }

}
