/* BUTTONS
===========================
Important:
- Place button variations in btn mixin
- You should be able to combine all variants without any issue
 */

a, .btn, button {
    text-decoration: none;
    @include transition-md();
    &:focus, &:active {
        outline: none !important;
        text-decoration: none;
    }
}

.btn-default, .btn {
    font-family: $font-content;
    font-size: 14px;
    padding: 10px 50px;
    border-radius: 0;
    border: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

@mixin btn ($background, $color) {
    @extend .btn-default;
    background-color: $background;
    color: $color;
    &:hover, &:focus, &.active {
        background-color: lighten($background, 5%);
        box-shadow: 0 4px 30px -5px lighten($background, 5%);
        color: $color;
        &.btn-action .btn-action-icon {
            background-color: lighten($background, 15%);
        }
    }
    &:active, &:active:focus, &:active:hover {
        background-color: darken($background, 5%);
        box-shadow: 0 4px 15px -5px darken($background, 5%);
        color: $color;
        .btn-action-icon {
            background-color: $background;
        }
    }
    .open>&.dropdown-toggle {
        &, &:focus, &:active, &:focus {
            background-color: darken($background, 5%);
        }
    }
    &.btn-outline {
        background-color: transparent;
        color: $background;
        border: 2px solid $background;
        &:hover, &:focus, &.active {
            background-color: $background;
            box-shadow: 0 4px 30px -5px lighten($background, 5%);
            color: $color;
        }
        &:active, &:active:focus, &:active:hover {
            background-color: darken($background, 5%);
            box-shadow: 0 4px 15px -5px darken($background, 5%);
            color: $color;
        }
    }
    &.btn-sm {
        padding: 16px 30px !important;
    }
}

.btn-primary { @include btn($color-primary, $white) }
.btn-grey { @include btn($grey-light, $color-primary) }
.btn-grey-dark { @include btn($grey, $white) }
.btn-white { @include btn($white, $color-primary) }

.btn-full {
    width: 100%;
}

.btn-blue-1 {
    position: relative;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $blue;
    text-decoration: none !important;
    font-family: $font-title;
    font-size: 15px;
    font-weight: 700;
    color: $white;
    border-radius: 32px;
    padding: 20px 35px;
    margin: 5px;
    border: none;
    overflow: hidden;
    .material-icons, svg {
        font-size: 20px;
        margin-right: 5px;
    }
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 110%;
        padding-bottom: 110%;
        border-radius: 50%;
        background-color: darken($blue, 8);
        transform: scale(0);
        transition: transform $time-sm ease, background-color $time-sm ease;
    }
    &.btn-sm {
        padding: 16px 30px !important;
    }
    &:hover, &:focus {
        color: $white;
        &:after {
            transform: scale(1);
        }
    }
    &:active, &:active:focus {
        color: $white;
        &:after {
            background-color: darken($blue, 12);
            transform: scale(1);
        }
    }
}

.btn-blue-1-alt {
    @extend .btn-blue-1;
    padding: 21px 30px;
    margin: 0;
    .btn-blue-1-alt-content {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .material-icons {
        font-size: 15px;
    }
}

.btn-blue-outline-1 {
    @extend .btn-blue-1;
    background-color: transparent;
    border: 1px solid $blue;
    color: $blue;
    font-weight: bold;
    &:after {
        background-color: #00407133;
    }
    &:hover, &:focus {
        color: $blue;
    }
    &:active, &:active:focus {
        color: $blue;
        &:after {
            background-color: $blue-alpha;
        }
    }
}

.btn-skin-1 {
    @extend .btn-blue-1;
    background-color: $skin;
    color: $blue;
    font-weight: bold;
    &:after {
        background-color: $skin-dark;
    }
    &:hover, &:focus {
        color: $blue;
    }
    &:active, &:active:focus {
        color: $blue;
        &:after {
            background-color: darken($skin-dark, 6%);
            transform: scale(1);
        }
    }
}

.btn-skin-alpha-1 {
    @extend .btn-blue-1;
    background-color: $skin-alpha;
    color: $red;
    font-weight: bold;
    &:after {
        background-color: $red;
    }
    &:hover, &:focus {
        color: $white;
    }
    &:active, &:active:focus {
        color: $white;
        &:after {
            background-color: darken($red, 6%);
            transform: scale(1);
        }
    }
}

.btn-blue-to-orange-1 {
    @extend .btn-blue-1;
    &:after {
        background-color: $red;
    }
    &:active, &:active:focus {
        &:after {
            background-color: darken($red, 4);
        }
    }
}
.btn-orange-to-orange-1 {
    @extend .btn-blue-1;
    background-color: $red;
    &:after {
        background-color: #bd3a21;
    }
    &:active, &:active:focus {
        &:after {
            background-color: darken(#bd3a21, 4);
            transform: scale(1);
        }
    }
    &:hover {
        color: white !important;
    }
}

.btn-white-1 {
    @extend .btn-blue-1;
    background-color: $white;
    color: $blue;
    font-weight: bold;
    &:after {
        background-color: $grey-hover;
    }
    &:hover, &:focus {
        color: $blue;
    }
    &:active, &:active:focus {
        color: $blue;
        &:after {
            background-color: darken($grey-hover, 5);
            transform: scale(1);
        }
    }
}

.btn-text-small {
    font-weight: 400;
    font-size: 13px;
}

.btn-blue-to-orange-short {
    @extend .btn-blue-to-orange-1;
    width: fit-content;
    padding: 15px 25px;
    .icon-east {
        display: inline;
    }
    .icon-open-in-new {
        display: none;
    }
    &:hover {
        .icon-east {
            display: none;
        }
        .icon-open-in-new {
            display: inline;
        }
    }
}

.btn-skin-pail {
    display: flex;
    width: fit-content;
    color: $red;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    background-color: $skin-pail;
    padding: 12px 24px;
    margin-bottom: 12px;
    border-radius: 4px;
    span {
        margin-right: 10px;
        &:last-child {
            margin-bottom: 3px;
        }
    }
    &:hover {
        background-color: $red;
        color: $white;
        text-decoration: none;
    }
}

@mixin hover-bg ($background) {
    background-color: $background;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 110%;
        padding-bottom: 110%;
        border-radius: 50%;
        transform: scale(0);
        transition: transform $time-sm ease, background-color $time-sm ease;
        background-color: darken($background, 8);
    }
    &:hover {
        text-decoration: none;
        &:after {
            transform: scale(1);
        }
    }
}
