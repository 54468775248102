.fo-ti-wrapper {
    .fo-ti-text-container {
        margin-bottom: 32px;
        .fo-ti-text {
            font-size: 20px;
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    .fo-ti-img-container {
        .fo-ti-img {
            width: 100%;
            max-width: 500px;
            border-radius: 24px;
        }
    }
    @include breakpoint(sm) {
        display: flex;
        align-items: center;
        .fo-ti-text-container {
            flex: 1;
            padding-right: 10px;
            margin-bottom: 0;
            .fo-ti-text {
                max-width: 414px;
            }
        }
        .fo-ti-img-container {
            flex: 1;
            padding-left: 10px;
        }
    }
}

.fo-text-img-section {
    padding-top: 40px;
    padding-bottom: 60px;
    @include breakpoint(lg) {
        padding-top: 60px;
        padding-bottom: 94px;
    }
}
