/* Default Stream Form
=========================== */

.default-stream-form-main-content-wrapper {
    background-color: $grey;
    padding-top: 60px;
    padding-bottom: 80px;
    .container {
        max-width: 1000px;
    }
    .form-section {
        align-content: center;
        .title {
            font-weight: bold;
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            .title-content {
                p, h1, h2, h3 {
                    margin-bottom: 0;
                }
            }
            .far {
                flex-grow: 0;
                flex-shrink: 0;
                background-color: #f7ebea;
                color: $red;
                border-radius: 50%;
                margin-right: 12px;
                margin-top: -3px;
                width: 48px;
                height: 48px;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .label-title {
            font-size: 16px;
            margin-bottom: 10px;
        }
        input:not([type=submit]), textarea, select {
            border-radius: 30px;
            background-color: $white;
            border: 1px solid $grey-alpha;
            width: 100%;
            padding: 20px 32px;
            margin-bottom: 24px;
            font-size: 16px;
            color: $blue;
            &::placeholder {
                color: $blue-alpha;
            }
        }
        ul{
            li{
                display: inline-block;
                margin-left: 30px;
            }
            list-style:none;
            padding-left:0;

        }
        .select-container {
            position: relative;
            margin-bottom: 24px;
            &:after {
                @include material-icon("expand_more");
                pointer-events: none;
                font-size: 26px;
                position: absolute;
                top: 50%;
                right: 28px;
                transform: translateY(-50%);
            }
            select {
                @include apprearance();
                padding-right: 55px;
                margin-bottom: 0;
            }
        }
        .form-bottom {
            margin-bottom: 32px;
            .form-bottom-text {
                font-size: 12px;
                margin-bottom: 10px;
            }
            .form-bottom-button {
                margin: 0;
            }
        }
    }
    @include breakpoint(sm) {
        .form-section {
            .form-bottom {
                display: flex;
                align-items: center;
                .form-bottom-text {
                    margin-bottom: 0;
                    margin-right: 20px;
                    flex-shrink: 1;
                    flex-grow: 1;
                }
                .form-bottom-button {
                    flex-shrink: 0;
                    flex-grow: 0;
                }
            }
        }
    }
    @include breakpoint(md) {
        .form-section {
            .form-bottom {
                margin-bottom: 0;
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 120px;
    }
}
