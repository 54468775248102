.advice-container {
    .advice-container-block {
        margin-bottom: 60px;
        &.cms-content {
            h2 {
                &:not(:last-child) {
                    margin-bottom: 40px;
                }
            }
            h3, h4 {
                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
            ul {
                margin-bottom: 24px;
                padding-left: 0;
                list-style: none;
                li {
                    position: relative;
                    margin-bottom: 16px;
                    padding-left: 20px;
                    list-style: none;
                    &:before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $red;
                        position: absolute;
                        top: 9px;
                        left: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .banner-info {
            padding: 0;
            .banner-info-title {
                margin-top: 2px;
                margin-bottom: 20px;
                h1, h2, h3, p {
                    font-size: 20px;
                    font-weight: 700;
                    b {
                        font-weight: 700;
                        color: $red;
                    }
                }
            }
            .banner-info-text {
                p {
                    font-size: 16px;
                    padding-left: 0;
                }
                a:not([class^="btn"]):not(.li-link) {
                    color: $blue-link;
                    position: relative;
                    text-decoration: underline !important;
                    transition: color $time-sm ease;
                    &:hover, &:focus {
                        color: darken($blue-link, 8%);
                    }
                    &:active, &:active:focus {
                        color: darken($blue-link, 12%);
                    }
                }
                ul {
                    margin-bottom: 24px;
                    padding-left: 0;
                    list-style: none;
                    li {
                        position: relative;
                        margin-bottom: 8px;
                        padding-left: 20px;
                        font-size: 16px;
                        list-style: none;
                        &:before {
                            content: "";
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: $red;
                            position: absolute;
                            top: 9px;
                            left: 0;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                p:last-child {
                    margin-bottom: 0;
                }
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
            .banner-one-content {
                p {
                    padding-left: 0;
                }
                .icon {
                    position: static;
                    display: block;
                    margin-bottom: 20px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    @include breakpoint(sm) {
        .advice-container-block {
            .banner-info {
                .banner-one-content {
                    .icon {
                        display: inline-block;
                        position: absolute;
                        margin-bottom: 0;
                    }
                }
                .banner-info-title, .banner-info-text {
                    padding-left: 55px;
                }
            }
        }
    }
}
