.for-organizations-caregiver-info-section-content {
    padding-top: 20px;
    padding-bottom: 60px;
    .banner-info {
        background: transparent;
        padding: 0;
        margin-bottom: 40px;
        .banner-info-title {
            margin-top: 2px;
            font-size: 20px;
            font-weight: 700;
        }
        .banner-info-text {
            font-size: 16px;
            a:not([class^="btn"]):not(.li-link) {
                color: $blue-link;
                position: relative;
                text-decoration: underline !important;
                transition: color $time-sm ease;
                &:hover, &:focus {
                    color: darken($blue-link, 8%);
                }
                &:active, &:active:focus {
                    color: darken($blue-link, 12%);
                }
            }
            ul {
                margin-bottom: 24px;
                padding-left: 55px;
                list-style: none;
                li {
                    position: relative;
                    margin-bottom: 16px;
                    padding-left: 20px;
                    list-style: none;
                    &:before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $red;
                        position: absolute;
                        top: 9px;
                        left: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            p:last-child {
                margin-bottom: 0;
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
        .news-article-block-title {
            margin-bottom: 40px;
        }
        h1, h2, h3 {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .banner-info {
            margin-bottom: 60px;
        }
    }
}
