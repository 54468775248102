.fo-section-four {
    padding: 50px 25px;
    .container {
        max-width: 995px;
        .content-flex {
            display: flex;
            align-content: center;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }
        p {
            font-size: 14pt;
            color: $blue;
        }
    }
}
