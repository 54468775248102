.pleasure-section-thumbs {
    width: 1000px;
    margin: auto;
    //margin-left: calc(100% / 5.5);
    margin-left: calc(100% / 4.5);
    margin-bottom: 100px;
    column-gap: 15px;
    img {
        width: 100%;
    }
    .figure {
        float: left;
        display: flex;
        overflow: hidden;
        position: relative;
        margin: 5px;
    }
    .overlay {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#004cff+0,000000+100&0.65+0,0+100 */
        background: -moz-linear-gradient(top,  rgba(0,76,255,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,76,255,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,76,255,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6004cff', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        opacity: 0;
        transition: opacity 0.5s linear;
        p {
            color: white;
            text-align: center;
            padding: 10px 0;
            margin-top: -50px;
            width: 100%;
            align-self: center;
        }
        .social-share {
            height: 100%;
            width: 100%;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .social-link {
                margin: 5px;
                text-decoration: none;
            }
            .fab {
                width: 30px;
                height: 30px;
                color: wheat;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
        }
    }
    .figure:hover {
        .overlay {

            opacity: 1;
        }
    }
    .card-1 {
        width: calc(100% / 3 - 10px);}
    .card-2 {
        width: calc(100% / 4.5 * 2 );}
    .card-3 {
        width: calc(100% / 3 - 10px);
        margin-top: -67px;
    }
    .card-4 {
        width: calc(100% / 4.4 - 10px);
    }
    .card-5 {
        width: calc(100% / 4.3 - 16px);
    }
    .card-6 {
        width: calc(100% / 4.5 * 2);
        margin-top: 5px;
    }
    .card-7 {
        width: calc(100% / 3 - 10px);
    }
    .card-8 {
        width: calc(100% / 3 * 1 - 10px);
        margin-top: 14px;
    }
    .card-9 {
        width: calc(100% / 4.4 - 10px);
        margin-top: -149px;
    }
    .card-10 {
        width: calc(100% / 4.4 - 10px);
        margin-top: -149px;
    }
    .card-11 {
        width: calc(100% / 3 - 10px);
        margin-top: 5px;
    }
    .card-12 {
        width: calc(100% / 4.5 * 2 );
        margin-top: -220px;
    }
    .card-13 {
        width: calc(100% / 3 - 10px);
        margin-top: 15px;
    }
    .card-14 {
        width: calc(100% / 4.4 - 10px);
        margin-top: -149px;
    }
    .card-15 {
        width: calc(100% / 4.4 - 10px);
        margin-top: -149px;
    }
}

@media (max-width: 1300px) {
    .pleasure-section-thumbs {
        margin-left: calc(100% / 6);
    }
}
@media (max-width: 1218px) {
    .pleasure-section-thumbs {
        margin-left: calc(100% / 7);
    }
}
@media (max-width: 1185px) {
    .pleasure-section-thumbs {
        width: auto;
        margin-left: calc(100% / 7);
    }
}
@media (max-width: 1178px) {
    .pleasure-section-thumbs {
        width: auto;
        margin-left: auto;
        .card-1 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-2 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-3 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-4 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-5 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-6 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-7 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-8 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-9 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-10 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-11 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-12 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-13 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-14 { width: calc(100% / 2 - 10px); margin: 5px }
        .card-15 { width: calc(100% / 2 - 10px); margin: 5px }

    }
}

#social-share-modal {
    .model-content, .modal-header, .modal-body, .modal-footer {
        background-color: #DEECEF !important;
        background: #DEECEF !important;
    }
    .modal-title {
        color: black;
        text-transform: uppercase;
    }
    label {
        color: black;
        font-weight: bold;
    }
    input {
        margin-bottom: 5px;
        border: 0;
        width: 100%;
        height: 35px;
    }
    textarea {
        border: 0;
        width: 100%;
        height: 80px;
    }
    .btn-primary {
        background-color: #E5503F;
        &:hover {
            background-color: #36ba54;
        }
    }
    .img-container {
        img {
            width: 100%;
        }
    }
}

$thumb-gap : 25px;
.pleasure-section-thumbs2 {
    max-width: 1016px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: $thumb-gap;
    .cards-row {
        width: 100%;
        display: flex;
        gap: $thumb-gap;
        height: 560px;
        div.thumb-card {
            background-position: center;
            background-size: cover;
        }
        .children-2 {
            width: 41.66%;
            display: flex;
            gap: $thumb-gap;
            flex-direction: column;
            height: 100%;
            div.thumb-card {
                background-color: #00cc66;
                min-height: 5vh;
                flex: 1;
            }
        }
        .children-2-only {
            @extend .children-2;
            width: 100%;
            flex-direction: row;
        }
        .children-3 {
            width: calc(100% - 41.66%);
            flex-direction: column;
            display: flex;
            gap: $thumb-gap;
            div.thumb-card.nth-1 {
                flex: 1.5;
                background-color: #0f74a8;
            }
            div.thumb-card-row {
                flex: 0.5;
                gap: $thumb-gap;
                display: flex;
                div.nth-1 {
                    background-color: red;
                    flex: 1;
                }
                div.nth-2 {
                    background-color: darkred;
                    flex: 1;
                }
            }
        }
    }

    .overlay {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#004cff+0,000000+100&0.65+0,0+100 */
        background: -moz-linear-gradient(top,  rgba(0,76,255,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,76,255,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,76,255,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6004cff', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
        height: 100%;
        width: 100%;
        flex-direction: column;
        display: flex;
        opacity: 0;
        transition: opacity 0.5s linear;
        justify-content: center;
        p {
            color: white;
            text-align: center;
            width: 100%;
            align-self: center;
        }
        .social-share {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .social-link {
                margin: 5px;
                text-decoration: none;
            }
            .fab {
                width: 30px;
                height: 30px;
                color: wheat;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
        }
    }
    .overlay:hover {
        opacity: 1;
    }
    .share-icon{
        display: none;
    }
    @media (max-width: 992px) {
        .cards-row {
            flex-direction: column;
            height: auto;
            gap: 25px;
            .children-2 {
                flex-direction: column;
                width: 100%;
            }
            .children-3 {
                flex-direction: column;
                width: 100%;
                div.thumb-card-row {
                    flex-direction: column;
                }
            }
            .thumb-card {
                min-height: 200px !important;
            }
        }
    }
    @media (max-width: 600px) {
        .share-icon {
            float: right;
            color: white;
            background-color: #004071;
            padding: 10px;
            opacity: 0.7;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px;
        }
        .overlay {
            height: 100vw;
            max-height: 200px;
            width: 50px;
            background: rgba(255,255,255,0.8);
            opacity: 1;
            display: none;
            p {
                display: none;
            }
            .social-share {
                flex-direction: column;
                max-width: 50px;
                height: 200px;
                justify-content: space-around;
            }
        }
    }
}
