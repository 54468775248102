.about-team-contact-section {
    .atcs-header {
        img {
            width: 100%;
        }
    }
    .atcs-body {
        background-color: $blue-light;
        width: 100%;
        padding: 60px 10px 60px 10px;
        .atcs-body-content {
            width: 100%;
            max-width: 942px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        }
        .circle-elem {
            width: 100%;
            max-width: 430px;
            position: relative;
            &:after {
                content: "";
                display: block;
                background-color: $white;
                border-radius: 50%;
                padding-bottom: 100%;
            }
        }
        .circle-elem-container {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 50%;
            overflow: hidden;
        }
        .circle-content {
            max-width: 265px;
            width: 100%;
        }
        .title-small {
            margin-bottom: 6px;
        }
        .title {
            margin-bottom: 12px;
            p, h1, h2, h3 {
                margin-bottom: 0;
            }
        }
        a {
            margin: 0;
            padding: 10px 20px;
            max-width: calc(100% - 20px);
        }
        .bubbles {
            min-width: 25px;
            min-height: 25px;
            background-color: white;
            border-radius: 50%;
            position: absolute;
            display: none;
            overflow: hidden;
            z-index: 0;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .bubbles:nth-child(1) {
            background-color: $skin-light;
            width: 55px;
            height: 55px;
            left: 70px;
            top: 35px;
        }
        .bubbles:nth-child(2) {
            width: 155px;
            height: 155px;
            left: -10px;
            top: 205px;
        }
        .bubbles:nth-child(3) {
            background-color: #003084;
            width: 35px;
            height: 35px;
            left: 160px;
            top: 400px;
        }
        .bubbles:nth-child(4) {
            width: 105px;
            height: 105px;
            right: 100px;
            top: -45px;
        }
        .bubbles:nth-child(5) {
            background-color: $red;
            width: 75px;
            height: 75px;
            right: 0;
            top: 80px;
        }
        .bubbles:nth-child(6) {
            width: 105px;
            height: 105px;
            right: 35px;
            top: 285px;
        }
        .circle-elem {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }
    }
    @include breakpoint(sm) {
        .atcs-body {
            padding: 60px 20px 60px 20px;
            .title-small {
                margin-bottom: 16px;
            }
            .title {
                margin-bottom: 40px;
            }
            a {
                max-width: 100%;
                padding: 20px 35px;
            }
        }
    }
    @include breakpoint(md) {
        .atcs-body {
            .bubbles {
                display: block;
            }
        }
    }
    @include breakpoint(lg) {
        .atcs-body {
            padding: 0 20px 160px 20px;
            .bubbles:nth-child(1) {
                left: 70px;
                top: 30px;
            }
            .bubbles:nth-child(2) {
                left: 0;
                top: 265px;
            }
            .bubbles:nth-child(3) {
                left: 250px;
                top: 470px;
            }
            .bubbles:nth-child(4) {
                right: 160px;
                top: -70px;
            }
            .bubbles:nth-child(5) {
                top: 100px;
            }
            .bubbles:nth-child(6) {
                right: 75px;
                top: 340px;
            }
        }
    }
}

.about-jobs-contact-section {
    .atcs-body {
        padding-top: 0;
    }
}
