.par-cards-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $grey;
    .par-cards-title {
        text-align: center;
        margin-bottom: 40px;
    }
    .par-cards {
        display: flex;
        flex-wrap: wrap;
        margin: -12px;
        .par-card-single-container {
            padding: 12px;
            width: 100%;
        }
    }
    .icon-link {
        position: absolute;
        background-color: $blue;
        right: 0;
        bottom: 0;
        border-radius: 70px 0 10px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        padding: 10px 0 0 10px;
        z-index: 1;
        transition: background-color $time-sm ease;
    }
    .card-unit {
        text-decoration: none;
        background-color: white;
        box-shadow: 0 2px 20px $grey-shadow;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        z-index: 1;
        min-height: 100%;
        .card-unit-top {
            flex-grow: 1;
            padding: 20px;
            min-height: 150px;
            transition: all .2s ease-in-out;
            color: $blue;
        }
        .card-unit-bottom {
            background-color: $grey-light;
            min-height: 150px;
            transition: all .2s ease-in-out;
            position: relative;
            overflow: hidden;
            .card-unit-bottom-img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-position: center center;
                background-size: cover;
                transform-origin: center center;
                transition: transform $time-sm ease;
            }
        }
        &:after {
            content: "";
            background-color: $grey-light;
            width: 125%;
            padding-bottom: 125%;
            min-height: 500px;
            min-width: 500px;
            border-radius: 50%;
            position: absolute;
            bottom: 50%;
            left: 50%;
            z-index: -1;
            transform: translateY(50%) translateX(-50%) scale(0);
            transform-origin: center center;
            transition: transform $time-sm ease, background-color $time-sm ease;
        }
        &:hover, &:focus {
            .card-unit-bottom {
                .card-unit-bottom-img {
                    transform: scale(1.1);
                }
            }
            .icon-link {
                background-color: darken($blue, 8);
            }
            &:after {
                transform: translateY(50%) translateX(-50%) scale(1);
            }
        }
        &:active, &:active:focus {
            .card-unit-bottom {
                .card-unit-bottom-img {
                    transform: scale(1.1);
                }
            }
            .icon-link {
                background-color: darken($blue, 12);
            }
            &:after {
                background-color: darken($grey-light, 3%);
                transform: translateY(50%) translateX(-50%) scale(1);
            }
        }
    }
    @include breakpoint(sm) {
        .par-cards {
            .par-card-single-container {
                width: calc(100% / 2);
            }
        }
        .card-unit {
            .card-unit-top {
                padding: 32px;
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
        .par-cards {
            .par-card-single-container {
                width: calc(100% / 3);
            }
        }
    }
}

.fo-par-cards-wrapper {
    padding-top: 0;
    background-color: $white;
    position: relative;
    z-index: 2;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 118px;
        width: 100%;
        background-color: $skin-light;
        z-index: -1;
    }
    .center-curve-wrapper {
        height: 172px;
        overflow: hidden;
        width: 100%;
        position: absolute;
        top: 117px;
        left: 0;
        z-index: -1;
        .center-curve {
            position: absolute;
            width: 110%;
            bottom: 0;
            left: -5%;
            height: 585px;
            border-radius: 50%;
            background: $skin-light;
        }
    }
}

.fo-par-cards-wrapper-black {
    padding-top: 0;
    background-color: #141414;

    .par-cards-title {
        color: $white;
    }
}

.fo-par-cards-wrapper-tilde {
    padding-top: 0;
    background-color: $green;

    .par-cards-title {
        color: $green-dark;
    }
}


