.family-video-wrapper {
    background-color: rgb(87, 190, 190);
    padding: 5rem 0;
    .family-video-container {
        position: relative;
        background-color: transparent;

        video {
            width: 100%;
            max-width: 800px;
        }

        //video, iframe {
        //    top: -242px;
        //    left: -200px;
        //    width: 180%;
        //    height: 180%;
        //    position: absolute;
        //    @media (min-width: 992px) and (max-width: 1199px) {
        //        top: -245px;
        //        left: -296px;
        //        width: 210%;
        //    }
        //    @media (max-width: 768px) {
        //        top: -50%;
        //        left: -50%;
        //        width: 220%;
        //        height: 200%;
        //    }
        //}
        //video::-webkit-media-controls-panel {
        //    display: none !important;
        //    opacity: 1 !important;
        //}
        //video::-webkit-media-controls-start-playback-button {
        //    display: none;
        //}
        //
        //video::-webkit-media-controls {
        //    display: none;
        //}

        .video-button-new {
            display: flex;
            align-items: center;
            background-color: #f5cdb1;
            padding: 20px 20px 20px 10px;
            font-size: 17px;
            font-weight: bold;
            border-radius: 50px;
            color: $blue;
            cursor: pointer;
            position: absolute;
            z-index: 5;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            overflow: hidden;

            span {
                margin-left: 10px;
            }

            img {
                width: 30px !important;
                margin-left: 10px;
                margin-bottom: 0 !important;
            }

            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                left: -5%;
                width: 110%;
                padding-bottom: 110%;
                border-radius: 50%;
                background-color: $skin-dark;
                transform: scale(0);
                transition: transform $time-sm ease, background-color $time-sm ease;
                overflow: hidden;
            }

            &:hover, &:focus {
                &:after {
                    transform: scale(0);
                }
            }
            &:active, &:active:focus {
                &:after {
                    background-color: darken($skin-dark, 6%);
                    transform: scale(1);
                }
            }
        }
    }
}
