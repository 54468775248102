.cop-details-section-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    .cop-details-section {
        max-width: 1000px;
        margin: 0 auto;
        border-bottom: 1px solid $blue-grey;
        .col-left {
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;
            flex-direction: column;
            padding: 32px 20px;
            .date-section {
                display: flex;
                color: $blue;
                font-size: 14px;
                align-items: center;
                margin-bottom: 20px;
                opacity: .7;
                .material-icons {
                    font-size: 14px;
                    margin-right: 12px;
                }
            }
            h3 {
                font-family: $font-content;
                font-size: rem(20);
                font-weight: bold;
                color: $blue;
                margin: 0;
            }
            p {
                color: $blue;
            }
        }
        .col-right {
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 20px 32px 20px;
            img {
                max-width: 282px;
            }
        }
    }
    &:last-child {
        margin-bottom: 60px;
    }
    @include breakpoint(md) {
        .cop-details-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .col-left {
                border-right: 1px solid $blue-grey;
                padding: 32px 60px 32px 0;
            }
            .col-right {
                padding: 32px 0 32px 60px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                min-width: 385px;
            }
        }
        &:last-child {
            margin-bottom: 80px;
        }
    }
}

.flexible-page-content {
    border-top: 1px solid $blue-grey;
}
