.arc-title_image_game_block.title_image_game_block {
    position: relative;
    margin-top: -35px;
    h2 {
        position: relative;
        font-size: rem(32px);
        line-height: 40px;

        @media (min-width: 992px) {
            margin-top: -35px;
        }
    }
}

.arc-title_image_game_block.bg-green.title_image_game_block {
    border-bottom: 1px solid $green;
}

.arc-title_image_game_block.bg-yellow.title_image_game_block {
    border-bottom: 1px solid $yellow;
}

.arc-title_image_game_block.bg-violet.title_image_game_block {
    border-bottom: 1px solid $purple;
}
