.docs-and-press-last-section {
    background-color: $grey;
    .dapls-header {
        img {
            width: 100%;
        }
    }
    .dapls-body {
        background-color: $blue-light;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 60px;
        .dapls-title {
            margin-bottom: 40px;
        }
    }
    @include breakpoint(lg) {
        .dapls-offset {
            transform: translateY(-46px);
        }
        .dapls-body {
            padding-bottom: 34px;
        }
    }
    @include breakpoint(xxl) {
        .dapls-offset {
            transform: translateY(-80px);
        }
        .dapls-body {
            padding-bottom: 0;
        }
    }
}
