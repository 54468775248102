.home-header {
    overflow: hidden;
    h1, h2 {
        font-size: rem(28);
        color: $blue;
        font-weight: bold;
        text-align: center;
        padding: 40px 20px 27px 20px;
        margin-bottom: 0;
    }
    .title-magic-square {
        color: white;
        position: absolute;width: 100%;
        text-shadow: 0 0 4px rgba(11, 7, 2, 0.30);
        font-size: 35px;
        opacity: 1;
    }
    .magic-squares {
        background: linear-gradient(0deg, $grey 35%, $white 35%);
        display: flex;
        justify-content: space-around;
        align-items: center;
        max-width: 100%;
        padding-bottom: 100px;
        .ms-card {
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            margin: 32px;
            display: flex;
            img, video {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        &.magic-squares-animated {
            .ms-card {
                opacity: 0;
                transform: scale(0) translateY(-200px);
                transform-origin: top center;
                &.animated-ms-card {
                    animation: appear_up_go_down 2000ms ease;
                    opacity: 1;
                    transform: scale(1) translateY(0);
                }
            }
            .col-flex-1 {
                .ms-card {
                    &.c1 {
                        height: 65vh;
                    }
                }
            }
            .col-flex-2 {
                .ms-card {
                    &.c1 {
                        height: 50vh;
                    }
                    &.c2 {
                        height: 30vh;
                    }
                }
            }
            .col-flex-3 {
                .ms-card {
                    &.c1 {
                        height: 100vh;
                    }
                }
            }
            .col-flex-4 {
                .ms-card {
                    &.c1 {
                        height: 30vh;
                    }
                    &.c2 {
                        height: 50vh;
                    }
                }
            }
            .col-flex-5 {
                .ms-card {
                    &.c1 {
                        height: 65vh;
                    }
                }
            }
        }
        .col-flex-1 {
            .c1 {
                width: 296px;
                height: 342px;
            }
        }
        .col-flex-2 {
            .c1 {
                width: 273px;
                height: 273px;
                margin-bottom: 30px;
            }
            .c2 {
                width: 273px;
                height: 172px;
            }

        }
        .col-flex-3 {
            .c1 {
                width: 379px;
                height: 582px;
            }
        }
        .col-flex-4 {
            .c1 {
                width: 272px;
                height: 172px;
                margin-bottom: 30px;
            }
            .c2 {
                width: 272px;
                height: 272px;

            }
        }
        .col-flex-5 {
            .c1 {
                width: 296px;
                height: 342px;
            }
        }
    }
    @include breakpoint(lg) {
        background: linear-gradient(0deg, $grey 35%, $white 35%);
        h1 {
            padding-top: 70px;
            padding-bottom: 60px;
            font-size: rem(40);
        }
    }
    @media (max-width: 991px) {
        min-height: 0 !important;
    }
}

.home-hero-nav-img-wrapper {
    cursor: pointer;
}

.hh-caroussel {
    display: flex;
    justify-content: center;
    padding: 0 20px 48px 20px;
    z-index: 2;
    .hh-caroussel-wrapper {
        width: 100%;
        max-width: 550px;
    }
    .hh-caroussel-content {
        .hh-caroussel-content-item {
            position: relative;
            /* this specific style was added to make video responsive witout black borders - standard video size */
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;
            border-radius: 8px;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center center;
            }
            video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .slick-list {
        margin-bottom: 5px;
    }
    .slick-dots {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        li {
            margin-right: 10px;
            button {
                -webkit-appearance: none;
                -moz-appearance: none;
                height: 8px;
                width: 8px;
                margin: 0;
                padding: 0;
                border: none;
                background-color: $blue;
                border-radius: 50%;
                opacity: .4;
                font-size: 0;
                transform: scale(.6);
                &:hover, &:focus {
                    opacity: 1;
                    transform: scale(1);
                }
                &:active, &:active:focus {
                    opacity: 1;
                    background-color: darken($blue, 10%);
                    transform: scale(1);
                }
            }
            &.slick-active {
                button {
                    opacity: 1;
                    transform: scale(1);
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .slick-active-after, .slick-active-before {
            button {
                transform: scale(.8);
            }
        }
    }
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: $grey;
        top: calc(50% - 20px);
        left: 0;
        height: calc(50% + 20px);
        width: 100%;
    }
}

.home-header.organisation-header {
    background: linear-gradient(0deg, $skin-light 35%, $blue-light 35%);
    .organisation-header-title {
        padding: 40px 20px 27px 20px;
        text-align: center;
        h1 {
            padding: 0;
            b {
                color: $red;
                font-weight: 700;
            }
        }
    }
    .magic-squares {
        background: linear-gradient(0deg, $skin-light 35%, $blue-light 35%);
    }
}

// new home hero banner starts
// mobile version
@media screen and (max-width: 991px) {
    .home-hero-links {
        display: none;
        &.hero-active-link {
            display: block;
            p {
                color: #004071;
                font-size: 20px !important;
                font-family: Caros,sans-serif;
                font-weight: 700;
                line-height: initial;
                margin-bottom: 1.5rem;
            }
        }
    }
    .home-hero-nav-img-wrapper {
        position: relative;
        cursor: pointer;
        img, video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .banner-content-buttons {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .home-hero-h1-container {
        margin-top: 3rem;
        margin-bottom: 3rem;
        opacity: 0;
        animation-name: 'home-hero-h1-container';
        animation-duration: 2s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-h1-container {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .home-hero-blue-text {
        color: $blue;
        font-size: 24px;
    }
    .home-hero-orange-text {
        color: $red;
        font-size: 24px;
    }
    .top-txt {
        .title-small {
            padding-top: 3rem;
        }
    }
}

// desktop version
@media screen and (min-width: 992px) {
    .home-hero-container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        height: 770px;
        animation-name: 'home-hero-container';
        animation-duration: 1s;
        animation-delay: 2.5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-container {
        0% {
            height: 770px;
        }
        100% {
            height: 720px;
        }
    }

    .home-hero-content-container {
        max-width: 100%;
        height: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-top: 10px;
        animation-name: 'home-hero-content-container';
        animation-duration: 1s;
        animation-delay: 2.5s;
        animation-fill-mode: forwards;

        .row {
            padding-right: 0;
            padding-left: 0;
            animation-name: 'home-hero-content-row';
            animation-duration: 1s;
            animation-delay: 2.5s;
            animation-fill-mode: forwards;
        }
    }

    @keyframes home-hero-content-container {
        0% {
            max-width: 100%;
            height: 100%;
            padding-right: 0;
            padding-left: 0;
            margin-top: 10px;
        }
        100% {
            max-width: 1414px;
            height: 520px;
            padding-right: 15px;
            padding-left: 15px;
            margin-top: 20px;
        }
    }

    @keyframes home-hero-content-row {
        0% {
            padding-right: 0;
            padding-left: 0;
        }
        100% {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .home-hero-img-container {
        left: 50%;
        transform: translate(-50%, 0);
        animation-name: 'home-hero-img-container';
        animation-duration: 2s;
        animation-delay: 2.5s;
        animation-fill-mode: forwards;
        flex: 0 0 100%;
        max-width: 100%;

    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
        @keyframes home-hero-img-container {
            0% {
                flex: 0 0 100%;
                max-width: 100%;
                left: 50%;
                transform: translate(-50%, 0);
            }
            50% {
                flex: 0 0 50%;
                max-width: 50%;
                left: 50%;
                transform: translate(-50%, 0);
            }
            100% {
                flex: 0 0 50%;
                max-width: 50%;
                left: 0;
                transform: translate(0%, 0);
            }
        }
    }

    @media screen and (min-width: 1200px) {
        @keyframes home-hero-img-container {
            0% {
                flex: 0 0 100%;
                max-width: 100%;
                left: 50%;
                transform: translate(-50%, 0);
            }
            50% {
                flex: 0 0 58.333333%;
                max-width: 58.333333%;
                left: 50%;
                transform: translate(-50%, 0);
            }
            100% {
                flex: 0 0 58.333333%;
                max-width: 58.333333%;
                left: 0;
                transform: translate(0%, 0);
            }
        }
    }

    .home-hero-img-wrapper {
        display: block;
        opacity: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation-name: 'home-hero-img-wrapper';
        animation-duration: 2s;
        animation-delay: 2.5s;
        animation-fill-mode: forwards;

        img, video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            animation-name: 'home-hero-img';
            animation-duration: 2s;
            animation-delay: 2.5s;
            animation-fill-mode: forwards;
        }
    }

    @keyframes home-hero-img-wrapper {
        0% {
            border-radius: initial;
            opacity: 1;
            display: block
        }
        50% {
            border-radius: 20px;
            opacity: 1;
            display: block
        }
        100% {
            border-radius: 20px;
            opacity: 0;
            display: none
        }
    }

    @keyframes home-hero-img {
        0% {
            border-radius: initial;
        }
        50% {
            border-radius: 20px;
        }
        100% {
            border-radius: 20px;
        }
    }

    .home-hero-nav-img-wrapper {
        opacity: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation-name: 'home-hero-nav-img-wrapper';
        animation-duration: 1s;
        animation-delay: 3.5s;
        animation-fill-mode: forwards;
        position: absolute;
        left: 0;
        top: 0;

        img, video {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            object-fit: cover;
        }
    }

    @keyframes home-hero-nav-img-wrapper {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }

    .home-hero-links-container {
        padding-left: 4.5rem;
    }

    .home-hero-links {
        position: relative;
        cursor: pointer;
        a,p {
            font-size: 24px;
        }
        a {
            p {
                font-size: 24px;
            }
        }
        p:hover, a:hover {
            text-decoration: none;
            color: $blue;
        }
        p:hover {
            opacity: 75%;
        }

        .home-hero-links-pointer {
            position: absolute;
            top: 50%;
            left: -15%;
            transform: translate(-50%, -100%);

            .home-hero-pointer-circle {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background-color: $red;
                display: inline-block;
            }

            .home-hero-pointer-line {
                height: 2px;
                width: 200px;
                background-color: $red;
                display: inline-block;
            }
        }

    }

    .hero-active-link {
        div {
            opacity: 0;
            display: none;
            animation-name: 'hero-active-link-div';
            animation-duration: 250ms;
            animation-fill-mode: forwards;
        }

        p {
            margin-left: 0;
            animation-name: 'hero-active-link-p';
            animation-duration: 250ms;
            animation-fill-mode: forwards;
        }
        p:hover, a:hover {
            text-decoration: none;
            color: $blue;
        }
    }

    @keyframes hero-active-link-div {
        0% {
            opacity: 0;
            display: none
        }
        100% {
            opacity: 1;
            display: block
        }
    }

    @keyframes hero-active-link-p {
        0% {
            margin-left: 0
        }
        100% {
            margin-left: 3rem
        }
    }

    .hero-inactive-link {
        div {
            opacity: 1;
            display: block;
            animation-name: 'hero-inactive-link-div';
            animation-duration: 750ms;
            animation-fill-mode: forwards;
        }

        p {
            margin-left: 3rem;
            animation-name: 'hero-inactive-link-p';
            animation-duration: 750ms;
            animation-fill-mode: forwards;
        }
    }

    @keyframes hero-inactive-link-div {
        0% {
            opacity: 1;
            display: block
        }
        100% {
            opacity: 0;
            display: none
        }
    }

    @keyframes hero-inactive-link-p {
        0% {
            margin-left: 3rem
        }
        100% {
            margin-left: 0
        }
    }


    #home-hero-link-1 {
        opacity: 0;
        animation-name: home-hero-link-1;
        animation-duration: 1s;
        animation-delay: 3.5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-link-1 {
        0% {
            left: -110px;
            opacity: 0
        }
        100% {
            left: 0;
            opacity: 1
        }
    }

    #home-hero-link-2 {
        opacity: 0;
        animation-name: home-hero-link-2;
        animation-duration: 500ms;
        animation-delay: 4.5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-link-2 {
        0% {
            left: -75px;
            opacity: 0
        }
        100% {
            left: 0;
            opacity: 1
        }
    }

    #home-hero-link-3 {
        opacity: 0;
        animation-name: home-hero-link-3;
        animation-duration: 500ms;
        animation-delay: 5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-link-3 {
        0% {
            left: -75px;
            opacity: 0
        }
        100% {
            left: 0;
            opacity: 1
        }
    }

    #home-hero-link-4 {
        opacity: 0;
        animation-name: home-hero-link-4;
        animation-duration: 500ms;
        animation-delay: 5.5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-link-4 {
        0% {
            left: -75px;
            opacity: 0
        }
        100% {
            left: 0;
            opacity: 1
        }
    }

    .home-hero-h1-container {
        padding-bottom: 1.5rem;
        position: absolute;
        bottom: 150px;
        left: 50%;
        transform: translate(-50%, 0);
        animation-name: 'home-hero-h1-container';
        //animation-duration: 3.5s;
        animation-duration: 2s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-h1-container {
        0% {
            bottom: 150px;
        }
        //28.5% {
        //    bottom: 20px;
        //}
        //71.3% {
        //    bottom: 20px;
        //}
        100% {
            bottom: 0;
        }
    }

    .home-hero-blue-text {
        color: #fff;
        animation-name: 'home-hero-blue-text';
        animation-duration: 1s;
        animation-delay: 2.5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-blue-text {
        0% {
            color: #fff;
        }
        100% {
            color: $blue;
        }
    }

    .home-hero-orange-text {
        color: #fff;
        animation-name: 'home-hero-orange-text';
        animation-duration: 1s;
        animation-delay: 2.5s;
        animation-fill-mode: forwards;
    }

    @keyframes home-hero-orange-text {
        0% {
            color: #fff;
        }
        100% {
            color: $red;
        }
    }

    .white-circle-icon {
        width: 38px;
        height: 38px;
        background-color: #fff;
        border-radius: 50%;
        color: $red;
        padding-top: 0.5rem
    }

    .background-stripe {
        background-color: #DBECF9;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: -1;
        animation-name: 'background-stripe';
        animation-duration: 1s;
        animation-delay: 2.5s;
        animation-fill-mode: forwards;
    }

    @keyframes background-stripe {
        0% {
            height: 0;
        }
        100% {
            height: 250px;
        }
    }

    .home-hero-links {
        p {
            font-size: 30px;
            color: $blue;
            font-family: Caros, sans-serif;
            font-weight: 700;
            line-height: initial;
        }
    }

    .fade-in {
        opacity: 0;
        animation-name: fade-in;
        animation-duration: 750ms;
        animation-fill-mode: forwards;
    }

    @keyframes fade-in {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }

    html.home-header-animation {
        height: 100%;
        overflow: hidden;
        animation-name: html-unfreeze;
        animation-duration: 100ms;
        animation-delay: 6s;
        animation-fill-mode: forwards;
    }
    @keyframes html-unfreeze {
        0% {
            height: 100%;
            overflow: hidden
        }
        100% {
            height: initial;
            overflow: initial
        }
    }
}

.content-switch {
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 16px;
    opacity: 0.9;
    background-color: #004071;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    position: relative;
    z-index: 5;
}
