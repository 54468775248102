.fo-section-five-wrapper {
    padding: 50px 25px;
    background-color: $blue-light;
    .fo-section-five {
        max-width: 1130px;
        display: flex;
        margin: 0 auto;
        .col-flex-1,
        .col-flex-5 {
            display: flex;
            flex-direction: column;
            flex: 0 0 12%;
            padding: 5px;
        }
        .col-flex-2,
        .col-flex-3,
        .col-flex-4 {
            display: flex;
            flex-direction: column;
            flex: 0 0 25%;
            padding: 5px;
        }
        .card-elem {
            height: 200px;
            padding: 25px;
            display: flex;
            width: 100%;
            border-radius: 35px;
            min-height: 10px;
            flex-wrap: wrap;
            margin-bottom: 10px;

            background-color: $blue;


            .unit-child {
                display: flex;
                width: 100%;
                color: $white;
            }
            .unit-child:last-child {
                font-size: 24pt;
                font-weight: 700;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
        }
        .unit-gray {
            background-color: #b1b3b7;
            padding: 0;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .bg-red { background-color: $red; }
        .bg-white {
            background-color: white;
            .unit-child {
                color: $blue;
            }
        }
        .bg-skin {
            background-color: $skin;
            .unit-child {
                color: $blue;
            }
        }
    }
    @media (max-width: 991px) {
        .fo-section-five {
            display: block;
            .col-flex-1,
            .col-flex-2,
            .col-flex-3,
            .col-flex-4,
            .col-flex-5 {
                flex: 0 0 100% !important;
            }
        }
    }
}
