.video_game_block, .video_flex_block  {
    @extend .video_podcast_block;
    .td-section-one-wrapper .container .row {
        //background-color: #57bebe;
        //border-bottom: 1px solid #33a4a4;
        .content-video {
            display: flex;
            align-items: center;
            height: calc(100vw - 40px);
            width: calc(100vw - 40px);
            max-height: 612px;
            max-width: 612px;
            border-radius: 345px 345px 8px 8px;
            overflow: hidden;
            transform: translateZ(0);
            @media (max-width: 768px) {
                width: initial;
            }
            @media (min-width: 992px) {
                flex-grow: 0;
                flex-shrink: 0;
                margin-right: 65px;
                margin-bottom: 0;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
                width: 100%;
            }
            .content-video-container {
                width: 100%;
                height: 100%;
                padding: 0;
            }
            .resp-video-container {
                position: relative;
                background-color: black;
            }
            video, iframe {
                top: -242px;
                left: -200px;
                width: 180%;
                height: 180%;
                position: absolute;
                @media (min-width: 992px) and (max-width: 1199px) {
                top: -245px;
                left: -296px;
                width: 210%;
                }
                @media  (max-width: 768px) {
                    top: -50%;
                    left: -50%;
                    width: 220%;
                    height: 200%;
                }
            }
        }
    }
    h2, p {
        color: $green-dark;
    }
}

body.txt-dark-False {
.video_game_block, .video_flex_block {
    h2, p {
        color: white  !important;
    }
}
}


