.radio-channels-stripe {
    background-color: $gray-dark2;
    .content {
        padding: 18px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        p {
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: white;
            margin-bottom: 20px;
        }
        img {
            width: auto;
            max-height: 40px;
        }
        .radio-cahnnel-btn-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: -6px -10px;
            a {
                padding: 12px 20px;
                min-height: 52px;
                margin: 6px 10px;
            }
        }
    }
    @include breakpoint(md) {
        background-color: #141414;
        .content {
            border-top: none;
            border-bottom: none;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                width: auto;
                text-align: left;
                margin-bottom: 0;
                margin-right: 32px;
            }
        }
    }
}
