@import "../components/PracticalAdvice/index";
@import "../components/PracticalAdvice/MajorTopics/index";
@import "../components/PracticalAdvice/TopicArticle/index";
@import "../components/PracticalAdvice/Topic/index";
@import "../components/PracticalAdvice/AwarenessTools/index";
@import "../components/PracticalAdvice/AwarenessTools/ToolDetail/index";
@import "../components/PracticalAdvice/AwarenessTools/ToolDetailFamiliesGame/index";
@import "../components/PracticalAdvice/AwarenessTools/ToolDetailFamiliesGame1/index";
@import "../components/PracticalAdvice/AwarenessTools/ToolDetailPodcast/index";

/** unclassified **/
.video-container-wrap {
    position: relative;
    .video-button {
        display: flex;
        align-items: center;
        background-color: #f5cdb1;
        padding: 20px;
        font-size: 13pt;
        font-weight: bold;
        border-radius: 50px;
        color: $blue;
        cursor: pointer;
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        overflow: hidden;
        img {
            max-width: 30px;
            margin-right: 7px;
            margin-bottom: 0 !important;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            left: -5%;
            width: 110%;
            padding-bottom: 110%;
            border-radius: 50%;
            background-color: $skin-dark;
            transform: scale(0);
            transition: transform $time-sm ease, background-color $time-sm ease;
        }
        &:hover, &:focus {
            &:after {
                transform: scale(1);
            }
        }
        &:active, &:active:focus {
            &:after {
                background-color: darken($skin-dark, 6%);
                transform: scale(1);
            }
        }
    }
}
@media (max-width: 400px) {
    .video-button {
        font-size: 10pt;
        padding: 12px;
    }
}

.practical-advice-content {
    .banner-one {
        &:first-child, &:nth-child(2) {
            padding-top: 0;
        }
    }
    @include breakpoint(lg) {
        .banner-one {
            &:first-child, &:nth-child(2) {
                padding-top: 80px;
            }
        }
    }
}
