.home-section-one-wrapper {
    background-color: $grey;
    .top-txt {
        padding: 0 20px 32px 20px;
        text-align: center;
        color: $blue-green;
        .top-txt-title {
            margin-bottom: 40px;
        }
    }
    .input-wrapper {
       // border: 1px solid $grey-alpha;
        border-radius: 4px;
       // padding: 12px;
        width: 100%;
        max-width: 522px;
       // background-color: $white;
        margin: 0 auto 12px auto;
        display: flex;
        align-content: center;
        .button-location {
            background-color: $blue;
            padding: 5px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 0;
            border-radius: 2px;
            font-family: $font-title;
            font-size: 12px;
            font-weight: 400;
            color: $white;
            min-width: 40px;
            border: 0;
            margin-left: auto;
            &:hover, &:focus {
                background-color: darken($blue, 5);
            }
            &:active, &:active:focus {
                background-color: darken($blue, 8);
            }
        }
        input {
            border: 0;
            background-color: transparent;
            padding: 5px;
            width: 100%;
            margin-right: 15px;
            font-size: 16px;
            color: $blue;
            flex-grow: 1;
            flex-shrink: 1;
            &::placeholder {
                color: $blue;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 0;
            width: 44px;
            padding-right: 12px;
            border-right: 1px solid $grey-alpha;
            margin-right: 15px;
            margin-bottom: 0;
            span {
                color: $red;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        padding-top: 100px;
        .button-location {
            img {
                margin-right: 8px;
            }
        }
    }
}
