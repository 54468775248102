.articles-list-one-topic-article {
    @extend .articles-list-one;
    padding: 0;
    position: relative;
    z-index: 1;
    .section-one-wrapper {
        background-color: #f5cdb1;
    }
    .top-curve-wrapper {
        height: 140px;
        overflow: hidden;
        width: 100%;
        margin-bottom: -60px;
        position: relative;
        margin-top: -1px;
        z-index: -1;
    }
    .content-wrap {
        padding: 55px 15px 80px 15px;
        background-color: $blue-light;

        .btn-blue-1 {
            background-color: transparent;
            border: 2px solid $blue;
            color: $blue;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                color: $blue;
                background-color: rgba(0,0,0,0.05);
            }
        }
    }
    .content {
        max-width: 1384px;
        width: 100%;
        margin: 0 auto;
        h2 {
            color: $blue;
            font-family: $font-title;
            font-size: 24px;
            text-align: center;
            font-weight: bold;
            margin-top: -55px;
            margin-bottom: 55px;
            text-transform: none;
        }
        .content-body {
            margin-top: 25px;
        }
    }
    .title-section {
        h2 {
            font-size: 24px;
        }
    }
    @include breakpoint(lg) {
        .content {
            h2 {
                font-size: 32px;
            }
        }
        .title-section {
            h2 {
                font-size: 32px;
            }
        }
    }
}
