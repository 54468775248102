.tool-detail-families-game--section-eight {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    background-position: top center;
    .center-content {
        width: 280px;
        height: 280px;
        background-color: $purple;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 80px;
        h2 {
            max-width: 240px;
            color: white;
            font-weight: bold;
            font-size: 24px;
            line-height: 1.4;
            text-align: center;
            margin-bottom: 40px;
        }
        .btn-skin-1:hover {
            background-color: #ffe0ca;
            color: $blue;
        }
    }
    @media (max-width: 575px) {
        background-color: $green;
        background-image: none !important;
    }
    @include breakpoint(sm) {
        .center-content {
            margin-top: 90px;
            width: 340px;
            height: 340px;
        }
    }
    @include breakpoint(md) {
        .center-content {
            width: 350px;
            height: 350px;
        }
    }
}
.tool-detail-families-game--section-eight-bottom {
    .section-one-wrapper {
        margin-top: -100px;
        background-color: transparent;
        .top-curve {
            background: white;
        }
        .section-one {
            background-color: white;
            .content {
                background-color: white;
            }
        }
    }
}
