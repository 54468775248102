.home-section-four {
    background-color: #dbebf8;
    padding: 60px 5px;
    color: $blue-green;
    .home-section-four-content {
        margin-bottom: 56px;
    }
    .home-section-four-title {
        margin-bottom: 32px;
    }
    .home-section-four-text {
        margin-bottom: 40px;
        font-size: 16px;
    }
    .home-section-four-btn {
        margin: 0;
    }
    .col-left {
        max-width: 100%;
        margin-bottom: 40px;
        img {
            width: 100%;
            max-width: 100%;
            border-radius: 8px;
        }
    }
    .sales-point {
        display: flex;
        align-items: flex-end;
        margin-bottom: 32px;
        color: $blue;
        .sales-image {
            height: 62px;
            width: 60px;
            margin-right: 40px;
            margin-bottom: 5px;
        }
        .sales-number {
            font-family: $font-title;
            font-weight: 700;
            font-size: 40px;
            margin-bottom: 0;
        }
        .sales-text {
            font-size: 16px;
            margin-top: -8px;
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(md) {
        .home-sales-point {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        .sales-point {
            margin-bottom: 0;
            display: inline-block;
            .sales-image {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
        .home-section-four-content {
            display: flex;
            align-items: flex-start;
            margin-bottom: 60px;
        }
        .col-left {
            margin-bottom: 0;
            flex-grow: 0;
            flex-shrink: 0;
            img {
                width: 510px;
            }
        }
        .col-right {
            padding: 50px 65px 0 65px;
            flex-grow: 1;
            flex-shrink: 1;
        }
        .sales-point {
            .sales-image {
                margin-bottom: 5px;
            }
            .sales-text {
                font-size: 20px;
            }
        }
    }
    @include breakpoint(xl) {
        .col-left {
            img {
                width: 690px;
            }
        }
    }
}


