/* FOOTER */

.info-aidant {
    background-color: $white;
    padding: 15px;
    border-radius: 8px;
    position: fixed;
    z-index: 101;
    bottom: 125px;
    right: 15px;
    width: 100%;
    max-width: 303px;
    text-align: center;
    box-shadow: 0 0 8px #22222229;
    //box-shadow: -1px -2px 5px 0px rgba(0,0,0,0.75);

    p {
        color : $blue;
        font-size: 14px;
        line-height: initial;
        margin: 0;
        &.title-txt {
            font-size: 16px;
            color: $red;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
    .material-icons {
        font-size: 10pt;
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        color: $blue;
        background-color: $skin-light;
        padding: 2px;
        border-radius: 50%;
        border: 1px solid #ccc;
        font-weight: bold;
        &:hover, &:focus {
            background-color: $skin;
        }
        &:active, &:active:focus {
            background-color: $skin-dark;
        }
    }
}

.info-aidant, .info-aidant-state-closed {
    @media (max-width: 991px) {
        display: none !important;
    }
}

.info-aidant:after{
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    right: 10px;
    bottom: 0;
    border-radius: 4px;
    transform: rotate(45deg) translateY(50%);
    background-color: $white;
    z-index: -1;
}
.info-aidant-state-closed {
    display: none;
    cursor: pointer;
    .material-icons {
        color: $red;
        position: fixed;
        z-index: 14;
        bottom: 78px;
        right: 22px;
        border-radius: 50%;
        font-size: 30pt;
        background-color: $white;
    }
}
#icChatButton {
    max-height: 50px;
}

@media (max-width: 580px) {
    #icChatButton {
        margin-bottom: 40px;
        margin-right: -15px;
    }
    footer .main-footer-footer {
        padding-bottom: 75px !important;
    }
}


.fixed-phone-btn-container {
    background-color: $skin-light;
}
.fixed-phone-btn {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    text-decoration: none !important;
    font-family: $font-title;
    font-size: 15px;
    font-weight: 700;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 17px 20px;
    color: $blue;
    background-color: $skin;
    border: none;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 110%;
        padding-bottom: 110%;
        border-radius: 50%;
        background-color: $skin-dark;
        transform: scale(0);
        transition: transform $time-sm ease, background-color $time-sm ease;
    }
    &:hover, &:focus {
        color: $blue;
        &:after {
            transform: scale(1);
        }
    }
    &:active, &:active:focus {
        color: $blue;
        &:after {
            background-color: darken($skin-dark, 6%);
            transform: scale(1);
        }
    }
    @include breakpoint(lg) {
        bottom: 78px;
        right: 66px;
        width: auto;
        background-color: $red;
        color: $white;
        border-radius: 32px;
        padding: 5px 15px;
        border: 4px solid white;

        .material-icons, svg {
            font-size: 20px;
            margin-right: 5px;
        }
        &:after {
            background-color: #bd3a21;
        }
        &:hover, &:focus {
            color: $white;
        }
        &:active, &:active:focus {
            color: $white;
            &:after {
                background-color: darken(#bd3a21, 4);
            }
        }
    }
}

footer {
    background-color: $skin-light;
    .content {
        margin: 0 auto;
        max-width: 1424px;
        padding: 0 20px;
        width: 100%;
    }
    .breadcrumb-overflow {
        overflow-x: auto;
    }
    .breadcrumbs {
        display: flex;
        align-items: center;
        padding: 15px 0;
        font-family: $font-title;
        font-size: 14px;
        font-weight: 400;
        color: $blue;
        border-bottom: 1px solid rgba(255,255,255,0.4);
        a {
            color: $blue;
            text-decoration: none;
        }
        a, span {
            flex-shrink: 0;
        }
        span.active {
            color: $red;
        }
        .breadcrumb-arrow {
            margin: 0 6px;
            font-size: 17px;
        }
    }
    .main-footer-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 38px 0;
        border-bottom: 1px solid rgba(255,255,255,0.4);
        .footer-logo {
            margin-bottom: 45px;
            img {
                width: 182px;
            }
        }
        .footer-buttons-container {
            text-align: center;
            margin-bottom: 40px;
            h4 {
                font-size: 20px;
                font-weight: 500;
                color: $blue;
                text-align: center;
                margin-bottom: 20px;
            }
            .footer-buttons-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                .btn-blue-1-alt {
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin-bottom: 14px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .main-footer-footer {
        display: block;
        align-items: baseline;
        text-align: center;
        color: $blue;
        padding: 30px 0 20px 0;
        p {
            font-family: $font-title;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 15px;
        }
        .copyright {
            font-size: 12px;
            font-weight: 400;
            margin-left: auto;
        }
        .footer-partner {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            .footer-partner-img {
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    @include  breakpoint(lg) {
        .main-footer-body {
            flex-direction: row;
            justify-content: space-between;
            .footer-logo {
                margin-bottom: 0;
            }
            .footer-buttons-container {
                margin-bottom: 0;
                .footer-buttons-body {
                    flex-direction: row;
                    .btn-blue-1-alt {
                        margin-right: 12px;
                        margin-bottom: 0;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            .footer-social-body {
                justify-content: space-between;
            }
        }
        .main-footer-footer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 30px 0;
            p {
                margin-bottom: 0;
                margin-right: 40px;
            }
            .footer-partner {
                margin-bottom: 0;
            }
        }
        .main-footer-footer-2 {
            padding: 50px 0;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            //gap: 5px;
            .f-col {
                display: flex;
                color: $blue;
                a {
                    color: $blue !important;
                    font-weight: bold;
                }
            }
            .f-col-border {
                width: 1px;
                background-color: white;
                height: 40px;
            }
            .footer-icon-2 {
                font-size: 21px;
                border: 10px solid #fff;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                float: right;
                background-color: $blue;
                color: white;
                transition: all 500ms ease;
                padding: 4px;
                &.icon-blue-svg {
                    display: none;
                }
            }

            .footer-icon-2-link {
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 5px;
                .wring-clip-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .wring-clip {
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border: 10px solid $blue;
                    border-radius: 50%;
                    position: absolute;
                    transition: all 500ms ease;
                    opacity: 0;
                }
            }
            .footer-icon-2-link:hover {
                .footer-icon-2 {
                    color: $blue;
                    background-color: $white;
                    &.icon-blue-svg {
                        display: inline-flex;
                    }
                    &.icon-white-svg {
                        display: none;
                    }
                }
                .wring-clip {
                    width: 50px;
                    height: 50px;
                    opacity: 1;
                }
            }
        }
    }
}
.chat-button-rectangle {
    opacity: 0.8;
}
@media (max-width: 975px) {
    .info-aidant-state-closed .material-icons {
        bottom: 15px;
        right: 15px;
    }
    .info-aidant {
        bottom: 40px;
        right: 20px;
    }
    .main-footer-footer-2 {
        padding-bottom: 50px;
        padding-top: 25px;
        flex-direction: row;
        text-align: center;
        .footer-partner-img {
            margin-top: 15px;
        }
        .f-col {
            margin-bottom: 15px;
        }
        .footer-icon-2 {
            display: none;
        }
    }
    .f-col-border {
        display: none;
    }
}

.footer-social-container {
    @extend .footer-buttons-container;
    margin-bottom: 0;
    .footer-social-body {
        display: flex;
        align-items: center;
        justify-content: center;
        .footer-social-item {
            flex-shrink: 0;
            position: relative;
            text-decoration: none !important;
            background-color: $white;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            .footer-social-icon {
                position: relative;
                z-index: 4;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $blue;
                border-radius: 50%;
                .footer-social-icon-fa {
                    position: relative;
                    z-index: 3;
                    font-size: 15px;
                    color: $white;
                    transition: color $time-xs ease;
                }
                &:after {
                    content: "";
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    background-color: $white;
                    transform: scale(0);
                    transition: transform $time-sm ease;
                }
            }
            &:after {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: $blue;
                transform: scale(0);
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover, &:focus {
                .footer-social-icon {
                    .footer-social-icon-fa {
                        color: $blue;
                    }
                    &:after {
                        transform: scale(1);
                    }
                }
                &:after {
                    transform: scale(1);
                }
            }
            &:active, &:active:focus {
                .footer-social-icon {
                    .footer-social-icon-fa {
                        color: darken($blue, 8);
                    }
                    &:after {
                        transform: scale(1);
                    }
                }
                &:after {
                    transform: scale(1);
                    background-color: darken($blue, 8);
                }
            }
        }
    }
    @include breakpoint(lg) {
        .footer-social-body {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

#chat-div-wrap {
    left: initial !important;
}

