.img-overlay-pp {

    &__bg {
        overflow: hidden;
        min-height: 625px;
        border-radius: 19vw 19vw 19vw 0;
        position: relative;
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(1, 58, 100, 0.8);

        @media (min-width: 576px) {
            background: rgba(1, 58, 100, 0.8);
            background: linear-gradient(90deg, rgba(136, 159, 181, 0.8) 0%, rgba(1, 58, 100, 0.8) 100%);
        }
    }

    &__container {
        margin: 0 auto;
        max-width: 1176px;
        padding: 62px 15px 50px;
        position: relative;

        @media (min-width: 576px) {
            padding: 114px 15px 100px;
        }
    }

    &__content {
        max-width: 435px;
        margin-left: auto;
    }

    &__title {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        font-family: Caros, sans-serif;
        color: $white;
        margin-bottom: 27px;
    }

    &__rich-text {
        margin-bottom: 40px;

        * {
            font-size: 18px;
            line-height: 24px;
            color: $white;
        }

        a {
            text-decoration: underline;
        }
    }

    &__link {
        padding: 20px 32px;
        background-color: $white;

        &-text {
            color: #013A64;
        }

        .material-icons {
            color: #013A64;
        }
    }
}
