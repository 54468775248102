.title_image_game_block {
    @extend .title_image_podcast_block;
    //background-color: #57bebe;
    //border-bottom: 1px outset $white;
    h2, p {
        color: $green-dark;
    }
}

body.txt-dark-False {
    .title_image_game_block h2 {
        color: white;
    }
}
