.history-cards-block {
    padding: 0 15px;
    .card .card-body {
        align-items: start;
    }
    .card-title, .card-text {
        color: inherit;
    }
    .card-text {
        p, a, li, blockquote {
            font-family: Moderat,sans-serif;
            font-size: 18px;
            font-weight: 400;
        }
    }
}
