.flex-content-wrapper {
    background-color: #f8f9fa;
    .flex-content-container {
        .banner-info {
            background-color: initial;
        }
    }
    .flex-content-container > div:last-child {
            position: relative;
            padding-bottom: 0;
    }
    .arc-container {
        margin-top: -200px;
    }
}




