.tdfg-tabs-display {
    @extend .tdp-tabs-display;
    //background-color: $green !important;
    color: $green-dark !important;
    .nav-item {
        .nav-link {
            color: $green-dark !important;
            &:hover, &:focus {
                background-color: #00353714 !important;
            }
            &:active, &:active:focus {
                background-color: #00353724 !important;
            }
            &.active {
                color: $red !important;
                background-color: #e6472a1f !important;
            }
        }
    }
    .tdp-tabs-intro-text {
        font-weight: 700;
    }
    h1, h2, h3, h4, p {
        color: $green-dark !important;
    }
    .person-unit {
        .person-unit-name {
            color: $green-dark;
        }
        .person-unit-desc {
            color: $green-dark;
            opacity: .6;
        }
    }
}
.tdp-tabs-display-bottom {
    @extend .wwa-header-bottom;
    background-color: #141414 !important;
    #curve-4 {
        display: none;
    }
}
.tool-detail-podcast-more-episodes {
    .more-episodes2 {
        background-color: #141414;
    }
}
.tool-detail-podcast-section-last {
    .tool-detail-families-game--section-eight-bottom {
        background: #141414;
        padding-top: 100px;
    }
}

body.txt-dark-False {
    .tdfg-tabs-display {
        color: white !important;
        P, H2, h4, .nav-item .nav-link {
            color: white !important;
        }
    }

}
