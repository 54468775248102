.documents-accordion {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $grey;
    h3 {
        color: $red;
        text-transform: uppercase;
        font-size: 11pt;
    }
    .btn-blue-1 {
        background-color: $skin;
        color: $blue;
    }
    .accordion {
        .card, .card-header {
            padding: 0;
        }
        .card {
            margin-bottom: 12px;
            border: 0;
            overflow: initial;
            background-color: transparent;
            .doc-wrapper {
                padding-top: 32px;
                padding-bottom: 32px;
                border-bottom: 1px solid $blue-grey;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .card-header {
            background: none;
            border: none;
        }
        .card-body {
            background-color: $white;
            border-radius: 0 0 8px 8px;
            box-shadow: 0 2px 20px $grey-shadow;
            padding: 36px 20px;
            .doc-wrapper-date {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: $blue;
                margin-bottom: 12px;
                .material-icons {
                    font-size: 10pt;
                    margin-right: 12px;
                }
            }
            .doc-wrapper-title {
                font-size: 14px;
                color: $red;
                margin-bottom: 20px;
            }
            .doc-wrapper-text {
                font-weight: 500;
                margin-bottom: 20px;
                p:last-child {
                    margin-bottom: 0;
                }
            }
            .doc-wrapper-btn {
                margin: 0;
            }
        }
        .btn-link {
            background-color: $white;
            border-radius: 8px 8px 0 0;
            border: none;
            padding: 16px;
            width: 100%;
            text-align: center;
            text-decoration: none;
            text-transform: initial;
            letter-spacing: initial;
            margin-bottom: 4px;
            .doc-thumb {
                height: 160px;
                border-radius: 8px;
                overflow: hidden;
                margin-bottom: 12px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center center;
                    transition: transform $time-sm ease;
                }
            }
            .title-section {
                color: $red;
                margin-bottom: 12px;
            }
            .icon-section {
                .icon-section-icon {
                    margin-left: auto;
                    margin-right: auto;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    background-color: $skin;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 1;
                    .icon-bar {
                        position: absolute;
                        width: 16px;
                        height: 2px;
                        background-color: $blue;
                        border-radius: 2px;
                        transform: rotate(0);
                        transform-origin: center center;
                        transition: transform $time-sm ease;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        background-color: $skin-dark;
                        transform: scale(0);
                        transition: transform $time-sm ease, background-color $time-sm ease;
                    }
                }
            }
            &.collapsed {
                border-radius: 8px;
                margin-bottom: 0;
                .title-section {
                    color: $blue;
                }
                .icon-section {
                    .icon-section-icon {
                        .icon-bar:last-child {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            &:hover, &:focus {
                .doc-thumb {
                    img {
                        transform: scale(1.1);
                    }
                }
                .title-section {
                    color: $red;
                }
                .icon-section {
                    .icon-section-icon {
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
            }
            &:active, &:active:focus {
                .doc-thumb {
                    img {
                        transform: scale(1.1);
                    }
                }
                .title-section {
                    color: darken($red, 6%);
                }
                .icon-section {
                    .icon-section-icon {
                        &:after {
                            background-color: darken($skin-dark, 6%);
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
    @include breakpoint(sm) {
        padding-top: 80px;
        padding-bottom: 80px;
        .accordion {
            .card-body {
                padding: 56px 40px 40px 40px;
            }
            .btn-link {
                display: flex;
                align-items: center;
                text-align: left;
                padding-right: 48px;
                .doc-thumb {
                    flex: 1;
                    margin-bottom: 0;
                    margin-right: 40px;
                }
                .title-section {
                    flex: 1;
                    margin-bottom: 0;
                    margin-right: 40px;
                }
                .icon-section {
                    flex: 0;
                    .icon-section-icon {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }
    }
    @include breakpoint(md) {
        .accordion {
            .card {
                .doc-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .doc-wrapper-content {
                        flex-grow: 1;
                        flex-shrink: 1;
                        max-width: 550px;
                        margin-right: 32px;
                    }
                    .doc-wrapper-btn-container {
                        flex-grow: 0;
                        flex-shrink: 0;
                    }
                }
            }
            .card-body {
                .doc-wrapper-text {
                    margin-bottom: 0;
                }
            }
        }
    }
}
