.tool-detail-families-game--section-seven {
    background-color: $green;
    padding: 60px 20px;
    .quoted-section {
        max-width: 790px;
        margin-bottom: 60px;
        p {
            color: $green-dark;
        }
    }
    .awareness-tools-main-section {
        width: 590px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
        color: $green-dark;
        h2 {
            margin-bottom: 32px;
        }
        &:last-child {
            .cms-content {
                p {
                    font-size: 16px;
                }
            }
        }
    }
    .awareness-video-section {
        width: 790px;
        .resp-video-container {
            background-color: $green;
            video {
                border-radius: 8px;
            }
        }
    }
    @include breakpoint(lg) {
        padding: 120px 20px;
    }
}
