.toppage-widget {
    position: fixed;
    left: 25px;
    bottom: 15px;
    z-index: 10;
    display: flex;
    padding: 0;
    background: none;
    border: none;
    text-decoration: none !important;
    pointer-events: auto;
    opacity: 1;
    transition: opacity $time-md ease;
    .toppage-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $blue;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-color: $skin;
        position: relative;
        z-index: 1;
        margin-right: 8px;
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $skin-dark;
            border-radius: 50%;
            transform: scale(0);
            transform-origin: center center;
            transition: transform $time-sm ease, background-color $time-sm ease;
        }
    }
    .toppage-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 20px $grey-shadow;
        border-radius: 4px;
        padding: 6px 8px;
        background-color: $white;
        .toppage-text {
            color: $blue;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
        }
    }
    &:hover, &:focus {
        .toppage-arrow {
            &:after {
                transform: scale(1);
            }
        }
    }
    &:active, &:active:focus {
        .toppage-arrow {
            &:after {
                background-color: darken($skin-dark, 6%);
            }
        }
    }
    &.stick-top {
        opacity: 0;
        pointer-events: none;
    }
    &.stick-bottom {
        position: relative;
        bottom: 20px;
    }
    @include breakpoint(sm) {
        left: initial;
        right: 25px;
        bottom: 248px;
        flex-direction: column;
        align-items: center;
        .toppage-arrow {
            margin-right: 0;
            margin-bottom: 8px;
        }
    }
}

.toppage-widget-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: $blue-light;
    @include breakpoint(sm) {
        justify-content: flex-end;
    }
}
