.accordion-arch-pp {
    background-color: #DBECF9;
    position: relative;
    overflow: hidden;
    width: 100%;

    &__container {
        max-width: 1136px;
        margin: 0 auto;
        padding: 0 15px 58px;
        position: relative;

        @media (min-width: 576px) {
            padding: 0 15px 115px;
        }

    }

    &__arch {
        width: calc(100% + 4px);
        display: inline-block;

        &-container {
           background-color: #F8F9FA;
           position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .accordion {
        background-color: $white;
        border-radius: 8px;
        padding: 24px 48px 35px 40px;

        @media (max-width: 575px) {
            padding: 24px;
        }

        .card-wrapper {
            .card {
                border-bottom: initial;
                border-left: initial;
                border-right: initial;
                border-top: 1px solid #eaeff5;
                padding: 24px 0;

                .card-left-border {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 8px;
                    height: 100%;
                    background-color: $skin;
                    transform: scaleY(0);
                    transition: all 0.15s linear;
                    transform-origin: bottom;
                }

                .card-header {
                    padding: 0;
                    background: initial;
                    border: initial;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    &__content {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        flex: 1;

                        @media (max-width: 991px) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }

                    .btn {
                        margin-left: 32px;
                        padding: 0;
                        width: 36px;
                        height: 36px;
                        background-color: $skin;
                        border-radius: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        &:after {
                            display: inline-block;
                            flex-shrink: 0;
                            width: 16px;
                            height: 16px;
                            content: " ";
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.304' height='17' viewBox='0 0 12.304 17'%3E%3Cpath id='ic_arrow' d='M10.929,14.828a.569.569,0,1,0-.8.8l4.09,4.094H.7a.571.571,0,0,0,0,1.14H14.217l-4.1,4.09a.58.58,0,0,0,0,.8.566.566,0,0,0,.8,0l5.066-5.066a.556.556,0,0,0,0-.8Z' transform='translate(26.421 0.341) rotate(90)' fill='%23013084' stroke='%23013184' stroke-width='1'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            transition: initial;
                        }
                    }

                    .card__rich-text {
                        width: 100%;
                        max-width: 432px;
                        margin-bottom: initial;

                        @media (min-width: 576px) and (max-width: 991px) {
                            margin-left: 64px;
                        }
                    }
                }

                &__circle {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    background-color: #DBECF9;
                    margin-right: 20px;
                    flex: none;
                    object-fit: cover;

                    @media screen and (max-width: 575px) {
                        display: none;
                    }
                }

                &__title {
                    color: $red;
                    font-family: Caros, sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                    width: 100%;
                    margin-right: 32px;

                    @media (max-width: 991px) {
                        margin-right: initial;
                    }

                    @media screen and (max-width: 575px) {
                        margin-bottom: initial;
                    }

                    &-container {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        flex: 1;

                        @media screen and (max-width: 575px) {
                            margin-bottom: 8px;
                        }
                    }
                }

                &__rich-text {
                    margin-bottom: 40px;

                    * {
                        font-size: 16px;
                        line-height: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    ul, ol {
                        padding-left: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    a {
                        text-decoration: underline;
                    }
                }

                .card-body {
                    padding-top: 24px;
                    padding-bottom: initial;
                    padding-left: initial;
                }

                .card-footer {
                    border: initial;
                    background-color: initial;
                    padding: 0 1.25rem;
                }

                .card-info-line {
                    display: flex;
                    margin-bottom: 27px;

                    &:first-child {
                        margin-top: 10px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &__icon {
                        margin-right: 16px;

                        img {
                            max-width: 24px;
                            max-height: 24px;
                        }
                    }

                    &__title {
                        font-family: Moderat, sans-serif;
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: bold;
                    }

                    &__description, &__colon {
                        font-family: Moderat, sans-serif;
                        font-size: 18px;
                        line-height: 24px;

                    }

                    &__colon {
                        margin: 0 5px;
                    }
                }
            }

            &:first-child {
                .card {
                    border-top: initial;
                }
            }

            &:last-child {
                border-bottom: 1px solid #eaeff5;
            }

            &:not(.collapsed) {
                .organization-accordion__title {
                    color: $red;
                }

                .card {
                    .card-left-border {
                        transform: scaleY(1);
                    }

                    .card-header .btn:after {
                        transform: rotate(180deg);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.304' height='17' viewBox='0 0 12.304 17'%3E%3Cpath id='ic_arrow' d='M10.929,14.828a.569.569,0,1,0-.8.8l4.09,4.094H.7a.571.571,0,0,0,0,1.14H14.217l-4.1,4.09a.58.58,0,0,0,0,.8.566.566,0,0,0,.8,0l5.066-5.066a.556.556,0,0,0,0-.8Z' transform='translate(26.421 0.341) rotate(90)' fill='%23013084' stroke='%23013184' stroke-width='1'/%3E%3C/svg%3E");
                    }
                }
            }
            &.collapsed {
                .card__title {
                    color: #013A64;
                }
            }
        }
    }

    &__title {
        font-family: Caros, sans-serif;
        font-size: 32px;
        font-weight: bold;
        line-height: 40px;
        color: #003963;

        @media (max-width: 575px) {
            font-size: 24px;
            line-height: 32px;
        }

        &-container {
            margin: 100px auto 58px;
            max-width: 650px;
            padding: 0 15px;
            position: relative;
            text-align: center;
            width: 100%;
            z-index: 1;
        }
    }

    &__subtitle {
        color: #003963;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 16px;
        text-align: center;
        text-transform: uppercase;
    }

    &__category {
        display: flex;
        align-items: center;
        border: 1px solid #00308429;
        border-radius: 4px;
        padding: 12px 16px;

        img {
            width: 24px;
            margin-right: 5px;
        }

        span {
            &:last-child {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &__rich-text {
        margin-bottom: 64px;

        * {
            font-size: 18px;
            line-height: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul, ol {
            padding-left: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            text-decoration: underline;
        }
    }

    &__button {
        margin: 0 0 28px;

        &-text {
            font-size: 15px !important;
            line-height: 24px;
        }

        .material-icons {
            font-size: 18px !important;
            font-weight: bold;
        }
    }
}
