.wwa-section-five-wrapper {
    background-color: $blue-light;
    padding: 40px 5px;
    color: $blue;
    .wwa-section-five-title {
        margin-bottom: 32px;
    }
    .card-link-container {
        margin: -5px 0;
        .card-link {
            display: flex;
            min-height: 100%;
            width: 100%;
            text-decoration: none;
        }
    }
    .card {
        border-radius: 10px;
        width: 100%;
        padding: 24px 30px 30px 30px;
        justify-content: space-between;
        color: $blue;
        border: none;
        box-shadow: 0px 2px 20px $grey-shadow;
        overflow: hidden;
        transition: color $time-md ease;
        .card-title {
            margin-bottom: 20px;
            p:last-child {
                margin-bottom: 0;
            }
        }
        .card-logo{
            height: 32px;
            width: 82px;
            object-fit: contain;
            object-position: left center;
        }
        .icon-link {
            position: absolute;
            background-color: $blue;
            right: 0;
            bottom: 0;
            border-radius: 70px 0 10px;
            width: 68px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            padding: 10px 0 0 10px;
            transition: background-color $time-md ease;
            .material-icons {
                font-size: 20px;
            }
        }
        &:hover, &:focus {
            color: $red;
            .icon-link {
                background-color: darken($blue, 5);
            }
        }
        &:active, &:active:focus {
            color: darken($blue, 10);
            .icon-link {
                background-color: darken($blue, 10);
            }
        }
    }
    .margin-top-bottom {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    @include breakpoint(lg) {
        padding: 60px 5px;
        .card-link-container {
            margin: -15px 0;
        }
        .card {
            padding: 24px 32px;
            .card-logo {
                width: 80px;
                height: 40px;
            }
        }
        .margin-top-bottom {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}
