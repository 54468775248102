.search-results {
    .subheader-main {
        position: relative;
        background: $white;
        z-index: 2;
    }

    .organization-search {
        background: #f8f9fa;

        .search-details-hr {
            width: 100%;
            margin-bottom: initial;
            height: 1px;
            border: initial;
            background: #E0E6F0;
            @media (max-width: 575px) {
                margin-top: 20px;
            }
        }

        .search-details {
            padding: initial;

            &-title {
                align-items: center;
                color: $blue-repertoire;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                font-weight: bold;
                gap: 8px;
                justify-content: flex-start;
                line-height: 24px;
                margin-bottom: initial;
                flex-wrap: wrap;

                @media (max-width: 1199px) {
                    margin-top: 16px;
                    justify-content: center;
                }

                .highlight {
                    color: $red;
                }
            }
        }

        &-inputs {
            border-bottom: 1px solid #cbd6e2;
            background: $white;
            position: relative;
            z-index: 2;
            @media(min-width: 1200px) {
                padding-bottom: 48px;
            }

            .filter-options-list {
                display: flex;
                flex-wrap: nowrap;
                margin-right: 15px;

                &__item {
                    padding: 0 8px;

                    &:first-child {
                        padding-left: 15px;
                    }

                    &:last-child {
                        padding-right: 15px;
                    }
                }

                &__btn {
                    width: auto;
                    display: inline-block;
                    padding: 10px 16px;
                    border-radius: 18px;
                    font-size: 14px;
                    line-height: 20px;
                    border: 1px solid #00308429;
                    background: #0030840a;
                    color: #004071;
                    white-space: nowrap;
                }

                &__container {
                    overflow-x: auto;
                    padding: 15px 0;
                    margin: 0 -15px;
                    @media(min-width: 1199px) {
                        display: none;
                    }
                }
            }
        //
        //    .address-search-button {
        //        padding: 18px 35px;
        //    }
        //
        //    .autocomplete-container, .autocomplete-select2-container {
        //        padding: 12px 12px 12px 62px;
        //        width: 100%;
        //        height: 56px;
        //        background: rgba(0, 48, 132, .0392156863);
        //        border: 1px solid rgba(0, 48, 132, .1607843137);
        //        border-radius: 4px;
        //        color: #02546d;
        //        position: relative;
        //        margin: 0;
        //        display: flex;
        //        flex-direction: column;
        //        justify-content: center;
        //
        //
        //        // TODO: select 2 hardcoded styles - should be replaced by dynamic styles
        //        // START: select 2 styles
        //        .css-b62m3t-container {
        //            position: relative;
        //            box-sizing: border-box;
        //        }
        //
        //        .css-7pg0cj-a11yText {
        //            z-index: 9999;
        //            border: 0px;
        //            clip: rect(1px, 1px, 1px, 1px);
        //            height: 1px;
        //            width: 1px;
        //            position: absolute;
        //            overflow: hidden;
        //            padding: 0px;
        //            white-space: nowrap;
        //        }
        //
        //        .css-13cymwt-control {
        //            -webkit-box-align: center;
        //            align-items: center;
        //            cursor: default;
        //            display: flex;
        //            flex-wrap: wrap;
        //            -webkit-box-pack: justify;
        //            justify-content: space-between;
        //            min-height: 38px;
        //            position: relative;
        //            transition: all 100ms ease 0s;
        //            background-color: rgb(255, 255, 255);
        //            border-color: rgb(204, 204, 204);
        //            border-radius: 4px;
        //            border-style: solid;
        //            border-width: 1px;
        //            box-sizing: border-box;
        //            outline: 0px !important;
        //        }
        //
        //        .css-hlgwow {
        //            -webkit-box-align: center;
        //            align-items: center;
        //            display: grid;
        //            flex: 1 1 0%;
        //            flex-wrap: wrap;
        //            position: relative;
        //            overflow: hidden;
        //            padding: 2px 8px;
        //            box-sizing: border-box;
        //        }
        //
        //        .css-1jqq78o-placeholder {
        //            grid-area: 1 / 1 / 2 / 3;
        //            color: rgb(128, 128, 128);
        //            margin-left: 2px;
        //            margin-right: 2px;
        //            box-sizing: border-box;
        //        }
        //
        //        .css-19bb58m {
        //            visibility: visible;
        //            flex: 1 1 auto;
        //            display: inline-grid;
        //            grid-area: 1 / 1 / 2 / 3;
        //            grid-template-columns: 0px min-content;
        //            margin: 2px;
        //            padding-bottom: 2px;
        //            padding-top: 2px;
        //            color: rgb(51, 51, 51);
        //            box-sizing: border-box;
        //
        //            &:after {
        //                css-19bb58m::after {
        //                    content: attr(data-value) " ";
        //                    visibility: hidden;
        //                    white-space: pre;
        //                    grid-area: 1 / 2;
        //                    font: inherit;
        //                    min-width: 2px;
        //                    border: 0px;
        //                    margin: 0px;
        //                    outline: 0px;
        //                    padding: 0px;
        //                }
        //            }
        //
        //            input {
        //                olor: inherit;
        //                background: 0px center;
        //                opacity: 1;
        //                width: 100%;
        //                grid-area: 1 / 2;
        //                font-style: inherit;
        //                font-variant: inherit;
        //                font-weight: inherit;
        //                font-stretch: inherit;
        //                font-size: 14px;
        //                line-height: inherit;
        //                font-family: inherit;
        //                font-optical-sizing: inherit;
        //                font-kerning: inherit;
        //                font-feature-settings: inherit;
        //                font-variation-settings: inherit;
        //                min-width: 2px;
        //                border: 0px;
        //                margin: 0px;
        //                outline: 0px;
        //                padding: 0px;
        //            }
        //        }
        //
        //        .css-1wy0on6 {
        //            -webkit-box-align: center;
        //            align-items: center;
        //            align-self: stretch;
        //            display: flex;
        //            flex-shrink: 0;
        //            box-sizing: border-box;
        //
        //            .css-1u9des2-indicatorSeparator {
        //                align-self: stretch;
        //                width: 1px;
        //                background-color: rgb(204, 204, 204);
        //                margin-bottom: 8px;
        //                margin-top: 8px;
        //                box-sizing: border-box;
        //            }
        //
        //            .css-1xc3v61-indicatorContainer {
        //                display: flex;
        //                transition: color 150ms ease 0s;
        //                color: rgb(204, 204, 204);
        //                padding: 8px;
        //                box-sizing: border-box;
        //
        //                .css-8mmkcg {
        //                    display: inline-block;
        //                    fill: currentcolor;
        //                    line-height: 1;
        //                    stroke: currentcolor;
        //                    stroke-width: 0;
        //                }
        //            }
        //        }
        //
        //        // END: select 2 styles
        //
        //
        //        .autocomplete-input {
        //            background-color: #f6f7fa;
        //            border: initial;
        //            box-shadow: initial;
        //            color: #004071;
        //            font-size: 16px;
        //            line-height: 24px;
        //
        //            &-icon {
        //                position: absolute;
        //                left: 0;
        //                top: 50%;
        //                -webkit-transform: translateY(-50%);
        //                transform: translateY(-50%);
        //                padding: 0 20px;
        //                border-right: 1px solid rgba(0, 48, 132, .1607843137);
        //
        //                svg {
        //                    width: 17px;
        //                    height: auto;
        //                }
        //            }
        //        }
        //    }
        //
        //    .autocomplete-select2-container {
        //        .css-13cymwt-control {
        //            border: initial;
        //            background: inherit;
        //        }
        //
        //        .css-1wy0on6 {
        //            visibility: hidden;
        //        }
        //
        //        .geolocation {
        //            position: absolute;
        //            display: flex;
        //            flex-direction: row;
        //            justify-content: center;
        //            align-items: center;
        //            right: 12px;
        //            top: 50%;
        //            -webkit-transform: translateY(-50%);
        //            transform: translateY(-50%);
        //            border: none;
        //            border-radius: 2px;
        //            font-family: Caros Soft Regular sans-serif;
        //            padding: 8px 12px;
        //            background: #004071;
        //            -webkit-transition: .3s;
        //            transition: .3s;
        //            margin: 0;
        //
        //            span {
        //                color: $white;
        //                font-family: Moderat, sans-serif;
        //                font-size: 12px;
        //                line-height: 16px;
        //                display: none;
        //                @media (min-width: 575px) {
        //                    display: inline-block;
        //                }
        //            }
        //
        //            svg {
        //                width: auto;
        //                height: 15px;
        //                @media (min-width: 575px) {
        //                    margin-right: 6px;
        //                }
        //
        //            }
        //        }
        //    }
        //
        //    .address-input-sub-label {
        //        margin-left: auto;
        //        margin-top: 12px;
        //        font-size: 14px;
        //        line-height: 22px;
        //        @media (min-width: 575px) {
        //            font-size: 18px;
        //            margin-top: 18px;
        //        }
        //    }
        //

        }

        .container-custom {
            padding-right: 15px;
            padding-left: 15px;

            //.row {
            //    .col-xl:first-of-type {
            //        @media (min-width: 1200px) {
            //            max-width: 30%;
            //        }
            //    }
            //}

            .main-content {
               // padding-left: 24px;
                @media (max-width: 767px) {
                    padding: initial;
                }

                .contact-banner-block {
                    border-radius: 8px;
                    margin-bottom: 12px;
                    @media (min-width: 992px) {
                        padding: 0 0 0 25px;
                    }

                    .contact-us-btn {
                        @media (max-width: 576px) {
                            margin-bottom: 15px;
                        }
                    }

                    .contact-us-content {
                        margin-top: 40px;
                        margin-bottom: 40px;

                        h2 {
                            font-size: 24px !important;
                            line-height: 32px;
                            font-weight: bold;
                            margin-bottom: 20px;
                        }

                        h3 {
                            font-size: 16px !important;
                            line-height: 24px;
                            font-weight: bold;
                            margin-bottom: 20px;
                            text-transform: uppercase;
                        }

                        &--text * {
                            font-size: 14px !important;
                            line-height: 20px;
                        }
                    }
                }
            }

            .sidebar {
                height: 100%;
                background: $white;
                width: 368px;

                &-header {
                    display: none;
                }

                &-main {
                    padding-left: 32px;
                    padding-right: 32px;
                }

                &-footer {
                    display: none;

                    &-btn {
                        padding: 15px 30px;

                        .material-icons {
                            font-size: 16px;

                        }

                        &-title {
                            font-size: 13px;
                            line-height: 18px;
                        }
                    }

                    &-reset {

                    }

                    &-search {
                        margin-left: 20px;
                    }
                }

                @media (max-width: 1199px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    background: #F8F9FA;
                    position: fixed;
                    top: 100%;
                    z-index: 1000;
                    left: 0;
                    width: 100%;
                    transition: all 400ms linear;
                    max-height: calc(100vh - 40px); // fallback
                    max-height: calc(100dvh - 40px);

                    &-header {
                        display: block;
                        width: 100%;
                        flex: 0 0 auto;
                        position: relative;
                    }

                    &-main {
                        overflow-y: auto;
                        flex: 1 1 auto;
                        width: 100%;
                    }

                    &-footer {
                        display: block;
                        width: 100%;
                        flex: 0 0 auto;
                        background: white;
                        border: 1px solid #cbd6e2;
                        padding: 12px 0;
                        text-align: center;

                        button:first-child {
                            margin-right: 20px;
                        }
                    }

                    &.open {
                        bottom: 0;
                        top:40px;
                        border-radius: 20px 20px 0 0;
                        box-shadow: 11px 2px 12px -7px rgba(0, 0, 0, 0.1);
                    }
                }

                &-mobile-decor {
                    width: 40px;
                    height: 4px;
                    background: #CED6E7;
                    border-radius: 4px;
                    margin: 0 auto 10px;
                }

                &-close-btn {
                    background: initial;
                    // padding: 7px 10px;
                    border: initial;
                    // border-radius: 4px;
                    // display: inline-flex;
                    // justify-content: center;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    //
                    //&:hover {
                    //    background: $grey-light-4;
                    //}

                    &-img {
                        width: 24px;
                        height: auto;
                    }

                    i {
                        color: $blue;
                        font-weight: 300;
                        font-size: 18px;
                    }

                    &-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0 16px 0;
                        border-bottom: 1px solid #cbd6e2;
                        @media (min-width: 1200px) {
                            display: none;
                        }

                        span {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: normal;
                        }
                    }
                }
            }
        }


    }


    .subheader .header-content .text-content {
        padding-bottom: 48px !important;
        @media(max-width: 991px) {
            padding: 32px 0 !important;
        }
        @media(max-width: 575px) {
            padding: 24px 0 !important;
        }

        h1 {
            font-size: 32px;
            line-height: 40px;
            @media(max-width: 575px) {
                font-size: 20px;
            }
        }
    }

    .top-link {
        font-size: 15px;
        line-height: 24px;
    }

    .content-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 62px;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
            background: white;
        }
        @media (max-width: 1199px) {
            justify-content: flex-start;
            padding-top: 16px;
            padding-bottom: 16px;
            margin: initial;
        }

        span {
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            margin-right: 20px;
            color: $blue-repertoire;
        }

        .toggle-buttons {
            padding: 10px 16px 9px 16px;
            background: $white;
            color: $blue;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: 1px solid #CBD6E2;

            span {
                text-transform: initial;
            }

            @media (max-width: 1199px) {
                margin: initial;
                padding: 22px 32px;
                border-radius: 32px !important;
                //min-width: 217px;
                border-color: $blue;
            }

            &.organization-filters-open-btn {
                border-radius: 4px;
                @media (min-width: 1200px) {
                    display: none;
                }

                span, i {
                    color: $blue;
                }

                span {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: bold;
                    margin-left: 8px;
                    margin-right: initial;
                    color: $blue-repertoire;
                }
            }
        }

        .view-toggle {
            display: flex;

            @media (max-width: 1199px) {
                position: fixed;
                z-index: 3;
                bottom: 20px;
                left: 0;
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                &.no-chat {
                    justify-content: center;
                    width: 100%;
                }
            }

            .toggle-buttons {
                margin: initial;

                &.active {
                    background: $blue-repertoire;
                    border-color: $blue-repertoire;

                    span {
                        color: $white;
                        font-family: Caros, sans-serif;
                        font-size: 15px;
                        line-height: 24px;
                    }

                    svg {
                        path {
                            fill: $white;
                        }
                    }

                    @media (max-width: 1199px) {
                        background: $red;
                        border: initial;
                        margin-right: 4px;
                    }
                }

                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }

                span {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: bold;
                    margin-right: initial;
                }

                &--map {
                    border-radius: 4px 0 0 4px;
                }

                &--list {
                    border-radius: 0 4px 4px 0;
                }
            }
        }
    }

    .organization-checked-filters {
        background: $white;
        display: inline-flex;
        padding: 10px 12px;
        border: 1px solid #cbd6e2;
        border-radius: 4px;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin-right: 14px;
        margin-bottom: 14px;
        order: 0;

        &-group {
            margin-top: 48px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: start;
            justify-content: start;
            @media (max-width: 1199px) {
                margin-bottom: 24px;
            }
            @media (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
            }
             @media (max-width: 575px) {
                margin-top: 24px;
            }
        }

        &--clear-all {
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            color: $blue-repertoire;
            background: $grey-light-4;
            border-color: $grey-light-4;
            order: 1;
        }

        &:hover {
            background: $grey-light-4;
        }

        span:first-child {
            font-size: 14px;
            line-height: 20px;
            color: $blue-repertoire;
            margin-right: 6px;
        }


        &__icon {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8ZyBpZD0iaWNfZGVsZXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNDUgNzIpIj4KICAgIDxwYXRoIGlkPSJQYXRoXzE1MTQ1IiBkYXRhLW5hbWU9IlBhdGggMTUxNDUiIGQ9Ik0xMCwyYTgsOCwwLDEsMCw4LDgsOCw4LDAsMCwwLTgtOFptMy4yNjMsMTAuNDUxYS41NzQuNTc0LDAsMSwxLS44MTEuODExTDEwLDEwLjgwNiw3LjU0OSwxMy4yNjNhLjU3NC41NzQsMCwxLDEtLjgxMS0uODExTDkuMTk0LDEwLDYuNzM3LDcuNTQ5YS41NzQuNTc0LDAsMSwxLC44MTEtLjgxMUwxMCw5LjE5NGwyLjQ1MS0yLjQ1N2EuNTc0LjU3NCwwLDEsMSwuODExLjgxMUwxMC44MDYsMTBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTQ3IC03NCkiIGZpbGw9IiMwMTQxNzIiLz4KICA8L2c+Cjwvc3ZnPg==');
            background-repeat: no-repeat;
            width: 16px;
            height: 16px;
        }
    }


    .result-list {
        .result-item {
            padding: 40px 40px 0 40px;
            background: $white;
            @media (max-width: 767px) {
                padding: 16px 16px 0 16px;
            }

            &-container {
                border: 2px solid #00308429;
                border-radius: 8px;
                overflow: hidden;
                margin-bottom: 16px;
                @media (max-width: 767px) {
                    border-bottom: initial;
                    border-right: initial;
                    border-left: initial;
                    border-radius: initial;
                }
            }

            &__title {
                display: block;
                margin-bottom: 20px;
                text-decoration: none;
                color: $blue;

                &:hover {
                    text-decoration: none;
                    opacity: 0.75;
                }

                h2 {
                    font-size: 18px;
                    line-height: 24px;
                    font-family: Moderat, sans-serif;
                    font-weight: bold;
                    color: $blue-repertoire;
                }
            }

            &__address {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                text-decoration: none;
                padding: 10px 16px;
                background: #F8F9FA;
                border-left: 3px solid #FFE4D4;
                border-top: 1px solid #F8F9FA;
                border-bottom: 1px solid #F8F9FA;
                border-right: 1px solid #F8F9FA;
                border-radius: 4px;
                position: relative;

                &:hover {
                    text-decoration: none;
                    border-top: 1px solid #FFE4D4;
                    border-bottom: 1px solid #FFE4D4;
                    border-right: 1px solid #FFE4D4;

                    span {
                        color: $red;
                    }
                }

                &-location {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: start;
                    @media (max-width: 767px) {
                        flex-direction: column;
                        align-items: start;
                        justify-content: center;
                        svg {
                            margin-bottom: 4px;
                        }
                    }

                    svg {
                        width: 14px;
                        height: 20px;
                        margin-right: 8px;
                    }
                }


                span {
                    font-size: 14px;
                    line-height: 20px;
                    color: $blue;
                }

                &-distance {
                    font-weight: bold;
                    color: $blue-repertoire;
                    @media (max-width: 767px) {
                        position: absolute;
                        top: 10px;
                        left: 42px;
                    }

                }

            }

            &__text {
                font-size: 16px;
                line-height: 24px;
                padding-bottom: 32px;
                border-bottom: 1px solid #00308429;
                margin-bottom: 0;
                color: $blue-repertoire;

                &-show-more {
                    background: initial;
                    border: initial;
                    color: $red;
                    font-weight: bold;
                    text-decoration: none;
                    padding: initial;

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }

            &__services {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: start;
                padding: 12px 16px;
                border: 1px solid #00308429;
                border-radius: 4px;
                margin-right: 12px;
                margin-bottom: 12px;
                min-height: 50px;

                svg {
                    width: 24px;
                    height: 24px;
                }

                span {
                    font-size: 14px;
                    line-height: 20px;
                    margin-left: 8px;
                    color: $blue-repertoire;
                }

                &-wrapper {
                    border-bottom: 1px solid #00308429;
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    align-items: center;
                    @media (max-width: 767px) {
                        flex-direction: column;
                        align-items: start;
                    }
                }

                &-container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: start;
                    align-items: center;
                    padding-top: 20px;
                    padding-bottom: 8px;

                }

                &-title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                    margin-right: 16px;
                    min-width: 72px;
                    color: $blue-repertoire;
                    @media (max-width: 767px) {
                        margin-top: 16px;
                    }
                }
            }

            &__contact {
                padding: 16px 20px;
                background: #fdf3ec;
                display: inline-flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                margin-right: 2px;
                text-decoration: none;
                margin-bottom: 4px;
                @media (max-width: 767px) {
                    display: flex;
                    border-radius: 4px;
                    justify-content: center;
                    &:last-child {
                        margin-bottom: initial;
                    }
                }

                &-container {
                    padding: 16px 0;
                }

                &:hover {
                    background: $red;
                    text-decoration: none;

                    span {
                        color: $white;
                    }

                    svg {
                        #ic_mail {
                            fill: $white;
                        }

                        #ic_phone {
                            fill: $white;
                        }
                    }
                }

                @media(min-width: 768px) {
                    &:first-child {
                        border-radius: 4px 0 0 4px;
                    }

                    &:last-child {
                        border-radius: 0px 4px 4px 0px;
                    }
                }

                svg {
                    width: auto;
                    height: 16px;
                    margin-right: 12px;
                    margin-top: 3px;
                }

                span {
                    font-size: 15px;
                    line-height: 24px;
                    font-weight: bold;
                    color: $red;
                    font-family: $font-title;
                }
            }

            &__button {
                @extend .btn-blue-1;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px;
                text-decoration: none;
                border-radius: initial;
                margin: initial;
                width: 100%;

                &:hover, &:focus {
                    color: $white;

                    &:after {
                        transform: scale(0);
                    }
                }

                span {
                    color: white;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                    margin-left: 8px;
                }
            }

            &__other {
                &-wrapper {
                    padding: 32px 0;
                }

                &-container {
                    display: none;

                    .result-item__address {
                        margin-bottom: 0;
                    }

                    .result-item__contact-container {
                        padding-bottom: 0;
                    }
                }

                &-button {
                    border: initial;
                    background: initial;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    padding: initial;

                    svg {
                        transform: rotate(180deg);
                        transition: all 300ms linear;
                    }

                    &.open {
                        svg {
                            transform: rotate(0deg);
                        }
                    }


                    &:hover {
                        opacity: 0.75;
                    }

                    span {
                        color: $blue-repertoire;
                        text-decoration: underline;
                    }

                    svg {
                        margin-left: 6px;
                    }
                }

                &-text {
                    font-size: 16px;
                    line-height: 24px;
                    color: $blue-repertoire;
                    margin: 24px 0 32px 0;
                }

                &-services {
                    margin-bottom: 16px;
                    border-bottom: 1px solid #e1e6f0;
                    padding-bottom: 16px;

                    &:last-child {
                        margin-bottom: initial;
                        border-bottom: initial;
                        padding-bottom: initial;
                    }
                }

                &-title {
                    text-decoration: initial;
                    color: $blue-repertoire;

                    :hover {
                        opacity: 0.75;
                    }

                    h3 {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: bold;
                        margin-bottom: 16px;
                        color: $blue-repertoire;
                    }
                }

            }

        }
    }

    .organization-filters {
        @extend .form-grey;

        .organization-filter-group {
            border-bottom: 1px solid #CBD6E2;
            position: relative;
            padding-right: 32px;

            .filter-group-label {
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
                text-transform: uppercase;
                margin: 24px 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: start;
                color: $blue-repertoire;
            }

            .filter-group-toggle {
                position: absolute;
                top: 12px;
                right: 0;
                width: 22px;
                height: 22px;
                border: 1px solid #CBD6E2;
                border-radius: 4px;
                background: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 6px;

                svg {
                    width: 8px;
                    height: 8px;
                    transform: translate(7.5px, -13.5px) rotate(90deg);
                    overflow: visible;
                }

                &.open {
                    svg {
                        transform: translate(-7.5px, 13.5px) rotate(270deg);
                    }
                }
            }

            .organization-filters-item {
                @extend .form-grey-check;

                input {
                    @extend .form-check-input;
                    display: block;
                    width: 0;
                    height: 0;
                }

                label {
                    @extend .form-check-label;
                    padding-left: 30px;
                    position: relative;
                    font-size: 14px;
                    line-height: 18px;
                    color: $blue-repertoire;

                    &:before, &:after {
                        width: 20px;
                        height: 20px;
                    }

                    &:before {
                        border-color: #CBD6E2;
                    }

                    &:after {
                        font-size: 13px;
                        font-weight: 700;
                    }
                }

                .organization-filters-container {
                    padding-top: 16px;
                    position: relative;
                }

                .filter-group-toggle {
                    top: 0;
                    right: -32px;
                }
            }
        }
    }

    .search-results-indicator {
        color: $blue-repertoire;
        font-size: 16px;
        line-height: 24px;
    }

    .rdr-scroll-up {
        position: fixed;
        z-index: 99;
        bottom: 160px;
        right: 24px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease, visibility 0.5s ease;

        &-arrow > button {
            position: relative;
            overflow: hidden;
            width: 36px;
            height: 36px;
            background: #f5cdb1;
            border-radius: 50%;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-transition: .3s;
            transition: .3s;
            margin-bottom: 8px;
            border: none;

            i {
                font-weight: 900;
            }
        }

        &-label {
            color: $blue;
            font-family: Caros, sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            background: #fff;
            border-radius: 4px;
            padding: 8px;
            -webkit-box-shadow: 0 2px 20px #000b560a;
            box-shadow: 0 2px 20px #000b560a;
        }
    }

    .provincial-resources {
        margin: 24px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-left: 10px;

        &__title {
            font-family: Moderat, sans-serif;
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
        }

        &__line {
            border-bottom: 3px solid $red;
            opacity: 0.2;
            display: block;
            flex: 1;
            margin-left: 16px;
            margin-top: 5px;
        }
    }

    .pagination ul li.active a {
        background: $blue-repertoire;
        color: $white;
    }

    #resultsMap {
        height: 500px;
        @media (max-width: 1199px) {
            // position: fixed;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            height: 100vh; // fallback
            height: 100svh;
            z-index: 1;
        }
    }

    .infoBox {
        transform: translate(-50%, -100%) !important;
        overflow: visible !important;
        min-width: 282px !important;
        max-width: 60% !important;
        //@media(max-width: 575px) {
        //    max-width: 90% !important;
        //}

        &:after {
            content: '';
            position: absolute;
            bottom: -11px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 12px solid #ffffff;
            filter: drop-shadow(0 4px 2px rgba(178, 178, 178, .4));
        }
    }


    .map-info-box {
        background-color: $white;
        padding: 16px;
        position: relative;
        border-radius: 8px;

        &__close {
            padding: initial;
            position: absolute;
            top: 5px;
            right: 5px;
            background: transparent;
            border: none;
            opacity: 0.75;
            &-img {
                width: 24px;
                height: 24px;
            }
        }

        &__link {
            text-decoration: none;
        }

        &__title {
            font-size: 16px;
            line-height: 24px;
            font-family: Moderat, sans-serif;
            font-weight: bold;
            color: $blue-repertoire;
            margin-right: 20px;
            margin-bottom: 16px;
        }

        &__address {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            font-family: Moderat, sans-serif;
            color: $blue-repertoire;
            margin-bottom: 16px;
        }

        &__phone, &__email {
            display: inline-flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            column-gap: 8px;
            &:hover {
                text-decoration: none;
                opacity: 0.8;
            }

            &-img {
                font-size: 18px;
                color: $red;
                margin-top: 3px;
            }

            &-title {
                color: $red;
                font-size: 15px;
                line-height: 24px;
                font-family: Caros, sans-serif;
                font-weight: bold;
                word-break: break-all;
            }
        }

        &__phone {
            margin-bottom: 12px;
        }

    }

    .fixed-phone-btn {
        @media (max-width: 991px) {
            display: none;
        }
    }

    @media (max-width: 1199px) {
        .button2-chat {
            position: static;
            padding: 22px 32px !important;
            height: auto;
            margin: 0 0 0 4px;

            &-container {
                position: fixed;
                z-index: 3;
                bottom: 20px;
                right: 0;
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }

            .chat-button-min-text {
                font-size: 15px;
                line-height: 24px;
                margin: 0 0 0 4px;
            }
        }
    }
}

.repertoire-search-results {
    &__title-wrapper {
        padding: 55px 0;
        background: #fff5ef;
        margin-bottom: 33px;
        min-height: 169px;
        @media (max-width: 576px) {
            padding: 25px 0;
            min-height: 108px;
        }
    }

    &__title-container {
        margin: auto;
        max-width: 1414px;
        padding: 0 15px;
        @media (max-width: 576px) {
            text-align: center;
        }
    }

    &__title {
        font-family: Caros, sans-serif;
        font-size: 32px;
        line-height: 52px;
        color: #003084;
        max-width: 1200px;
        margin-left: auto;
        @media (max-width: 576px) {
            font-size: 20px !important;
            line-height: 26px !important;
        }

        em {
            color: $red;
            font-style: normal;
        }
    }

    &__form-container, &__list-container {
        max-width: 1414px;
        padding: 0 15px;
        margin: auto;
    }

    &__form {
        max-width: 1200px;
        margin-left: auto;
    }

    &__input {
        font-family: Moderat, sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $blue;
        width: 100%;
        border-right: 1px solid $blue;
        border-left: initial;
        border-top: initial;
        border-bottom: initial;
        padding-left: 14px;
        &::placeholder {
            color: #a5bccd;
        }

        &-container {
            max-width: 650px;
            padding: 14px 11px;
            border: 1px solid #b4c6d5;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 32px;
        }
    }

    &__search-icon {
        color: $blue;
        font-size: 25px;
    }

    &__close-btn {
        background: initial;
        border: initial;
        margin-left: 10px;
        background: $blue;
        border-radius: 50%;
        padding: initial;

    }


    &__close-icon {
        color: $white;
        width: 26px;
        height: 26px;
        align-items: center;
        justify-content: center;
        &.fas {
            display: inline-flex;
        }
    }

    &__filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        @media (max-width: 450px) {
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
        }
    }

    &__filter-assistant, &__filter-org {
        padding: 12px 17px;
        border: 1px solid #00308429;
        border-radius: 4px;
        font-family: Moderat, sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #003084;
        background: initial;
        &.active {
            background: #00407115;
        }
        &:hover {
            border: 1px solid #00407115;
            background: #00407115;
        }
    }

    &__filter-assistant {
    }

    &__filter-org {
    }

    &__hr-wrapper {
        max-width: calc(1414px + (100vw - 1414px) / 2 );
        margin-left: auto;
        padding-left: 15px;
    }

    &__hr-container {
        margin-left: auto;
        max-width: calc(100vw - (100vw - 1215px));
        @media(min-width: 1415px) {
            max-width: calc(100% - 185px);
        }
    }

    &__hr {
        border-top: 1px solid #d7e1e9;
        margin-top: 10px;
        margin-bottom: initial;
    }

    &__indicator {
        color: #a5bccd;
        font-family: Moderat, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 21px;
    }

    &__list {
        list-style-type: none;
        padding: initial;
        max-width: 1200px;
        margin-left: auto;
        &-item {

        }

    }

    &__card {
        &-title {
            font-family: Caros, sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 32px;
            color: $blue-repertoire;
            margin-bottom: 8px;
        }

        &-text, &-link {
            font-family: Caros, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: $blue-repertoire;
            margin-bottom: 8px;
        }

        &-link {
            color: $red;
        }
    }
}

.results-divider {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 25px 0;

    @media(max-width: 767px) {
        margin: 25px 15px;
    }

    &__title {
        color: $blue-repertoire;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        margin-right: 24px;
    }

    &__line {
        background-color: $red;
        display: inline-block;
        flex: 1;
        height: 3px;
        margin-top: 3px;
        opacity: 20%;
    }
}
