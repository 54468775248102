.text-two-cols-pp {
    padding: 144px 0 117px;
    border-radius: 0 100px 0 0;
    background-color: #F8F9FA;

    &__container {
        margin: 0 auto;
        max-width: 1176px;
        padding: 0 15px;
    }

    &__title {
        font-size: 32px;
        line-height: 40px;
        color: #013A64;
        margin-bottom: 30px;
    }

    &__rich-text {
        * {
            font-size: 18px;
            line-height: 24px;
            color: #013A64;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ol {
            padding-left: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            margin-bottom: 24px;
            padding-left: 0;
            list-style: none;
            li {
                position: relative;
                margin-bottom: 14px;
                padding-left: 20px;
                list-style: none;

                &:before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $red;
                    position: absolute;
                    top: 9px;
                    left: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            text-decoration: underline;
        }
    }
}
