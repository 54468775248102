.awareness-tools-banner {
    @extend .banner-one;
    padding: 0;
    .content {
        background-color: transparent;
        p {
            color: $blue;
        }
    }
    .top-content {
        background-color: $gray-dark2;
        margin-bottom: -90px;
        background-size: cover;
        background-position: center;
        padding-top: 80px;
        padding-bottom: 165px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        .content-middle-wrapper {
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
            color: $white;
            .content-middle {
                max-width: 590px;
            }
            .awareness-tools-banner-title {
                margin-bottom: 24px;
            }
            .awareness-tools-banner-text {
                font-size: 16px;
                margin-bottom: 32px;
                a {
                    color: #a8cfec !important;
                }
            }
        }
    }
    @media (max-width: 991px) {
        .content {
            .content-container {
                order: 2;
            }
        }
    }
}

.awareness-files-section {
    .awareness-files-title {
        font-family: $font-content;
        font-size: rem(18);
        font-weight: 700;
        margin-bottom: 20px;
        p:last-child {
            margin-bottom: 0;
        }
    }
    .cms-content {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 24px;
        li {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            .li-link {
                display: flex;
                align-items: center;
                font-size: 16px;
                text-decoration: none !important;
                color: $blue-link;
                .arrow-circle-icon {
                    flex-grow: 0;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #e6472a1f;
                    padding: 5px;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 12px;
                    position: relative;
                    z-index: 2;
                    .arrow-circle-icon-svg {
                        .arrow-circle-icon-path {
                            fill: $red;
                            transition: fill $time-sm ease;
                        }
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        background-color: $red;
                        border-radius: 50%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        transform: scale(0);
                        transition: transform $time-sm ease, background-color $time-sm ease;
                    }
                }
                &:hover, &:focus {
                    color: $red;
                    .arrow-circle-icon {
                        .arrow-circle-icon-svg {
                            .arrow-circle-icon-path {
                                fill: $white;
                            }
                        }
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
                &:active, &:active:focus {
                    color: darken($red, 6%);
                    .arrow-circle-icon {
                        .arrow-circle-icon-svg {
                            .arrow-circle-icon-path {
                                fill: $white;
                            }
                        }
                        &:after {
                            transform: scale(1);
                            background-color: darken($red, 6%);
                        }
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.awareness-title-text-section {
    @extend .awareness-files-section;
    .awareness-title-text-section-title {
        @extend .awareness-files-title;
    }
}

.awareness-tools-main-container {
    .awareness-tools-main-section {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

body.txt-dark-False {
    .awareness-files-section ul li .li-link {
        color: #aff9ff;
    }
}

.awareness-tools-main-section.awareness-title-text-section .cms-content  {
    ul {
        list-style: none;
        padding-left: 0;
    }

    ul li {
        position: relative;
        padding-left: 25px;
        list-style: none;
    }

    ul li::before {
        content: '•';
        position: absolute;
        left: 3px;
        top: -2px;
        scale: 2.5;
    }

}
