.cop-details-section-cards-wrapper {
    img.top-art {
        width: 100%;
    }
    .cop-details-section-title {
        text-align: center;
        margin-bottom: 40px;
    }
    .par-cards-wrapper {
        display: inline-block;
        width: 100%;
        padding-top: 0 !important;
        background-color: $blue-light;
    }
    @include breakpoint(md) {
        .cop-details-offset-container {
            transform: translateY(-40px);
        }
        .par-cards-wrapper {
            padding-bottom: 40px;
        }
    }
    @include breakpoint(xl) {
        .cop-details-offset-container {
            transform: translateY(-80px);
        }
        .par-cards-wrapper {
            padding-bottom: 0;
        }
    }
}
