.par-valuation-report-wrapper {
    border-top: 2px solid #f1f4f7;
    .content {
        max-width: 1200px;
        margin: 0 auto;
        .section-row {
            display: flex;
            flex-direction: row;
            border-bottom: 2px solid #f1f4f7;
            .col-left {
                display: flex;
                flex: 0 0 65%;
                flex-direction: column;
                padding: 50px;
                border-right: 2px solid #f1f4f7;
                .date-section {
                    display: flex;
                    color: #8ea2c5;
                    font-weight: 700;
                    font-size: 9pt;
                    align-items: center;
                    margin-bottom: 5px;
                    .material-icons {
                        font-size: 10pt;
                        margin-right: 5px;
                    }
                }
                h3 {
                    font-size: 14pt;
                    font-weight: bold;
                    color: $blue;
                }
                p {
                    color: $blue;
                }
                ul {
                    list-style: none;
                    padding-left: 13px;
                    li {
                        color: $blue;
                        list-style: none;
                    }
                    li::before {
                        content: "\2022";
                        color: $red;
                        font-weight: 800;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
            .col-right {
                display: flex;
                flex: 0 0 35%;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                padding: 25px;
            }
        }
    }
    @media (max-width: 750px) {
        .content {
            .section-row {
                flex-direction: column;
                .col-left {
                    border-right: 0;
                    padding: 25px;
                }
                .col-right {
                    display: flex;
                    flex: 0 0 35%;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 25px;
                }
            }
        }
    }
}
