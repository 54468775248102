/* MIXINS
=========================== */

$time-xs: 150ms; //1/2 time-sm
$time-sm: 300ms;
$time-md: 600ms;
$time-lg: 900ms;

@function rem($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return calc($pixels / $context * 1rem);
}

@mixin transition-sm($type:all, $ease:ease) {
    @include transition-fix();
    -webkit-transition: $type $time-sm $ease;
    -moz-transition: $type $time-sm $ease;
    -o-transition: $type $time-sm $ease;
    transition: $type $time-sm $ease;
}

@mixin transition-md($type:all, $ease:ease) {
    @include transition-fix();
    -webkit-transition: $type $time-md $ease;
    -moz-transition: $type $time-md $ease;
    -o-transition: $type $time-md $ease;
    transition: $type $time-md $ease;
}

@mixin transition-lg($type:all, $ease:ease) {
    @include transition-fix();
    -webkit-transition: $type $time-lg $ease;
    -moz-transition: $type $time-lg $ease;
    -o-transition: $type $time-lg $ease;
    transition: $type $time-lg $ease;
}

@mixin transition-fix() {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0)
}

@mixin cover-image() {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

@mixin fa-icon($icon) {
    content: $icon;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

@mixin fa-pro-icon($icon) {
    content: $icon;
    font-family: "Font Awesome 5 Pro";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

@mixin io-icon ($icon) {
  content: $icon;
  font-family: "Ionicons";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

@mixin material-icon ($icon) {
    content: $icon;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

@mixin apprearance($value:none) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    -ms-appearance: $value;
    -o-appearance: $value;
    appearance: $value;
}

@mixin placeholder-color($color) {
    ::-webkit-input-placeholder {
        color: $color;
    }
    :-moz-placeholder {
        color: $color;
    }
    ::-moz-placeholder {
        color: $color;
    }
    :-ms-input-placeholder {
        color: $color;
    }
}

@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-reset {
    @include list-reset;
}

.list-inline {
    @include list-reset;
    margin: 0;
    li {
        padding: 0;
        display: inline-block;
    }
}

.clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
