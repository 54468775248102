.wwa-section-two {
    .content {
        padding: 60px 5px;
        .left-content {
            .title {
                color: $blue;
                margin-bottom: 40px;
            }
            .txt {
                font-size: rem(18);
                color: $blue;
                margin-bottom: 40px;
            }
        }
        .right-content {
            img {
                max-width: 100%;
                width: 335px;
                margin: 0 auto;
                display: block;
            }
        }
    }
    @include breakpoint(lg) {
        .content {
            display: flex;
            float: 0 0 50%;
            .left-content {
                display: flex;
                flex-wrap: wrap;
                flex: 0 0 50%;
                .left-content-items {
                    max-width: 455px;
                    width: 100%;
                    padding-right: 15px;
                }
                .title {
                   margin-bottom: 32px;
                }
                .txt {
                    margin-bottom: 0;
                }
            }
            .right-content {
                display: flex;
                flex: 0 0 50%;
                justify-content: flex-end;
                img {
                    width: 590px;
                    margin: 0;
                }
            }
        }
    }
}
