.banner-one {
    padding: 60px 20px 40px 20px;
    .content {
        max-width: 1384px;
        margin: 0 auto;
        border-radius: 8px;
        overflow: hidden;
        background-color: #fdf6ed;
        display: flex;
        .img-container {
            width: 50%;
            margin-right: auto;
            img {
                height: 185px;
                width: 100%;
                object-fit: cover;
            }
        }
        .content-container {
            display: flex;
            align-items: center;
            width: 50%;
            margin-left: auto;
            padding:50px 90px;
            .like-section {
                display: flex;
                align-items: center;
                justify-content: left;
                margin: 15px 0;
                font-size: 16px;
                color: $blue;
                img {
                    margin-right: 10px;
                }
            }
        }
    }
    @include breakpoint(md) {
        .content {
            .img-container {
                img {
                    height: 385px;
                }
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 50px;
        .content {
            .content-container {
                .like-section {
                    margin: 18px 0 30px 0;
                }
            }
        }
    }
    @media (max-width: 991px) {
        .content {
            display: flex;
            flex-wrap: wrap;
            .content-container,
            .img-container {
                width: 100%;
            }
            .content-container {
                padding: 30px;
            }
        }
    }
}
