.title-text-block {
    &--text-container {
        text-align: center;
        max-width: 640px;
        margin: 0 auto;
        padding: 60px 20px 40px 20px;
        @media screen and (min-width: 992px) {
            padding: 80px 20px;
        }
        ul {
            text-align: initial;
        }
        &.story-detail {
            &.center {
                text-align: center;
            }
            &.left {
                text-align: left;
            }
            &.right {
                text-align: right;
            }
            &.justify {
                text-align: justify;
            }
        }
        h2 {
            font-size: 32px !important;
            margin-bottom: 38px;
            @media screen and (min-width: 768px) {
                font-size: 2.2857142857rem;
                line-height: 40px;
            }
        }
    }
    &--text {
        display: flex;
        flex-direction: column;
        align-items: center;
        * {
            font-size: 18px !important;
        }
    }
}
