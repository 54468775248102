/* COLORS
===========================
Important: Always define primary color
*/

$color-primary: #2abfd4;
//$color-secondary: #ff0000; (Use or remove)
//$color-extra:  #00ff00; (Use or remove)

$white: #FFF;
$grey-shadow: #000b560a;
$grey-alpha: #00407129;
$grey-light-alpha: #0040710a;
$grey-hover: #ebeff5;
$grey-light: #eaeef5;
$grey-light-2: #f0f0f0;
$grey-light-3: #f5f7fa;
$grey-light-4: #dce2ee;
$grey: #f8f9fa;
$grey-dark: #626467;
$gray-dark2: #1D1D1D;
$black: #000;
$black-light: #003638;
$blue: #004071;
$blue-repertoire: #014172;
$blue-green: #02546D;
$blue-alpha: #00407160;
$purple: #3F5797;
$blue-light: #dbecf9;
$blue-grey: #cbd6e2;
$blue-link: #1465d0;
$red: #e6472a; /** #e6482b **/
$skin: #f5cdb1;
$skin-alpha: #f5cdb140;
$skin-light: #ffe4d4;
$skin-dark: #e2b79e; //lower saturation, cannot be achieved using scss darken
$skin-pail: #fdf6ed;
$green: #57BEBE;
$green-alt: #4FADAD;
$green-dark: #003537;
$yellow: #FFE66F;

/* SOCIAL MEDIA */

$facebook-color: #3b5999;
$twitter-color: #55acee;
$linkedin-color: #0077b5;
$youtube-color: #cd201f;
$pinterest-color: #cb2026;
$googleplus-color: #dd5044;
$instagram-color: #e4405f;


/* TEXT COLORS
=========================== */

.text-primary { color: $color-primary !important }
.text-blue { color: $blue !important }
.text-blue-repertoire { color: $blue-repertoire !important }
.text-blue-green { color: $blue-green !important }
.text-white { color: $white !important }
.text-grey-light { color: $grey-light !important }
.text-grey { color: $grey !important }
.text-grey-dark { color: $grey-dark !important }
.text-black { color: $black !important }
.text-red { color: $red !important }


/* BACKGROUND COLORS
=========================== */

.bg-primary { background-color: $color-primary !important }
.bg-white { background-color: $white !important }
.bg-grey-light { background-color: $grey-light !important }
.bg-grey { background-color: $grey !important }
.bg-grey-dark { background-color: $grey-dark !important }
.bg-black { background-color: $black !important }
.bg-blue-light { background-color: $blue-light !important }
.bg-none { background: none !important }
.bg-green { background-color: $green !important }
.bg-yellow { background-color: $yellow !important }
.bg-violet { background-color: $purple !important }
.bg-skin-light { background-color: $skin-light !important }
