.about-team-under-header-txt {
    padding: 50px 25px;
    p {
        color: $blue;
        font-size: 14pt;
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
    }
}
