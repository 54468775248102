.contact-banner-block {
    &.contact-us {
        background: #ffe4d4;
        overflow: hidden;
        position: relative;

        .contact-us-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;
            .contact-us-btn.btn-orange-to-orange-1 {
                margin: initial;
            }
        }

        .contact-us__wrapper {
            max-width: 1414px;
            margin: 0 auto;
            position: relative;
            padding-top: 72px;
            padding-bottom: 72px;
            @media screen and (max-width: 991px) {
                padding-bottom: initial;
            }
        }

        h2 {
            font-family: Caros, sans-serif;
            font-weight: 700;
            font-size: 32px !important;
            //font-weight: bold;
            line-height: 40px;
            color: $blue-repertoire;
            margin-bottom: 24px;
        }

        h3 {
            font-family: Caros, sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 20px !important;
            line-height: 28px;
            color: $blue-repertoire;
            margin-bottom: 20px;
        }

        .contact-us-btn {
            &--email {
                background-color: $blue-repertoire;
            }
        }
    }

    .contact-us-content--text {
        @media(min-width: 992px) {
            max-width: 485px;
        }

        * {
            font-size: 16px !important;
            font-family: $font-content;
            color: $blue-repertoire;
            margin-bottom: 32px;
        }
    }

    .contact-us__bg {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 42%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        @media screen and (max-width: 1199px) {
            align-items: center;
        }
        @media screen and (max-width: 991px) {
            position: relative;
            top: initial;
            right: initial;
            max-width: initial;
            height: initial;
            width: 100%;
            justify-content: center;
        }

        img {
            width: 100%;
        }

        &--results {
            justify-content: flex-end;
            @media screen and (max-width: 1439px) {
                justify-content: center;
            }
            @media screen and (min-width: 991px) and (max-width: 1199px){
                justify-content: flex-end;
            }
        }
    }

    .contact-us-btn {
        img {
            margin-right: 5px;
        }
    }

}
