.about-team-banner-one-wrapper {
    margin-bottom: -54px;
    position: relative;
    overflow: overlay;
    @include breakpoint(lg) {
        margin-bottom: -60px;
    }
}

.about-team-banner-one {
    background-size: cover;
    background-position: center center;
    padding: 60px 20px;
    text-align: center;
    background-color: $grey;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .about-team-banner-text {
        color: $white;
        margin-left: auto;
        margin-right: auto;
        max-width: 590px;
        width: 100%;
        margin-bottom: 35px;
    }
    @include breakpoint(lg) {
        padding: 80px 20px;
    }
}
.about-team-bg-gray {
    background-color: $grey;
}
