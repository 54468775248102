.tool-detail-families-game--section-one {
    background-color: $green;
    color: $green-dark;
    .row-flex {
        padding-top: 60px;
        padding-bottom: 60px;
        .col-flex.left {
            .tool-title {
                color: $green-dark;
                margin-bottom: 32px;
            }
            .tool-desc {
                margin-bottom: 60px;
            }
        }
        .col-flex.right {
            img {
                width: 100%;
                border-radius: 8px;
            }
        }
    }
    @include breakpoint(lg) {
        .row-flex {
            display: flex;
            align-items: center;
            padding-top: 80px;
            padding-bottom: 80px;
            .col-flex.left {
                width: calc(50% + 12px);
                padding-right: 40px;
                .tool-title {
                    max-width: 510px;
                }
                .tool-desc {
                    max-width: 510px;
                    margin-bottom: 0;
                }
            }
            .col-flex.right {
                width: calc(50% - 12px);
            }
        }
    }
}
