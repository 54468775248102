.title_image_podcast_block {
    color: white;
    text-align: center;
    background-color: #1d1d1d;
    img {
        margin: 25px auto;
        max-width: 600px;
        width: 90%;
    }
    .container-custom {
        max-width: 900px;
    }
    h2 {
        padding-top: 25px;
    }
}
