/* POSITIONING
=========================== */


/* SPACING
===========================
Adding a size:
- Create a variable containing value
- Add a label and value in $spacing-sizes, it will create all desired variants
*/

$spacing-xs: 15px;
$spacing-sm: 30px;
$spacing-md: 40px;
$spacing-lg: 100px;

$spacing-sizes: (
    (xs, $spacing-xs),
    (sm, $spacing-sm),
    (md, $spacing-md),
    (lg, $spacing-lg)
);

@each $label, $size in $spacing-sizes {
    .padding-#{$label} {
        padding-top: #{$size};
        padding-bottom: #{$size};
    }
    .padding-side-#{$label} {
        padding-left: #{$size};
        padding-right: #{$size};
    }
    .padding-top-#{$label} {
        padding-top: #{$size};
    }
    .padding-right-#{$label} {
        padding-right: #{$size};
    }
    .padding-bottom-#{$label} {
        padding-bottom: #{$size};
    }
    .padding-left-#{$label} {
        padding-left: #{$size};
    }
    .margin-#{$label} {
        margin-top: #{$size};
        margin-bottom: #{$size};
    }
    .margin-side-#{$label} {
        margin-left: #{$size};
        margin-right: #{$size};
    }
    .margin-top-#{$label} {
        margin-top: #{$size};
    }
    .margin-right-#{$label} {
        margin-right: #{$size};
    }
    .margin-bottom-#{$label} {
        margin-bottom: #{$size};
    }
    .margin-left-#{$label} {
        margin-left: #{$size};
    }
}

/* SEPARATORS
=========================== */

hr {
    margin-top: $spacing-sm;
    margin-bottom: $spacing-sm;
    border: 0;
    border-top: 2px solid $black;
}

/* ALIGNMENT
=========================== */

.vcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-sm {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
}

.container-alt {
    padding-left: 20px;
    padding-right: 20px;
}

.container-custom {
    width: 100%;
    max-width: 1424px; /* size to match .content elements on pages */
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

.container-custom-lg {
    @extend .container-custom;
    max-width: 1240px;
}

.container-custom-md {
    @extend .container-custom;
    max-width: 1016px;
}

.container-custom-sm {
    @extend .container-custom;
    max-width: 832px;
}

.container-custom-xs {
    @extend .container-custom;
    max-width: 694px;
}

.container-custom-xxs {
    @extend .container-custom;
    max-width: 630px;
}

.row-narrow {
    margin-left: -7px;
    margin-right: -7px;
    [class*="col"] {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.row-no-padding {
    margin: 0;
    [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.bt-0 {
    border-top: none !important;
}

.py-60px {
    padding-bottom: 60px !important;
    padding-top: 60px !important;
}

.max-width-lg-1414px {
    @media screen and (min-width: 992px) {
        max-width: 1414px;
    }
}

.w-75-sm-50-lg-100-per {
    width: 75%;
    @media screen and (min-width: 576px) {
        width: 50%;
    }
    @media screen and (min-width: 992px) {
        width: 100%;
    }
}

.relative {
    position: relative;
}

.pt-32px {
    padding-top: 32px !important;
}

.flex-0-0-auto {
    flex: 0 0 auto;
}

.flex-1-1-auto {
    flex: 1 1 auto;
}

.mw-800px {
    max-width: 800px;
}

.pl-0-lg {
    @media (max-width: 991px) {
        padding-left: 0;
    }
}

.gap-xl-24px {
    @media (min-width: 1200px) {
        gap: 24px;
    }
}
