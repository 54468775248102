.home-section-two-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $grey;
    padding: 0 20px 60px 20px;
    .top-txt {
        .top-txt-title {
            font-family: $font-title;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            color: $blue-green;
            margin-bottom: 16px;
        }
    }
    .services-buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: -5px -5px 25px -5px;
        max-width: 810px;
        text-align: center;
        .service-btn-container {
            width: 50%;
            padding: 5px;
        }
        .service-btn {
            border-radius: 4px;
            border: 1px solid $grey-alpha;
            color: $blue;
            background-color: $white;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            text-decoration: none;
            min-height: 100%;
            width: 100%;
            cursor: pointer;
            .service-btn-img {
                width: 32px;
                height: 32px;
                object-fit: contain;
                object-position: center center;
                margin-bottom: 12px;
            }
            .service-btn-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                width: 32px;
                height: 32px;
                margin-bottom: 12px;
            }
            .service-btn-text {
                margin-bottom: 0;
                font-size: 16px;
                line-height: 1.2;
            }
            &:hover, &:focus {
                background-color: #00407114;
                border-color: $blue;
            }
            &:active, &:active:focus {
                background-color: #0040712e;
                border-color: $blue;
            }
        }
        .service-btn-input {
            display: none;
        }
        .service-btn-input:checked + .service-btn {
            background-color: $blue;
            border-color: $blue;
            color: $white;
            .service-btn-img {
                filter: brightness(0) invert(1);
            }
        }
    }
    .home-section-two-btn {
        margin: 0;
    }
    @include breakpoint(lg) {
        padding-bottom: 80px;
        .services-buttons-wrapper {
            margin-bottom: 22px;
            justify-content: center;
            .service-btn-container {
                width: 270px;
            }
            .service-btn {
                flex-direction: row;
                justify-content: flex-start;
                text-align: left;
                margin-left: 5px;
                margin-right: 5px;
                .service-btn-img, .service-btn-icon {
                    margin-bottom: 0;
                    margin-right: 12px;
                }
            }
        }
    }
}
