.td-section-three-wrapper {
    .top-content {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding-bottom: 180px;
        background-color: $gray-dark2;
        margin-bottom: -100px;
        background-size: cover;
        background-position: center;
        padding-top: 60px;
        .content-middle-wrapper {
            border: 1px solid rgba(255, 255, 255, 0.5);
            background-clip: content-box;
            padding: 45px;
            width: 430px;
            height: 430px;
            display: block;
            border-radius: 100%;
            margin: 0 auto;
            position: relative;
            z-index: 2;
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                z-index: -1;
                width: 520px;
                height: 520px;
                border-radius: 50%;
                border: 1px solid #ffffff1a;
            }
        }
        .content-middle {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            flex-direction: column;
            border-radius: 100%;
            margin: 0 auto;
            padding: 12px;
            text-align: center;
            border-spacing: 5px 0;
            border: 2px solid white;
            background-clip: content-box;
            h2 {
                margin-bottom: 40px;
            }
            .content-middle-text {
                padding: 10px;
                .btn-blue-1-alt {
                    padding: 16px 20px;
                }
                a {
                    .material-icons {
                        font-size: 23px;
                    }
                }
            }
        }
    }
    .top-curve-wrapper {
        height: 140px;
        overflow: hidden;
        width: 100%;
        margin-bottom: -60px;
        position: relative;
        margin-top: -1px;
        background-color: transparent;
    }
    .top-curve {
        position: relative;
        width: 110%;
        left: -5%;
        height: 250px;
        border-radius: 50%;
        background: $blue-light;
        overflow: hidden;
        margin-bottom: -80px;
    }
    .top-curve :after{
        content:'';
        position:absolute;
        top:-175px; left:150px;
        height:500px; width:300px;
        border-radius:inherit;
        background:#000;
    }
    @media (max-width: 991px) {
        .top-content {
            background-image: none !important;
            background-color: #141414;
        }
        .top-curve {
            height: 150px;
        }
    }
    .td-section-three {
        position: relative;
        @extend .banner-one;
        background-color: $blue-light;
        .content {
            background-color: $blue-light;
            .img-container {
                background-color: transparent;
                justify-content: center;
                display: flex;
                img {
                    max-width: 500px;
                }
            }
            .content-container {
                p {
                    color: $blue;
                    font-size: 12pt;
                }
                .p-title {
                    @extend p;
                    text-transform: uppercase;
                }
                .btn-blue-1 {
                    background-color: $red;
                    &:hover {
                        background-color: #cc3e25;
                    }
                }
            }
        }
    }
}

