/* 404 */
#page-404 {
    padding-top: 100px;
    background-color: aliceblue;
    span {
        color: $red;
    }
    .container {
        max-width: 800px;
    }
    img {
        max-height: 350px;
    }
    @media (max-width: 750px) {
        text-align: center;
        h1, h2, a {
            margin-top: 25px;
        }
        a {
            margin-bottom: 25px;
        }
        .col-left {
            order: 2;
        }
    }
}

.template-404 {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    #page-404 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
    }
}
