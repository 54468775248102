.podcast-banner-wrap {
    padding-top: 15px;
    padding-bottom: 50px;
    background: linear-gradient(180deg, $white calc(50% + 17.5px), $grey calc(50% + 17.5px));
    .podcast-banner {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 8px;
        background-color: $gray-dark2;
        padding: 32px;
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
        .podcast-tag {
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            color: $red;
            padding: 8px 16px;
            border-radius: 4px;
            background-color: rgba(230, 71, 42, 0.14);
            margin-bottom: 16px;
        }
        .podcast-title {
            color: white;
            width: 100%;
            margin-bottom: 16px;
            p:last-child {
                margin-bottom: 0;
            }
        }
        .podcast-text {
            font-size: 16px;
            color: white;
            margin-bottom: 30px;
            p:last-child {
                margin-bottom: 0;
            }
        }
        .btn-blue-1 {
            margin: 0;
        }
        .col-left {
            order: 2;
        }
        .col-right {
            overflow: hidden;
            margin-bottom: 24px;
            .img-wrap {
                width: 100%;
                height: 150px;
                border-radius: 8px;
                overflow: hidden;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center center;
                    transition: transform $time-sm ease;
                }
            }
        }
    }
    a {
        text-decoration: none;
        &:hover, &:focus {
            .podcast-title {
                color: $red;
            }
            .btn-blue-1 {
                color: $white;
                &:after {
                    transform: scale(1);
                }
            }
            .col-right {
                .img-wrap {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        &:active, &:active:focus {
            .podcast-title {
                color: $red;
            }
            .btn-blue-1 {
                color: $white;
                &:after {
                    background-color: darken($blue, 12);
                    transform: scale(1);
                }
            }
            .col-right {
                .img-wrap {
                    img {
                        transform: scale(1.15);
                    }
                }
            }
        }
    }
    @include breakpoint(lg) {
        background: linear-gradient(180deg, $white calc(50% + 15px), $grey calc(50% + 15px));
        padding-top: 80px;
        .podcast-banner {
            padding-left: 64px;
            flex-direction: row;
            .col-left {
                flex: 1;
                order: 1;
                padding: 15px 20px 15px 0;
                .col-left-content-wrapper {
                    max-width: 450px;
                }
            }
            .col-right {
                flex: 1;
                order: 2;
                margin-bottom: 0;
                .img-wrap {
                    height: 100%;
                }
            }
        }
    }
}
