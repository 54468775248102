.organization-subtitle {
    font-size: 24px;
    border-left: 4px solid $red;
    padding-left: 15px;
    margin-bottom: 40px;
    line-height: 32px;
    color: $blue-green;
}

.text-accordion-map {
    background-color: #f8f9fa;
    padding: 50px 0;

    .container {
        max-width: 1414px;

        // START: Organization description
        .organization-description {
            margin-bottom: 100px;

            &__text {
                font-family: Moderat, sans-serif;
                font-weight: normal;
                max-width: 670px;
                font-size: 18px !important;
                line-height: 24px;
                margin-bottom: 12px;
                overflow: hidden;
                color: $blue-repertoire;

                * {
                    font-size: 16px !important;
                    line-height: 24px;
                }
            }

            &__button {
                border: initial;
                background: initial;
                padding: initial;
                display: inline-flex;

                &:hover {
                    opacity: 0.7;
                }

                &.closed {
                    .show-less {
                        display: none;
                    }
                }

                &.opened {
                    .show-more {
                        display: none;
                    }
                }

                span {
                    font-size: 18px !important;
                    line-height: 24px;
                    font-weight: bold;
                    color: $red;

                    &:nth-child(2n) {
                        font-size: 26px !important;
                        margin-left: 6px;
                        position: relative;
                        bottom: 2px;
                    }

                    &:last-child {
                        bottom: initial;
                    }
                }
            }
        }

        // END: Organization description

        // START: Organization accordion
        .organization-accordion {
            margin-bottom: 70px;

            .accordion {
                .card-wrapper {
                    .card {
                        box-shadow: 0 2px 20px #000B560A;
                        border-radius: 8px;
                        border: initial;
                        overflow: hidden;
                        padding: 25px 30px;
                        margin-bottom: 20px;

                        .card-left-border {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 8px;
                            height: 100%;
                            background-color: $skin;
                            transform: scaleY(0);
                            transition: all 0.15s linear;
                            transform-origin: bottom;
                        }

                        .card-header {
                            padding: 0;
                            background: initial;
                            border: initial;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            .btn {
                                margin-left: 32px;
                                padding: 0;
                                width: 36px;
                                height: 36px;
                                background-color: $skin;
                                border-radius: 50%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                position: relative;

                                &:after {
                                    display: inline-block;
                                    flex-shrink: 0;
                                    width: 16px;
                                    height: 16px;
                                    content: " ";
                                    background-image: url("../img/ic_plus_repertoire.svg");
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                    transition: initial;
                                }
                            }
                        }

                        .card-body {
                            border-top: 1px solid #eaeff5;
                            margin-top: 20px;
                            padding-bottom: initial;
                            padding-left: initial;
                        }

                        .card-footer {
                            border: initial;
                            background-color: initial;
                            padding: 0 1.25rem;
                        }

                        .card-info-line {
                            display: flex;
                            align-items: center;
                            margin-bottom: 27px;

                            &:first-child {
                                margin-top: 10px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &__icon {
                                margin-right: 16px;

                                img {
                                    width: 24px;
                                    height: 24px;
                                }
                            }

                            &__title {
                                font-family: Moderat, sans-serif;
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: bold;
                            }

                            &__description, &__colon {
                                font-family: Moderat, sans-serif;
                                font-size: 18px;
                                line-height: 24px;

                            }

                            &__colon {
                                margin: 0 5px;
                            }
                        }

                    }

                    &:not(.collapsed) {
                        .organization-accordion__title {
                            color: $red;
                        }

                        .card {
                            .card-left-border {
                                transform: scaleY(1);
                            }

                            .card-header .btn:after {
                                background-image: url("../img/ic_moins_repertoire.svg");
                            }
                        }
                    }
                }
            }

            &__title {
                font-family: Moderat, sans-serif;
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
                width: 100%;
                @media screen and (max-width: 575px) {
                    margin-bottom: initial;
                }
            }

            &__category {
                display: flex;
                align-items: center;
                border: 1px solid #00308429;
                border-radius: 4px;
                padding: 12px 16px;

                img {
                    width: 24px;
                    margin-right: 5px;
                }

                span {
                    &:last-child {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }

            &__rich-text {
                * {
                    font-size: 16px;
                    line-height: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                ul {
                    list-style: none;
                    padding-left: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    li {
                        background-image: url(../img/ic_check_list.svg);
                        background-repeat: no-repeat;
                        background-position: 0 50%;
                        padding-left: 18px;
                        margin-left: 10px;
                        color: $blue-repertoire;
                    }
                }
            }
        }

        // END: Organization accordion

        // START: Organization territories starts
        .organization-territories {
            &__title {
                font-family: Moderat, sans-serif;
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
            }

            &__text {
                font-size: 18px;
                line-height: 24px;
                color: $blue-repertoire;
            }

            &__regions {
                margin-bottom: 20px;
            }
        }

        // END: Organization territories

        // START: Organization address
        .organization-address {
            width: 100%;
            max-width: 410px;
            @media screen and (max-width: 991px) {
                max-width: initial;
            }

            &__map {
                width: 100%;
            }

            &__content {
                margin-top: -5px;
                padding: 30px 30px 20px;
                border-right: 1px solid #00308429;
                border-left: 1px solid #00308429;
                background-color: #FFFFFF;

                a {
                    span:last-child {
                        font-family: Caros, sans-serif;
                        font-weight: bold;
                        font-size: 15px;
                        margin-bottom: initial;
                    }
                }

            }

            &__name {
                margin-bottom: 18px;
                color: $blue-repertoire;
            }

            &__location {
                font-size: 18px;
                margin-bottom: 24px;
                display: flex;

                .material-icons {
                    color: $red;
                    font-size: 24px !important;
                    margin-right: 5px;
                }

                span:last-child {
                    margin-bottom: 3px;
                    color: $blue-repertoire;
                }
            }

            &__link {
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Caros, sans-serif;
                width: 50%;

                &--web {
                    border-bottom-left-radius: 10px;
                    @include hover-bg($red);
                }

                &--itinerary {
                    border-bottom-right-radius: 10px;
                    @include hover-bg($blue);
                }

                &--full {
                    width: 100%;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                span {
                    color: white;
                    font-size: 16px;

                    &:first-child {
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }
            }
            &__route-icon {
                width: 18px;
                margin-right: 10px;
            }
        }

        // END: Organization address
    }
}

// START: Organization similar results

.similar-results, .organization-other-service-points {
    padding: 60px 0 40px;

    .container {
        max-width: 1414px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-bottom: 18px;

        &__title {
            font-family: Moderat, sans-serif;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 18px;
            color: $blue-repertoire;
        }

        &__text {
            font-family: Moderat, sans-serif;
            font-weight: normal;
            font-size: 16px;
            margin-bottom: 18px;
            color: $blue-repertoire;
        }

        &__content {
            border-top: 2px solid #d7deeb;
            border-right: 2px solid #d7deeb;
            border-left: 2px solid #d7deeb;
            border-bottom: none;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 30px;
            flex: 1;
        }

        &__btn {
            @include hover-bg($blue);
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            font-family: Caros, sans-serif;

            span {
                color: white;
                font-size: 15px;
                font-weight: 700;

                &:first-child {
                    font-size: 18px;
                    margin-right: 10px;
                    font-weight: bold;
                }
            }
        }

        &__link {
            display: flex;
            width: fit-content;
            color: $red;
            font-size: 16px;
            margin-bottom: 20px;
            justify-content: center;
            align-items: center;
            font-weight: bold;

            &:hover {
                opacity: 0.7;
                color: $red;
                text-decoration: none;
            }
        }
    }
}

.similar-results {
    .row {
        row-gap: 24px;
    }
}

.similar-results__card, .organization-address, .organization-other-service-points {
    .btn-skin-pail {
        @media(max-width: 575px) {
            width: 100%;
            border-radius: 4px !important;
        }
    }
}

.similar-results__card {
    &__phone, &__email {
        span {
            word-break: break-all;

            &:last-child {
                font-family: "Caros", sans-serif;
                font-weight: 700;
                font-size: 15px;
                line-height: 24px;
            }
        }

    }

    &__phone {
        span:last-child {
            margin-bottom: initial;
        }
    }
}

.organization-other-service-points {
    padding: initial;

    &__card {
        &__title {
            font-size: 18px;
            color: $blue-repertoire;
        }

        &__phone, &__email {
            span:last-child {
                font-family: "Caros", sans-serif;
                font-weight: bold;
                font-size: 15px;
                line-height: 24px;
            }
        }

        &__phone {
            span:last-child {
                margin-bottom: 2px;
                margin-top: 1px;
            }
        }

        &__content {
            border-bottom: 2px solid #d7deeb;
            border-radius: 10px;
            background-color: white;
            padding: 40px 40px 25px 40px;
            @media(max-width: 575px) {
                padding: 20px 20px 15px 20px;

            }

            .btn-skin-pail {
                display: inline-flex;

                &:nth-of-type(1) {
                    border-top-right-radius: initial;
                    border-bottom-right-radius: initial;
                }

                &:nth-of-type(2) {
                    border-top-left-radius: initial;
                    border-bottom-left-radius: initial;
                }
            }
        }
    }

    .organization-address__location {
        padding: 12px 10px;
        background: #F8F9FA;
        border-left: 3px solid #FFE4D4;
        border-radius: 4px;

        span {
            &:last-child {
                font-size: 14px;
            }
        }
    }
}

.organization-languages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-top: 64px;
    margin-bottom: 64px;

    &__icon {
        width: 24px;
        height: auto;
        margin-right: 12px;
    }

    &__text {
        font-size: 18px;
        line-height: 24px;
    }
}

.organization-social {
    margin-top: 32px;
    margin-bottom: 12px;

    &__icons {
        display: inline-block;
        width: 32px;
        height: auto;
    }

    &__links {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 25px;
        }
    }
}

.organization-territories__text {
    display: inline;
}

.organization-territories__button {
    border: initial;
    background: initial;
    font-size: 18px;
    line-height: 24px;

    &:hover {
        opacity: 0.7;
    }

    &.closed {
        .show-less {
            display: none;
        }
    }

    &.opened {
        .show-more {
            display: none;
        }
    }
}

.organization-other-service-points {
    .organization-subtitle {
        margin-bottom: 27px;
        color: $blue-green;
    }

    .organization-rich-text {
        font-size: 16px;
        margin-bottom: 36px;
        color: $blue-repertoire;
    }
}

// END: Organization similar results

.organization-share-card {
    background: $white;
    box-shadow: 0 2px 20px #000B560A;
    border: 1px solid #00308429;
    border-radius: 8px;
    padding: 21px;
    margin-top: 16px;
    text-align: center;
    &__title {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 12px;
        color: $blue;
    }

    &__text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        color: $blue;

    }

    &__link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 13px;
        border-radius: 4px;
        @include hover-bg($blue);

        img {
            width: 17px;
            height: auto;
        }

        span {
            font-family: "Caros", sans-serif;
            font-weight: 700;
            margin-left: 14px;
            font-size: 15px;
            line-height: 24px;
            color: $white;
        }

    }
}

.detail-organization-page {
    .top-nav-wrapper, .top-nav.fixed {
        background: #FFE4D4 !important;
    }
}
