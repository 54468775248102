header.wwa-main-header {
    @extend .main-header;
    height: auto;
}

.wwa-header-wrapper {
    background-color: $blue-light;
    .wwa-header {
        padding-top: 60px;
        h1 {
            text-align: center;
            color: $blue;
            font-size: 20pt;
            font-weight: bold;
            max-width: 600px;
            margin: 15px auto;
        }
        p.top-p {
            text-align: center;
            max-width: 600px;
            margin: 0 auto $spacing-xs auto;
            text-transform: uppercase;
            color: $blue;
            font-size: rem(17);
        }
        .wwa-title {
            color: $blue;
            text-align: center;
            margin-bottom: 25px;
            line-height: 1.15;
        }
        .header-actions {
            text-align: center;
            margin-bottom: 100px;
            img {
                width: 100%;
                max-width: 1000px;
            }
        }
    }
    .bottom-text {
        .bottom-text-title {
            color: $blue;
            margin-bottom: 20px;
        }
        .bottom-text-text {
            font-size: 16px;
            color: $blue;
            margin-bottom: 32px;
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    @include breakpoint(lg) {
        .wwa-header {
            padding-top: 80px;
            .wwa-title {
                max-width: 590px;
                margin-left: auto;
                margin-right: auto;
            }
            p.top-p {
                font-size: rem(14);
            }
        }
        .bottom-text {
            .bottom-text-text {
                margin-bottom: 0;
            }
        }
    }
}

.wwa-mosaic-wrapper {
    overflow: hidden;
    height: 290px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 26px;
    @include breakpoint(lg) {
        height: 470px;
        margin-bottom: 40px;
    }
}

.wwa-mosaic {
    flex-shrink: 0;
    height: 230px;
    width: 543px;
    position: relative;
    .wwa-mosaic-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        .wwa-mosaic-content-img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            border-radius: 8px;
            -webkit-box-shadow: 0px 0px 32px 10px rgba(0,0,0,0.13);
            box-shadow: 0px 0px 32px 10px rgba(0,0,0,0.13);
        }
    }
    .wwa-mosaic-content-img-container, .wwa-mosaic-content-info {
        .wwa-mosaic-content-img {
            transform: scale(0);
            transform-origin: center center;
            transition: transform $time-md ease;
        }
        &.wwa-mosaic-content-pop {
            .wwa-mosaic-content-img {
                transform: scale(1);
            }
        }
    }
    .wwa-mosaic-content-1 {
        z-index: 20;
        .wwa-mosaic-content-img {
            width: 46px;
            height: 46px;
            top: 18px;
            left: 0;
        }
    }
    .wwa-mosaic-content-2 {
        .wwa-mosaic-content-img {
            width: 22px;
            height: 22px;
            top: 33px;
            left: 132px;
        }
    }
    .wwa-mosaic-content-3 {
        z-index: 10;
        .wwa-mosaic-content-img {
            width: 33px;
            height: 33px;
            top: 15px;
            left: 223px;
        }
    }
    .wwa-mosaic-content-4 {
        z-index: 10;
        .wwa-mosaic-content-img {
            width: 33px;
            height: 33px;
            top: 27px;
            right: 145px;
        }
    }
    .wwa-mosaic-content-5 {
        .wwa-mosaic-content-img {
            width: 22px;
            height: 22px;
            top: 0;
            right: 45px;
        }
    }
    .wwa-mosaic-content-6 {
        .wwa-mosaic-content-img {
            width: 22px;
            height: 22px;
            left: 20px;
            bottom: 60px;
        }
    }
    .wwa-mosaic-content-7 {
        z-index: 10;
        .wwa-mosaic-content-img {
            width: 33px;
            height: 33px;
            left: 96px;
            bottom: 62px;
        }
    }
    .wwa-mosaic-content-8 {
        .wwa-mosaic-content-img {
            width: 22px;
            height: 22px;
            left: 180px;
            bottom: 98px;
        }
    }
    .wwa-mosaic-content-9 {
        .wwa-mosaic-content-img {
            width: 22px;
            height: 22px;
            left: 210px;
            bottom: 20px;
        }
    }
    .wwa-mosaic-content-10 {
        .wwa-mosaic-content-img {
            width: 22px;
            height: 22px;
            right: 196px;
            bottom: 70px;
        }
    }
    .wwa-mosaic-content-11 {
        z-index: 10;
        .wwa-mosaic-content-img {
            width: 33px;
            height: 33px;
            right: 115px;
            bottom: 43px;
        }
    }
    .wwa-mosaic-content-12 {
        z-index: 20;
        .wwa-mosaic-content-img {
            width: 46px;
            height: 46px;
            top: 110px;
            right: 0;
        }
    }
    .wwa-mosaic-content-info {
        z-index: 3;
        .wwa-mosaic-content-img {
            top: calc(50% - 27px);
            left: calc(50% - 27px);
            height: 54px;
            width: 54px;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
    .wwa-mosaic-content-map {
        z-index: 1;
        .wwa-mosaic-content-map-svg {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 212px;
            height: 220px;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            .wwa-mosaic-content-map-circle {
                display: none;
                fill: $white;
            }
        }
    }
    @include breakpoint(lg) {
        height: 400px;
        width: 1000px;
        .wwa-mosaic-content-1 {
            .wwa-mosaic-content-img {
                width: 80px;
                height: 80px;
                top: 32px;
            }
        }
        .wwa-mosaic-content-2 {
            .wwa-mosaic-content-img {
                width: 40px;
                height: 40px;
                top: 56px;
                left: 242px;
            }
        }
        .wwa-mosaic-content-3 {
            .wwa-mosaic-content-img {
                width: 60px;
                height: 60px;
                top: 28px;
                left: 408px;
            }
        }
        .wwa-mosaic-content-4 {
            .wwa-mosaic-content-img {
                width: 60px;
                height: 60px;
                top: 48px;
                right: 264px;
            }
        }
        .wwa-mosaic-content-5 {
            .wwa-mosaic-content-img {
                width: 40px;
                height: 40px;
                right: 80px;
            }
        }
        .wwa-mosaic-content-6 {
            .wwa-mosaic-content-img {
                width: 40px;
                height: 40px;
                left: 40px;
                bottom: 104px;
            }
        }
        .wwa-mosaic-content-7 {
            .wwa-mosaic-content-img {
                width: 60px;
                height: 60px;
                left: 180px;
                bottom: 114px;
            }
        }
        .wwa-mosaic-content-8 {
            .wwa-mosaic-content-img {
                width: 40px;
                height: 40px;
                left: 335px;
                bottom: 176px;
            }
        }
        .wwa-mosaic-content-9 {
            .wwa-mosaic-content-img {
                width: 40px;
                height: 40px;
                left: 384px;
                bottom: 36px;
            }
        }
        .wwa-mosaic-content-10 {
            .wwa-mosaic-content-img {
                width: 40px;
                height: 40px;
                right: 358px;
                bottom: 127px;
            }
        }
        .wwa-mosaic-content-11 {
            .wwa-mosaic-content-img {
                width: 60px;
                height: 60px;
                right: 204px;
                bottom: 76px;
            }
        }
        .wwa-mosaic-content-12 {
            .wwa-mosaic-content-img {
                width: 80px;
                height: 80px;
                top: 200px;
            }
        }
        .wwa-mosaic-content-info {
            .wwa-mosaic-content-img {
                top: calc(50% - 49px);
                left: calc(50% - 49px);
                width: 98px;
                height: 98px;
            }
        }
        .wwa-mosaic-content-map {
            .wwa-mosaic-content-img {
                width: 385px;
                height: 400px;
            }
        }
    }
}

.wwa-header-bottom {
    background-color: $skin-light;
    img {
        width: 100%;
    }
}
