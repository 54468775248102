.tool-detail-families-game--section-quote {
    @extend .tool-detail-families-game--section-four;
}

.tool-detail-families-game--section-six {
    background-color: $yellow;
    padding-bottom: 60px;
    .tool-detail-families-game--section-six--top {
        @extend .tool-detail-families-game--section-one;
        background-color: $yellow;
        .row-flex {
            .col-flex.left {
                .tool-title {
                    color: $green-dark;
                }
                .tool-desc {
                    color: $green-dark;
                    &:not(:last-child) {
                        margin-bottom: 30px;
                    }
                }
                .tool-btn {
                    margin-bottom: 60px;
                }
            }
        }
    }
    .tool-detail-families-game--section-quote {
        @extend .tool-detail-families-game--section-four;
        .yellow-section {
            @extend .purple-section;
            background-color: $yellow;
        }
        .quoted-section {
            border-top: 1px solid $grey-alpha;
            border-bottom: 1px solid $grey-alpha;
            p {
                color: $green-dark;
            }
        }
    }
    @include breakpoint(lg) {
        padding-bottom: 80px;
        .tool-detail-families-game--section-six--top {
            .row-flex {
                .col-flex.left {
                    .tool-btn {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
