.home-section-cards-wrapper {
    background-color: $grey;
    padding: 60px 5px;
    color: $blue;
    .home-section-cards-title {
        margin-bottom: 40px;
    }
    .icon-link {
        position: absolute;
        background-color: $blue;
        right: 0;
        bottom: 0;
        border-radius: 70px 0 8px;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: $white;
        padding: 10px 0 0 10px;
        z-index: 1;
        transition: background-color $time-sm ease;
    }
    .card-unit-link {
        display: inline-block;
        height: 100%;
        width: 100%;
        text-decoration: none !important;
    }
    .card-unit {
        height: 100%;
        background-color: white;
        box-shadow: 0px 2px 20px $grey-shadow;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        cursor: pointer;
        .card-unit-top {
            background-color: $grey-light;
            height: 260px;
            overflow: hidden;
            .card-unit-top-img {
                height: 100%;
                width: 100%;
                background-position: center center;
                background-size: cover;
                transition: transform $time-sm ease;
                transform-origin: center center;
            }
        }
        .card-unit-bottom {
            flex-grow: 1;
            padding: 24px 24px 65px 24px;
            h1, h2, h3, p {
                font-family: $font-title;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.5;
                color: $blue-green;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        &:hover, &:focus {
            .card-unit-top {
                .card-unit-top-img {
                    transform: scale(1.1);
                }
            }
            .icon-link {
                background-color: darken($blue-green, 5);
            }
        }
        &:active, &:active:focus {
            .card-unit-top {
                .card-unit-top-img {
                    transform: scale(1.15);
                }
            }
            .icon-link {
                background-color: darken($blue, 8);
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
        .card-unit-link {
            width: 100%;
        }
        .card-unit {
            .card-unit-bottom {
                padding: 24px 70px 32px 24px;
            }
        }
    }
}

.hc-caroussel {
    display: flex !important;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 40px;
    .hc-caroussel-item {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
    .slick-list {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        order: 1;
        margin-bottom: 20px;
    }
    .slick-arrow {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50%;
        background-color: $blue-light;
        font-size: 0;
        &:before {
            font-size: 16px;
            color: $blue;
            transition: color $time-sm ease;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: darken($blue, 8);
            transform: scale(0);
            transition: transform $time-sm ease, background-color $time-sm ease;
        }
        &:hover, &:focus {
            &:before {
                color: $white;
            }
            &:after {
                transform: scale(1);
            }
        }
        &:active, &:active:focus {
            &:before {
                color: $white;
            }
            &:after {
                background-color: darken($blue, 12);
                transform: scale(1);
            }
        }
    }
    .slick-prev {
        order: 2;
        margin-left: 20px;
        &:before {
            pointer-events: none;
            @include material-icon("keyboard_arrow_left");
            font-size: 30px;
        }
    }
    .slick-next {
        order: 4;
        margin-right: 20px;
        &:before {
            pointer-events: none;
            @include material-icon("keyboard_arrow_right");
            font-size: 30px;
        }
    }
    .slick-dots {
        order: 3;
        flex-grow: 1;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        li {
            margin-right: 10px;
            button {
                -webkit-appearance: none;
                -moz-appearance: none;
                height: 8px;
                width: 8px;
                margin: 0;
                padding: 0;
                border: none;
                background-color: $blue;
                border-radius: 50%;
                opacity: .4;
                font-size: 0;
                transform: scale(.6);
                &:hover, &:focus {
                    opacity: 1;
                    transform: scale(1);
                }
                &:active, &:active:focus {
                    opacity: 1;
                    background-color: darken($blue, 10%);
                    transform: scale(1);
                }
            }
            &.slick-active {
                button {
                    opacity: 1;
                    transform: scale(1);
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .slick-active-after, .slick-active-before {
            button {
                transform: scale(.8);
            }
        }
    }
    @include breakpoint(lg) {
        flex-wrap: nowrap;
        margin-bottom: 30px;
        margin-left: -12px;
        margin-right: -12px;
        .hc-caroussel-item {
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 0;
        }
        .hc-caroussel-item {
            flex: 1;
        }
    }
}
