.wwa-section-three-wrapper {
    background-color: $blue-light;
    padding-top: 40px;
    padding-bottom: 40px;
    .top-curve-wrapper {
        background-color: $grey;
    }
    .wwa-section-three {
        overflow: auto;
    }
    .wwa-section-three-intro {
        max-width: 590px;
        padding: 0 5px;
        margin: 0 auto 60px auto;
        color: $blue;
        h2 {
            margin-bottom: 32px;
        }
        p:last-child {
            font-size: 20px;
            margin-bottom: 0;
        }
    }
    .row-flex-container {
        min-width: 690px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;
        .row-flex {
            display: flex;
            justify-content: space-between;
        }
        .col-flex {
            display: flex;
            flex-direction: column;
            padding: 0 5px;
            width: calc((100% - 50px) / 4);
            &:first-child, &:last-child {
                width: calc((100% - 50px) / 8);
            }
            &:nth-child(3) {
                width: calc((100% - 50px) / 4 + 50px);
            }
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
    .unit-gray,
    .unit-blue {
        flex: 1;
        border-radius: 24px;
        margin: 5px 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .unit-gray {
        background-color: $grey;
        overflow: hidden;
        img {
            height: 135px;
            min-height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    .unit-blue {
        background-color: $blue;
        color: $white;
        .unit-blue-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 20px 20px 10px 20px;
        }
        .unit-blue-child {
            font-family: $font-title;
            &:first-child {
                font-size: 14px;
                font-weight: 500;
                flex-grow: 1;
                margin-bottom: 26px;
            }
            &:last-child {
                font-size: 32px;
                font-weight: 700;
                text-align: right;
            }
            &.unit-blue-child-big {
                font-size: 40px;
            }
            &.unit-blue-child-medium {
                font-size: 32px;
            }
            &.unit-blue-child-small {
                font-size: 26px;
            }
        }
        &.unit-red {
            background-color: $red;
        }
        &.unit-skin-light {
            background-color: $skin-light;
            color: $blue;
        }
        &.unit-white {
            background-color: $white;
            color: $blue;
        }
    }
    &.fo-stats {
        padding-top: 0;
        padding-bottom: 60px;
    }
    @include breakpoint(md) {
        .row-flex-container {
            padding: 0 20px 60px 20px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 60px;
        padding-bottom: 60px;
        .wwa-section-three-intro {
            margin: 0 auto 80px auto;
        }
        .row-flex-container {
            .col-flex {
                padding: 0 7.5px;
            }
        }
        .unit-gray,
        .unit-blue {
            margin: 7.5px 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .unit-gray {
            img {
                height: 215px;
            }
        }
        .unit-blue {
            .unit-blue-content {
                padding: 30px 20px 10px 30px;
            }
            .unit-blue-child {
                &:first-child {
                    font-size: 20px;
                }
            }
        }
        &.fo-stats {
            padding-top: 0;
            padding-bottom: 120px;
        }
    }
    @include breakpoint(xl) {
        .unit-blue {
            .unit-blue-child {
                &:last-child {
                    font-size: 42px;
                }
                &.unit-blue-child-big {
                    font-size: 60px;
                }
                &.unit-blue-child-medium {
                    font-size: 42px;
                }
                &.unit-blue-child-small {
                    font-size: 32px;
                }
            }
        }
    }
    @include breakpoint(xxl) {
        .unit-blue {
            .unit-blue-child {
                &:last-child {
                    font-size: 48px;
                }
                &.unit-blue-child-big {
                    font-size: 72px;
                }
                &.unit-blue-child-medium {
                    font-size: 48px;
                }
                &.unit-blue-child-small {
                    font-size: 38px;
                }
            }
        }
    }
}
@media (max-width: 880px) {
    .wwa-section-three-wrapper {
        .unit-blue {
            .unit-blue-child {
                &:last-child {
                    font-size: 25px;
                }
                &.unit-blue-child-big {
                    font-size: 32px;
                }
                &.unit-blue-child-medium {
                    font-size: 25px;
                }
                &.unit-blue-child-small {
                    font-size: 20px;
                }
            }
        }
    }
    .wwa-section-three-wrapper .row-flex-container {
        min-width: auto;
        .row-flex {
            flex-direction: column;
            .col-flex {
                width: 100%;
                flex-direction: initial;
                .unit-gray,
                .unit-blue {
                    flex: 1 1 auto;
                    margin: 10px;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .wwa-section-three-wrapper .row-flex-container {
        .row-flex {
            .col-flex {
                width: 100%;
                flex-direction: column;
            }
        }
    }
}
