.newsletter-form-container {
    padding: 20px;
    background-color: $white;
    border-radius: 8px;
    @include breakpoint(lg) {
        padding: 40px;
    }
}

.newsletter-main {
    padding-top: 40px;
    padding-bottom: 60px;
    .newsletter-main-section {
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        padding-top: 60px;
        padding-bottom: 80px;
    }
}

.newsletter-intro {
    margin-bottom: 24px;
    .newsletter-intro-title, .newsletter-intro-text {
        margin-bottom: 40px;
        text-align: center;
        &:last-child {
            margin-bottom: 0;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
    .newsletter-intro-title {
        color: $red;
        margin-bottom: 40px;
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .newsletter-intro-text {
        b {
            color: $red;
            font-weight: 700;
        }
    }
    @include breakpoint(lg) {
        .newsletter-intro-title, .newsletter-intro-text {
            margin-bottom: 60px;
        }
    }
}

.newsletter-bubbles-container {
    width: 100%;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
    overflow: hidden;
    @include breakpoint(sm) {
        padding-bottom: 0;
    }
    @include breakpoint(md) {
        padding-top: 100px;
        padding-bottom: 75px;
    }
    @include breakpoint(lg) {
        padding-left: 115px;
        padding-right: 128px;
    }
}

.newsletter-bubbles-section {
    width: 100%;
    padding-bottom: 100%;
    background-color: $blue-light;
    border-radius: 50%;
    position: relative;
    .newsletter-bubble {
        display: none;
        height: 300px;
        width: 300px;
        padding: 54px;
        border-radius: 50%;
        border: 1px solid #E6492C4E;
        position: absolute;
        .newsletter-bubble-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
        &:not(.newsletter-bubble-central) {
            .newsletter-bubble-img {
                transition: transform $time-sm ease;
                &:hover, &:focus, &:active, &:active:focus {
                    transform: scale(1.1);
                }
            }
        }
        &.newsletter-bubble-1 {
            top: -100px;
            left: 85px;
        }
        &.newsletter-bubble-2 {
            top: 138px;
            right: -128px;
        }
        &.newsletter-bubble-3 {
            bottom: -75px;
            left: 66px;
        }
        &.newsletter-bubble-central {
            display: block;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            .newsletter-bubble-img {
                padding: 15px;
                border: 2px solid #E6492C4E;
            }
        }
        .newsletter-subbubble {
            position: absolute;
            height: 16px;
            width: 16px;
            background-color: $red;
            border-radius: 50%;
            .newsletter-subbubble-text {
                position: absolute;
                width: 200px;
                margin: 0;
                font-family: $font-title;
                font-size: 20px;
                font-weight: 700;
            }
            @media (max-width: 600px) {
                .newsletter-subbubble-text {
                    font-size: 15px;
                }
            }
            &.newsletter-subbubble-1 {
                top: -4px;
                right: 112px;
                .newsletter-subbubble-text {
                    top: 100%;
                    left: calc(50% - 100px);
                }
            }
            &.newsletter-subbubble-2 {
                bottom: 105px;
                right: -4px;
                .newsletter-subbubble-text {
                    top: 100%;
                    right: -2px;
                }
            }
            &.newsletter-subbubble-3 {
                bottom: -2px;
                right: 100px;
                .newsletter-subbubble-text {
                    top: 100%;
                    left: calc(50% - 100px);
                }
            }
            &.newsletter-subbubble-4 {
                bottom: 74px;
                left: 7px;
                .newsletter-subbubble-text {
                    top: 100%;
                    left: -8px;
                }
            }
            &.newsletter-subbubble-5 {
                top: 98px;
                left: -3px;
                .newsletter-subbubble-text {
                    top: 100%;
                    left: 2px;
                }
            }
        }
    }
    @include breakpoint(sm) {
        .newsletter-bubble {
            .newsletter-subbubble {
                &.newsletter-subbubble-2 {
                    .newsletter-subbubble-text {
                        right: initial;
                        left: calc(50% - 100px);
                    }
                }
                &.newsletter-subbubble-4 {
                    .newsletter-subbubble-text {
                        left: calc(50% - 100px);
                    }
                }
                &.newsletter-subbubble-5 {
                    .newsletter-subbubble-text {
                        left: calc(50% - 100px);
                    }
                }
            }
        }
    }
    @include breakpoint(md) {
        .newsletter-bubble {
            display: block;
            .newsletter-subbubble {
                &.newsletter-subbubble-1 {
                    .newsletter-subbubble-text {
                        top: -14px;
                        left: calc(100% + 10px);
                    }
                }
                &.newsletter-subbubble-2 {
                    .newsletter-subbubble-text {
                        top: -14px;
                        left: calc(100% + 10px);
                    }
                }
                &.newsletter-subbubble-3 {
                    .newsletter-subbubble-text {
                        top: -10px;
                        left: calc(100% + 10px);
                    }
                }
                &.newsletter-subbubble-4 {
                    .newsletter-subbubble-text {
                        top: -15px;
                        left: initial;
                        right: calc(100% + 10px);
                    }
                }
                &.newsletter-subbubble-5 {
                    .newsletter-subbubble-text {
                        top: -12px;
                        left: initial;
                        right: calc(100% + 10px);
                    }
                }
            }
        }
    }
    @include breakpoint(xl) {
        .newsletter-bubble {
            height: 400px;
            width: 400px;
            .newsletter-subbubble {
                &.newsletter-subbubble-1 {
                    top: -3px;
                    right: 146px;
                }
                &.newsletter-subbubble-2 {
                    bottom: 122px;
                    right: 4px;
                }
                &.newsletter-subbubble-3 {
                    bottom: -6px;
                    right: 165px;
                }
                &.newsletter-subbubble-4 {
                    bottom: 96px;
                    left: 15px;
                }
                &.newsletter-subbubble-5 {
                    top: 100px;
                    left: 12px;
                }
            }
        }
    }
    @include breakpoint(xxl) {
        .newsletter-bubble {
            height: 432px;
            width: 432px;
            .newsletter-subbubble {
                &.newsletter-subbubble-1 {
                    top: -4px;
                    right: 170px;
                }
                &.newsletter-subbubble-2 {
                    bottom: 140px;
                    right: 3px;
                }
                &.newsletter-subbubble-3 {
                    bottom: -2px;
                    right: 162px;
                }
                &.newsletter-subbubble-4 {
                    bottom: 108px;
                    left: 15px;
                }
                &.newsletter-subbubble-5 {
                    top: 108px;
                    left: 15px;
                }
            }
        }
    }
}
