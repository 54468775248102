.event-calendar-intro {
    color: $blue;
    padding: 40px 20px;
    .event-calendar-intro-wrapper {
        max-width: 100%;
        width: 932px;
        margin-left: auto;
        margin-right: auto;
    }
    .event-calendar-intro-content {
        max-width: 100%;
        width: 660px;
    }
    .event-calendar-intro-title {
        margin-bottom: 22px;
    }
    .event-calendar-intro-text {
        font-size: 16px;
    }
    @include breakpoint(lg) {
        padding: 60px 20px;
        .event-calendar-intro-wrapper {
            padding-left: 50px;
        }
    }
}
