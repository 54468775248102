.subject-selection-bar-wrapper {
    padding: 24px 0;
    border-top: 1px solid $blue-grey;
    .subject-selection-bar {
        ul {
            padding-left: 0;
            margin: -6px;
            list-style: none;
            li {
                display: inline-block;
                margin: 6px;
                a {
                    padding: 12px 16px;
                    text-decoration: none;
                    color: $blue;
                    font-size: 16px;
                    border-radius: 4px;
                    background-color: $grey-light-alpha;
                    border: 1px solid $grey-alpha;
                    display: inline-block;
                    &:hover, &:focus {
                        border-color: $blue;
                        background-color: #00407115;
                    }
                    &:active, &:active:focus, &.active {
                        color: $white;
                        border-color: $blue;
                        background-color: $blue;
                    }
                }
            }
        }
    }
}
