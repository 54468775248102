.title-text-container {
    .awareness-tools-main-section.awareness-title-text-section {
        width: initial;
        @media (min-width: 576px) {
            width: 80%;
        }
        @media (min-width: 768px) {
            width: 70%;
        }
        @media (min-width: 992px) {
            width: 60%;
        }
        @media (min-width: 1200px) {
            width: 50%;
        }
    }
}

.list-marker.awareness-tools-main-section {
    ul li::marker {
        color: white;
    }
}

.w-100 .cms-content ul li {
    list-style: none;
}
