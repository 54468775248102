.header-story {
    .text-content-bg.reversed {
        border-bottom-right-radius: 260px !important;
        border-top-right-radius: initial !important;
    }

    .text-content-text * {
        color: inherit !important;
    }

    .header-content .text-content .text-content-body span.material-icons.arrow-scroll-header-down {
        background-color: #FFFFFF !important;

        &:visited, &:active, &:hover {
            background-color: #FFFFFF !important;
        }

        &:after {
            background-color: #FFFFFF !important;
        }
    }
    .subheader-main-img-wrapper {
        width: calc(100% + 160px);
        height: 100%;
        transform: translateX(-160px);
        .subheader-main-img-content {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.template-story-list-page {
    @media screen and (min-width: 1424px) {
        .subheader.subheader_full_image .subheader-main .subheader-main-img-container {
            width: calc(50vw + 110px);
            margin-left: initial;
        }
    }
    @media screen and (min-width: 992px) {
        .subheader.subheader_full_image .subheader-main .subheader-main-img-container {
            width: calc(50vw + 110px);
            margin-left: initial;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        .subheader.subheader_full_image .subheader-main .subheader-main-img-container {
            width: calc(50vw - 10px);
            margin-left: 20px;
        }
    }
}
