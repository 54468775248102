.video_game_block .td-section-one-wrapper .container div.row {
    p, h2, li {
        color: unset;
    }
}

.video_game_block.text-white {
    .cms-content.font-18 a {
        color: $twitter-color !important;
    }
}

.bg-green.td-section-one-wrapper {
        border-bottom: 1px solid $green;
}

.bg-yellow.td-section-one-wrapper {
        border-bottom: 1px solid $yellow;
}

.bg-violet.td-section-one-wrapper {
        border-bottom: 1px solid $purple;
}

.video_game_block .td-section-one-wrapper .resp-video-container {
    height: calc(100vw - 40px);
    max-height: 612px;
    max-width: 612px;
    border-radius: 345px 345px 8px 8px;
    overflow: hidden;
    transform: translateZ(0);
    @media (max-width: 1199px) {
       margin-right: auto;
        margin-left: auto;
    }
}
