.fo-section-three-wrapper {
    padding: 25px;
    .fo-section-three {
        padding: 50px 0;
        max-width: 995px;
        margin: 0 auto;
        border: 1px solid #e5eaf2;
        border-left: 0;
        border-right: 0;
        .icons-top {
            display: flex;
            margin: 0 auto;
            justify-content: center;
            margin-top: -85px;
            margin-bottom: 50px;
            .quote-blue {
                img {
                    max-width: 50px;
                }
            }
            .circle-icon {
                width: 50px;
                height: 50px;
                background-color: #cccccc;
                border-radius: 50%;
                margin: 5px;
            }
        }
        p {
            font-size: 18pt;
            font-weight: bold;
            color: $blue;
            text-align: center;
        }
    }
}
