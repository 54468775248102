.tool-detail-families-game--section-two {
    background-color: $purple;
    .title-small,
    .header-content-title p,
    .header-content-text p {
        color: $green-dark;
    }
    .tool-title, .tool-desc {
        max-width: 590px;
    }
    .tool-title {
        color: white;
        margin-bottom: 24px;
    }
    .tool-desc {
        color: white;
        p {
            font-size: 16px;
        }
    }
    .row {
        margin: 0;
    }
    .col-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 40px 20px;
    }
    @include breakpoint(md) {
        .col-center {
            padding: 32px;
        }
    }
    @include breakpoint(lg) {
        .col-center {
            padding: 60px 70px;
        }
    }
}
