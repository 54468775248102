.tool-detail-families-game--section-four {
    .green-section {
        padding: 50px 0 0;
        padding-top: 60px;
        background-color: $green;
        color: $green-dark;
        overflow: hidden;
    }
    .bg-round {
        background-size: cover;
        background-position: center 130px;
        background-repeat: no-repeat;
        padding-bottom: 80px;
    }
    .top-section {
        margin-bottom: 50px;
    }
    .top-title {
        margin-bottom: 24px;
    }
    .members-wrapper {
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 1035px;
        .row-flex {
            display: flex;
            justify-content: space-between;
            .col-flex {
                p {
                    font-size: 13px;
                    text-align: center;
                    color: white;
                    max-width: 110px;
                    margin: 0 auto;
                }
                img {
                    max-width: 100%;
                }
                .stick {
                    text-align: center;
                    color: white;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .purple-section {
        background-color: $purple;
        padding: 20px 20px 0 20px;
    }
    .quoted-section {
        padding: 60px 0 40px;
        text-align: center;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        max-width: 790px;
        margin: 0 auto;
        p {
            font-family: $font-title;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0;
            color: white;
        }
        .icons-wrapper {
            display: flex;
            justify-content: center;
            margin-top: -80px;
            margin-bottom: 40px;
        }
        .icon-wrap {
            background-color: $white;
            border-radius: 5px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            img {
                width: 19px;
            }
        }
        .avatar-wrapper {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid $white;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                border-radius: 50%;
            }
        }
    }
    @include breakpoint(lg) {
        .green-section {
            padding-top: 100px;
        }
        .top-section {
            margin-bottom: 80px;
        }
    }
}
