.sources-pp {
    background: #003963;
    padding: 48px 0;
    border-top-left-radius: 50px;

    @media (min-width: 576px) {
        padding: 96px 0;
    }

    &__container {
        margin: 0 auto;
        max-width: 1414px;
        padding: 0 15px;
    }

    &__title {
        color: $white;
        font-family: Caros, sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 40px;
    }

    &__row {
        margin: 0 -12px;
        row-gap: 24px;
    }

    &__col {
        padding: 0 12px;
    }

    &__card {
        align-items: flex-start;
        background-color: $white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;
        padding: 40px 32px 54px;
        position: relative;
        text-decoration: initial;

        @media (max-width: 575px) {
            padding: 15px 15px 54px;
        }

        &:hover {
            text-decoration: initial;
        }

        &-title {
            color: #003963;
            font-family: Caros, sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 18px;
        }

        &-link {
            background-color: initial;
            border: initial;
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-start;
            padding: initial;

            &-container {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 16px 32px;
            }

            &-text {
                font-size: 16px;
                line-height: 24px;
                text-decoration: underline;
                color: #013A64;
                margin-right: 9px;
            }

            .material-icons {
                color: #013A64;
                font-size: 17px;
                margin-bottom: 1px;
            }
        }

        &-icon {
            background-color: #E83829;
            border-radius: 100% 0 0 0;
            bottom: 0;
            height: 59px;
            position: absolute;
            right: 0;
            width: 67px;

            img {
                width: 16px;
                height: 16px;
                bottom: 14px;
                position: absolute;
                right: 16px;
            }
        }
    }
}
