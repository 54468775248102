.to-be-referenced-page {
    .to-be-referenced-main {
        background-color: #f8f9fa;
        padding: 50px 0;
        .container {
            max-width: 1414px;
            .default-stream-form-main-content-wrapper {
                padding-top: initial;
                padding-bottom: 20px;
            }
            // START: title-and-text-block
            .title-and-text-block {
                &__title {
                    font-size: 26px;
                    border-left: 4px solid $red;
                    padding-left: 15px;
                    margin-bottom: 40px;
                    line-height: 32px;
                }
                &__text {
                    max-width: 588px;
                    margin-bottom: 12px;
                    * {
                        font-size: 16px;
                        line-height: 24px;
                        color: $blue-repertoire;
                    }
                }
            }
            // END: title-and-text-block
            .accordion-block {
                &.most-consulted-tips-wrapper {
                    padding-top: initial;
                    padding-left: initial;
                    padding-right: initial;
                    margin-top: 46px;
                    @media screen and (max-width: 991px) {
                        margin-bottom: 46px;
                    }
                    .container {
                        padding-left: initial;
                        padding-right: initial;
                        h2.d2.mct-title {
                            font-size: 26px;
                            border-left: 4px solid $red;
                            padding-left: 15px;
                            margin-bottom: 40px;
                            line-height: 32px;
                            text-align: initial;
                        }
                        .header-title {
                            font-size: 20px;
                            line-height: 24px;
                        }
                        .card-body-text {
                            * {
                                font-size: 18px;
                                line-height: 24px;
                                margin-bottom: 20px;
                                color: $blue-repertoire;
                            }
                            ul {
                                padding-left: 20px;
                            }
                            ol {
                               padding-left: 22px;
                            }
                            li::marker {
                                color: $red;
                                font-size: 22px;
                            }
                        }
                        .card-body-btn {
                            margin-bottom: 18px;
                            @include breakpoint(lg) {
                                margin-bottom: 36px;
                            }
                        }
                    }
                }
            }
            .to-be-referenced-sidebar {
                width: 100%;
                max-width: 410px;
                box-shadow: 0 2px 20px #000B560A;
                @media screen and (max-width: 991px) {
                    max-width: initial;
                }
                &__content {
                    padding: 30px 30px 20px;
                    border-right: 1px solid #00308429;
                    border-left: 1px solid #00308429;
                    border-top: 1px solid #00308429;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    background-color: #FFFFFF;
                    a {
                        span:last-child {
                            font-family: Caros, sans-serif;
                            font-weight: bold;
                            font-size: 15px;
                            margin-bottom: 2px;
                        }
                    }
                }
                &__title {
                    font-size: 18px;
                    margin-bottom: 18px;
                    font-family: Moderat, sans-serif;
                    color: $blue-repertoire;
                }
                &__text {
                   * {
                       font-size: 16px;
                       margin-bottom: 24px;
                       color: $blue-repertoire;
                   }
                    a {
                        color: $red;
                        text-decoration: underline;
                        &:hover {
                            color: $red;
                            text-decoration: none;
                            opacity: 0.7;
                        }
                    }
                }
                &__link {
                    padding: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    width: 100%;
                    @include hover-bg($red);
                    span {
                        color: white;
                        font-family: Caros,sans-serif;
                        font-size: 17px;
                        font-weight: bold;
                    }
                }
            }
        }
        .most-consulted-tips-wrapper .accordion-tips .card .card-header .card-header-content[aria-expanded=false] .header-title {
            color: $blue-repertoire;
        }
    }
}
