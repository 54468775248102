.flex-pdf-block {
    padding-top: 80px;
    padding-bottom: 80px;
    .pl-lg-6rem {
        @include breakpoint(lg) {
            padding-left: 6rem;
        }
    }
    .img-fluid {
        border-radius: 8px;
    }
    .pdf-block {
        border-radius: 0;
        padding: 0 0 60px 0;
        .pdf-block-intro, .pdf-block-actions {
            padding-top: 60px;
        }
    }
    &:not(:last-child) {
        .pdf-block {
            padding-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        .pdf-block {
            padding: 0 0 80px 0;
            .pdf-block-intro, .pdf-block-actions {
                padding-top: 80px;
                padding-bottom: 32px;
            }
            .pdf-block-actions {
                width: 365px;
            }
        }
        &:not(:last-child) {
            .pdf-block {
                padding-bottom: 0;
            }
        }
    }
}
