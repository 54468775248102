.topics-table {
    background-color: #fafafa;
    padding-top: 60px;
    .content {
        display: flex;
        .table-of-content {
            .table-of-content-wrapper {
                overflow: hidden;
                max-width: 350px;
                background-color: white;
                border-radius: 0 5px 5px 0;
                box-shadow: 2px 3px 13px 0 rgba(0,0,0,0.15);
            }
            .table-of-content-header {
                background-color: $blue;
                padding: 25px 40px;
                color: white;
            }
            ul {
                padding: 0;
                li {
                    &.active {
                        background-color: #eaeef5;
                    }
                    &.active:after {
                        content: " ";
                        height: 5px;
                        width: 70px;
                        background-color: $red;
                        display: block;
                        position: absolute;
                        margin-top: -3px;

                    }
                    &:hover {
                        background-color: #eaeef5;
                    }
                    a {
                        padding: 25px 40px;
                        display: flex;
                        text-decoration: none;
                        color: $blue;
                    }
                }

            }
            .li-footer {
                padding: 15px 0;
                a {
                    padding-top: 15px;
                    font-weight: bold;
                    text-decoration: underline;
                    text-align: center;
                    justify-content: center;
                    border-top: 2px solid #d7dbe3;
                    display: block;
                    color: $blue;
                }
            }
        }
        .content-wrap {
            padding-left: 65px;
            max-width: 960px;
            width: 100%;
            .content-body {
                h2 {
                    font-weight: bold;
                }
                h2, p {
                    color: $blue;
                }
                p, li {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                img {
                    width: 100%;
                    border-radius: 8px;
                }
                .img-uploaded-wrapper img {
                    margin-bottom: 0;
                    width: 240px;
                    height: 240px;
                    object-fit: cover;
                }
                .content-body-table-container {
                    width: 100%;
                    overflow-x: auto;
                }
                .content-body-table {
                    color: $blue;
                    min-width: 450px;
                    border-radius: 5px;
                    overflow: hidden;
                    border: 2px solid $grey-alpha;
                    .row {
                        border-bottom: 1px solid $grey-alpha;
                        .left-column, .right-column {
                            .col-content {
                                padding: 24px;
                                p {
                                    font-size: 16px;
                                }
                            }
                        }
                        .left-column {
                            background-color: $blue-light;
                            .col-content {
                                padding-right: 10px;
                                p {
                                    font-weight: 700;
                                }
                            }
                        }
                        .right-column {
                            background-color: $white;
                            .col-content {
                                padding-left: 10px;
                                padding-right: 32px;
                            }
                        }
                        &.table-header {
                            .left-column, .right-column {
                                .col-content {
                                    p {
                                        font-size: 20px;
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    video {
        max-width: 100%;
    }
    @media (min-width: 786px) {
        .content {
            .content-wrap {
                margin-right: 20px;
            }
        }
    }
    @media (max-width: 800px) {
        .content {
            flex-wrap: wrap;
            .table-of-content {
                display: none;
                //max-width: 100%;
                //height: auto;
                //width: 100%;
                //border-radius: 5px;
                //margin: 0 25px 25px;
                //.table-of-content-header {
                //    border-radius: 0;
                //}
            }
            .content-wrap {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}
