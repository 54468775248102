.awareness-slider-container {
    overflow: hidden;
    .slick-track {
        width: max-content !important;
    }
}

.awareness-slider {
    display: flex !important;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 304px;
    .awareness-slider-item {
        padding: 0 12px;
        width: 304px;
        opacity: 1;
        transition-property: opacity;
        transition-duration: $time-md;
        transition-timing-function: ease;
        color: $blue-green;
        text-decoration: none;
        transition: all 0.5s ease;
        &:hover {
            border-radius: 10px;
            box-shadow: 0 0 8px #22222229;
            padding: 10px;
            margin-top: -20px;
        }
        .awareness-slider-content {
            opacity: 0;
            transition-property: opacity;
            transition-duration: $time-md;
            transition-timing-function: ease;
        }
        &.slick-current {
            .awareness-slider-content {
                opacity: 1;
            }
        }
    }
    .awareness-slider-img-container {
        width: 100%;
        height: 280px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 32px;
        .awareness-slider-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .awareness-slider-title {
        margin-bottom: 12px;
        h1, h2, h3, p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .awareness-slider-text {
        font-size: 16px;
        margin-bottom: 0;
        h1, h2, h3, p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .slick-list {
        overflow: visible;
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        order: 1;
        margin-bottom: 20px;
    }
    .slick-arrow {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50%;
        background-color: $blue-light;
        font-size: 0;
        transition: opacity $time-md ease;
        &:before {
            font-size: 16px;
            color: $blue;
            transition: color $time-sm ease;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: darken($blue, 8);
            transform: scale(0);
            transition: transform $time-sm ease, background-color $time-sm ease;
        }
        &:hover, &:focus {
            &:before {
                color: $white;
            }
            &:after {
                transform: scale(1);
            }
        }
        &:active, &:active:focus {
            &:before {
                color: $white;
            }
            &:after {
                background-color: darken($blue, 12);
                transform: scale(1);
            }
        }
    }
    .slick-prev {
        order: 2;
        margin-left: 12px;
        &:before {
            pointer-events: none;
            @include material-icon("keyboard_arrow_left");
            font-size: 30px;
        }
    }
    .slick-next {
        order: 4;
        margin-right: 12px;
        &:before {
            pointer-events: none;
            @include material-icon("keyboard_arrow_right");
            font-size: 30px;
        }
    }
    .slick-disabled {
        opacity: .4;
        cursor: not-allowed;
        &:before {
            color: $blue !important;
        }
        &:after {
            transform: scale(0) !important;
        }
    }
    .slick-dots {
        order: 3;
        flex-grow: 1;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        li {
            margin-right: 10px;
            button {
                -webkit-appearance: none;
                -moz-appearance: none;
                height: 8px;
                width: 8px;
                margin: 0;
                padding: 0;
                border: none;
                background-color: $blue;
                border-radius: 50%;
                opacity: .4;
                font-size: 0;
                transform: scale(.6);
                &:hover, &:focus {
                    opacity: 1;
                    transform: scale(1);
                }
                &:active, &:active:focus {
                    opacity: 1;
                    background-color: darken($blue, 10%);
                    transform: scale(1);
                }
            }
            &.slick-active {
                button {
                    opacity: 1;
                    transform: scale(1);
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .slick-active-after, .slick-active-before {
            button {
                transform: scale(.8);
            }
        }
    }
    @include breakpoint(lg) {
        width: 414px;
        justify-content: flex-end;
        .awareness-slider-item {
            width: 414px;
        }
        .awareness-slider-img-container {
            width: 100%;
            height: 390px;
        }
        .slick-prev {
            margin-right: 20px;
        }
    }
}

.template-practical-advice-page {
    .awareness-slider {
        .slick-arrow {
            background-color: $white;
        }
    }
}
