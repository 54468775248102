#history {
    background-color: $blue-light;
}

.history-slider-wrapper {
    background: linear-gradient(180deg, $white 50%, $blue-light 50%);
    overflow: hidden;
    padding: 40px 5px;
    @include breakpoint(lg) {
        padding: 60px 5px;
    }
}

.history-caroussel {
    display: flex !important;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;
    .card {
        min-height: 225px;
        margin-left: 7.5px;
        margin-right: 7.5px;
        margin-bottom: 40px;
        box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.05);
        border: 1px solid #cccccc;
        border-radius: 7px;
        padding: 30px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .card-body {
            padding: 0;
        }
        .card-year {
            color: $blue;
            margin-bottom: 12px;
        }
        .card-title {
            color: $red;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .card-text {
            font-size: rem(16);
            color: $blue;
            margin-bottom: 0;
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.slick-slider {
        .slick-list {
            width: 1265px;
            flex-shrink: 0;
            flex-grow: 0;
            order: 1;
            .slick-track {
                margin-left: 0;
            }
        }
        .slick-arrow {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 50%;
            background-color: $blue;
            font-size: 0;
            &:before {
                font-size: 16px;
                color: $white;
            }
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: darken($blue, 8);
                transform: scale(0);
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
            &:hover, &:focus {
                color: $white;
                &:after {
                    transform: scale(1);
                }
            }
            &:active, &:active:focus {
                color: $white;
                &:after {
                    background-color: darken($blue, 12);
                    transform: scale(1);
                }
            }
        }
        .slick-prev {
            order: 2;
            margin-left: 7.5px;
            &:before {
                @include fa-pro-icon("\f053");
            }
        }
        .slick-next {
            order: 4;
            margin-right: 7.5px;
            &:before {
                @include fa-pro-icon("\f054");
            }
        }
        .slick-dots {
            order: 3;
            flex-grow: 1;
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0 32px;
            padding: 0;
            max-width: 534px;
            li {
                flex: 1;
                font-size: 0;
                button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    width: 100%;
                    height: 3px;
                    margin: 0;
                    padding: 0;
                    border: none;
                    background-color: $white;
                }
                &.slick-active {
                    button {
                        background-color: $blue;
                    }
                }
            }
        }
    }
    &.history-caroussel-full {
        &.slick-slider {
            .slick-list {
                width: 100%;
            }
        }
    }
    @include breakpoint(md) {
        &.slick-slider {
            .slick-prev {
                margin-left: auto;
            }
            .slick-next {
                margin-right: auto;
            }
        }
    }
    @include breakpoint(lg) {
        margin-left: -17.5px;
        margin-right: -17.5px;
        .card {
            min-height: 302px;
            padding: 30px 40px 40px 40px;
            margin-left: 17.5px;
            margin-right: 17.5px;
            .card-year {
                margin-bottom: 20px;
            }
        }
        &.slick-slider {
            .slick-list {
                width: 1660px;
            }
            .slick-dots {
                margin: 0 62px;
            }
        }
    }
}
