.for-organizations {
    background-color: $grey;
    padding-bottom: 50px;
    .for-organizations-project-funding-tabs {
        .nav-link.active {
            color: $red;
            background-color: #f6e4e1;
        }
        .nav-link {
            color: $blue;
            &:hover {
                background-color: #e5e9f0;
            }
        }
        max-width: 780px;
        margin: 0 auto;
        .tab-content {
            padding-top: 50px;

            ul {
                list-style: none;
                li {
                    color: $blue;
                    font-size: 12pt;
                    list-style: none;
                }
                li::before {
                    content: "\2022";
                    color: $red;
                    font-weight: 800;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }
        h3 {
            color: $blue;
            font-size: 16pt;
            font-weight: bold;
            margin-bottom: 25px;
        }
        p {
            color: $blue;
            font-size: 12pt;
        }

    }
}
