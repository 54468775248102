.video_podcast_block {
    .td-section-one-wrapper {
        .resp-video-container {
            position: initial;
            @media screen and (min-width: 1200px) {
                width: 605px;
                height: 605px;
            }

        }
        div.row {
            h2 {
                @media screen and (max-width: 576px) {
                    font-size: rem(32);
                }
            }
            h2,p {
                color: #ffffff;
            }
            p {
               font-size: rem(16);
               line-height: 24px;
            }
        }
    }
    .title-text-video-podcast-container {
        .col-right {
            margin-left: 10%;
        }
    }
}

.px-xl-5-per {
    @media screen and (min-width: 1200px) {
        padding-right: 5%;
        padding-left: 5%;
    }
}
