.topic-article-header {
    .left-col {
        .row-top {
            padding: 40px 20px;
        }
        .row-bottom {
            padding: 40px 20px;
            background-color: $skin-light;
        }
    }
    .right-col {
        display: none;
    }
    .top-link {
        text-decoration: none;
        font-family: $font-title;
        font-size: 15px;
        font-weight: 700;
        color: $blue;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        .arrow-icon {
            position: relative;
            width: 36px;
            height: 36px;
            color: $blue;
            border-radius: 50%;
            display: flex;
            background-color: $skin;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            .material-icons {
                position: relative;
                z-index: 2;
                font-size: 22px;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: $skin-dark;
                border-radius: 50%;
                transform: scale(0);
                transform-origin: center center;
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
        }
        &:hover, &:focus {
            .arrow-icon {
                &:after {
                    transform: scale(1);
                }
            }
        }
        &:active, &:active:focus {
            .arrow-icon {
                &:after {
                    transform: scale(1);
                    background-color: darken($skin-dark, 6%);
                }
            }
        }
    }
    .row-top-title {
        margin-bottom: 32px;
    }
    .button-link {
        color: $blue;
        text-decoration: none;
        border: 2px solid $blue-grey;
        padding: 12px 16px;
        margin-bottom: 40px;
        display: inline-block;
        border-radius: 30px;
    }
    .alter-elem {
        background-color: $grey;
        font-size: 14px;
        color: $blue;
        padding: 12px;
        border-radius: 4px;
        border-left: 3px solid $skin-light;
        display: inline-block;
        margin-bottom: 40px;
    }
    .article-mobile-img {
        img {
            border-radius: 8px;
            width: 100%;
        }
    }
    @include breakpoint(md) {
        display: flex;
        align-items: flex-start;
        background-color: $skin-light;
        .left-col {
            flex-grow: 1;
            flex-shrink: 1;
            .row-top {
                position: relative;
                z-index: 1;
                padding-right: 40px;
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100vw;
                    background-color: $white;
                    z-index: -1;
                }
            }
            .row-bottom {
                padding-right: 40px;
            }
        }
        .right-col {
            position: relative;
            z-index: 3;
            flex-grow: 0;
            flex-shrink: 0;
            display: inline-block;
            padding-top: 40px;
            padding-right: 20px;
            img {
                width: 334px;
                height: 334px;
                border-radius: 8px;
                object-fit: cover;
            }
        }
        .article-mobile-img {
            display: none;
        }
        .button-link, .alter-elem {
            margin-bottom: 0;
        }
    }
    @include breakpoint(xxl) {
        .left-col {
            .row-top {
                padding-top: 60px;
                padding-left: 85px;
                padding-right: 70px;
                padding-bottom: 65px;
            }
            .row-bottom {
                padding-top: 60px;
                padding-left: 85px;
                padding-right: 70px;
            }
        }
        .right-col {
            padding-top: 60px;
            padding-right: 85px;
            img {
                width: 486px;
                height: 486px;
            }
        }
    }
}

.topic-article-header-bottom {
    display: flex;
    align-items: flex-start;
    .topic-article-header-bottom-curve {
        width: 100%;
        margin-top: -1px;
    }
    @include breakpoint(md) {
        position: relative;
        z-index: 2;
        margin-top: -16px;
    }
    @include breakpoint(xxl) {
        margin-top: -28px;
    }
}

body.template-promising-pratices-guides-page {
    header.main-header {
        .topic-article-header-bottom {
            background-color: $white;
        }
    }
}
