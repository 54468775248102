.repertoire-topic-module {
    &.subject-card-wrapper .title-section .subject-card-title {
        font-size: 24px;
        margin-bottom: 22px;
        line-height: 1.4;
        color: $blue-repertoire;
    }
    &--text * {
        font-size: 18px;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;
        @media screen and (min-width: 992px) {
            max-width: 60%;
        }
    }
    &--button-container {
        text-align: center;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 32px;
        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $blue;
            background: $blue-light;
            color: $blue;
            overflow: hidden;
            position: relative;
            padding: 20px 32px;
            border-radius: 32px;
            font-size: 17px;
            line-height: 1.5rem;
            font-family: Caros, sans-serif;
            font-weight: bold;
            img {
                margin-right: 4px;
                line-height: unset;
                height: 12px;
                width: auto;
                border-style: none;
            }
        }
    }
    &.subject-card-wrapper {
        background-color: $blue-light;
        padding-top: 80px;
        .card .card-header .card-header-title {
            @media (min-width: 992px) {
                margin-bottom: 80px;
            }
        }
    }


}
