.header-repertoire-organization {
    min-height: initial;
    .text-content-title {
        h1 {
            color: $blue-repertoire;
        }
    }
    .subheader-main.container-custom {
        width: 100%;
        max-width: 1414px;
        padding: 0 15px;
        justify-content: space-between;
        @media screen and (max-width: 991px) {
            display: block !important;
        }
    }

    .subheader-main .subheader-main-content {
        width: 57.5%;
        @media screen and (max-width: 991px) {
            width: 100%;
            padding-right: initial;
            .text-content-bg {
                border-top-right-radius: 0;
                border-bottom-right-radius: initial;
                position: absolute;
                top: 0;
                left: -100%;
            }
        }
        @media screen and (min-width: 768px) {
            .text-content-bg {
                position: absolute;
                top: 0;
                left: -100%;
            }
        }
    }

    .subheader-main-img-container {
        margin-bottom: initial !important;
        position: static !important;
        width: 42.5% !important;
        padding: 60px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: initial;
        @media screen and (max-width: 991px) {
            display: none;
        }
        .subheader-main-img-frame {
            padding: 48px 32px;
            width: fit-content;
            border-radius: 10px;
            img {
                max-width: 346px;
                width: 100%;
                height: auto;
            }
        }
    }
    .text-content {
        min-height: initial!important;
        padding-top: 60px!important;
        padding-bottom: 60px!important;
        @media screen and (max-width: 991px) {
            padding-top: 40px !important;
            align-items: center;
            display: flex !important;
            flex-direction: column;
        }
        .text-content-body {
            @media (max-width: 991px) {
                align-items: center;
                display: flex;
                flex-direction: column;
            }
        }
        h1 {
            font-size: 38px!important;
        }
        .mobile-logo {
            width: 100%;
            margin-bottom: 50px;
            max-width: 346px
        }
        .org-btn {
            color: white;
            font-size: 18px;
            background-color: #004071;
            padding: 10px 20px;
            display: inline-block;
            width: fit-content;
            border-radius: 4px;
            margin-top: 32px;;
            text-align: center;
        }
    }
}
