.job-list {
    background-color: $grey;
    .filter-bar {
        border-top: 1px solid $grey;
        background-color: $white;
        padding: 25px 0;
    }
    .content {
        padding-top: 40px;
        padding-bottom: 60px;
        max-width: 1384px;
        margin:0 auto;
        .job-li {
            text-decoration: none;
            display: flex;
            align-items: center;
            background-color: $white;
            border-radius: 5px;
            margin-bottom: 12px;
            padding: 20px;
            box-shadow: 0 2px 20px $grey-shadow;
            &:last-child {
                margin-bottom: 0;
            }
            .location-type {
                margin-bottom: 4px;
            }
            .location-section {
                font-size: 14px;
                color: $red;
                margin-bottom: 20px;
                .location-location {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    opacity: .6;
                    .location-icon {
                        flex-grow: 0;
                        flex-shrink: 0;
                        margin-right: 10px;
                    }
                    span {
                        flex-grow: 1;
                        flex-shrink: 1;
                        color: $blue;
                    }
                }
                .location-icon {
                    width: 13px;
                }
            }
            .desc-section {
                font-size: rem(20);
                font-weight: 700;
                color: $blue;
                word-break: break-word;
            }
            .content-section {
                flex-grow: 1;
                flex-shrink: 1;
            }
            .icon-section {
                flex-grow: 0;
                flex-shrink: 0;
                margin-left: 32px;
                .material-icons {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: $skin;
                    color: $blue;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 1;
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        background-color: $skin-dark;
                        transform: scale(0);
                        transition: transform $time-sm ease, background-color $time-sm ease;
                    }
                }
            }
            &:hover, &:focus {
                .desc-section {
                    color: $red;
                }
                .icon-section {
                    .material-icons {
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
            }
            &:active, &:active:focus {
                .desc-section {
                    color: darken($red, 6%);
                }
                .icon-section {
                    .material-icons {
                        &:after {
                            background-color: darken($skin-dark, 6%);
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
    .filter-1 {
        display: flex;
        align-items: center;
        color: $blue;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 12px;
        .filter-1-svg {
            width: 16px;
            margin-right: 12px;
        }
    }
    .filter-2-container {
        position: relative;
        display: inline-block;
        max-width: 100%;
        &:after {
            @include material-icon("expand_more");
            pointer-events: none;
            font-size: 20px;
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);
        }
        .filter-2 {
            @include apprearance();
            background-color: $grey;
            font-size: 16px;
            color: $blue;
            font-weight: 400;
            border-radius: 4px;
            padding: 16px 35px 16px 20px;
            border: 1px solid $grey-alpha;
            max-width: 100%;
            &:hover {
                border-color: $blue;
            }
        }

    }
    .pagination {
        margin-top: 40px;
    }
    @include breakpoint(sm) {
        .filter-bar-content {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .content {
            .job-li {
                .location-section {
                    display: flex;
                    align-items: center;
                    .location-type {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                    .location-location {
                        width: auto;
                    }
                }
            }
        }
        .filter-1 {
            margin-bottom: 0;
            margin-right: 24px;
        }
        .filter-2-container {
            .filter-2 {
                padding: 16px 45px 16px 35px;
            }
        }
    }
    @include breakpoint(lg) {
        .content {
            padding-top: 60px;
            padding-bottom: 80px;
            .job-li {
                padding: 24px 32px;
            }
        }
    }
}

.job-article-content-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    .news-article-block {
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.job-article-header {
    .info-bar-top {
        flex-wrap: wrap;
        margin: -5px;
        .info-bar-col {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #e6482b;
            margin: 5px;
            min-width: 150px;
            .icon-custom {
                background-color: #f7ebe9;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 10px 0 0;
                flex-shrink: 0;
                .material-icons {
                    font-size: 20px;
                }
            }
        }
    }
}
