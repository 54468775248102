.breadcrumbs-wrapper {
    background-color: $blue;
    .breadcrumbs-container {
        width: 100%;
        max-width: 1414px;
        margin: 0 auto;
        padding: 10px 15px;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
        }
        li {
            margin-right: 10px;
            color: white;
        }
        li + li:before {
            content: ">";
            margin-right: 10px;
            opacity: 0.5;
        }
        li a {
            text-decoration: none;
            color: white;
            opacity: 0.5;
        }
    }
}
