@import "TabsDisplay";
.green-section {
    .tdp-tabs-display-bottom {
        // background-color: $white !important;
        #curve-4 {
            display: initial;
        }
        #curve-3 {
            display: none;
        }
    }

    .tool-detail-families-game--section-eight {
        display: none;
    }
    .tool-detail-families-game--section-eight-bottom {
        .tool-detail-families-game--section-eight-bottom {
            background-color: $purple;
        }
        .top-curve-wrapper {
            margin-top: 98px;
        }
    }
}
