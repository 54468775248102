.who-we-are {
    background-color: $skin-light;
    padding: 80px 0;
    .wwa-top-container {
        margin-bottom: 60px;
    }
    .wwa-section-one {
        .thumb-1 {
            width: 100%;
            max-width: 230px;
            margin: 0 auto 32px auto;
            display: block;
        }
        p {
            text-align: center;
            color: $blue;
            max-width: 790px;
            margin: 0 auto;
        }
    }
    .accordion-wwa-so {
        .card {
            border-radius: 20px;
            margin-top: -25px;
            padding: 60px 20px 32px 20px;
        }
        .card-header {
            background-color: transparent;
            border-bottom: 0;
            padding: 0;
            text-decoration: none;
            button {
                padding: 0;
                font-weight: bold;
                color: $blue;
                font-size: 32px;
                letter-spacing: initial;
                width: 100%;
                text-align: left;
                text-decoration: none;
                text-transform: none;
            }
            .hidden {
                display: none;
            }
        }
        .card-body {
            padding: 0;
            img {
                width: 100%;
                max-width: 335px;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
            p.title {
                text-align: left;
                padding-bottom: 24px;
                margin-bottom: 24px;
                border-bottom: 1px solid $blue-grey;
            }
            p.txt {
                font-family: $font-content;
                font-weight: 400;
                text-align: left;
                margin-bottom: 40px;
            }
        }
        .card:nth-child(1) {
            z-index: 10;
        }
        .card:nth-child(2) {
            z-index: 9;
            background-color: $blue-light;
            padding-top: 56px;
        }
        .card:nth-child(3) {
            z-index: 8;
            background-color: $blue;
            p {
                color: $white;
            }
            padding-top: 50px;
            button {
                color: $white;
            }
        }
        .card:nth-child(4) {
            z-index: 7;
        }
    }
    @include breakpoint(lg) {
        padding-bottom: 120px;
        .wwa-top-container {
            margin-bottom: 80px;
        }
        .wwa-accordion-container {
            padding: 0 25px;
        }
        .accordion-wwa-so {
            .card {
                padding: 60px 80px 30px 80px;
            }
            .card-body {
                display: flex;
                img {
                    margin: 0;
                    max-width: 490px;
                }
                p.title {
                    padding-bottom: 60px;
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 24px;
                }
                p.txt {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 0;
                }
                .left-content, .right-content {
                    display: flex;
                    flex: 0 0 50%;
                    margin-bottom: 25px;
                }
                .left-content {
                    flex-wrap: wrap;
                    flex-direction: column;
                }
                .right-content {
                    padding-left: 80px;
                    align-items: flex-start;
                    justify-content: flex-end;
                }
            }
        }
    }
}
