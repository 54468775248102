.quot-image-podcast-container {
    background-color: #1d1d1d;
    width: 100%;
    padding-top: 7%;
    padding-bottom: 7%;
}

.quote-icon-container {
    width: 59px;
    height: 59px;
    border-radius: 8px;
    background-color: #ffffff;
}

.quote-img-container {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    overflow: hidden;

    .quote-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
