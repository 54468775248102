.professional-video-block{
    &.video_podcast_block {
        .td-section-one-wrapper {
            border-bottom: initial;
        }
    }
    h2 {
        font-size: 32px !important;
    }
    &--text {
        * {
            font-size: 16px !important;
            color: inherit !important;
        }
    }
    .td-section-one-wrapper .resp-video-container {
        height: initial !important;
    }
    .resp-video-container {
        height: initial !important;
    }
}
