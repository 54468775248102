.subtitle-title-text-image-backlink-block {
    display: flex;
    flex-direction: row;

    @media (max-width: 991px) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
    }

    &__text-container {
        align-items: flex-start;
        background-color: #fff;
        border-top-right-radius: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        max-width: 712px;
        padding: 84px 80px 90px 15px;
        position: relative;
        width: 50%;
        z-index: 1;

        @media (max-width: 991px) {
            max-width: 100%;
            width: 100%;
            padding-right: 15px;
        }

        @media (max-width: 576px) {
            padding: 42px 40px 45px 15px;
        }
    }

    &__backlink-container {
        display: block;
        text-decoration: initial;

        &:hover {
            text-decoration: initial;
        }
    }

    &__backlink-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        margin-right: 12px;
        background-color: #F5CDB1;
        margin-bottom: 55px;
        border-radius: 50%;

        img {
            width: 17px;
            height: 13px;
        }
    }

    &__backlink-text {
        color: #013A64;
        font-family: Caros, sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
    }

    &__title {
        color: #013A64;
        font-size: 40px;
        line-height: 54px;
        font-family: Caros, sans-serif;
        margin-bottom: 36px;
    }

    &__subtitle {
        color: #013A64;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 22px;
        //text-transform: capitalize;
        font-family: Moderat, sans-serif;
    }

    &__rich-text {
        * {
            color: #013A64;
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__img {
        bottom: 0;
        height: 100%;
        object-fit: cover;
        position: absolute;
        right: 0;
        width: calc(100% + 100px);

        @media (max-width: 991px) {
            width: 100%;
        }

        &-container {
            aspect-ratio: 16 / 9;
            position: relative;
            width: 50%;

            @media (max-width: 991px) {
                width: 100%;
            }

            @media (min-width: 1920px) {
                aspect-ratio: initial;
                min-height: 540px;
            }
        }
    }
}
