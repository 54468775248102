.pdf-block-container {
    .pdf-block-wrapper {
        padding: 40px 5px 0 5px;
        background-color: $skin-light;
        .news-article-block {
            margin-bottom: 0;
            img {
                border-radius: 8px;
            }
            .news-article-block-text {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &:only-child {
            padding-top: 0;
            background-color: $grey;
        }
        &:last-child {
            position: relative;
            z-index: 2;
            overflow: hidden;
            padding-bottom: 40px;
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                width: 110%;
                top: 50%;
                left: -5%;
                height: 150px;
                border-radius: 50%;
                background: $blue-light;
                overflow: hidden;
            }
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                width: 100%;
                top: calc(50% + 75px);
                left: 0;
                height: calc(50% - 75px);
                background: $blue-light;
            }
        }
    }
    @include breakpoint(lg) {
        .pdf-block-wrapper {
            padding-top: 70px;
            &:last-child {
                padding-bottom: 70px;
            }
        }
    }
}

.pdf-block {
    padding: 28px 20px 48px 20px;
    background-color: $white;
    border-radius: 8px;
    .pdf-block-intro, .pdf-block-actions {
        border-bottom: 1px solid $blue-grey;
        padding-bottom: 28px;
    }
    .pdf-block-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 28px;
    }
    .pdf-block-btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .pdf-block-date {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 24px;
        opacity: .7;
        .material-icons {
            font-size: 14px;
            margin-right: 12px;
            flex-shrink: 0;
        }
    }
    .pdf-block-title {
        font-size: rem(20);
        color: $blue;
        font-family: $font-content;
        line-height: 1.5;
        margin-bottom: 24px;
    }
    .pdf-block-text {
        font-size: 16px;
        color: $blue;
        p {
            margin-bottom: 24px;
            a:not([class^="btn"]):not(.li-link) {
                color: $blue-link;
                position: relative;
                text-decoration: underline !important;
                transition: color $time-sm ease;
                &:hover, &:focus {
                    color: darken($blue-link, 8%);
                }
                &:active, &:active:focus {
                    color: darken($blue-link, 12%);
                }
                b {
                    color: $red;
                    &:hover, &:focus {
                        color: darken($red, 8%);
                    }
                    &:active, &:active:focus {
                        color: darken($red, 12%);
                    }
                }
            }
            b {
                font-weight: 400;
                color: $red;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .pdf-block-img {
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        margin-bottom: 28px;
        object-fit: cover;
        object-position: center center;
    }
    .pdf-block-btn {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 20px;
        margin: 0 0 28px 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        display: flex;
        padding-left: 38px;
        padding-right: 54px;
        .pdf-block-intro {
            flex-grow: 1;
            flex-shrink: 1;
            border-right: 1px solid $blue-light;
            padding: 48px 64px 48px 0;
        }
        .pdf-block-actions {
            flex-grow: 0;
            flex-shrink: 0;
            width: 330px;
            padding: 20px 0 20px 32px;
        }
        .pdf-block-img {
            min-height: 350px;
        }
    }
}

.about-publications-pdf-block {
    .pdf-block {
        border-radius: 0;
        padding: 0 0 60px 0;
        .pdf-block-intro, .pdf-block-actions {
            padding-top: 60px;
        }
    }
    &:not(:last-child) {
        .pdf-block {
            padding-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        .pdf-block {
            padding: 0 0 80px 0;
            .pdf-block-intro, .pdf-block-actions {
                padding-top: 80px;
                padding-bottom: 32px;
            }
            .pdf-block-actions {
                width: 365px;
            }
        }
        &:not(:last-child) {
            .pdf-block {
                padding-bottom: 0;
            }
        }
    }
}
