.banner-quote {
    display: flex;
    margin: 25px auto;
    padding: 62px 0;
    border: 1px solid #ccc;
    border-right: 0;
    border-left: 0;
    justify-content: space-between;
    p {
        padding: 0;
        margin: 0 !important;
    }
    .top-info {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        span {
            color: $red;
        }
    }
    .bottom-txt {
        max-width: 492px;
    }
    .dec {
        display: inline-block;
    }
    .icon-wrap {
        img {
            padding: 15px;
            background-color: $white;
            border-radius: 8px;
            display: inline-block;
            width: 60px !important;
            height: 60px !important;
            margin-right: 20px;
        }
    }
    .large-strong {
        font-weight: bold;
        font-size: 25pt;
        line-height: initial;
    }
    .img-uploaded-wrapper {
        img {
            width: 100%;
            max-width: 400px;
            border-radius: 50% !important;
        }
    }
}

@media (max-width: 972px) {
    .banner-quote {
        flex-direction: column;
        .top-info {
            justify-content: center;
            .dec {
                p , p span {
                    font-size: 14px !important;
                }
            }
        }
        .col-left {
            order: 2;
        }
        .img-uploaded-wrapper {
            text-align: center;
            margin-bottom: 32px;
        }
        .bottom-txt {
            margin-right: 0;
            max-width: none;
            text-align: center;
        }
    }
}
