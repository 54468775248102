.video-arch-block {
    position: relative;
    overflow: hidden;
    margin-bottom: -20px;
    z-index: 2;
    .general {
        background-color: transparent;
    }
    .bottom-line {
        width: 70%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &--arch {
        position: absolute;
        top: 0;
        left: 50%;
        height: 210%;
        width: 130%;
        clip-path: ellipse(50% 28% at 50% 3%);
        transform: translateX(-50%);
        z-index: -1;
    }
    .tool-detail-families-game--section-seven .awareness-video-section .resp-video-container video {
        border-radius: initial;
    }
}

