.story-bottom-banner {
    &.td-section-three-wrapper {
        .top-content {
            padding-top: 100px;
            padding-bottom: 300px;
            margin-bottom: -135px;
        }
        .top-curve-wrapper {
            margin-bottom: initial;
            .top-curve {
                height: 460px;
                @media screen  and (max-width: 991px){
                    height: 250px;
                    width: 126%;
                    left: -11%;
                }
            }
        }
        .podcast-block.top-content {
            .content-middle-wrapper {
                border: 1px solid rgba(61, 63, 72,.5);
                &:after {
                    border: 1px solid rgba(61, 63, 72, .1019607843);
                }
                .content-middle {
                    background-color: rgba(61, 63, 72);
                    border: 2px solid rgba(61, 63, 72);
                    .content-middle-text {
                        .btn-blue-1-alt {
                            background-color: #e2f2ff;
                            color: #004071;
                            span.material-icons {
                                color: #004071;
                            }
                            &:after {
                                background-color: darken(#e2f2ff, 8);
                            }
                        }
                    }
                }
            }
        }
    }
    .section-one-white .section-one .content {
        background-color: transparent;
    }
    .assistant-description * {
        color: inherit !important;
    }
    .section-one {
        @media screen and (max-width: 576px){
            padding-top: initial !important;
        }
        @media screen and (min-width: 992px) {
            padding-top: 60px;
        }
    }
}
