.title-text-document-block {
    @media screen and (min-width: 1100px) {
        padding: 100px 0 0;
    }
    @media screen and (max-width: 1099px) {
        background-color: #FFFFFF!important;
    }

    .container-custom-md {
        background-color: #FFFFFF;
        @media screen and (min-width: 992px) {
            border-radius: 10px;
        }
        @media screen and (min-width: 576px) {
            padding: 0 50px 50px 50px;
        }
    }
    .about-publications-pdf-block .pdf-block {
        .pdf-block-intro {
            padding-top: 60px !important;
        }
        .pdf-block-actions {
            padding-top: 60px !important;
        }
    }
}
