/* CARDS
=========================== */

.card {
    padding: 30px;
}

.card-white {
    background-color: $white;
}

.card-primary {
    background-color: $color-primary;
}
