.slider-pp {
    border-radius: 0 0 0 100px;
    background-color: #DBECF9;
    padding: 83px 0 132px;

    &__container {
        margin: 0 auto;
        max-width: 1424px;
        padding: 0 15px;
    }

    &__card:not(:first-child) {
        display: none;
    }

    &__text-content {
        @media (min-width: 576px) {
            padding-left: 62px;
        }

        @media (min-width: 1200px) {
            padding-left: 124px;
        }
    }

    &__quote {
        background-color: $white;
        width: 59px;
        height: 59px;
        border-radius: 8px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 28px;
            height: 20px;
        }

        &-text {
            color: #013A64;
            font-family: Caros, sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
        }
    }

    &__name {
        color: $red;
        margin-bottom: 6px;

        &-container {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__profession {
        color: #013A64;
        margin-bottom: initial;
    }

    &__img {
        height: 100%;
        object-fit: cover;
        width: 100%;

        &-container {
            border-radius: 50%;
            height: 260px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 48px;
            overflow: hidden;
            width: 260px;

            @media (min-width: 576px) {
                height: 306px;
                margin-left: 62px;
                width: 306px;
            }
            @media (min-width: 1200px) {
                margin-left: 102px;
                margin-top: initial;
            }
        }
    }

    &__button {
        align-items: center;
        background-color: #013A64;
        border-radius: 50%;
        border: initial;
        display: inline-flex;
        flex-direction: row;
        height: 40px;
        justify-content: center;
        width: 40px;

        &.disabled {
            opacity: .5;
            cursor: not-allowed;
        }

        img {
            width: 10px;
            height: 16px;
        }

        &-prev {
            margin-right: 16px;

            @media (min-width: 1200px) {
                margin-bottom: 24px;
                margin-right: initial;
                margin-top: 50px;
            }
        }

        &-next {
            transform: rotate(180deg);
        }
    }
}
