.quote-image-block {
    .arch-container {
        width: 100%;
        height: clamp(40px, calc(40px + 2vw), 80px);
        margin-top: -80px;
        position: relative;
        overflow: hidden;
        background-color: transparent;
        @media (min-width: 768px) {
            height: clamp(40px, 8vh, 80px);
        }
        .arch {
            background: #fccdff;
            width: 110%;
            height: 327%;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -14%);
        }
    }

    .advice-container-block {
        background: #fccdff;
        .banner-quote {
            max-width: 1056px;
            border: initial;
            margin: 0 auto;
            padding: 20px 20px 90px;
            @media (min-width: 1366px) and (max-width: 1535px) {
                padding: 30px 20px 90px;
            }
            @media (min-width: 992px) and (max-width: 1365px) {
                padding: 40px 20px 90px;
            }
            .bottom-txt {
                max-width: 630px;
            }
        }
        .img-uploaded-wrapper img {
            margin-bottom: 0;
            width: 300px;
            height: 300px;
            @media (max-width: 575px) {
                width: 240px;
                height: 240px;
            }
            object-fit: cover;
            max-width: 400px;
            border-radius: 50%!important;
        }
        .dec span {
            color: #a1d9ed;
        }
    }
}

