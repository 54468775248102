.for-organizations-project-funding {
    .section-three {
        background-color: $grey;
        .container {
            .filter-bar {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                padding: 25px 0;
                .filter-1, .filter-2 {
                    padding: 10px 10px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    color: $blue;
                    text-transform: uppercase;
                    font-size: 9pt;
                    font-weight: 700;
                    cursor: pointer;
                    .material-icons {
                        font-size: 12pt;
                        margin-right: 5px;
                    }
                }
                .filter-2 {
                    background-color: #eaeef5;
                    border: 1px solid #ccd5e5;
                }
            }
        }
    }
}
