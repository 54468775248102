.for-organizations-header {
    background-color: $blue-light;
    .title-section {
        text-align: center;
        padding: 50px 25px;
        p {
            color: $blue;
            text-transform: uppercase;
            font-size: 9pt;
        }
        h1 {
            color: $blue;
            font-size: 20pt;
            font-weight: bold;
            span {
                color: $red;
            }
        }
    }
    .img-section {
        padding-bottom: 50px;
        background-color: $skin-light;
        img {
            width: 100%;
        }
    }
}
