.info-box-pp {
  padding: 72px 0 72px;
  background-color: #FFE4D4;

  &__container {
    margin: 0 auto;
    max-width: 1424px;
    padding: 0 15px;
  }

  &__card {
    background-color: $white;
    border-radius: 8px;
    padding: 32px 62px;
    border-left: 6px solid #023B65;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;

    @media (max-width: 767px) {
      padding: 32px;
      flex-direction: column;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    color: #013A64;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__rich-text {
    * {
      font-size: 16px;
      line-height: 24px;
      color: #013A64;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul, ol {
      padding-left: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  &__img {
    width: 32px;
    height: 32px;
  }
}
