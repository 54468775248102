.fo-section-one {
    .section-one {
        background-color: $skin-light;
        .content {
            background-color: $skin-light;
        }
    }
}

.fo-section-one-alt {
    .section-one {
        background-color: $skin-light;
        .content {
            background-color: $skin-light;
            .content-container {
                .btn-blue-1 {
                    &:nth-child(2) {
                        border: 1px solid $blue;
                        background: transparent;
                        color: $blue;
                        &:after {
                            background-color: #00407114;
                        }
                        &:active, &:active:focus {
                            &:after {
                                background-color: #0040712e;
                            }
                        }
                    }
                }
            }
        }
    }
    @include breakpoint(lg) {
        .section-one {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
}
