.par-top-desc {
    border-top: 1px solid $grey;
    padding: 40px 20px;
    .par-top-desc-text, .par-top-desc-text-alt {
        max-width: 590px;
        margin: 0 auto;
        color: $blue;
        p:last-child {
            margin-bottom: 0;
        }
    }
    .par-top-desc-text {
        font-size: 20px;
        text-align: center;
    }
    &.par-top-desc-lg {
        .par-top-desc-text, .par-top-desc-text-alt {
            max-width: 792px;
        }
    }
    @include breakpoint(lg) {
        padding: 55px 20px;
    }
    @media (max-width: 600px) {
        padding: 15px 20px;
        .par-top-desc-text, .par-top-desc-text-alt {
            padding: 10px 0 0;
        }
    }
}


