.more-news-cards-top-art {
    img {
        width: 100%;
    }
}
.more-news-cards-wrapper {
    background-color: $blue-light;
    padding-bottom: 40px;
    .more-news-title {
        margin-bottom: 40px;
        text-align: center;
    }
    .news-cards {
        .news-card {
            flex: 0 0 28%;
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid $blue-grey;
            text-decoration: none;
            padding-bottom: 32px;
            .image-wrapper, .image-wrapper-alt {
                height: 210px;
                width: 100%;
                overflow: hidden;
                border-radius: 8px;
                background-color: $white;
                margin-bottom: 20px;
                .image-wrapper-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    transform-origin: center center;
                    transition: transform $time-sm ease;
                }
            }
            .image-wrapper-alt {
                padding: 25px;
                .image-wrapper-img {
                    object-fit: contain;
                }
            }
            .tag-wrapper {
                display: flex;
                margin-bottom: 24px;
                .col-left {
                    padding: 4px 12px;
                    font-size: 14px;
                    text-decoration: none;
                    color: $blue;
                    border-radius: 5px;
                    background-color: $grey-light-alpha;
                    border: 1px solid $grey-alpha;
                    display: flex;
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .col-right {
                    margin-left: auto;
                    align-items: center;
                    color: $red;
                    .icon-custom {
                        background-color: #f7ebe9;
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        margin: 0 10px 0 0;
                    }
                }
            }
            @media (max-width: 400px) {
                .tag-wrapper {
                    flex-direction: column;
                    gap: 15px;
                    .col-right, .col-left {
                        justify-content: center;
                        display: flex;
                        width: 100%;
                    }
                }
            }
            .title-wrapper {
                .title {
                    font-family: $font-title;
                    font-size: rem(20);
                    font-weight: 500;
                    color: $blue-green;
                    margin-bottom: 0;
                }
            }
            &:hover {
                .image-wrapper, .image-wrapper-alt {
                    img {
                        transform: scale(1.1);
                    }
                }
                .title-wrapper {
                    .title {
                        color: $red;
                    }
                }
            }
        }
    }
    .news-caroussel-mobile {
        margin-bottom: 0;
        width: calc(100% + 40px);
        .slick-arrow {
            background-color: $white;
        }
        .slick-dots {
            li:only-child {
                display: none;
            }
        }
    }
    @include breakpoint(lg) {
        padding-bottom: 80px;
        .news-cards {
            .news-card {
                min-height: 100%;
            }
        }
        .news-caroussel-mobile {
            margin-left: -20px;
            margin-right: -20px;
            justify-content: center;
            .hc-caroussel-item {
                width: calc(100% / 3);
                padding-left: 20px;
                padding-right: 20px;
                flex: initial;
            }
        }
    }
}

