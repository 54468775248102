.most-consulted-tips-wrapper {
    background-color: $grey;
    padding: 40px 5px 0 5px;
    color: $blue;
    position: relative;
    z-index: 2;
    .mct-title, .mct-title-sm {
        text-align: center;
    }
    .mct-title {
        margin-bottom: 48px;
    }
    a {
        text-decoration:  none;
    }
    .content {
        max-width: 1384px;
        margin: 0 auto;
    }
    .accordion-tips {
        background-color: $white;
        box-shadow: 0 2px 20px $grey-shadow;
        border-radius: 8px;
        padding: 20px 32px 50px 32px;
        .card {
            padding: 0;
            border: 0;
            border-bottom: 1px solid $grey-alpha;
            .card-header {
                border: 0;
                padding: 24px 0;
                background-color: transparent;
                .card-header-content {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: $blue;
                    text-transform: initial;
                    font-weight: bold;
                    padding: 0;
                    width: 100%;
                    .header-title {
                        color: $red;
                        font-family: $font-title;
                        font-size: rem(18);
                        font-weight: 500;
                        letter-spacing: initial;
                        text-align: left;
                        margin-right: 24px;
                        flex-grow: 1;
                        flex-shrink: 1;
                        p {
                            margin-bottom: 0;
                        }
                    }
                    .icon-wrap {
                        flex-grow: 0;
                        flex-shrink: 0;
                        span {
                            position: relative;
                            z-index: 2;
                            width: 36px;
                            height: 36px;
                            color: $blue;
                            background-color: $skin;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 20px;
                            border-radius: 50%;
                            float: right;
                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                z-index: -1;
                                background-color: $skin-dark;
                                border-radius: 50%;
                                transform: scale(0);
                                transform-origin: center center;
                                transition: transform $time-sm ease, background-color $time-sm ease;
                            }
                            &:hover, &:focus {
                                &:after {
                                    transform: scale(1);
                                }
                            }
                            &:active, &:active:focus {
                                &:after {
                                    transform: scale(1);
                                    background-color: darken($skin-dark, 6%);
                                }
                            }
                        }
                        .down {
                            display: none;
                        }
                    }
                    &[aria-expanded="false"] {
                        .icon-wrap {
                            .up {
                                display: none;
                            }
                            .down {
                                display: flex;
                            }
                        }
                        .header-title {
                            color: $blue;
                        }
                    }
                }
            }
            .card-body {
                padding: 0 0 24px 0;
                .card-body-text {
                    margin-bottom: 32px;
                    font-size: 16px;
                    p:last-child {
                        margin-bottom: 0;
                    }
                }
                .card-body-btn {
                    margin: 0;
                }
                .right-column {
                    display: none;
                    img {
                        width: 100%;
                        max-width: 234px;
                    }
                }
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 60px;
        .mct-title {
            margin-bottom: 42px;
        }
        .accordion-tips {
            .card {
                border-radius: 0;
                .card-body {
                    display: flex;
                    align-items: flex-start;
                    padding-bottom: 5px;
                    .card-body-text {
                        max-width: 548px;
                    }
                    .card-body-btn {
                        margin-bottom: 15px;
                    }
                    .left-column {
                        flex-grow: 1;
                        flex-shrink: 1;
                        margin-right: 40px;
                    }
                    .right-column {
                        flex-grow: 0;
                        flex-shrink: 0;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
