.articles-list-one {
    padding: 0 20px 45px 20px;
    .content {
        max-width: 1384px;
        margin: 0 auto;
        h2:not(.d2) {
            color: $blue;
            font-size: 14px;
            font-family: $font-content;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 30px;
        }
        .content-body {
            .article-card {
                min-height: 135px;
                height: 100%;
                padding-bottom: 15px;
                a {
                    position: relative;
                    overflow: hidden;
                    border-radius: 8px;
                    background-color: $white;
                    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.05);
                    text-decoration: none;
                    //justify-content: space-between;
                    display: flex;
                    min-height: 100%;
                    justify-content: flex-start;
                    .img-wrapper {
                        position: relative;
                        z-index: 2;
                        width: 110px;
                        flex-shrink: 0;
                        overflow: hidden;
                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            transition-duration: 0.5s;
                            transform-origin: center center;
                        }
                    }
                    .text {
                        position: relative;
                        z-index: 2;
                        color: $blue;
                        font-size: 16px;
                        font-family: $font-title;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        padding: 25px;
                        p {
                            margin: 0;
                        }
                    }
                    .arrow-icon {
                        position: relative;
                        z-index: 2;
                        display: flex;
                        align-items: flex-end;
                        margin-left: auto;
                        .arrow-elem {
                            background-color: $blue;
                            color: $white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-top-left-radius: 100%;
                            width: 50px;
                            height: 50px;
                            padding-left: 10px;
                            padding-top: 10px;
                        }
                    }
                    &:after {
                        content: "";
                        align-self: center;
                        justify-self: center;
                        position: absolute;
                        z-index: 1;
                        width: calc(100% + 20px);
                        padding-bottom: calc(100% + 20px);
                        background-color: $grey-hover;
                        border-radius: 50%;
                        transform: scale(0);
                        opacity: .2;
                        transition: transform $time-sm ease, background-color $time-sm ease, opacity $time-sm ease;
                    }
                    &:hover, &:focus {
                        .img-wrapper {
                            img {
                                transform: scale(1.1);
                            }
                        }
                        .arrow-icon {
                            .arrow-elem {
                                background-color: darken($blue, 5);
                            }
                        }
                        &:after {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                    &:active, &:active:focus {
                        .img-wrapper {
                            img {
                                transform: scale(1.1);
                            }
                        }
                        .arrow-icon {
                            .arrow-elem {
                                background-color: darken($blue, 7);
                            }
                        }
                        &:after {
                            background-color: darken($grey-hover, 5);
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
    @include breakpoint(lg) {
        padding-bottom: 55px;
        .content {
            .content-body {
                .article-card {
                    padding-bottom: 25px;
                    a {
                        .text {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
