/* Top Nav */
header.main-header {
    .top-nav-wrapper {

        .logo-top {
            max-width: 124px;
            margin-top: 10px;
            width: 100%;

            &.logo-bg-bright {
                max-width: 124px;
                width: 100%;
                @media(max-width: 575px) {
                    max-width: 74px;
                }
            }
        }

        .top-stripe {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid rgba(145, 145, 145, 0.2);

            .content {
                margin: 0 auto;
                max-width: 1544px;
                padding: 16px 80px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                flex-wrap: wrap;
                width: 100%;
                column-gap: 10px;

                button {
                    text-transform: uppercase;
                    background: rgba(0, 0, 0, 0);
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    width: auto;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #004071;
                    font-weight: 700;
                    padding: 0 11px;
                    white-space: nowrap;
                    word-wrap: normal;
                    word-break: keep-all;
                    text-align: center;

                    &:hover {
                        background-color: $grey-light;
                        border-color: $blue;
                    }

                    &.disable {
                        opacity: .5;
                        pointer-events: none;
                    }
                }

                .button-lang {
                    @extend button;
                }

                a {

                    height: 30px;
                    text-transform: uppercase;
                    color: $blue;
                    text-decoration: none;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    padding: 0 10px;
                    border-radius: 3px;

                    &:hover {
                        background-color: $grey-light;
                    }
                }
            }

            .top-stripe-size-v-line {
                width: 1px;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.05);
            }
        }

        .top-nav {
            .logo-link {
                width: inherit;
                max-width: 300px;
            }

            ul {
                width: inherit;

                .btn-blue {
                    .link-nav {
                        @extend .btn-blue-1;
                        cursor: default;
                    }
                }
            }

            .content {
                position: relative;
            }

            //#main-menu, .global-search-icon  {
            //        display: none;
            //}

            #main-menu {
                display: flex;
                padding-right: 68px;
            }


            // #global-search-header-container {
            //     width: 50px;
            //     height: 50px;
            //     border-radius: 50%;
            //     border: 2px solid $red;
            //     flex-shrink: 0;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     margin-left: 16px;
            //     .global-search-icon {
            //         width: 14px;
            //         height: auto;
            //     }
            //     #global-search-header {
            //         display: none;
            //     }
            //
            // }
            //&.global-search-open {
            //    #main-menu {
            //        display: none;
            //    }
            //
            //    #global-search-header {
            //        width: 70%;
            //    }
            //}
        }

        .top-nav.fixed {
            position: fixed;
            width: 100%;
            background: white;
            z-index: 30;
            top: 0;
            animation-name: 'show-fixed-nav';
            animation-duration: 0.5s;
            animation-fill-mode: forwards;

            li:not(.btn-blue) a.link-nav {
                color: $blue !important;
            }

            .sub-nav {
                top: 80px;
            }
        }

        @keyframes show-fixed-nav {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        div[id*="subnav"]:not(.active) {
            display: none;
        }

        @include breakpoint(md) {
            .top-nav-wrapper:not(.top-nav-transparent-blue) {
                //border-bottom: 1px solid rgba(0, 0, 0, .08) !important;
            }
        }
        @include breakpoint(xl) {
            .top-nav-mob-wrapper {
                display: none;
            }
            .top-nav {
                .content {
                    margin: 0 auto;
                    max-width: 1414px;
                    padding: 10px 15px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                ul {
                    flex-shrink: 1;
                    flex-grow: 1;
                    margin: 0;
                    list-style: none;
                    display: flex;
                    justify-content: flex-end;

                    &.autocomplete-dropdown {
                        display: block;
                    }

                    li {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        list-style: none;

                        .top-dot {
                            width: 8px;
                            height: 8px;
                            background-color: $red;
                            border-radius: 50%;
                            position: absolute;
                            top: -12px;
                            left: calc(50% - 4px);
                            transform: scale(0);
                            transition: transform $time-sm ease;
                        }

                        &.sub-nav-li {
                            &.active {
                                .top-dot {
                                    transform: scale(1);
                                }
                            }
                        }

                        &:hover, &:focus {
                            .top-dot {
                                transform: scale(1);
                            }
                        }

                        &:active, &:active:focus {
                            .top-dot {
                                transform: scale(1.15);
                                background-color: darken($red, 4%);
                            }
                        }
                    }

                    .link-nav {
                        margin: 0 15px;
                        padding-bottom: 5px;
                        color: $blue;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 15px;
                        font-weight: bold;
                        position: relative;

                        .material-icons:not(.active) {
                            display: none;
                        }
                    }

                    .btn-blue {
                        .link-nav {
                            border-radius: 25px;
                            padding: 15px 25px;
                            background-color: $blue;
                            color: $white;
                            font-size: 14px;
                            cursor: pointer;
                            margin-right: initial;
                        }

                        .sub-nav {
                            margin-left: 35px;
                        }
                    }
                }

                .sub-nav {
                    z-index: 5;
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                    padding: 0;
                    border-radius: 5px;
                    position: absolute;
                    top: 70px;
                    color: black;
                    width: 100%;
                    max-width: 235px;
                    margin-left: -30px;
                    filter: drop-shadow(-2px -3px 4px rgba(163, 163, 163, 0.05));
                    display: none;

                    li {
                        a {
                            background: $white;
                            padding: 10px 30px;
                            width: 100%;
                            text-decoration: none;
                            color: $blue;
                            font-weight: bold;

                            &:hover {
                                background-color: $grey-light;
                            }
                        }

                        &:first-child {
                            a {
                                border-radius: 5px 5px 0 0;
                                padding: 30px 30px 10px;
                            }
                        }

                        &:last-child {
                            a {
                                border-radius: 0 0 5px 5px;
                                padding: 10px 30px 30px;
                            }
                        }

                        &.active {
                            a {
                                background-color: $grey-light;
                            }
                        }

                        &:first-child {
                            border-radius: 5px 5px 0 0;
                        }

                        &:last-child {
                            border-radius: 0 0 5px 5px;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 0;
                        z-index: 1;
                        border-style: solid;
                        border-color: $white transparent;
                        border-width: 0 15px 15px;
                        top: -15px;
                        left: 50%;
                        margin-left: -20px;
                    }

                    &.sub-nav-blue {
                        margin-top: 15px;
                        box-shadow: 0 0 8px #22222229;
                        filter: none;

                        li {
                            a {
                                background: $blue;
                                color: $white;

                                &:hover, &:focus {
                                    background-color: darken($blue, 5);
                                }

                                &:active, &:active:focus {
                                    background-color: darken($blue, 8);
                                }
                            }
                        }

                        &:before {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 32px;
                            position: absolute;
                            left: 0;
                            bottom: 100%;
                        }

                        &:after {
                            border-color: $blue transparent;
                        }
                    }
                }
            }
        }
        @media (max-width: 1199px) {
            .info-aidant-state-closed {
                .material-icons {
                    bottom: 30px;
                }
            }
            .top-nav, .top-stripe {
                display: none;
            }
            .top-nav-mob {
                position: fixed;
                width: 100%;
                padding: 15px;
                z-index: 30;
                border-bottom: 1px solid #ccc;
                background-color: white;
                top: 0;
                left: 0;
                min-height: 81px;

                .float-right {
                    display: inline-flex;
                    // margin-top: 10px;
                }

                .menu-burger {
                    border-radius: 50%;
                    border: 1px solid $blue;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    width: 48px;
                    height: 48px;
                    margin-left: 10px;

                    .material-icons {
                        font-size: 24pt;
                        color: $blue;
                        opacity: 1;
                    }

                    .menu {
                        display: inline-block
                    }

                    .close {
                        display: none
                    }

                    &.active {
                        .menu {
                            display: none
                        }

                        .close {
                            display: inline-block
                        }
                    }
                }

                .btn-blue .material-icons:not(.active) {
                    display: none;
                }

                .btn-blue {
                    font-family: Caros, sans-serif;
                    height: 48px;
                    border-radius: 25px;
                    padding: 15px 25px;
                    background-color: $blue;
                    color: $white;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 14px;
                    margin-right: 10px;
                    .btn-blue-link {
                        text-decoration: none;
                        color: $white !important;
                        .ressources {
                            display: inline-block;
                        }
                    }

                    .ressources {
                        display: inline-block
                    }

                    .fermer {
                        display: none
                    }

                    &.active {
                        .ressources {
                            display: none
                        }

                        .fermer {
                            display: inline-block
                        }
                    }
                }

                .sub-nav-mob {
                    display: flex;
                    height: calc(100vh - 84px);
                    width: 100%;
                    position: absolute;
                    left: 0;
                }

                .sub-nav-mob#subnav-1 {
                    margin-top: 25px;
                    background-color: $blue;
                    color: $white;
                    top: 65px;

                    position: fixed;
                    height: 100vh;
                    z-index: 2;

                    .btn.btn-link {
                        .expand_more {
                            display: none;
                        }

                        .expand_less {
                            display: block;
                        }
                    }

                    .btn.btn-link.collapsed {
                        .expand_more {
                            display: block;
                        }

                        .expand_less {
                            display: none;
                        }
                    }

                    .accordion {
                        width: 100%;
                    }

                    .card {
                        background-color: transparent;
                        width: 100%;
                        padding: 0;

                        a {
                            display: flex;
                            width: 100%;
                            padding: 25px;
                            font-size: 12pt;
                            color: $white;
                            text-decoration: none;
                            font-weight: bold;
                            border-bottom: 1px solid $grey-light;
                        }

                        .card-header {
                            padding: 0;
                            background-color: transparent;

                            button {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                padding: 25px;
                                text-align: left;
                                color: $white;
                                text-decoration: none;
                                font-weight: bold;
                                letter-spacing: normal;
                                text-transform: initial;
                                font-size: 12pt;
                                border-bottom: 1px solid $grey-light;

                                .material-icons {
                                    font-size: 20pt;
                                    margin-left: auto;
                                }
                            }
                        }

                        .card-body {
                            padding: 0;

                            ul {
                                padding: 0;
                                background: $grey-light;
                            }
                        }
                    }
                }

                .sub-nav-mob#subnav-2 {
                    top: 74px;
                    margin-top: 16px;
                    background-color: $white;
                    color: $blue;
                    position: fixed;
                    height: 100vh;
                    z-index: 2;
                    overflow: auto;

                    .btn.btn-link {
                        .expand_more {
                            display: none;
                        }

                        .expand_less {
                            display: block;
                        }
                    }

                    .btn.btn-link.collapsed {
                        .expand_more {
                            display: block;
                        }

                        .expand_less {
                            display: none;
                        }
                    }

                    .accordion {
                        width: 100%;
                    }

                    .card {
                        width: 100%;
                        padding: 0;

                        a {
                            display: flex;
                            width: 100%;
                            padding: 25px;
                            font-size: 20px;
                            color: $blue;
                            text-decoration: none;
                            font-weight: bold;
                        }

                        li a {
                            font-size: 16px;
                            font-weight: 500;
                        }

                        .card-header {
                            padding: 0;
                            background-color: transparent;

                            button {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                padding: 25px;
                                text-align: left;
                                color: $blue;
                                text-decoration: none;
                                font-weight: bold;
                                letter-spacing: normal;
                                text-transform: initial;
                                font-size: 20px;

                                .material-icons {
                                    font-size: 20pt;
                                    margin-left: auto;
                                }
                            }
                        }

                        //.collapse.show {
                        //    padding-bottom: 65px;
                        //}
                        .card-body {
                            padding: 0;

                            ul {
                                margin: 0;
                                padding: 0;
                                background: $grey-light;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 600px) {
            .logo-top {
                max-width: 80px;
            }
            .top-nav-mob {
                .sub-nav-mob#subnav-2 {
                    margin-top: 25px;
                    top: 65px;
                }
            }
        }
        @media (max-width: 400px) {
            .top-nav-mob {
                .btn-blue {
                    font-size: 11px;
                    padding: 15px 15px;
                }
            }
        }

        #global-search-header, #global-search-header-mob {
            width: auto;
            position: absolute;
            top: 15px;
            right: 0;
            padding-right: inherit;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: self-end;

            .autocomplete-container {
                width: 52px;
                margin-top: 2px;
                transition: all 0.1s linear;

                &.open-list {
                    margin-top: -15px;
                }

                .autocomplete-input {
                    width: 0;
                    padding: 0;
                   // transition: all 400ms linear;
                   // transition: all 200ms linear;
                }

                .open-global-search-btn {
                    cursor: pointer;
                    padding: 25px;

                    @media (max-width: 1199px) {
                        padding: 24px;
                    }

                    .fa-search {
                        color: $red;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .close-global-search-btn {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    padding: 0;
                   // transition: opacity 300ms linear;
                    transition: initial;
                }
            }
        }

        #global-search-header-mob {
            position: static;

            .autocomplete-container {
                // padding-top: initial;
                height: 48px;
                width: 48px;

                .autocomplete-input {
                   // transition: all 50ms linear;
                    transition: all 200ms linear;
                }
            }

        }

        .top-nav-mob-wrapper {
            .top-nav-mob {

                .logo-link-mob {
                    display: inline-block;
                    opacity: 1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: inherit;
                    // transition: all 400ms linear;
                    transition: initial;
                }

                .float-right {

                    .btn-blue {
                        opacity: 1;
                        // transition: all 200ms linear 400ms;
                    }

                    .menu-burger {
                        opacity: 1;
                        transition: all 400ms linear;
                    }

                }
            }
        }

        &.search-open {

            #main-menu {
                display: none;
            }

            #global-search-header, #global-search-header-mob {
                width: 70%;

                .autocomplete-container {
                    width: 100%;
                    height: auto;
                    // transition: all 400ms linear;
                    // transition: all 200ms linear;

                    .autocomplete-input-container {
                        justify-content: flex-end;
                    }

                    .autocomplete-input {
                        width: 100%;
                        padding: 10px;
                       // transition: all 400ms linear;
                        transition: all 200ms linear;
                    }

                    .open-global-search-btn {
                        cursor: initial;
                        padding: 25px;

                        @media (max-width: 1199px) {
                            padding: 23px;
                        }

                        .fa-search {
                            color: #c2c9d4;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-left: 6px;
                        }
                    }

                    .close-global-search-btn {
                        opacity: 1;
                        width: 24px;
                        height: 24px;
                        padding: 1px 6px;
                        margin-right: 20px;
                    }
                }
            }

            #global-search-header-mob {
                width: 100%;
               // transition: all 400ms linear 50ms;
                transition: all 200ms linear;

                .autocomplete-container {
                   // transition: all 400ms linear 50ms;
                    transition: all 200ms linear;

                    &.open-list {
                        @media(max-width: 575px) {
                            background-color: $white;
                            padding-bottom: initial;
                        }
                    }

                    .autocomplete-input {
                        // transition: all 400ms linear 50ms;
                        transition: all 200ms linear;
                    }

                    .autocomplete-dropdown {
                        max-height: 72vh;
                        overflow-y: auto;
                        @media(max-width: 575px) {
                            max-height: 72vh; // fallback
                            max-height: 72svh;
                        }
                    }

                }
            }

            .top-nav-mob-wrapper {
                .top-nav-mob {
                    .logo-link-mob {
                        opacity: 0;
                    }

                    .float-right {
                        float: initial !important;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;

                        .btn-blue {
                            opacity: 0;
                            width: 0px;
                            padding: 0px;
                            margin: 0px;
                           // transition: opacity 10ms linear, width 10ms linear 10ms, padding 10ms linear 10ms, margin 10ms linear 10ms;
                        }

                        .menu-burger {
                            display: none;
                            opacity: 0;
                        }

                    }
                }
            }
        }
    }

}

.template-home-page {
    header.main-header {
        .top-nav-wrapper:not(.top-nav-transparent-blue) {
            border-bottom: 0 !important;
        }

        .top-stripe {
            border-color: rgba(0, 0, 0, .08);
        }
    }
}

body.template-who-are-we-page {
    .top-nav-wrapper {
        background-color: $blue-light !important;
    }
}

body {
    @media (max-width: 1199px) {
        padding-top: 81px;
    }

    &.repertoire-page, &.to-be-referenced-page {
        header.subheader .top-nav-wrapper .top-nav {
            #global-search-header {
                display: none;
            }

            #main-menu {
                padding-right: initial;
            }
        }
    }
}
