.home-section-three {
    .section-wrapper {
        @extend .banner-one;
        position: relative;
        background-color: $skin-light;
        padding: 60px 20px !important;
        .content {
            flex-direction: column;
            max-width: 1062px !important;
            background-color: transparent !important;
            .content-video {
                display: flex;
                align-items: center;
                height: calc(100vw - 40px);
                width: calc(100vw - 40px);
                max-height: 612px;
                max-width: 612px;
                border-radius: 345px 345px 8px 8px;
                overflow: hidden;
                transform: translateZ(0); /* safari fix */
                margin-bottom: 40px;
                .content-video-container {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                }
                .content-video-button {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 20px;
                    align-items: flex-start;
                    justify-content: flex-end;
                    flex-direction: column;
                    color: $white;
                    background-color: #00123129;
                    font-family: $font-title;
                    font-size: 16px;
                    font-weight: 500;
                    overflow: visible;
                    border-radius: 0;
                    transform: none;
                    &:after {
                        display: none;
                    }
                    img {
                        position: static;
                        width: auto !important;
                        height: auto !important;
                        max-width: 100%;
                        margin-left: 0;
                        margin-bottom: 8px !important;
                    }
                }
                iframe {
                    top: -40%;
                    left: -200px;
                    width: 180%;
                    height: 180%;
                }
            }
            .content-container {
                margin: 0 !important;
                width: auto !important;
                padding: 0 !important;
                color: $blue;
                .home-section-three-title {
                    margin-bottom: 32px;
                }
                .home-section-three-text {
                    margin-bottom: 40px;
                    font-size: 16px;
                    p:last-child {
                        margin-bottom: 0;
                    }
                }
                .home-section-three-btn {
                    margin: 0;
                }
            }
        }
    }
    @include breakpoint(lg) {
        .section-wrapper {
            .content {
                flex-direction: row;
                .content-video {
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin-right: 65px;
                    margin-bottom: 0;
                }
                .content-container {
                    flex-grow: 1;
                    flex-shrink: 1;
                }
                .content-video {
                    .content-video-button {
                        padding: 40px 50px;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
