.banner-two {
    @extend .banner-one;
    background-color: $blue-light;
    padding-top: 80px;
    @media (max-width: 991px) {
        padding-top: 60px;
        .content {
            .content-container {
                order: 2;
            }
        }
    }
}
