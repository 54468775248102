.banner-quote-2-wrapper {
    background-color: $skin-light;
    .pattern {
        width: 100%;
        height: 85px;
        background-position: 0 15px;
    }
}
.banner-quote-2 {
    display: flex;
    flex-direction: column-reverse;
    max-width: 1050px;
    margin: 0 auto;
    padding: 0 25px 80px 25px;
    border-right: 0;
    border-left: 0;
    p {
        padding: 0;
        margin: 0 !important;
        color: $blue;
    }
    .top-info {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        span {
            color: $red;
        }
    }
    .dec {
        display: inline-block;
        font-size: 14px;
    }
    .icon-wrap {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
    img {
        margin: 0 auto;
        display: block;
    }
    .wwa-quote-txt-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .wwa-quote-img-container {
        margin-bottom: 30px;
    }
    .wwa-quote-img {
        height: 240px;
        width: 240px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center center;
    }
    @include breakpoint(lg) {
        padding-bottom: 122px;
        flex-direction: row;
        align-items: center;
        .wwa-quote-txt-container {
            display: initial;
            text-align: initial;
            padding-right: 100px;
        }
        .wwa-quote-img-container {
            margin-bottom: 0;
        }
        .wwa-quote-img {
            height: 306px;
            width: 306px;
        }
    }
}
