.pagination {
    display: flex;
    justify-content: center;
    ul {
        padding: 0;
        list-style: none;
        display: inline-flex;
        margin-bottom: 0;
        li {
            a {
                width: 30px;
                height: 30px;
                display: flex;
                background-color: $white;
                border: 1px solid #cfd8e7;
                text-decoration: none;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: bold;
                color: $blue;
                position: relative;
                z-index: 1;
                &:hover, &:focus {
                    border-color: $blue;
                    background-color: #00407105;
                    z-index: 3;
                }
                &:active, &:active:focus {
                    background-color: $blue;
                    color: $white;
                    border-color: $blue;
                }
            }
            .material-icons {
                font-size: 16px;
            }
            &:first-child {
                a {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }
            &:not(:last-child) {
                margin-right: -1px;
            }
            &:last-child {
                a {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
            &.active {
                a {
                    background-color: $blue;
                    color: $white;
                    border-color: $blue;
                }
            }
        }
    }
    &.pagniation-large {
        ul {
            li {
                display: none;
                &:first-child,
                &:nth-child(2),
                &:nth-last-child(2),
                &:last-child,
                &.active,
                &.active-prev,
                &.active-next,
                &.active-prev-dots,
                &.active-next-dots {
                    display: inline-block;
                }
                &.active-prev-dots:not(:first-child):not(:nth-child(2)) a,
                &.active-next-dots:not(:last-child):not(:nth-last-child(2)) a {
                    font-size: 0 !important;
                    pointer-events: none;
                    &:after {
                        content: "…";
                        position: absolute;
                        top: calc(50% - 14px);
                        left: 50%;
                        transform: translateX(-50%);
                        display: block;
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
    @include breakpoint(sm) {
        ul {
            li {
                a {
                    width: 38px;
                    height: 38px;
                }
            }
        }
    }
    @include breakpoint(lg) {
        ul {
            li {
                a {
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }
}
