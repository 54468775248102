.news-cards-wrapper {
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: $grey;
    display: flex;
    flex-wrap: wrap;
    .news-cards {
        margin: -16px;
        display: flex;
        flex-wrap: wrap;
        .news-card-container {
            padding: 16px;
            width: 100%;
        }
        .news-card {
            display: inline-block;
            width: 100%;
            min-height: 100%;
            border-bottom: 2px solid #ccc;
            text-decoration: none;
            padding-bottom: 32px;
            .image-wrapper, .image-wrapper-alt {
                height: 210px;
                width: 100%;
                overflow: hidden;
                border-radius: 8px;
                background-color: $white;
                margin-bottom: 20px;
                .image-wrapper-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    transition: transform $time-sm ease;
                }
            }
            .image-wrapper-alt {
                padding: 25px;
                .image-wrapper-img {
                    object-fit: contain;
                }
            }
            .tag-wrapper {
                display: flex;
                margin-bottom: 24px;
                .col-left {
                    padding: 4px 12px;
                    text-decoration: none;
                    font-size: 14px;
                    border-radius: 4px;
                    background-color: $grey-light-alpha;
                    color: $blue-green;
                    border: 1px solid $grey-alpha;
                    display: flex;
                    align-items: center;
                }
                .col-right {
                    margin-left: auto;
                    align-items: center;
                    color: $red;
                    .icon-custom {
                        background-color: #f7ebe9;
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        margin: 0 10px 0 0;
                    }
                }
            }
            @media (max-width: 400px) {
                .tag-wrapper {
                    flex-direction: column;
                    gap: 15px;
                    .col-right, .col-left {
                        justify-content: center;
                        display: flex;
                        width: 100%;
                    }
                }
            }
            .title-wrapper {
                .title {
                    font-family: $font-title;
                    font-size: rem(20);
                    font-weight: 500;
                    color: $blue-green;
                    margin-bottom: 0;
                }
            }
            &:hover, &:focus {
                .title-wrapper {
                    .title {
                        color: $red;
                    }
                }
                .image-wrapper, .image-wrapper-alt {
                    .image-wrapper-img {
                        transform: scale(1.1);
                    }
                }
            }
            &:active, &:active:focus {
                .title-wrapper {
                    .title {
                        color: $red;
                    }
                }
                .image-wrapper, .image-wrapper-alt {
                    .image-wrapper-img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        &:not(:only-child) {
            margin-bottom: 40px;
        }
    }
    @include breakpoint(sm) {
        .news-cards {
            .news-card-container {
                width: 50%;
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 60px;
        padding-bottom: 80px;
        .news-cards {
            .news-card-container {
                width: calc(100% / 3);
            }
            &:not(:only-child) {
                margin-bottom: 60px;
            }
        }
    }
}

