.repertoire-page {
    h1 {
        color: $blue;
    }

    .text-content-text * {
        color: $blue;
    }

    .repertoire-content-wrapper {
        background-color: #f8f9fa;

        .repertoire-content-container {
            @media(min-width: 992px) {
                padding-top: 32px;
            }

            .citoyens-form-container {
                display: flex;
                flex-direction: column;
                align-items: center;

                .form-container {
                    border: initial;
                    box-shadow: initial;
                    margin: 80px 0;
                    background-color: #f8f9fa;
                }
            }
        }
    }

    .or-element {
        font-size: 16px;
        line-height: 20px;
        color: $blue;
        padding: 12px 18px;
        background: $white;
        border-radius: 4px;
    }

    .search-service {
        padding: 40px 55px 30px 55px;
        background-color: $white;
        border-radius: 8px;
        max-width: 1384px;
        margin-bottom: 90px;
        @media (max-width: 575px) {
            padding: 40px 15px 30px 15px;
            margin-bottom: 52px;
        }

        &__title {
            color: #024273;
            @media(max-width: 575px) {
                font-size: 16px;
                margin-bottom: initial;
            }

            &-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;
                @media(max-width: 575px) {
                    font-size: 16px;
                    //justify-content: center;
                }
            }
        }

        &__back {
            border: initial;
            background: initial;
            align-items: center;
            display: inline-flex;

            &:hover {
                opacity: 0.75;
            }

            span {
                font-size: 15px;
                line-height: 24px;
                font-weight: bold;
                color: $blue-repertoire;


                &.material-icons {
                    font-size: 20px;
                    line-height: 24px;
                    margin-right: 6px;
                }
            }
        }

        &__indicators {
            display: inline-block;
            height: 2px;
            width: 100%;
            background-color: $red;
            border-radius: 8px;
            opacity: 0.25;

            &--active {
                opacity: 1;
            }

        }

        &__buttons {
            min-height: 147px;
            background: $grey-light-3;
            border-radius: 4px;
            width: 100%;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            padding: 0 32px;

            &:hover {
                background: #dbe2ee;
            }

            img {
                width: 32px;
                height: auto;
                max-height: 32px;
                margin-bottom: 12px;
            }

            span {
                font-size: 16px;
                line-height: 20px;
                color: $blue-repertoire;
                display: inline-block;
                text-align: center;
            }

            &-container {
                margin: 50px auto 0 auto;
                max-width: 1084px;
            }
        }

        &__address-input {
            display: none;
        }

        &.pre-submit {
            .search-service__back {
                display: inline-flex;
            }

            .search-service__address-input {
                display: flex;
                flex-direction: column;
            }

            .search-service__filters {
                padding: 10px 12px;
                background: $white;

                &.checked {
                    display: inline-flex;
                }

                &:hover {
                    background: $grey-light-4;
                }

                span {
                    display: inline-block;
                    font-size: 14px;
                    line-height: 20px;
                    color: $blue-repertoire;
                    margin-right: 6px;
                }

                label {
                    padding-left: 0 !important;
                    font-size: 14px !important;
                    font-weight: bold;
                    color: $blue-repertoire;

                    &:before, &:after {
                        display: none;
                    }
                }

                img {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin-left: 10px;
                    margin-top: 2px;
                }

                &--next {
                    display: none;
                }

                &--submit {
                    display: inline-flex;
                    color: $blue-repertoire;
                }

                &--select-all {
                    display: none;
                }

                &--clear-all {
                    display: inline-flex;
                    color: $blue-repertoire;
                }

            }


        }
    }

    .search-service__filters {
        border: 1px solid #CBD6E2;
        border-radius: 4px;
        padding: 20px;
        background: $grey-light-3;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin-bottom: initial;

        &:hover {
            background: $grey-light-4;
        }

        span {
            display: none;
        }

        label {
            padding-left: 36px !important;
            color: $blue-green;
            cursor: pointer;


            &:before, &:after {
                left: 20px !important;
                top: 50% !important;
                transform: translateY(-50%) !important;
            }
        }

        img {
            display: none;
        }

        &-container {

        }

        &-group {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: start;
            justify-content: start;
            margin-bottom: 32px;
            gap: 14px;
        }

        &-header {
            @media (max-width: 575px) {
                padding: 32px 22px;
            }
            padding: 32px 44px;
            background: $grey-light-3;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 24px;

            img {
                width: 32px;
                height: auto;
                max-height: 32px;
                margin-right: 4px;
            }

            span {
                font-size: 16px;
                line-height: 20px;
                color: $blue-green;
            }
        }

        &--clear-all {
            display: none;
        }

        &--select-all, &--clear-all {
            font-size: 16px;
            line-height: 20px;
            color: $blue-green;
            border: initial;
            padding: 23px 20px;
            font-weight: bold;
        }

        &--next, &--submit {
            margin-bottom: 30px;

            span {
                color: $blue-repertoire;
            }
        }
    }

    .breadcrumbs-wrapper {
        @media (max-width: 991px) {
            display: none;
        }
    }

}
