.banner-three {
    @extend .banner-one;
    background-color: $skin-light;
    padding-top: 80px;
    .content {
        background-color: $grey-light-2;
        .img-container {
            order: 2;
        }
    }
    @media (max-width: 991px) {
        padding-top: 60px;
        .content {
            .img-container {
                order: 1;
            }
            .content-container {
                order: 2;
            }
        }
    }
}
