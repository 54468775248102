.resource-list {
    &__hero {
        position: relative;
        background-color: $grey;
        min-height: 750px;
        -webkit-mask-size: 100vw 750px;
        mask-size: 100vw 750px;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        margin-bottom: -200px;
        border-radius: 50%/50px;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        padding-top: 300px;
        background-position: center;
        background-size: cover;
        z-index: 0;
        @media (max-width: 991px) {
            min-height: unset;
            margin-bottom: 40px;
            -webkit-mask-image: unset;
            mask-image: unset;
            -webkit-mask-size: unset;
            mask-size: unset;
            -webkit-mask-repeat: unset;
            mask-repeat: unset;
            -webkit-mask-position: unset;
            mask-position: unset;
            padding-top: 125px;
            padding-bottom: 87px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: transparent -webkit-gradient(linear, left top, left bottom, from(#002948), color-stop(12%, #002948A8), color-stop(25%, #00294860), color-stop(49%, #002747DD), color-stop(73%, #002647), color-stop(100%, #001F44B2), to(#00184200)) 0 0 no-repeat padding-box;
            background: transparent linear-gradient(180deg, #002948, #002948A8 12%, #00294860 25%, #002747DD 49%, #002647 73%, #001F44B2 100%, #00184200 0) 0 0 no-repeat padding-box;
            height: 100%;
            z-index: -1;
        }

        h1 {
            color: #fff;
            margin: 24px 0;
            text-align: center;
            font-size: 2.5rem;
            line-height: 3rem;
        }
    }

    &__card {
        background: #fff;
        border: 2px solid #00308429;
        border-radius: 8px;
        overflow: hidden;
        padding: 24px 25px;
        color: $blue-green;
        position: relative;
        z-index: 1;
    }

    &__content {
        //display: flex;
        //flex-wrap: wrap;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        list-style: none;
    }

    &__item {
        //width: 50%;
        padding: 0 15px;
        @media (max-width: 768px) {
            width: 100%;
            padding: 0;
        }
    }

    &__link {
        text-decoration: none;
        color: $blue;
        font-size: 16px;
        line-height: 145%;
        &:hover {
            color: $blue;
        }
    }
}
