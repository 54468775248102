.for-organizations-project-funding {
    .section-two {
        .container {
            max-width: 1200px;
            .col-right {
                border-left: 1px solid #f1f4f7;
            }
        }
    }
    @media (max-width: 768px) {
        .section-two {
            .container {
                .col-right {
                    border-left: 0;
                    padding-top: 50px;
                }
            }
        }
    }
}
