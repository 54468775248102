.gd-list-wrapper {
    padding: 50px;
    .content {
        max-width: 1200px;
        margin: 0 auto;
        .section-row {
            display: flex;
            flex-direction: row;
            border-bottom: 2px solid #f1f4f7;
            .col-left {
                display: flex;
                flex: 0 0 65%;
                flex-direction: column;
                padding: 50px;
                border-right: 2px solid #f1f4f7;
                h3 {
                    font-weight: bold;
                    color: $blue;
                    margin-bottom: 25px;
                }
                p {
                    color: $blue;
                    font-size: 14pt;
                }
            }
            .col-right {
                display: flex;
                flex: 0 0 35%;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                padding: 25px;
            }
        }

    }
    @media (max-width: 750px) {
        padding: 25px;
        .content {
            .section-row {
                flex-direction: column;
                .col-left {
                    border-right: 0;
                    padding: 25px;
                }
                .col-right {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
