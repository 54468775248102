.subject-card-wrapper {
    padding: 40px 5px;
    background-color: $grey;
    .custom-col-container {
        margin-bottom: -20px;
        .row {
            margin-left: -10px;
            margin-right: -10px;
        }
        div[class*="col-"] {
            padding-right: 10px;
            padding-left: 10px;
            margin-bottom: 20px;
        }
    }
    a {
        text-decoration:  none;
    }
    .content {
        max-width: 1384px;
        margin: 0 auto;
    }
    .title-section {
        text-align: center;
        color: $blue;
        .subject-card-title {
            margin-bottom: 40px;
        }
        .subject-card-intro {
            font-size: 20px;
            max-width: 588px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 60px;
        }
    }
    .card {
        padding: 0;
        height: 100%;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;
        transition: background-size $time-sm ease-in;
        border: none;
        box-shadow: 0 2px 20px $grey-shadow;
        .card-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
            flex-shrink: 1;
            background-color: $white;
            padding: 32px 32px 39px 32px;
            z-index: 1;
            .bg-cover {
                background-color: $grey-light;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                left: calc(50% - 25px);
                transition: all 300ms ease-in;
                z-index: -1;
            }
            .card-header-title {
                text-align: left;
                color: $blue;
                font-size: rem(24);
                line-height: 1.4;
                flex-grow: 1;
                margin-bottom: 30px;
            }
            .header-footer {
                display: flex;
                align-items: center;
                text-align: left;
                z-index: 1;
                font-size: 16px;
                color: $blue;
                img {
                    margin-right: 8px;
                }
            }
        }
        .card-body {
            flex-grow: 0;
            flex-shrink: 0;
            position: relative;
            height: 150px;
            padding: 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            z-index: 1;
            transition: background-size $time-sm ease-in;
            overflow: hidden;
            .arrow-elem {
                background-color: $blue;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-left-radius: 100%;
                width: 50px;
                height: 50px;
                padding-left: 10px;
                padding-top: 10px;
                position: relative;
                z-index: 5;
                transition: background-color $time-sm ease;
            }
            .card-body-img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center center;
                transition: transform $time-sm ease;
            }
        }
        &.card-last {
            border: 1px solid $blue;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px 32px;
            background-color: $grey;
            color: $blue;
            text-align: center;
            transition: background-color $time-sm ease;
            .title {
                font-family: $font-title;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            .desc {
                font-size: 14px;
                margin-bottom: 32px;
                p:last-child {
                    margin-bottom: 0;
                }
            }
            .card-btn {
                position: relative;
                z-index: 2;
            }
            &:hover, &:focus {
                background-color: $white;
            }
            &:active, &:active:focus {
                background-color: $white;
            }
        }
        &:hover, &:focus {
            .card-header {
                .bg-cover {
                    width: 800px;
                    height: 800px;
                    left: calc(50% - 400px);
                }
            }
            .card-body {
                .card-body-img {
                    transform: scale(1.1);
                }
                .arrow-elem {
                    background-color: darken($blue, 8);
                }
            }
        }
        &:active, &:active:focus {
            .card-header {
                .bg-cover {
                    width: 800px;
                    height: 800px;
                    left: calc(50% - 400px);
                    background-color: darken($grey-light, 3%);
                }
            }
            .card-body {
                .card-body-img {
                    transform: scale(1.1);
                }
                .arrow-elem {
                    background-color: darken($blue, 12);
                }
            }
        }
    }
    .btn-blue-1{
        background-color: #f5cdb1;
        color: $blue;
    }
    @include breakpoint(lg) {
        padding: 60px 5px;
        .custom-col-container {
            margin-bottom: -24px;
            .row {
                margin-left: -12px;
                margin-right: -12px;
            }
            div[class*="col-"] {
                padding-right: 12px;
                padding-left: 12px;
                margin-bottom: 24px;
            }
        }
        .title-section {
            .subject-card-intro {
                margin-bottom: 80px;
            }
        }
        .card {
            .card-header {
                padding-bottom: 20px;
                .card-header-title {
                    margin-bottom: 45px;
                }
            }
            &.card-last {
                padding: 40px;
            }
        }
    }
}
