.tool-detail-families-game--under-bar {
    @extend .radio-channels-stripe;
    background-color: $green;
    .content {
        p {
            color: $green-dark;
        }
    }
    @include breakpoint(md) {
        background-color: $green-alt;
    }
}

body.txt-dark-False {
    .tool-detail-families-game--under-bar .content p {
        color: white;
    }
}
