.info-box-block {
    padding-top: initial;
    .flex-info-box-container-block {
        margin-bottom: initial;
        .banner-info {
            position: relative;
            top: 75px;
            z-index: 2;
            background: transparent;
            .banner-info-title p b {
                color: inherit;
            }
        }
    }
    .top-curve-wrapper {
        height: 170px;
        .top-curve {
            height: 210px;
            position: relative;
            width: 110%;
            left: -5%;
            border-radius: 50% 50% 0 0;
            overflow: hidden;
            padding-bottom: 100px;
        }
    }
}
