.ppg-info-cards-wrapper {
    background-color: $grey;
    padding-top: 60px;
    padding-bottom: 60px;
    .info-card {
        width: 100%;
        text-decoration: none;
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 2px 20px $grey-shadow;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .info-card-left-col {
        padding: 32px;
        img {
            width: 180px;
            max-width: 100%;
        }
    }
    .info-card-right-col {
        display: flex;
        flex-direction: column;
        padding: 0 32px 32px 32px;
        .info-card-right-content {
            max-width: 628px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .info-card-top-title {
            display: inline-block;
            background-color: $grey;
            border-radius: 4px;
            padding: 12px;
            border-left: 3px solid $skin-light;
            margin-bottom: 20px;
        }
        .info-card-title {
            font-weight: 700;
            font-size: rem(20);
            margin-bottom: 16px;
            span {
                color: $red;
            }
            strong, b {
                color: $red;
                font-weight: 700;
            }
            p, h1, h2, h3, h4 {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .info-card-text {
            font-size: 16px;
            margin-bottom: 24px;
            p:last-child {
                margin-bottom: 0;
            }
        }
        .info-card-btn {
            margin: 0;
        }
    }
    @include breakpoint(sm) {
        .info-card {
            display: flex;
            align-items: center;
        }
        .info-card-left-col {
            img {
                max-width: none;
            }
        }
        .info-card-right-col {
            padding: 32px 32px 32px 0;
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
        .info-card-left-col {
            padding: 40px;
        }
        .info-card-right-col {
            padding: 40px 40px 40px 0;
        }
    }
}
