/* FORMS
=========================== */
.form-control {
    font-size: 16px; //ios form autozoom fix
    @include breakpoint(sm) {
        font-size: 14px;
    }
}

/* CUSTOM CHECKBOX */

.checkbox-custom {
    position: relative;
    text-align: left;

    label {
        display: inline-block;
        width: calc(100% - 22px);
        padding-left: 35px;
    }

    input[type=checkbox] {
        visibility: hidden;
        display: inline;
    }
    label {
        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            width: 22px;
            height: 22px;
            left: 0;
            top: 0;
            cursor: pointer;
            background-color: $white;
            transition: all 500ms ease;
            border-radius: 0;
        }
        &:before {
            border: 2px solid $grey;
        }

        &:after {
            @include fa-icon('\f00c');
            color: $white;
            text-align: center;
            transform: scale(0);
            transition: all 300ms ease;
            background-color: transparent;
        }
        &:active:before {
            background-color: darken($grey-light, 20%);
        }
        &:hover:before {
            background-color: darken($grey-light, 10%);
        }
    }
    input[type=checkbox]:checked + label {
        &:before {
            background-color: $color-primary;
            border-color: $color-primary;
        }
        &:after {
            transform: scale(1);
        }
        &:active:before {
            background-color: darken($color-primary, 20%);
            border-color: darken($color-primary, 20%);
        }
        &:hover:before {
            background-color: darken($color-primary, 10%);
            border-color: darken($color-primary, 10%);
        }
    }
    @include breakpoint(md) {
        label {
            padding-left: 38px;
        }
    }
}

/* CUSTOM RADIO */

.radio-custom {
    position: relative;

    input[type=radio] {
        visibility: hidden;
        display: inline;
    }

    label {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            left: 0;
            top: 2px;
            cursor: pointer;
            background-color: $grey-light;
            transition: all 500ms ease;
            border-radius: 30px;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            left: 4px;
            top: 6px;
            background-color: $color-primary;
            border-radius: 30px;
            transform: scale(0);
        }
        &:active:before {
            background-color: darken($grey-light, 20%);
        }
        &:hover:before {
            background-color: darken($grey-light, 10%);
        }
    }
    input[type=radio]:checked + label {
        &:after {
            transform: scale(1);
        }
    }
    @include breakpoint(md) {
        label {
            padding-left: 10px;
        }
    }
}

/* CUSTOM SELECT */

.select-custom {
    position: relative;
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    &:before {
        @include fa-icon('\f107');
        position: absolute;
        top: -32px;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 20px;
        font-size: 24px;
        color: $grey-dark;
        pointer-events: none;
    }
    @include breakpoint(lg) {
        &:before {
            height: 25px;
        }
    }
}

/* REMOVE YELLOW AUTOFILL COLOR  */

@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}
input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}
.form-container {
    background-color: white;
    max-width: 1000px;
    width: 100%;
    padding: 40px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05)
}
.form-grey {
    .form-grey-label, .form-grey-subtitle {
        display: block;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .form-grey-control, .form-grey-select {
        font-size: 16px;
        color: $blue;
        background-color: $grey-light-alpha;
        border: 1px solid $grey-alpha;
        border-radius: 4px;
        height: 56px;
        width: 100%;
        padding: 15px 20px;
        margin-bottom: 20px;
    }
    .form-grey-select-container {
        margin-bottom: 20px;
        position: relative;
        &:after {
            @include material-icon("expand_more");
            pointer-events: none;
            font-size: 30px;
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);
        }
        .form-grey-select {
            @include apprearance();
            padding-right: 45px;
            margin-bottom: 0;
        }
    }
    .form-grey-input {
        display: flex;
        margin: 32px 0 0 auto;
    }
    .form-grey-check {
        position: relative;
        margin-bottom: 14px;
        .form-check-input {
            visibility: hidden;
            display: inline;
        }
        .form-check-label {
            display: inline-block;
            font-size: 16px;
            padding-left: 16px;
            &.red-border {
                &:before {
                    border: 1px solid red;
                }
            }
            &:before, &:after {
                content: "";
                display: block;
                position: absolute;
                width: 24px;
                height: 24px;
                left: 0;
                top: 0;
                cursor: pointer;
                background-color: $white;
                border-radius: 4px;
                transition: all 200ms ease;
            }
            &:before {
                border: 1px solid $blue;
            }

            &:after {
                content: '';
                background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2210.067%22 height=%227.2%22 viewBox=%220 0 10.067 7.2%22%3E%3Cpath id=%22check%22 d=%22M4.333,7.2,0,2.867l1.4-1.4L4.333,4.333,8.667,0l1.4,1.4Z%22 fill=%22%23FFFFFF%22/%3E%3C/svg%3E');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 10.067px 7.2px;
                transform: scale(0);
                transition: all 100ms ease;
                background-color: transparent;
            }

            //&:active:before {
            //    background-color: $grey;
            //}
            //
            //&:hover:before {
            //    background-color: darken($grey, 4%);
            //}
        }
        .form-check-input:checked + .form-check-label {
            &:before {
                background-color: $blue-green;
                border-color: $blue-green;
            }
            &:after {
                transform: scale(1);
            }
            //&:active:before {
            //    background-color: darken($blue-green, 50%);
            //    border-color: darken($blue-green, 50%);
            //}
            //&:hover:before {
            //    background-color: darken($blue-green, 100%);
            //    border-color: darken($blue-green, 50%);
            //}
        }
    }
}

.form-confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    padding: 32px;
    border: 1px $blue dashed;
    margin-bottom: 32px;
    .form-confirmation-icon {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: $blue;
        margin-right: 24px;
        transform: scale(0);
        transition: transform $time-md ease;
        .form-confirmation-icon-check {
            color: $white;
        }
    }
    .form-confirmation-text {
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: -25px;
        transition: margin-left $time-md ease;
        p:last-child {
            margin-bottom: 0;
        }
    }
    &.loaded {
        .form-confirmation-icon {
            transform: scale(1);
        }
        .form-confirmation-text {
            margin-left: 0;
        }
    }
    @include breakpoint(lg) {
        .form-confirmation {
            padding: 32px;
            margin-bottom: 0;
        }
    }
}

.default-forms {
    label {
        font-size: 17px;
        font-weight: 700;
        margin: 15px 0;
    }
    .errorlist {
        li {
            color: $red;
        }
    }
    .checkbox-wrapper {
        display: inline-block;
        margin-left: 10px;
        input[type="checkbox"] {
            width: initial!important;
            accent-color: $blue;
        }
    }
    .help-text {
        margin-top: -10px;
    }
    .radio-wrapper {
        li {
            margin-left: initial!important;
        }
        ul {
            margin-bottom: 10px;
        }
        label {
             margin-right: 10px;
             font-weight: 500;
             font-size: 15px;
             margin-top: 0;
             margin-bottom: 0;
        }
        input[type='radio']{
             width: initial!important;
             margin-right: 5px;
             accent-color: $blue;
        }
    }
    .g-recaptcha {
          margin-bottom: 20px;
    }
    #id_check-box-cousher {
            label {
             font-weight: 500;
             font-size: 15px;
        }
        input {
            margin-right: 5px;
        }
    }
}

.messages {
    .error {
         color: $red;
    }
    .success {
        color: $green;
        font-style: italic;
        font-size: 20px;
        font-weight: 700;
        margin-top: 50px;
    }
}
