.accordion-pp {
    padding: 58px 15px;
    background-color: #F8F9FA;

    @media (min-width: 576px) {
        padding: 115px 15px;
    }

    &__container {
        max-width: 1136px;
        margin: 0 auto;
    }

    .accordion {
        .card-wrapper {
            .card {
                box-shadow: 0 2px 20px #000B560A;
                border-radius: 8px;
                border: initial;
                overflow: hidden;
                padding: 25px 30px;
                margin-bottom: 20px;

                &__link {
                    background-color: initial;
                    border: initial;
                    display: inline-flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    padding: initial;

                    &-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-content: flex-start;
                        gap: 16px 32px;
                        margin-bottom: 40px;
                    }

                    &-text {
                        font-size: 16px;
                        line-height: 24px;
                        text-decoration: underline;
                        color: #013A64;
                        margin-right: 9px;
                    }

                    .material-icons {
                        color: #013A64;
                        font-size: 17px;
                        margin-bottom: 1px;
                    }
                }

                .card-left-border {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 8px;
                    height: 100%;
                    background-color: $skin;
                    transform: scaleY(0);
                    transition: all 0.15s linear;
                    transform-origin: bottom;
                }

                .card-header {
                    padding: 0;
                    background: initial;
                    border: initial;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    &__content {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        flex: 1;

                        @media (max-width: 991px) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }

                    .btn {
                        margin-left: 32px;
                        padding: 0;
                        width: 36px;
                        height: 36px;
                        background-color: $skin;
                        border-radius: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        &:after {
                            display: inline-block;
                            flex-shrink: 0;
                            width: 16px;
                            height: 16px;
                            content: " ";
                            background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2216%22 height%3D%2216%22 viewBox%3D%220 0 16 16%22%3E%3Cpath id%3D%22ic_plus%22 d%3D%22M26%2C18a1%2C1%2C0%2C0%2C0-1%2C1v6H19a1%2C1%2C0%2C0%2C0%2C0%2C2h6v6a1%2C1%2C0%2C0%2C0%2C2%2C0V27h6a1%2C1%2C0%2C0%2C0%2C0-2H27V19A1%2C1%2C0%2C0%2C0%2C26%2C18Z%22 transform%3D%22translate(34 -18) rotate(90)%22 fill%3D%22%23013184%22/%3E%3C/svg%3E');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            transition: initial;
                        }
                    }

                    .card__rich-text {
                        width: 100%;
                        max-width: 432px;
                        margin-bottom: initial;

                        @media (min-width: 576px) and (max-width: 991px) {
                            margin-left: 64px;
                        }
                    }
                }

                &__circle {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    background-color: #DBECF9;
                    margin-right: 20px;
                    flex: none;
                    object-fit: cover;

                    @media screen and (max-width: 575px) {
                        display: none;
                    }
                }

                &__title {
                    color: $red;
                    font-family: Caros, sans-serif;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 24px;
                    width: 100%;
                    margin-right: 32px;

                    @media (max-width: 991px) {
                        margin-right: initial;
                    }

                    @media screen and (max-width: 575px) {
                        margin-bottom: initial;
                    }

                    &-container {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        flex: 1;

                        @media screen and (max-width: 575px) {
                            margin-bottom: 8px;
                        }
                    }
                }

                &__rich-text {
                    margin-bottom: 24px;

                    * {
                        font-size: 16px;
                        line-height: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    ul, ol {
                        padding-left: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    a {
                        text-decoration: underline;
                    }
                }

                .card-body {
                    border-top: 1px solid #eaeff5;
                    margin-top: 20px;
                    padding-bottom: initial;
                    padding-left: initial;
                }

                .card-footer {
                    border: initial;
                    background-color: initial;
                    padding: 0 1.25rem;
                }

                .card-info-line {
                    display: flex;
                    margin-bottom: 27px;

                    &:first-child {
                        margin-top: 10px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &__icon {
                        margin-right: 16px;

                        img {
                            max-width: 24px;
                            max-height: 24px;
                        }
                    }

                    &__title {
                        font-family: Moderat, sans-serif;
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: bold;
                    }

                    &__description, &__colon {
                        font-family: Moderat, sans-serif;
                        font-size: 18px;
                        line-height: 24px;

                    }

                    &__colon {
                        margin: 0 5px;
                    }
                }

            }

            &:not(.collapsed) {
                .organization-accordion__title {
                    color: $red;
                }

                .card {
                    .card-left-border {
                        transform: scaleY(1);
                    }

                    .card-header .btn:after {
                        transform: rotate(90deg);
                        background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%222%22 height%3D%2216%22 viewBox%3D%220 0 2 16%22%3E%3Cpath id%3D%22ic_moins%22 d%3D%22M25%2C25H19a1%2C1%2C0%2C0%2C0%2C0%2C2h6c-.007-.025%2C1.991-.009%2C2%2C0h6a1%2C1%2C0%2C0%2C0%2C0-2H27C26.954%2C25.012%2C25%2C25.025%2C25%2C25Z%22 transform%3D%22translate(27 -18) rotate(90)%22 fill%3D%22%23013184%22/%3E%3C/svg%3E');
                    }
                }
            }
        }
    }

    &__title {
        font-family: Caros, sans-serif;
        font-size: 32px;
        font-weight: bold;
        line-height: 40px;
        margin-bottom: 30px;
        color: #003963;
    }

    &__category {
        display: flex;
        align-items: center;
        border: 1px solid #00308429;
        border-radius: 4px;
        padding: 12px 16px;

        img {
            width: 24px;
            margin-right: 5px;
        }

        span {
            &:last-child {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &__rich-text {
        margin-bottom: 64px;

        * {
            font-size: 18px;
            line-height: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul, ol {
            padding-left: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            text-decoration: underline;
        }
    }
}
