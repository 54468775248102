.toolbox-intro {
    background-color: $grey;
    padding-top: 40px;
    padding-bottom: 40px;
    .toolbox-intro-content {
        max-width: 670px;
        h1:not(:last-child) {
            margin-bottom: 32px;
        }
        h2:not(:last-child) {
            margin-bottom: 20px;
        }
        p {
            font-size: 16px;
        }
        ul {
            li {
                font-size: 16px !important;
                b {
                    font-size: 16px !important;
                }
            }
        }
    }
    @include breakpoint(lg) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
