.home-slider-two-wrapper {
    padding-top: 60px;
    /* default background-color */
    background-color: $blue-light;
    .content {
        margin: 0 auto;
        max-width: 1424px;
        padding: 0 80px;
        width: 100%;
    }
    .bottom-cover {
        position: relative;
        bottom: 0;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        height: 10vw;
        display: flex;
        align-items: flex-end;
        img {
            min-width: 1350px;
            width: 100%;
        }
    }
    .images-load {
        background-size: 100%;
        width: 100%;
        //height: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        img {
            width: 100%;
        }
    }
    .arrows-section {
        .icon-arrow {
            flex-grow: 0;
            flex-shrink: 0;
            margin-top: -100px;
            width: 40px;
            height: 40px;
            color: $white;
            font-size: 16px;
            background-color: $blue;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: none;
            position: relative;
            z-index: 5;
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: darken($blue, 8);
                transform: scale(0);
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
            &:hover, &:focus {
                color: $white;
                &:after {
                    transform: scale(1);
                }
            }
            &:active, &:active:focus {
                color: $white;
                &:after {
                    background-color: darken($blue, 12);
                    transform: scale(1);
                }
            }
        }
        .arrow-section2 {
            display: flex;
            align-items: center;
        }
    }
    .content-sider {
        color: $blue;
        .content-sider-title {
            margin-bottom: 32px;
            h1, h2, h3, h4 {
                margin-bottom: 0;
            }
        }
        .content-sider-text {
            margin-bottom: 40px;
            font-size: 16px;
            p:last-child {
                margin-bottom: 0;
            }
        }
        .bullets {
            margin: 0;
            padding: 0;
            list-style: none;
            line-height: initial;
            display: flex;
            align-items: center;
            justify-content: center;
            .bullet-item {
                height: 8px;
                width: 8px;
                background-color: $blue;
                opacity: .4;
                border-radius: 50%;
                transform: scale(.6);
                margin-right: 10px;
                transition: opacity $time-md ease, transform $time-md ease;
                &:last-child {
                    margin-right: 0;
                }
                &:hover, &:focus, &.active {
                    opacity: 1;
                    transform: scale(1);
                }
                &:active, &:active:focus {
                    opacity: 1;
                    background-color: darken($blue, 10%);
                    transform: scale(1);
                }
                &.active-after, &.active-before {
                    transform: scale(.8);
                }
            }
        }
    }
    .content-slider-image {
        flex-grow: 1;
        flex-shrink: 1;
        .content-slider-image-img {
            width: 100%;
            padding: 0 20px;
        }
    }
    .slider-btn-container {
        display: flex;
        justify-content: center;
        .slider-btn {
            margin: 0;
            transform: translateY(calc(15px - 100%));
            z-index: 2;
        }
    }
    @include breakpoint(lg) {
        position: relative;
        padding-top: 10px;
        .slider-btn-container {
            margin-bottom: 60px;
            justify-content: flex-start;
            .slider-btn {
                transform: none;
            }
        }
        .arrows-section {
            display: flex;
            .arrow-section2 {
                padding-top: 120px;
                flex-direction: column;
            }
            .icon-arrow {
                margin-top: 0;
                margin-bottom: 24px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .content-sider {
            position: relative;
            z-index: 4;
            padding-top: 70px;
            padding-bottom: 70px;
            width: 435px;
            flex-shrink: 0;
            flex-grow: 0;
            .bullets {
                justify-content: flex-start;
            }
        }
        .content-slider-image {
            position: relative;
            .content-slider-image-img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                object-fit: contain;
                object-position: center;
                padding: 0 50px;
            }
        }
        .bottom-cover {
            height: auto;
            display: block;
            position: absolute;
            img {
                min-width: 0;
            }
        }
    }
    @media (max-width: 974px) {
        .content {
            padding: 0 20px;
        }
        .slider-btn-container {
            .slider-btn {
                margin-top: 90px ;
            }
        }
       .content-sider {
           .content-sider-text {
               margin-bottom: 90px;
           }
       }
    }
}
