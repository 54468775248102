
.articles-list-four {
    .article-card {
        margin-bottom: 15px;
        a {
            display: flex;
            flex-direction: column;
            text-decoration: none;
            background-color: white;
            border-radius: 8px;
            box-shadow: 0 2px 20px $grey-shadow;
            .img-wrapper {
                padding: 24px;
                .img-container {
                    width: 120px;
                    height: 120px;
                    border-radius: 8px;
                    overflow: hidden;
                }
                .article-card-img {
                    max-width: none;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    margin: 0;
                    transition: transform $time-sm ease;
                }
            }
            .text-wrapper {
                padding: 0 24px 24px 24px;
                .text-title {
                    font-family: $font-title;
                    font-weight: 700;
                    font-size: 20px;
                    margin-bottom: 16px;
                }
                .text-text {
                    font-size: 16px;
                    margin-bottom: 0;
                }
            }
            .arrow-icon {
                display: flex;
                align-items: flex-end;
                margin-left: auto;
                .arrow-elem {
                    background-color: $blue;
                    color: $white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top-left-radius: 100%;
                    border-bottom-right-radius: 8px;
                    width: 50px;
                    height: 50px;
                    padding-top: 10px;
                    padding-left: 10px;
                    transition: background-color $time-md ease;
                }
            }
            &:hover, &:focus {
                .img-wrapper {
                    .article-card-img {
                        transform: scale(1.2);
                    }
                }
                .text-wrapper {
                    .text-title {
                        color: $red;
                    }
                }
                .arrow-icon {
                    .arrow-elem {
                        background-color: darken($blue, 8);
                    }
                }
            }
            &:active, &:active:focus {
                .img-wrapper {
                    .article-card-img {
                        transform: scale(1.25);
                    }
                }
                .text-wrapper {
                    .text-title {
                        color: darken($red, 4%);
                    }
                }
                .arrow-icon {
                    .arrow-elem {
                        background-color: darken($blue, 12);
                    }
                }
            }
        }
    }
    @include breakpoint(lg) {
        .article-card {
            a {
                flex-direction: row;
                .img-wrapper, .arrow-icon {
                    flex-grow: 0;
                    flex-shrink: 0;
                }
                .text-wrapper {
                    flex-grow: 1;
                    flex-shrink: 1;
                    padding: 24px 10px 24px 0;
                }
            }
        }
    }
}

.source-section {
    padding: 40px 20px 60px 20px;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    .source-section-item {
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid #00407129;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    h3 {
        color: $blue;
        font-weight: bold;
    }
    ul {
        padding-left: 15px;
        list-style: none;
        li {
            color: $blue;
            font-style: italic;
            margin-bottom: 15px;
            position: relative;
            padding-left: 20px;
            list-style: none;
            &::before {
                position: absolute;
                left: 0;
                top: 6px;
                flex-shrink: 0;
                flex-grow: 0;
                content: "";
                width: 8px;
                height: 8px;
                background-color: $red;
                border-radius: 50%;
                display: inline-block;
            }
            p {
                font-size: 14px;
                margin-bottom: 0;
            }
            a {
                color: $blue-link;
                text-decoration: underline !important;
                transition: color $time-sm ease;
                &:hover, &:focus {
                    color: darken($blue-link, 8%);
                }
                &:active, &:active:focus {
                    color: darken($blue-link, 12%);
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .ref-desc {
        display: flex;
        .img-wrap {
            display: flex;
            padding-right: 25px;
            img {
                height: 80px;
                width: 80px;
                object-fit: cover;
                object-position: center center;
                border-radius: 50%;
            }
        }
        .info-wrap {
            p {
                margin-bottom: 0;
                color: $blue;
            }
            .text-featured {
                margin-bottom: $spacing-xs;
            }
        }
    }
    @include breakpoint(lg) {
        padding: 60px 20px 80px 20px;
        .source-section-item {
            padding-top: 60px;
            padding-bottom: 60px;
            border-bottom: 1px dashed #00407129;
            &:last-child {
                padding-bottom: 60px;
                border-bottom: 1px dashed #00407129;
            }
        }
    }
}
