/* FONTS
===========================
Important:
- Define default font families and font styles here.
- Design titles only using .d1, .d2... Not h1, h2...
- Use EM font sizes and adjust responsive by setting body font size.
- Default body font size is 16px.
*/

$font-title: 'Caros', sans-serif;
$font-content: 'Moderat', sans-serif;
$font-fancy: 'Caros', sans-serif;

$baseFontSize : 14;

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 400;
    src: local('Caros Soft Regular'), url('../fonts/Caros/CarosSoft.woff') format('woff');
}

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 100;
    src: local('Caros Soft Thin'), url('../fonts/Caros/CarosSoftThin.woff') format('woff');
}

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 200;
    src: local('Caros Soft ExtraLight'), url('../fonts/Caros/CarosSoftExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 300;
    src: local('Caros Soft Light'), url('../fonts/Caros/CarosSoftLight.woff') format('woff');
}

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 500;
    src: local('Caros Soft Medium'), url('../fonts/Caros/CarosSoftMedium.woff') format('woff');
}

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 700;
    src: local('Caros Soft Bold'), url('../fonts/Caros/CarosSoftBold.woff') format('woff');
}

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 800;
    src: local('Caros Soft ExtraBold'), url('../fonts/Caros/CarosSoftExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 900;
    src: local('Caros Soft Heavy'), url('../fonts/Caros/CarosSoftHeavy.woff') format('woff');
}

@font-face {
    font-family: 'Caros';
    font-style: normal;
    font-weight: 950;
    src: local('Caros Soft Black'), url('../fonts/Caros/CarosSoftBlack.woff') format('woff');
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Black.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Black-Italic.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Black-Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Light-Italic.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Light-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Light.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Medium-Italic.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Medium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Bold-Italic.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Bold-Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Bold.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Thin-Italic.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Thin-Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Medium.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Regular-Italic.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Regular-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Thin.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('../fonts/Moderat/Moderat-Regular.woff2') format('woff2'),
        url('../fonts/Moderat/Moderat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html, body {
    font-family: $font-content;
    color: $blue;
    font-size: $baseFontSize - 2 * 1px;
    @include breakpoint(lg) {
      font-size: $baseFontSize * 1px;
    }
}

h1, h2, h3, h4, h5, h6, .d1, .d2, .d3, .d4, .d5, .d6, .d1-alt {
    font-family: $font-title;
    font-weight: 700;
    line-height: initial;
}

* { outline: none }

.text-uppercase { text-transform: uppercase }
.text-capitalize { text-transform: capitalize }

.text-thin { font-weight: 100 }
.text-normal { font-weight: 400 }
.text-bold { font-weight: 700 }
.text-extrabold { font-weight: 900 }

.text-fancy { font-family: $font-fancy }

.d1 {
    font-size: rem(35);
    line-height: 1.3;
    @include breakpoint(md) {
        font-size: rem(40);
    }
}

.d1-alt {
    font-size: rem(28);
    @include breakpoint(md) {
        font-size: rem(40);
    }
}

.d1-alt-md {
    font-size: rem(34);
    line-height: 1.3;
    @include breakpoint(md) {
        font-size: rem(40);
    }
}

.d2 {
    font-size: rem(26);
    @include breakpoint(md) {
        font-size: rem(32);
        line-height: 40px;
    }
}

.d3 {
    font-size: rem(26);
    @include breakpoint(md) {
        font-size: rem(24);
    }
}

.d4 {
    font-size: rem(21);
    @include breakpoint(md) {
        font-size: rem(18);
    }
}

.d5 {
    font-size: rem(18);
    line-height: 24px;
}

.text-featured {
    font-family: $font-content;
    font-size: 16px;
    font-weight: 700;
}

.text-featured-alt {
    font-family: $font-content;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 32px;
}

.title-small {
    font-size: 14px;
    font-family: $font-content;
    text-transform: uppercase;
    font-weight: 400;
    color: $blue;
    margin-bottom: 16px;
}

.cms-content {
    h1 {
        @extend .d1;
        &:last-child {
            margin-bottom: 0;
        }
    }
    h2 {
        @extend .d2;
    }
    h3 {
        @extend .d3;
    }
    h4 {
        @extend .d4;
    }
    p, li {
        font-size: rem(18);
        &:empty {
            display: none;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.font-18 > p, li, a {
        font-size: rem(18) !important;
        line-height: 24px;
        list-style: inside;
    }
    a {
        color: $blue-link;
        text-decoration: underline;
        transition: color $time-sm ease;
        &:hover, &:focus {
            color: darken($blue-link, 8%);
            text-decoration: underline;
        }
        &:active, &:active:focus {
            color: darken($blue-link, 12%);
            text-decoration: underline;
        }
    }
    blockquote {
        position: relative;
        font-size: rem(18);
        font-style: italic;
        border-top: 1px solid #00407129;
        border-bottom: 1px solid #00407129;
        padding: 20px 0 20px 50px;
        &:before {
            content: "";
            display: block;
            width: 30px;
            height: 25px;
            background-image: url("../img/ic_quote-blue.svg");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            top: 20px;
            left: 0;
        }
        &:empty {
            display: none;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    ol {
        list-style: none;
        counter-reset: item;
        padding-left: initial;
        li {
            counter-increment: item;
            margin-bottom: 5px;
            list-style: none;
        }
        li:before {
            margin-right: 10px;
            content: counter(item)".";
            color: $red;
            width: 1.2em;
            text-align: center;
            display: inline-block;
            font-weight: 500;
        }
    }
}
body.txt-dark-False {
    .cms-content a {
        color: #aff9ff;
    }
}

.to-uppercase {
    text-transform: uppercase;
}
