.home-slider-one {
    .more-news-cards-top-art {
        overflow: hidden;
        align-items: flex-start;
        display: flex;
        justify-content: center;
        height: 9vw;
        background-color: $skin-light;
        img {
            width: 1350px;
        }
    }
    .slider-one-wrapper {
        padding-top: 0;
        background: none;
        .content {
            margin: 0 auto 40px;
        }
    }
    .slider-controllers {
        .material-icons {
            background-color: $blue-light;
        }
    }
    @include breakpoint(lg) {
        position: relative;
        .more-news-cards-top-art {
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            height: auto;
            width: 100%;
            img {
                width: 100%;
            }
        }
        .slider-one-wrapper {
            padding-top: 80px;
        }
    }
}
