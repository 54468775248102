.title-text-video-backlink-block {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
    padding-top: 60px;

    @media(max-width: 1199px) {
        flex-direction: column;
    }

    &__backlink-container {
        display: block;
        text-decoration: initial;
        margin-bottom: 80px;

        @media(max-width: 1199px) {
            margin-bottom: 40px;
        }

        &:hover {
            text-decoration: initial;
        }
    }

    &__backlink-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        margin-right: 12px;
        border-radius: 50%;

        img {
            width: 17px;
            height: 13px;
        }
    }

    &__backlink-text {
        color: #013A64;
        font-family: Caros, sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
    }

    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &-container {
            width: 50%;
            max-width: 712px;
            margin-left: auto;
            padding-left: 15px;

            @media(max-width: 1199px) {
                width: 100%;;
                padding: 0 15px;
                max-width: initial;
                margin-left: initial;
            }
        }

        &-content {
            aspect-ratio: 16 / 9;
            border-radius: 17px;
            margin-bottom: 111px;
            overflow: hidden;
            position: relative;

            @media(max-width: 1199px) {
                margin-bottom: 55px;
            }
        }

        &-thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }

        &-btn {
            align-items: center;
            background-color: rgba(0, 18, 49, 0.8);
            border-radius: 50%;
            border: initial;
            display: flex;
            flex-direction: row;
            height: 66px;
            justify-content: center;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 66px;
            z-index: 1;

            img {
                width: 20px;
                height: 25px;
            }
        }
    }

    &__text {
        &-container {
            width: 50%;
            position: relative;
            @media(max-width: 1199px) {
                width: 100%;
            }
        }

        &-content {
            max-width: 712px;
            margin-right: auto;
            margin-top: 134px;
            margin-left: 64px;
            padding-right: 60px;

            @media(max-width: 1199px) {
                padding: 0 15px;
                margin: 67px 0;
                max-width: initial;
            }
        }
    }

    &__title {
        color: $white;
        font-size: 40px;
        line-height: 54px;
        font-family: Caros, sans-serif;
        margin-bottom: 38px;
    }

    &__rich-text {
        * {
            color: $white;
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__background {
        background-color: #013A64;
        border-top-left-radius: 60px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% + 250px);
        z-index: -1;
    }

}
