.iframe-block {
    iframe {
        width: 100%;
    }
}

@media (max-width: 390px) {
    .iframe-block {
        margin-bottom: 40px;
    }
}

@media (max-width: 520px) {
    .iframe-block {
        iframe {
            height: 550px;
        }
    }
}

@media (min-width: 992px) {
    .iframe-block {
        margin-bottom: 60px;
    }
}

