.section-one-wrapper {
    background-color: $skin-light;
}

.section-one-img-container {
    display: none;
    position: relative;
    z-index: 5;
    .section-one-img {
        position: absolute;
        transform: translateX(-50%);
        &:first-child {
            top: 18px;
            left: 33.33%;
        }
        &:last-child {
            top: -42px;
            left: 66.66%;
        }
    }
    @include breakpoint(sm) {
        display: block;
    }
}

.template-single-family-page {
    .section-one-img-container {
        display: none;
    }
}

.top-curve-wrapper {
    height: 80px;
    overflow: hidden;
    width: 100%;
    position: relative;
    margin-top: -1px;
    @include breakpoint(lg) {
        height: 120px;
    }
}

.top-curve {
    position: relative;
    width: 110%;
    left: -5%;
    height: 150px;
    border-radius: 50%;
    background: $blue-light;
    overflow: hidden;
    margin-bottom: -80px;
    @include breakpoint(lg) {
        height: 250px;
    }
}

.top-curve :after{
    content:'';
    position:absolute;
    top:-175px; left:150px;
    height:500px; width:300px;
    border-radius:inherit;
    background:#000;
}

.top-curve-wrapper-alt {
    @extend .top-curve-wrapper;
    @include breakpoint(lg) {
        height: 160px;
        .top-curve {
            height: 540px;
        }
    }
}

.section-one {
    @extend .banner-one;
    position: relative;
    @media screen and (min-width: 576px) {
        padding: 0 20px 80px 20px;
    }
    background-color: $blue-light;
    .content {
        background-color: $blue-light;
        .img-container {
            width: auto;
            flex-grow: 0;
            background-color: transparent;
            justify-content: center;
            display: flex;
            margin-right: 0;
            margin-bottom: 40px;
            img {
                max-width: 334px;
            }
        }
        .content-container {
            padding: 0;
            h2 {
                margin-bottom: 32px;
            }
            p {
                color: $blue;
                font-size: 16px;
                &:last-child {
                    margin-bottom: 40px;
                }
            }
            .p-title {
                @extend p;
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 16px;
            }
            .btn-blue-1 {
                @extend .btn-orange-to-orange-1;
                margin: 0;
            }
            .section-one-btn-container {
                margin: -8px;
                .btn-blue-1 {
                    margin: 8px;
                }
            }
        }
    }
    @include breakpoint(lg) {
        padding-bottom: 120px;
        .content {
            justify-content: center;
            .img-container {
                margin-bottom: 0;
                margin-right: 102px;
                img {
                    max-width: 510px;
                }
            }
            .content-container {
                max-width: 404px;
                margin-left: 0;
                &.reset {
                    max-width: initial;
                    margin-left: initial;
                }
                &.offset-lg-1 {
                    margin-left: 8.333333%;
                }
            }
        }
    }
}

.section-one-white {
    .top-curve-wrapper {
        .top-curve {
            background: $white;
        }
    }
    .section-one {
        background-color: $white;
        .content {
            background-color: $white;
        }
    }
}

.big-topic-bottom-banner {
    margin-top: -55px;
    position: relative;
    z-index: 1;
    .section-one-wrapper {
        background: none;
    }
    @include breakpoint(xl) {
        margin-top: -35px;
    }
}
