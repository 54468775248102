.subject-card-topic-article-wrapper {
    background-color: $grey;
    a {
        text-decoration:  none;
    }
    .title-section {
        text-align: center;
        h2 {
            margin-bottom: 40px;
        }
    }
    .subject-card-topic-caroussel-wrapper {
        padding: 0;
    }
    .subject-card-topic-caroussel {
        margin-bottom: 40px;
        .subject-card-topic-caroussel-item {
            width: 100%;
            padding-left: 4px;
            padding-right: 4px;
        }
    }
    .slider-one {
        background-color: $blue-light;
        padding-bottom: 40px;
    }
    .card {
        padding: 0;
        height: 100%;
        border-radius: 8px;
        border: none;
        box-shadow: 0 2px 20px $grey-shadow;
        overflow: hidden;
        transition: background-size $time-lg ease;
        .card-header {
            flex-grow: 1;
            flex-shrink: 1;
            min-height: 230px;
            padding: 32px 32px 38px 32px;
            background-color: $white;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .bg-cover {
                background-color: $grey-light;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                left: calc(50% - 25px);
                transition: all $time-sm ease-in;
            }
            h3 {
                text-align: left;
                color: $blue;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 32px;
                z-index: 1;
            }
            .header-footer {
                display: flex;
                align-items: center;
                z-index: 5;
                .advices-numb {
                    color: $blue;
                    font-size: 16px;
                    margin-left: 15px;
                }
            }
        }
        .card-body {
            flex-grow: 0;
            flex-shrink: 0;
            background-size: 100%;
            background-position: center;
            height: 150px;
            padding: 0;
            justify-content: flex-end;
            display: flex;
            align-items: flex-end;
            z-index: 1;
            transition: background-size $time-sm ease;
            .arrow-elem {
                background-color: $blue;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-left-radius: 100%;
                width: 50px;
                height: 50px;
                padding-left: 10px;
                padding-top: 10px;
                transition: background-color $time-sm ease;
            }
        }
        &.card-last {
            border-color: $blue;
            display: flex;
            padding: 25px;
            justify-content: center;
            .title {
                color: $blue;
                font-size: 12pt;
                font-weight: 700;
                z-index: 1;
                text-align: center;
                padding-bottom: 10px;
            }
        }
        &:hover, &:focus {
            .card-header {
                .bg-cover {
                    width: 800px;
                    height: 800px;
                    left: calc(50% - 400px);
                }
            }
            .card-body {
                background-size: 120%;
                .arrow-elem {
                   background-color: darken($blue, 8);
                }
            }
        }
        &:active, &:active:focus {
            .card-header {
                .bg-cover {
                    width: 800px;
                    height: 800px;
                    left: calc(50% - 400px);
                    background-color: darken($grey-light, 3%);
                }
            }
            .card-body {
                background-size: 125%;
                .arrow-elem {
                    background-color: darken($blue, 12);
                }
            }
        }
    }
    @include breakpoint(md) {
        .slider-one {
            padding-bottom: 55px;
        }
        .subject-card-topic-caroussel-wrapper {
            padding: 0 20px;
        }
        .subject-card-topic-caroussel {
            margin: -12px;
            display: flex;
            flex-wrap: wrap;
            .subject-card-topic-caroussel-item {
                flex-grow: 0;
                flex-shrink: 0;
                padding: 12px;
                width: 50%;
            }
        }
        .subject-card-view-all {
            display: none;
        }
    }
    @include breakpoint(lg) {
        .subject-card-topic-caroussel {
            .subject-card-topic-caroussel-item {
                width: calc(100% / 3);
            }
        }
        .card {
            .card-header {
                min-height: 230px;
                h3 {
                    font-size: 24px;
                }
            }
        }
    }
}

.subject-card-topic-article-wrapper-alt {
    .slider-one {
        padding-bottom: 80px;
    }
    @include breakpoint(md) {
        .slider-one {
            padding-bottom: 120px;
            .title-section {
                transform: translateY(-50px);
            }
        }
    }
}
