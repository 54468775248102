/* HOME */
header.main-header {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    //overflow: hidden;

    .top-nav.fixed {
        position: fixed;
        width: 100%;
        z-index: 1000;
        top: 0;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }

    .topic-article-header-bottom {
        background-color: #fafafa;
    }
    .header-content {
        .text-content {
            display: flex;
            position: relative;
            padding: 40px 0 45px 0;
            height: auto;
            width: 100%;
            align-items: center;
            z-index: 1;
            .text-content-body {
                max-width: 100%;
                color: $blue-green;
                .header-content-title {
                    max-width: 490px;
                    margin-bottom: 32px;
                    h1, h2, h3, p {
                        @extend .d1;
                        line-height: 1.15;
                        margin-bottom: 0;
                    }
                    .title-bright {
                        color: $white;
                    }
                    i {
                        font-style: initial;
                        color: $red;
                    }
                }
                .header-content-text {
                    font-size: 16px;
                    margin-bottom: 46px;
                }
                span.material-icons {
                    position: relative;
                    z-index: 2;
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    background-color: $skin;
                    color: $blue;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 22px;
                    font-weight: bold;
                    cursor: pointer;
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-color: $skin-dark;
                        border-radius: 50%;
                        transform: scale(0);
                        transform-origin: center center;
                        transition: transform $time-sm ease, background-color $time-sm ease;
                    }
                    &:hover, &:focus {
                        &:after {
                            transform: scale(1);
                        }
                    }
                    &:active, &:active:focus {
                        &:after {
                            background-color: darken($skin-dark, 6%);
                        }
                    }
                }
            }
        }
        .text-content-bg {
            background-color: $white; /* fallback color */
            position: absolute;
            top: 0;
            bottom: 0;
            left: -80px;
            right: -20px;
            z-index: -1;
        }
        .img-content {
            margin-right: auto;
            display: inline-block;
            position: absolute;
            right: 0;
        }
    }
    .top-nav-wrapper {
        .logo-bg-bright {
            display: initial;
        }
        .logo-bg-dark {
            display: none;
        }
        .logo-bg-turquoise {
            display: none;
        }
    }
    .main-header-mob-img {
        margin-top: 0;
        img {
            width: 100%;
            max-width: 100%;
        }
    }
    @include breakpoint(lg) {
        .container-custom {
            max-width: 1544px;
            padding-left: 80px;
            padding-right: 80px;
        }
        .header-content {
            .text-content {
                border-top-right-radius: 160px;
                padding: 0;
                min-height: 460px;
                width: 50%;
                .text-content-body {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 70px 110px 70px 0;
                    height: 100%;
                    width: 100%;
                    .header-content-title {
                        margin-bottom: 40px;
                    }
                    .header-content-text {
                        width: 436px;
                        max-width: 100%;
                        margin-bottom: 0;
                    }
                    span.material-icons {
                        position: absolute;
                        bottom: 20px;
                    }
                }
            }
            .text-content-bg {
                border-top-right-radius: 160px;
                right: 0;
            }
        }
        .main-header-mob-img {
            display: none;
        }
    }
    @media (min-width: 1424px) {
        .header-content {
            .text-content-bg {
                left: calc((-100vw + 100%)/ 2 + 326px);
            }
        }
    }
}

header.main-header.header-dark {
    .main-header-mob-img {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $blue;
            opacity: .6;
        }
    }
    .top-nav-mob {
        border-bottom: 1px solid #ffffff1a;
        transition: background-color $time-md ease;
    }
    .top-nav-wrapper {
        background-color: $gray-dark2;
        border-bottom: 1px solid #ffffff1a;
        .logo-bg-bright {
            display: none;
        }
        .logo-bg-dark {
            display: initial;
        }
        .logo-bg-turquoise {
            display: none;
        }
        .top-stripe {
            border-bottom: 1px solid #ffffff1a;
            .content {
                a {
                    color: white;
                }
                button {
                    color: white;
                    background-color: #ffffff29;
                    border: 1px solid #ffffff67;
                    &:hover, &:focus {
                        color: $blue;
                        background-color: $white;
                        border: 1px solid $white;
                    }
                    &:active, &:active:focus {
                        color: darken($blue, 8);
                        background-color: $grey-hover;
                        border: 1px solid $grey-hover;
                    }
                }
            }
        }
        .top-nav ul {
            .link-nav {
                color: white;
            }
        }
    }
    .header-content {
        .text-content {
            background-color: $gray-dark2;
            .text-content-body {
                p, .p1, h2 , .episode-title > h1 {
                    color: white;
                }
            }
        }
    }
    .top-link {
        color: $white;
        .top-link-icon {
            color: $blue;
        }
    }
    @media (max-width: 991px) {
        .top-nav-mob {
            .menu-burger {
                border: 1px solid $white;
                .material-icons {
                    color: $white;
                    text-shadow: none;
                }
            }
        }
    }
}

header.main-header.header-turquoise {
    .main-header-mob-img {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $blue;
            opacity: .6;
        }
    }
    .top-nav-mob {
        border-bottom: 1px solid #ffffff1a;
        transition: background-color $time-md ease;
        &.top-nav-mob-top {
            .logo-bg-bright {
                display: none;
            }
            .logo-bg-dark {
                display: initial;
            }
            .logo-bg-turquoise {
                display: none;
            }
        }
    }
    .top-nav-wrapper {
        background-color: $green;
        border-bottom: 1px solid #ffffff1a;
        .logo-bg-bright {
            display: none;
        }
        .logo-bg-dark {
            display: none;
        }
        .logo-bg-turquoise {
            display: initial;
        }
        .top-stripe {
            border-bottom: 1px solid #ffffff1a;
            .content {
                a {
                    color: white;
                }
                button {
                    color: white;
                    background-color: #ffffff29;
                    border: 1px solid #ffffff67;
                    &:hover, &:focus {
                        color: $blue;
                        background-color: $white;
                        border: 1px solid $white;
                    }
                    &:active, &:active:focus {
                        color: darken($blue, 8);
                        background-color: $grey-hover;
                        border: 1px solid $grey-hover;
                    }
                }
            }
        }
        .top-nav ul {
            .link-nav {
                color: white;
            }
        }
    }
    .header-content {
        .text-content {
            background-color: $green;
            .text-content-body {
                p, .p1, h2 {
                    color: $green-dark;
                }
            }
        }
    }
    .top-link {
        color: $green-dark;
        .top-link-icon {
            color: $blue;
        }
        &:hover, &:focus {
            color: $red;
        }
        &:active, &:active:focus {
            color: darken($red, 4%);
        }
    }
    @media (max-width: 991px) {
        .top-nav-mob {
            .menu-burger {
                border: 1px solid $black-light;
                transition: border $time-md ease;
                .material-icons {
                    color: $black-light;
                    transition: color $time-md ease;
                    text-shadow: none;
                }
            }
            &.top-nav-mob-top {
                .menu-burger {
                    border: 1px solid $white;
                    .material-icons {
                        color: $white;
                        text-shadow: none;
                    }
                }
            }
        }
    }
    @include breakpoint(lg) {
        .top-nav-wrapper {
            .top-stripe {
                .content {
                    a {
                        color: $black-light;
                    }
                    button {
                        color: $black-light;
                        background-color: #00363829;
                        border: 1px solid #00363867;
                        &:hover, &:focus {
                            color: $blue;
                            background-color: $white;
                            border: 1px solid $white;
                        }
                        &:active, &:active:focus {
                            color: darken($blue, 8);
                            background-color: $grey-hover;
                            border: 1px solid $grey-hover;
                        }
                    }
                }
            }
            .top-nav ul {
                .link-nav {
                    color: $black-light;
                }
                .btn-blue {
                    .link-nav {
                        color: $white;
                    }
                }
            }
        }
        .header-content {
            .text-content {
                .text-content-body {
                    p, .p1, h2 {
                        color: $black-light;
                    }
                }
            }
        }
        .top-link {
            color: $black-light;
        }
    }
}

header.main-header.header-transparent-blue {
    &:after {
        position: absolute;
        z-index: -1;
    }
    .top-nav-wrapper.top-nav-transparent-blue {
        .top-stripe {
            border-bottom: 1px solid #ffffff1a;
            .content {
                a {
                    color: white;
                    &:hover, &:focus {
                        background-color: #ffffff29;
                    }
                    &:active, &:active:focus {
                        background-color: #ffffff69;
                    }
                }
                button {
                    color: white;
                    border: 1px solid #ffffff67;
                    background-color: #ffffff29;
                    &:hover, &:focus {
                        background-color: $white;
                        color: $blue;
                    }
                    &:active, &:active:focus {
                        background-color: $blue;
                        color: $white;
                    }
                }
            }
        }
        .top-nav {
            background-color: transparent !important;

            &.fixed {
                background-color: $white !important;
            }

            ul {
                .link-nav {
                    color: white;
                }
            }
        }
    }
    .main-header-mob-img {
        background-color: $blue !important;
        img {
            opacity: .4;
        }
    }
    .top-nav-mob {
        transition: background-color $time-md ease;
        .menu-burger {
            transition: border $time-md ease;
            .material-icons {
                transition: color $time-md ease;
                text-shadow: none;
            }
        }
        &.top-nav-mob-top {
            .logo-bg-bright {
                display: none;
            }
            .logo-bg-dark {
                display: initial;
            }
            .logo-bg-turquoise {
                display: none;
            }
        }
    }
    @media (max-width: 991px) {
        .top-nav-mob {
            &.top-nav-mob-top {
                .menu-burger {
                    border: 1px solid $white;
                    .material-icons {
                        color: $white;
                    }
                }
            }
        }
    }
    @include breakpoint(lg) {
        .top-nav-wrapper.top-nav-transparent-blue {
            .logo-bg-bright {
                display: none;
            }
            .logo-bg-dark {
                display: initial;
            }
            .logo-bg-turquoise {
                display: none;
            }
        }
    }
}

@media (max-width: 991px) {
    header.main-header {
        background-image: none !important;
        .main-header-mob-img {
            img {
                min-height: 50vh;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
}

.subheader {
    @extend .main-header;
    .subheader-main {
        display: flex;
        flex-direction: column;
        .subheader-main-content {
            flex-grow: 0;
            flex-shrink: 0;
            position: relative;
        }
        &.video-holder {
            background-color: black;
            .subheader-main-content {
                .text-content-bg {
                    border-bottom-right-radius: 260px;
                }
            }
        }
        .subheader-main-img-container {
            flex-grow: 1;
            flex-shrink: 1;
            min-height: 150px;
            position: relative;
            .subheader-main-img {
                position: absolute;
                top: 0;
                left: -20px;
                right: -20px;
                bottom: 0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                .subheader-main-img-video {
                    position: absolute;
                    padding-bottom: 0;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: $black;
                }
            }
        }
    }
    .top-link {
        display: flex;
        align-items: center;
        font-family: $font-title;
        font-weight: 700;
        font-size: rem(18);
        color: $blue;
        margin-bottom: 35px;
        .material-icons {
            position: relative;
            z-index: 2;
            width: 36px !important;
            height: 36px !important;
            background: none !important;
            font-size: 20px;
            margin-top: 0 !important;
            &:after {
                display: none;
            }
        }
        .top-link-icon {
            position: relative;
            width: 36px !important;
            height: 36px !important;
            background-color: $skin;
            border-radius: 50%;
            margin-right: 15px;
            &:after {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: $skin-dark;
                border-radius: 50%;
                transform: scale(0);
                transform-origin: center center;
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
            span.material-icons {
                position: relative !important;
                bottom: initial !important;
                right: initial !important;
            }
        }
        &:hover, &:focus {
            color: $blue;
            text-decoration: none !important;
            .top-link-icon {
                &:after {
                    transform: scale(1);
                }
            }
        }
        &:active, &:focus:active {
            .top-link-icon {
                &:after {
                    background-color: darken($skin-dark, 6%);
                }
            }
        }
    }
    .top-stripe {
        border-bottom: 1px solid rgba(0, 0, 0, .08) !important;
    }
    .text-content {
        height: auto !important;
        margin-top: 0 !important;
        padding: 40px 0 !important;
        background: none !important;
    }
    .text-content-body {
        color: $blue;
        padding: 0 !important;
        .text-content-title {
            margin-bottom: $spacing-sm;
            &:last-child {
                margin-bottom: 0;
            }
            h1, h2, h3, h4, p:last-child {
                margin-bottom: 0;
            }
        }
        .text-content-text {
            font-size: rem(16);
            p:last-child {
                margin-bottom: 0;
            }
            &.txt-bright {
                color: $white;
                opacity: 0.8;
            }
        }
    }

    @include breakpoint(md) {
        display: flex;
        flex-direction: column;
        height: auto !important;
        min-height: calc(100vh - 165px);
        background-color: $grey;
        .top-nav-mob {
            z-index: 21 !important;
        }
        .top-nav-wrapper {
            border-bottom: 1px solid rgba(0, 0, 0, .08);
        }
        .subheader-main {
            flex-direction: row;
            flex-grow: 1;
            min-height: 0;
            .subheader-main-content {
                display: flex;
                z-index: 2;
                padding-right: 80px;
                width: calc(50% - 30px);
                border-bottom-right-radius: 120px;
                .text-content-bg {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 120px;
                }
            }
            .subheader-main-img-container {
                position: relative;
                z-index: 1;
                width: calc(50vw + 45px);
                margin-left: -10px;
                margin-bottom: 90px;
                flex-grow: 0;
                flex-shrink: 0;
                .subheader-main-img {
                    left: 0;
                }
            }
        }
        .text-content {
            width: 100% !important;
            max-width: 486px;
            display: initial !important;
            padding-top: 60px !important;
            padding-bottom: 60px !important;
        }
        .text-content-body {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            width: 100% !important;
            max-width: 700px !important;
            .cms-content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }
            .btn-orange-to-orange-1 {
                .material-icons {
                    border-radius: initial !important;
                    width: initial !important;
                    height: initial !important;
                    background: none !important;
                    color: $white !important;
                    display: initial !important;
                    margin-top: 0 !important;
                }
            }
        }
        &.subheader_full_image {
            .subheader-main {
                .subheader-main-img-container {
                    width: calc(50vw + 148px);
                    margin-bottom: 0;
                    margin-left: -118px;
                }
            }
        }
    }

    @include breakpoint(lg) {
        .text-content {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    @media (min-width: 1424px) {
        .subheader-main {
            .subheader-main-img-container {
                .subheader-main-img {
                    //right: calc((-100vw + 100% + 668px) / 2);
                }
            }
        }
        &.subheader_full_image {
            .subheader-main {
                .subheader-main-img-container {
                    width: calc(50vw + 154px);
                    margin-left: -118px;
                }
            }
        }
    }
}

body.txt-dark-False {
    header.header-turquoise.subheader {
        .top-link {
            color: white;
        }
        .top-nav-wrapper {
            .top-nav ul .link-nav {
                color: white;
            }
            .top-stripe  {
                .content a {
                    color: white;
                }
                .content button {
                    color: white;
                    &:hover {
                        color: black;
                    }
                }
            }
        }
    }
    header.subheader .header-content .text-content .text-content-body {
        color: white;
    }
}

.to-be-referenced-page, .repertoire-page {
    .subheader {
        min-height: initial;
        overflow: hidden;

        .header-content .text-content {
            min-height: initial;

            h1 {
                font-size: 42px;
                line-height: 54px;
                color: $blue-repertoire;
                @media (max-width: 575px) {
                    font-size: 20px;
                    text-align: center;
                }
            }

            .text-content-text * {
                color: $blue-repertoire;
                font-family: Moderat, sans-serif;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                @media (max-width: 575px) {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }

        .subheader-main {
            flex-grow: initial;
            @media (max-width: 991px) {
                flex-direction: column;
            }

            .subheader-main-content {
                width: 60.5%;
                @media screen and (max-width: 991px) {
                    width: 100%;
                    order: 2;
                    .text-content-bg {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: initial;
                        position: absolute;
                        top: 0;
                        left: -100%;
                    }
                }
                @media screen and (min-width: 768px) {
                    .text-content-bg {
                        position: absolute;
                        top: 0;
                        left: -100%;
                    }
                }
            }

            .subheader-main-img-container {
                @media screen and (max-width: 991px) {
                    order: 1;
                    aspect-ratio: 16/9;
                    top: 0;
                    left: 0;
                    margin-left: -20px;
                    margin-right: -20px;
                    width: initial;

                    .subheader-main-img {
                        width: 100%;
                        height: 100%;
                        left: 0;
                        right: 0;
                    }
                }
            }

            &.container-custom {
                padding-left: 15px;
                padding-right: 15px;
                @include breakpoint(lg) {
                    max-width: 1414px;
                    padding-left: 15px;
                    margin-left: auto;
                }
            }
        }
        .subheader-main .subheader-main-img-container {
            margin-bottom: initial;
            min-height: 296px;
        }
        @include breakpoint(md) {
            .text-content {
                max-width: initial;
            }
            .subheader-main .subheader-main-img-container {
                margin-left: -30px;
            }
        }
        @include breakpoint(lg) {
            .subheader-main .subheader-main-img-container {
                margin-bottom: 45px;
            }
        }
    }
}

.repertoire-page {
    &.search-results {
        header.subheader {
           background-color: initial;
           overflow: initial;

            .subheader-main {
                .header-content {
                    &.subheader-main-content {
                        width: 100%;
                        padding-right: initial;
                    }
            }
               .text-content {
                     .text-content-body {
                        display: block;
                         position: relative;
                         text-align: center;
                         min-width: 100%;
                         @media(max-width: 575px) {
                             display: flex;
                             justify-content: flex-start;
                             align-items: center;
                         }

                         .text-content-title {
                             margin-bottom: initial;
                         }
                         .top-link {
                             color: #034374;
                             position: absolute;
                             left: 0;
                             margin-bottom: 0;
                             top: 50%;
                             transform: translateY(-50%);
                             @media(max-width: 575px) {
                                 position: static;
                                 transform: initial;
                             }
                             @media(min-width: 992px) and (max-width: 1199px) {
                                 position: static;
                             }

                             &-text {
                                 @media(max-width: 991px) {
                                     display: none;
                                 }
                             }

                             .arrow-icon {
                                 .material-icons {
                                     color: #034374;
                                 }
                             }
                         }
                     }
               }
            }

        }

    }
    .header-repertoire-organization {
          @include breakpoint(lg) {
              &.subheader {
                  .subheader-main {
                      .subheader-main-content {
                          width: 57.5%;
                      }
                  }
              }
          }
        @include breakpoint(md) {
                .subheader-main .subheader-main-img-container {
                    margin-left: -45px;
                    padding-left: 24px;
                }
        }
    }
}
