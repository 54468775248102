.template-single-episode-page {
    background-color: $gray-dark2;
    .subheader {
        background-color: $gray-dark2;
    }
}

.tdp-tabs-display {
    background-color: $gray-dark2;
    color: $white;
    padding-top: 60px;
    padding-bottom: 15px;
    .tdp-tabs-intro-text {
        font-size: rem(18);
        margin-bottom: 40px;
        p:last-child {
            margin-bottom: 0;
        }
    }
    .tab-content {
        //padding: 50px 0;
    }
    .nav-tabs {
        border-bottom: 1px solid #fff3;
        margin-bottom: 32px;
    }
    .nav-item {
        .nav-link {
            padding: 24px;
            border-radius: 0;
            color: white;
            border: 0;
            .nav-link-number {
                font-size: 12px;
                margin-bottom: 8px;
            }
            .nav-link-title {
                font-size: 20px;
            }
            &:hover, &:focus {
                border: 0;
                background-color: #ffffff14;
            }
            &:active, &:active:focus {
                border: 0;
                background-color: #ffffff24;
            }
            &.active {
                background-color: #e6472a1f;
                border: 0;
                color: $red;
                cursor: default;
                &:hover, &:focus {
                    background-color: #e6472a1f;
                }
                &:active, &:active:focus {
                    background-color: #e6472a1f;
                }
            }
        }
    }
    h1, h2, h3, h4, p {
        color: white;
    }
    .person-unit-container {
        margin-bottom: -32px;
    }
    .person-unit {
        text-align: center;
        margin-bottom: 32px;
        .person-unit-image {
            width: 60px;
            height: 60px;
            object-fit: cover;
            object-position: center center;
            border-radius: 50%;
            margin-bottom: 20px;
        }
        .person-unit-image-default {
            background-color: #ffffff29;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            .person-unit-image {
                width: 40px;
                height: 40px;
                margin-bottom: 0;
            }
        }
        .person-unit-name {
            font-size: 20px;
            font-weight: 700;
            color: $white;
            margin-bottom: 8px;
        }
        .person-unit-desc {
            font-size: 16px;
            margin-bottom: 0;
            color: #fff9;
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
        padding-bottom: 0;
        .tdp-tabs-intro-text {
            margin-bottom: 60px;
        }
    }
}
.tdp-tabs-display-bottom {
    @extend .wwa-header-bottom;
    background-color: #141414 !important;
    #curve-4 {
        display: none;
    }
}
.tool-detail-podcast-more-episodes {
    .more-episodes2 {
        background-color: #141414;
    }
}
.tool-detail-podcast-section-last {
    .tool-detail-families-game--section-eight-bottom {
        background: #141414;
        padding-top: 100px;
    }
}
