.about-team-team-list {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $grey;
}

.member-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: normal;
    text-decoration: none;
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 20px;
    box-shadow: 0 2px 20px $grey-shadow;
    border-left: 8px solid $white;
    &:last-child {
        margin-bottom: 0;
    }
    .member-card-start {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .icon {
            width: 35px;
            height: 35px;
            background-size: cover;
            background-position: center center;
            border-radius: 50%;
            background-color: #cccccc;
            margin-right: 10px;
            flex-shrink: 0;
        }
        .member-card-name {
            font-family: $font-title;
            font-size: 16px;
            font-weight: 500;
            color: $blue;
            margin-bottom: 0;
        }
    }
    .member-card-center {
        display: flex;
        align-items: center;
        padding-left: 45px;
        .member-card-title {
            font-size: 16px;
            color: $blue;
            margin-bottom: 0;
        }
    }
    .member-card-end {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        .icon-section-icon {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $blue;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            .icon-section-svg {
                width: 18px;
            }
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: $red;
                transform: scale(0);
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
        }
    }
    &:hover, &:focus {
        border-color: $skin;
        .member-card-start {
            .member-card-name {
                color: $red;
            }
        }
        .member-card-end {
            .icon-section-icon {
                &:after {
                    transform: scale(1);
                }
            }
        }
    }
    &:active, &:active:focus {
        border-color: darken($skin, 4%);
        .member-card-start {
            .member-card-name {
                color: darken($red, 4%);
            }
        }
        .member-card-end {
            .icon-section-icon {
                &:after {
                    background-color: darken($red, 4%);
                    transform: scale(1.1);
                }
            }
        }
    }
}
@include breakpoint(md) {
    .member-card {
        flex-direction: row;
        align-items: center;
        .member-card-start {
            flex: 2;
            margin-bottom: 0;
            .member-card-name {
                padding-right: 32px;
            }
            .icon {
                width: 45px;
                height: 45px;
            }
        }
        .member-card-center {
            flex: 2;
            padding-left: 0;
        }
        .member-card-end {
            flex: 1;
            margin-left: 0;
            .icon-section-icon {
                width: 54px;
                height: 54px;
                .icon-section-svg {
                    width: 22px;
                }
            }
        }
    }
}

@include breakpoint(lg) {
    .member-card {
        padding-left: 32px;
        padding-right: 32px;
        .member-card-start {
            .icon {
                margin-right: 20px;
            }
        }
    }
}
