.news-article-content-wrapper {
    &.gray {
        background-color: $grey;
        padding-bottom: 0;
    }
    .news-article-content-container {
        width: 100%;
        max-width: 830px;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        .news-article-content {
            padding-left: 20px;
            padding-right: 20px;
            p:empty {
                display: none;
            }
        }
        .news-article-block, .banner-info {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .info-bar-top {
        display: flex;
        border-top: 1px dashed #e1e7ef;
        padding-top: 25px;
        margin-bottom: 32px;
        .col-left {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $red;
            margin-right: 30px;
            .icon-custom {
                background-color: #f7ebe9;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 10px 0 0;
            }
        }
        .col-right {
            margin-left: auto;
            .cat-tag {
                padding: 5px 12px;
                text-decoration: none;
                font-size: 14px;
                border-radius: 4px;
                background-color: #f5f7fa;
                border: 1px solid $grey-alpha;
                margin: 2px 0;
                display: inline-block;
                transition: border-color $time-sm ease, background-color $time-sm ease;
                &:hover, &:focus {
                    border-color: $blue;
                }
                &:active, &:active:focus {
                    border-color: $blue;
                    background-color: darken(#f5f7fa, 4%);
                }
            }
        }
    }
    .article-img {
        padding-bottom: 40px;
        background-size: cover;
        background-position: center 139px;
        background-repeat: no-repeat;
        img {
            width: 100%;
            border-radius: 8px;
        }
    }
    .li-link {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        align-items: center;
        font-size: 16px;
        text-decoration: none !important;
        color: $blue-link;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        .arrow-circle-icon {
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f7ebe9;
            padding: 5px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 12px;
            position: relative;
            z-index: 2;
            .arrow-circle-icon-svg {
                .arrow-circle-icon-path {
                    fill: $red;
                    transition: fill $time-sm ease;
                }
            }
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                background-color: $red;
                border-radius: 50%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transform: scale(0);
                transition: transform $time-sm ease, background-color $time-sm ease;
            }
        }
        &:hover, &:focus {
            color: $red;
            .arrow-circle-icon {
                .arrow-circle-icon-svg {
                    .arrow-circle-icon-path {
                        fill: $white;
                    }
                }
                &:after {
                    transform: scale(1);
                }
            }
        }
        &:active, &:active:focus {
            color: darken($red, 6%);
            .arrow-circle-icon {
                .arrow-circle-icon-svg {
                    .arrow-circle-icon-path {
                        fill: $white;
                    }
                }
                &:after {
                    transform: scale(1);
                    background-color: darken($red, 6%);
                }
            }
        }
    }
    .news-article-content-intro {
        font-size: 20px;
        margin-bottom: 40px;
    }
    &.news-article-content-wrapper-empty {
        height: 60px;
    }
    @include breakpoint(lg) {
        .news-article-content-container {
            .news-article-content {
                padding-left: 80px;
                padding-right: 80px;
            }
        }
        .article-img {
            padding-bottom: 80px;
        }
        .news-article-content-intro {
            margin-bottom: 60px;
        }
        &.news-article-content-wrapper-empty {
            height: 80px;
        }
    }
}

.news-article-header {
    padding-top: 40px;
    .news-article-header-title {
        margin-bottom: 32px;
        word-break: break-word;
        h1:last-child,
        h2:last-child,
        h3:last-child,
        p:last-child {
            margin-bottom: 0;
            word-break: break-word;
        }
    }
    .news-article-header-intro {
        margin-bottom: 32px;
        font-size: 20px;
        font-weight: 500;
        h1:last-child,
        h2:last-child,
        h3:last-child,
        p:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(lg) {
        padding-top: 80px;
    }
}

.news-article-block {
    margin-bottom: 40px;
    .news-article-block-title {
        margin-bottom: 32px;
    }
    .news-article-block-text {
        font-size: 20px;
        margin-bottom: 32px;
        h1 {
            @extend .d1;
        }
        h2 {
            @extend .d2;
        }
        h3 {
            @extend .d3;
        }
        h4 {
            font-size: rem(20);
        }
        h1, h2, h3, h4, ul {
            margin-bottom: 32px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        ul {
            margin-bottom: 24px;
            padding-left: 0;
            list-style: none;
            li {
                position: relative;
                margin-bottom: 16px;
                padding-left: 20px;
                list-style: none;
                &:before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $red;
                    position: absolute;
                    top: 11px;
                    left: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .news-article-block-image {
        width: 100%;
        border-radius: 8px;
    }
    .news-article-block-list {
        margin-bottom: 24px;
        padding-left: 0;
        list-style: none;
        font-size: 20px;
        li {
            position: relative;
            margin-bottom: 16px;
            padding-left: 20px;
            list-style: none;
            &:before {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $red;
                position: absolute;
                top: 9px;
                left: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .news-article-btn-container {
        margin: -8px;
        display: flex;
        flex-wrap: wrap;
        .news-article-btn {
            margin: 8px;
        }
    }
    a:not([class^="btn"]):not(.li-link) {
        color: $blue-link;
        position: relative;
        text-decoration: underline !important;
        transition: color $time-sm ease;
        &:hover, &:focus {
            color: darken($blue-link, 8%);
        }
        &:active, &:active:focus {
            color: darken($blue-link, 12%);
        }
    }
    a[class^="btn"] {
        margin: 0 5px 5px 0;
    }
    @include breakpoint(lg) {
        margin-bottom: 60px;
    }
}

.news-article-content {
    .advice-container-block {
        @extend .news-article-block;
        &.cms-content {
            h2 {
                &:not(:last-child) {
                    margin-bottom: 40px;
                }
            }
            h3, h4 {
                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
            ul {
                margin-bottom: 24px;
                padding-left: 0;
                list-style: none;
                li {
                    position: relative;
                    margin-bottom: 16px;
                    padding-left: 20px;
                    list-style: none;
                    &:before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $red;
                        position: absolute;
                        top: 11px;
                        left: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
