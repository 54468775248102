.flex-title-text-image {
    padding-bottom: 80px;
    padding-top: 80px;
    .border-radius-8px {
        border-radius: 8px;
    }
    .font-size-16px {
        font-size: 16px;
        p {
            font-size: 16px;
            a {
                font-weight: bolder;
                font-size: 16px!important;
            }

        }
        &.text-white {
            a {
                color: $twitter-color;
            }
            a:hover {
                color: #3093dc;
            }
        }
    }
}
