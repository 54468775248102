.pdf-list-container {
    background-color: $grey;
    padding: 60px 5px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        width: 110%;
        top: 215px;
        left: -5%;
        height: 150px;
        border-radius: 50%;
        background: $blue-light;
        overflow: hidden;
    }
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        top: calc(215px + 75px);
        left: 0;
        height: calc(100% - (215px + 75px));
        background: $blue-light;
    }
    @include breakpoint(lg) {
        padding: 90px 5px;
    }
}

.pdf-list {
    padding: 10px 20px;
    border-radius: 0 0 8px 8px;
    width: 100%;
    background-color: $white;
    box-shadow: 0 2px 20px $grey-shadow;
    list-style: none;
    margin-bottom: 0;
    .pdf-list-item {
        padding: 30px 5px 15px 5px;
        border-bottom: 1px solid $blue-grey;
        .pdf-list-content {
            margin-bottom: 24px;
        }
        .pdf-list-img {
            width: 100%;
            border-radius: 24px;
            margin-bottom: 24px;
        }
        .pdf-list-title {
            font-family: $font-content;
            font-size: 14px;
            font-weight: 500;
            color: $red;
            margin-bottom: 15px;
        }
        .pdf-list-text {
            font-family: $font-title;
            font-size: 16px;
            font-weight: 500;
            color: $blue;
            p {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .pdf-list-btn {
            margin: 0;
        }
        &:last-child {
            border-bottom: none;
        }
    }
    @include breakpoint(md) {
        .pdf-list-item {
            display: flex;
            align-items: center;
            .pdf-list-content {
                flex-shrink: 1;
                flex-grow: 1;
                margin-bottom: 0;
                margin-right: 32px;
            }
            .pdf-list-img {
                flex-shrink: 0;
                flex-grow: 0;
                height: 100px;
                width: 120px;
                object-fit: cover;
                object-position: center center;
                margin-bottom: 0;
                margin-right: 24px;
            }
            .pdf-list-btn {
                align-self: flex-end;
                flex-shrink: 0;
                flex-grow: 0;
            }
        }
    }
    @include breakpoint(lg) {
        padding: 10px 38px;
        .pdf-list-item {
            .pdf-list-content {
                margin-right: 42px;
            }
            .pdf-list-img {
                height: 150px;
                width: 170px;
                margin-right: 34px;
            }
        }
    }
}
